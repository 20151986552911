<script>import { createEventDispatcher } from "svelte";
import Select from "svelte-select";
export let options = [];
export let selection = [];
export let canCreate = false;
export let id = Math.random.toString();
const dispatch = createEventDispatcher();
const handleSelect = (e) => {
    selection = (e.detail || []).map(({ value }) => value);
    dispatch("change", { selection });
};
const handleClear = () => {
    selection = [];
    dispatch("change", { selection });
};
</script>

<span class="row">
  <span class="select-box">
    <Select
      {id}
      placeholder="Enter tags"
      items={options}
      value={selection}
      isMulti={true}
      on:select={handleSelect}
      isCreatable={canCreate} />
  </span>
  <button class="clear-button" on:click|preventDefault|stopPropagation={handleClear}> CLEAR </button>
</span>

<style>@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Italic"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf") format("truetype");
  font-weight: bold; }

/* use Koopman for fixed labels (button) */
@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.tt") format("truetype"); }

@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.tt") format("truetype");
  font-weight: bold; }

* {
  box-sizing: border-box;
  font-family: Pegasus,serif; }

button,
span {
  font-family: Koopman, sans-serif; }

.row {
  display: flex;
  align-items: center;
  flex-grow: 1; }

.select-box {
  /* This one isn't in the styleguide but it is in the app */
  flex-grow: 1;
  padding: 0;
  color: #006666;
  --border: none;
  --background: transparent;
  --borderHoverColor: transparent;
  --borderFocusColor: #006666;
  --height: 30px;
  --inputColor: #fff;
  --inputFontSize: 0.875rem;
  --listZIndex: 40;
  --multiSelectPadding: 0;
  --multiSelectInputMargin: 5px 0 0;
  --multiItemHeight: 1.375rem;
  --multiItemActiveBG: #fff;
  --multiItemActiveColor: #002850;
  --multiItemBG: rgba(255, 255, 255, 0.9);
  --multiItemHeight: 30px;
  --multiClearWidth: 0.625rem;
  --multiClearHeight: 0.625rem;
  --multiClearTop: 10px;
  --multiClearBG: #006666;
  --multiClearHoverBG: red;
  --multiClearHoverFill: #fff;
  --clearSelectWidth: 0;
  --itemColor: #333;
  --itemHoverBG: #e0e6e6;
  --listBackground: #fff; }

.select-box:hover {
  outline: 1px solid rgba(255, 255, 255, 0.3); }

.clear-button {
  font-size: 12px;
  color: #fff;
  opacity: 0;
  transition: opacity 200ms; }

.row {
  padding: 0; }

.row:hover .clear-button {
  opacity: 0.9; }

button {
  background-color: transparent;
  border: none; }
</style>
