<script context="module">
  export function setUnreadToZero(messages, user_id) {
    messages.update(messages => {
      if (messages[user_id] && messages[user_id].unreadMessages) {
        messages[user_id].unreadMessages = 0;
      }
      return messages;
    });
   }
</script>

<script>
  import { createEventDispatcher } from 'svelte';
  import ChatList from "./ChatList";

  import { thinkin } from "../../stores/sessionStore";
  import { messages, privateMessages } from "../../stores/chatStore";
  import { groupBy, fetchData } from "../../common/helpers";

  export let host = false;

  const dispatch = createEventDispatcher();

  $: mainMessages = $messages.filter((m) => !m.in_reply_to);
  $: starredMessages = $messages.filter((m) => m.starred == true);
  $: threads = groupBy($messages.filter((m) => m.in_reply_to), "in_reply_to");
  
  $: wrapper_class = host ? "chat-wrapper host" : "chat-wrapper";

  fetchData(`/thinkins/${$thinkin.id}/messages`).then((data) => {
    messages.set(data.messages);
  });

  let filter = "all";

  $: private_messages = Object.entries($privateMessages).map((o) => o[1].messages).flat();
  $: all_messages = mainMessages.concat(private_messages).sort((m1, m2) => m1.timestamp < m2.timestamp ? -1: 1);
</script>

<div class={wrapper_class}>

    <div class="filter">
      <span>Show </span>
      <select size="1" name="filter" bind:value={filter}>
        <option value="all">all messages</option>
        <option value="highlights">highlights</option>
        <option value="private">only private messages</option>
      </select>
    </div>

    <ChatList messages={all_messages} {threads} {filter} {host} on:view on:spotlight />
</div>

<style>
  .chat-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0,0,0,.1);
  }
  .filter {
    text-align: left;
    padding: 5px 5px 0 10px;
    font-size: 16px;
  }
  .filter select {
    background-color: transparent;
    color: #fff;
    width: 235px;
    background-image: url(/assets/caret-white.svg);
  }
</style>
