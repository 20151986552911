<script>
  export let ticketCount;
  export let ticketType = "";
  let status = "Booked";
  let numberMessage = `${ticketCount} x ${ticketType.toLowerCase()} ticket${
    ticketCount > 1 ? "s" : ""
  }`;
</script>

<div class="booking_summary">
  <span class="booking_summary__status">
    <p class="text sans-small-text">{status}</p>
  </span>
  <p class="text sans-small-text">{numberMessage}</p>
</div>

<style>
  .booking_summary {
    display: flex;
    align-items: center;
  }

  .booking_summary__status {
    /* align-self: flex-start prevents the element (and its background colour) from expanding vertically when it doesn't need to */
    align-self: flex-start;
    background-color: var(--moss);
    padding: 2px 8px 4px 8px;
    margin-right: var(--spacer-tiny);
    font-family: Koopman, sans-serif;
    font-weight: bold;
  }

  .text {
    all: unset;
    display: block;
    text-align: start;
  }

  .sans-small-text {
    font-family: Koopman, serif;
    font-size: 16px;
    line-height: 22px;
    color: var(--text);
  }
</style>
