<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let spotifyUrl = "";

  $: iframeSrc = spotifyUrl.includes("embed")
    ? spotifyUrl
    : spotifyUrl.split(".com/").join(".com/embed/").split("?")[0];

  function clearPlaylistInput() {
    spotifyUrl = "";
    dispatch("change", { spotifyUrl: "" });
  }
</script>

<form on:submit|preventDefault on:change={() => dispatch("change", { spotifyUrl: iframeSrc })}>
  <p>
    <label for="spotify-url" class="spotify-label">Spotify URL</label>
    <input
      required
      name="spotify-url"
      type="text"
      bind:value={spotifyUrl}
      autocomplete="off"
      placeholder="Place a URL here" />
    <button type="button" on:click={clearPlaylistInput}>Clear</button>
  </p>
</form>

{#if spotifyUrl}
  <iframe
    src={iframeSrc}
    width="300"
    height="380"
    frameborder="0"
    allowtransparency="true"
    allow="encrypted-media"
    title="recap-playlist" />
{/if}

<style>
  .spotify-label {
    width: revert;
  }
  button {
    color: var(--lettuce);
  }
</style>
