<script>
  import { goto } from "@sveltech/routify";
  import { onMount } from "svelte";
  import { THINKIN_DEFAULT_LOCATION } from "@galapagos/shared";
  import { handleTimeFormatting, sameDate, fetchData } from "../../common/helpers";

  export let filterValue = "upcoming";
  export let size = 32;
  export let columnToFilter = "status";

  let listWrapper;

  let thinkins = [];
  let totalAmount;
  let page = 0;
  let orderBy;

  async function getNewBatch() {
    const fetchUrl = `/thinkins?page=${page}&size=${size}&columnToFilter=${columnToFilter}&filterValue=${filterValue}${
      orderBy ? "&orderBy=" + orderBy : ""
    }`;
    const data = await fetchData(fetchUrl);
    totalAmount = data.totalItems;
    thinkins = [...thinkins, ...data.thinkins];
    return data;
  }

  onMount(() => {
    getNewBatch();
  });

  function handleThinkinJoin(id) {
    $goto("/host/thinkin/:id/settings", { id });
  }

  function handleLoadMore() {
    page = page + 1;
    getNewBatch();
  }

  async function handleSort(sortBy) {
    if (sortBy == "ticketsSold") {
      thinkins.sort((a, b) => {
        const aPercentage = a.allocated / a.capacity;
        const bPercentage = b.allocated / b.capacity;
        return aPercentage > bPercentage ? 1 : bPercentage > aPercentage ? -1 : 0;
      });
      return;
    }

    orderBy = sortBy;
    const sortedBatch = await getNewBatch();
    thinkins = sortedBatch.thinkins;
  }

  async function handleFiltering(event) {
    filterValue = event.target.value;
    const fetchUrl = `/thinkins?page=${page}&size=${size}&columnToFilter=${columnToFilter}&filterValue=${filterValue}${
      orderBy ? "&orderBy=" + orderBy : ""
    }`;
    const data = await fetchData(fetchUrl);
    page = 0;
    totalAmount = data.totalItems;
    thinkins = data.thinkins;
  }
</script>

<div class="list-wrapper">
  <nav>
    <button value="upcoming" on:click={handleFiltering} class:active={filterValue == "upcoming"}>Upcoming</button>
    <button value="completed" on:click={handleFiltering} class:active={filterValue == "completed"}>Completed</button>
    <button value="draft" on:click={handleFiltering} class:active={filterValue == "draft"}>Draft</button>
  </nav>

  <table class="list" bind:this={listWrapper}>
    <thead>
      <tr>
        <th />
        <th class="date isSortable" on:click={() => handleSort("scheduled_date")}>Date</th>
        <th />
        <th class="isSortable" on:click={() => handleSort("name")}>Name</th>
        <th />
        <th class="isSortable" on:click={() => handleSort("ticketsSold")}>Tickets allocated</th>
      </tr>
    </thead>
    <tbody>
      {#if thinkins}
        {#each thinkins as { id, scheduled_date, name, status, allocated_ticket_count, in_person_ticket_count, digital_ticket_count, in_person_tickets, poster_url, audience, location }, i (id)}
          <tr on:click={() => handleThinkinJoin(id)} class={`status-${status.replace(" ", "-")}`}>
            <td class="poster"
              >{#if poster_url}<img src={poster_url} alt="" />{/if}</td>
            <td class="date">
              {#if i == 0 || !sameDate(scheduled_date, thinkins[i - 1].scheduled_date)}
                <span class="day">{handleTimeFormatting(scheduled_date).WD}</span><br />
                <span class="digit">{handleTimeFormatting(scheduled_date).DD}</span><br />
                <b>{handleTimeFormatting(scheduled_date).MMM}</b>
              {/if}
            </td>
            <td class="time">{handleTimeFormatting(scheduled_date).TIME}</td>
            <td class="event-name">
              <span class="name">{name}</span>
              {#if in_person_ticket_count > 0}
                <span class="location">{location || THINKIN_DEFAULT_LOCATION}</span>
              {/if}
              {#if audience == "private"}<span class="private">Private</span>
              {:else if audience == "exclusive"}<span class="exclusive">Exclusive</span>{/if}
            </td>
            <td class="status">
              <span>{status.split(" ")[0]}</span>
            </td>
            <td class="tickets">
              {#if digital_ticket_count}
                <span class="totals"
                  ><b
                    >{parseInt(allocated_ticket_count.digital || 0) +
                      parseInt(allocated_ticket_count.in_person || 0)}</b
                  >/{digital_ticket_count}</span>
                <span class="progress">
                  <span
                    style={`width:${Math.round(
                      ((allocated_ticket_count.digital || 0) / digital_ticket_count) * 100
                    )}%`} />
                  {#if in_person_ticket_count}
                    <span
                      style={`width:${Math.round(
                        ((allocated_ticket_count.in_person || 0) / digital_ticket_count) * 100
                      )}%`} />
                  {/if}
                </span>
              {/if}
              {#if allocated_ticket_count.in_person && allocated_ticket_count.in_person > in_person_tickets}
                <span class="soldout">Sold out</span>
              {/if}
            </td>
          </tr>
        {/each}
        {#if totalAmount > thinkins.length}
          <button class="btn" name="more" on:click={handleLoadMore}>Load more</button>
        {/if}
      {:else}
        <span>None found</span>
      {/if}
    </tbody>
  </table>
</div>

<style>
  .totals {
    display: block;
    margin-top: 1px;
  }
  .progress {
    display: block;
    position: relative;
    height: 4px;
    width: 120px;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    margin: 4px 0;
  }
  .progress span {
    display: inline-block;
    height: 100%;
  }
  .progress span:first-child {
    background-color: var(--lettuce);
  }
  .progress span:nth-child(2) {
    background-color: var(--orange);
  }
  .event-name {
    padding-top: 6px;
  }
  .name {
    font-size: 18px;
  }
  .private,
  .exclusive,
  .location {
    display: block;
    padding: 3px;
    background-repeat: no-repeat;
    background-position: left 4px;
    background-size: 12px 12px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.6;
  }

  .private,
  .exclusive {
    padding-left: 16px;
  }
  .private {
    background-image: url(/assets/lock.svg);
  }
  .exclusive {
    background-image: url(/assets/bright-star.svg);
  }
</style>
