<script>
  export let title;
  export let description;
</script>

<div class="body">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path d="M18.48 0L6.72 12.71L1.51 8.52L0 10.02L5.69 18H7.74L19.99 1.5L18.48 0Z" fill="#333333" />
    </svg>
  </div>
  <div>
    <span class="bold">
      {title}
    </span>
    <br />
    {description}
  </div>
</div>

<style>
  .body {
    font-family: Wolpe Pegasus, serif;
    font-size: 24px;
    font-style: normal;
    line-height: 34px;
    display: flex;
    gap: 16px;
  }
  .bold {
    font-weight: 700;
  }

  @media only screen and (max-width: 600px) {
    .body {
      font-size: 20px;
      line-height: 28px;
    }
  }
</style>
