<script>
  const localPng = fileName => `/assets/email-preferences/${fileName}.png`;
</script>

<div id="header-phone" class="header-phone">
  <img src={localPng("phone_newsletter")} alt="header phone" />
  <!-- <div class="scroll-box">
    Read more
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="21" viewBox="0 0 10 21" fill="none">
      <path
        d="M9.06488 14.58L5.62488 18.01L5.62488 0.5L4.37488 0.5L4.37488 18L0.934883 14.57L-0.00511807 15.5L5.00488 20.5L10.0049 15.52L9.06488 14.59L9.06488 14.58Z"
        fill="black" />
    </svg>
  </div> -->
</div>

<style>
  .header-phone {
    font-family: Wolpe Pegasus;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    text-decoration-line: underline;

    min-width: 370px;
    position: relative;

    & img {
      position: absolute;
      bottom: -30px;
    }
  }

  /* .scroll-box {
    position: absolute;
    width: 372px;
    height: 70px;
    bottom: 15px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  } */

  @media only screen and (max-width: 1380px) {
    .header-phone {
      display: none !important;
    }
  }
</style>
