<script>
  import { createEventDispatcher, onDestroy, onMount, tick } from "svelte";
  import { user } from "../../stores/sessionStore";
  import { timestamp } from "../../common/helpers";

  import { session } from "./session";

  export let participant;
  export let index;
  export let host = false;

  export function getIndex() {
    return index;
  }

  export function getAge() {
    return Date.now() - added_at;
  }
  
  function play() {
    console.debug(timestamp(), participant.uuid, participant.name, "screenshare.play()", participant.hasVideo);
    if (is_playing) {
      console.warn("**already playing");
      return Promise.resolve();
    }
    if (is_me) {
      console.debug(timestamp(), "loading my own screenshare");
      let ss_el = session.getScreenshareElement()
      element.appendChild(ss_el);
      ss_el.style.width = "100%";
      ss_el.style.height = "100%";
      is_playing = true;
      return Promise.resolve();
    } else 
    if (participant.hasScreenshare) {
      console.warn(timestamp(), participant.uuid, participant.name, "subscribing to stream");
      return session.subscribe(participant.uuid, element, "screen")
        .then(() => {
          console.warn("now we're sharing");
          is_playing = true;
        }).catch(handleError);
    } else {
      console.warn(timestamp(), participant.uuid, participant.name, "waiting for stream");
      return Promise.resolve();
    }
  }

  function stop() {
    console.log("Screenshare.stop()");
    if (is_me) {
      console.debug("stopping my own screenshare");
      // TODO: this bit should be explicitly stopping the share
      is_playing = false;
      return Promise.resolve();
    } 
    if (!is_playing) {
      console.warn("WARNING: no video to pause");
      return Promise.resolve();
    }

    console.debug(timestamp(), participant.uuid, participant.name, "unsubscribing from screenshare");
    return session.unsubscribe(participant.uuid, "screen")
      .then(() => {
        is_playing = false;
      });
}

  export function paused() {
    return !is_playing;
  }

  const dispatch = createEventDispatcher();


  let element;
  let actual_width;
  let dropdown_open = false;
  let added_at;
  let is_playing = false;
  let has_video = false;
  let tile_class;

  $: is_me = participant.uuid == $user.uuid;

  $: if (element) updateScreenshare(participant.hasScreenshare);

  $: size = actual_width < 200 ? "small" : actual_width < 400 ? "medium" : "large";
  $: tile_class = `tile tile-${ index + 1 } ${ size }`;

  function updateScreenshare(has_screenshare) {
    console.log("Screenshare.updateScreenshare()");
    if (has_screenshare == undefined) return;
    if (participant.uuid == $user.uuid) return;
    console.warn("** HAS SCREENSHARE", has_screenshare, participant.type);
    if (!has_screenshare) {
      console.warn("no screenshare");
    } else {
      return session.subscribe(participant.uuid, element, "screen")
        .then(() => {
          console.warn("Now we’re playing screenshare");
          is_playing = true;
        }).catch(handleError);
    }
  }

  function handleError(err) {
    dispatch("error", {  message: err });
  }

  onMount(() => {
    console.debug(timestamp(), participant.uuid, participant.name, "mounted screen", "streaming=", participant.hasVideo);
    added_at = Date.now();
    play();
  });

  onDestroy(() => {
    if (is_playing) stop();
    console.debug(timestamp(), participant.uuid, participant.name, "destroyed screen");
  });

</script>

<style>
  .screen {
    transition: opacity 300ms, transform 300ms, width 300ms, height 300ms, top 300ms, left 300ms;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
  .video {
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }
</style>

<div class="screen"
  class:paused={!is_playing}
  class:host
  bind:offsetWidth={actual_width}
  data-id={participant.uuid}>

  <div class="video" bind:this={element}></div>

</div>     
