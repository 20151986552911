<script>
  import { params } from "@sveltech/routify";
  import ExitSurvey from "../../../components/lobby/ExitSurvey";
  import Promotions from "../../../components/lobby/Promotions";

  const thinkin_id = $params.id;
</script>

<div class="fill-screen center-all">
  <div id="exiting">
    <div class="survey">
      <ExitSurvey />
    </div>
    <Promotions id={thinkin_id} />
  </div>
</div>

<style>
  .center-all {
    width: 100vw;
    height: 100vh;
    background-color: var(--kelpDark);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #exiting {
    background: #fff;
    width: 1200px;
    max-width: 90%;
  }
</style>
