<!-- NOTE membership type for the upgrade scheme is different to membership type overall. Consult the spreadsheet https://docs.google.com/spreadsheets/d/1wtDLIPX1UWWicu4DjuA1Cc4k8IMU3_VsqHPMLTXwB5I/edit#gid=0 -->
<script>
  import { user } from "../../../../stores/sessionStore";
  import { MEMBERSHIP_BENEFITS } from "../data/membership-benefits";
  import { MEMBERSHIP_VALUES } from "../data/membership-guide-values";
  import { FullScreenSpinner } from "@galapagos/svelte-components";

  let benefits = [];
  let membershipGuide = [];
  let displayMemberType = "Member";

  const userResourceAccesseArray = $user.resourceAccesses.sort(
    (resourceAccessA, resourceAccessB) => resourceAccessB.start_date - resourceAccessA.start_date
  );

  const description = userResourceAccesseArray[0].resource.description;
  const memberType = description.substring(description.indexOf("[") + 1, description.lastIndexOf("]"));

  switch (memberType) {
    case "Founding member + Quarterly":
      benefits = MEMBERSHIP_BENEFITS["FOUNDING_MEMBERS_QUARTERLY"];
      membershipGuide = MEMBERSHIP_VALUES["FOUNDING_MEMBERS_QUARTERLY"];
      displayMemberType = "Founding Member";
      break;
    case "Newsroom member":
      benefits = MEMBERSHIP_BENEFITS["NEWSROOM_MEMBERS"];
      membershipGuide = MEMBERSHIP_VALUES["NEWSROOM_MEMBERS"];
      displayMemberType = "Newsroom Member";
      break;
    case "Friend of Tortoise":
      benefits = MEMBERSHIP_BENEFITS["FRIEND_OF_TORTOISE"];
      membershipGuide = MEMBERSHIP_VALUES["FRIEND_OF_TORTOISE"];
      displayMemberType = "Friend of Tortoise";
      break;
    case "Basic member":
      benefits = MEMBERSHIP_BENEFITS["BASIC_MEMBERS"];
      membershipGuide = MEMBERSHIP_VALUES["BASIC_MEMBERS"];
      break;
    case "Founding member":
      benefits = MEMBERSHIP_BENEFITS["FOUNDING_MEMBERS"];
      membershipGuide = MEMBERSHIP_VALUES["FOUNDING_MEMBERS"];
      displayMemberType = "Founding Member";
      break;
    case "Standard member":
      benefits = MEMBERSHIP_BENEFITS["STANDARD_MEMBERS"];
      membershipGuide = MEMBERSHIP_VALUES["STANDARD_MEMBERS"];
      break;
    case "Student":
      benefits = MEMBERSHIP_BENEFITS["STUDENTS"];
      membershipGuide = MEMBERSHIP_VALUES["STUDENTS"];
      displayMemberType = "Student";
      break;
    case "B2C Pro":
      benefits = MEMBERSHIP_BENEFITS["B2C_PRO"];
      membershipGuide = MEMBERSHIP_VALUES["B2C_PRO"];
      break;
  }
</script>

{#if memberType}
  <slot {benefits} {membershipGuide} {displayMemberType} />
{:else}
  <FullScreenSpinner />
{/if}
