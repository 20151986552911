<script>
  import ThinkinSummary from "../ThinkinSummary.svelte";
  export let thinkIns = [];
  export let fallbackText = "";
</script>

{#if !thinkIns.length}
  <div class="fallback-bg">
    <img src="/assets/agatha-black.svg" alt="Tortoise 'Agatha' Logo" class="fallback-message" />
    <p class="text fallback-message">
      {fallbackText}
    </p>
    <p class="text fallback-message">
      <a target="_parent" href={`https://${process.env.TORTOISE_DOMAIN}/book/`}>
        Find something to book <img src="/assets/arrow-pegasus.svg" alt="Arrow icon" class="right-arrow" />
      </a>
    </p>
  </div>
{:else}
  <div class="thinkins-container">
    {#each thinkIns as thinkin}
      <ThinkinSummary {thinkin} />
    {/each}
  </div>
{/if}

<style>
  .text {
    all: unset;
    display: block;
    text-align: start;
  }

  .fallback-bg {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  .fallback-message {
    margin-right: var(--spacer-tiny);
    margin-left: var(--spacer-s3);
    color: var(--text);
    font-family: Pegasus, serif;
    font-size: 24px;
    line-height: 34px;
    margin-top: var(--spacer-medium);
    text-align: start;
  }

  .fallback-message a:hover {
    border-bottom: solid 1px var(--text);
  }

  .right-arrow {
    font-weight: bolder;
  }

  a {
    color: var(--text);
    text-decoration: none;
  }

  @media screen and (min-width: 645px) {
    .thinkins-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--grid-gutter-standard);
      row-gap: var(--spacer-s5);
      margin-bottom: var(--spacer-s2);
    }
    .fallback-message {
      margin-right: unset;
      margin-left: unset;
    }
  }
</style>
