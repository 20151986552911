<script>
  export let selection;
  export let onNewSelection;
  const titles = ["Captions", "Chat"];
  export let highlightsOnly;
  export let setHighlightsOnly;
</script>

<div>
  {#each titles as title, index (title)}
    <h4 on:keydown={() => onNewSelection(index)} on:click={() => onNewSelection(index)} class:selected={selection === index}>{title}</h4>
  {/each}
  <span>
    <label for="highlightsOnly">Highlights only</label>
    <input
      type="checkbox"
      class="switch"
      id="highlightsOnly"
      bind:checked={highlightsOnly}
      on:change={() => setHighlightsOnly(highlightsOnly)} />
  </span>
</div>

<style>
  div {
    display: flex;
    align-items: center;
  }

  h4 + h4 {
    margin-left: 20px;
    padding-left: 10px;
  }

  h4 {
    color: var(--lettuce);
    cursor: pointer;
    font-weight: normal;
    margin: 10px 0;
  }

  .selected {
    color: white;
    text-decoration: underline;
    cursor: default;
  }
  span {
    white-space: nowrap;
    margin-left: auto;
  }
  label {
    width: auto;
    padding-right: 5px;
  }
  input {
    margin-left: 0;
  }
</style>
