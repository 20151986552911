<script>
  import { onMount } from "svelte";
  import { goto } from "@sveltech/routify";
  import { DateTime } from "luxon";
  import Select from "svelte-select";
  import { uploadRecapAsset, makeJSONFile, getFileSrc, RECAP_ACTION } from "@galapagos/shared";

  import ConfirmModal from "../modal/ConfirmModal.svelte";
  import Dropdown from "../dropdown/Dropdown";
  import Attendees from "./Attendees";
  import HostsAndGuests from "./HostsAndGuests";
  import HTMLEditor from "../htmleditor/HTMLEditor";
  import Slides from "./Slides";
  import Partners from "./Partners";
  import Polls from "./Polls";
  import ReadOut from "./ReadOut";
  import Recap from "./Recap";
  import Video from "./Video";
  import Ticketing from "./Ticketing";
  import Playlist from "./Playlist.svelte";
  import Emails from "./emails/Emails.svelte";

  import { fetchData, makeId, saveResource, selectImageFromComputer, uploadImage } from "../../common/helpers";
  import { activeTab } from "../../stores/thinkinDetails.js";
  import { Selector } from "@galapagos/svelte-components";

  export let thinkin;
  let savedThinkIn;
  $: if (!savedThinkIn) {
    savedThinkIn = JSON.parse(JSON.stringify(thinkin));
  }
  $: thinkinTagOptions = [];
  $: staff = [];

  let video = {
    path: thinkin.recording_path,
    url: thinkin.recording_url,
  };
  let transcript = {
    path: thinkin.recording_transcript_path,
    url: thinkin.recording_transcript_url,
  };
  let comments = {
    path: thinkin.recording_comments_path,
    url: thinkin.recording_comments_url,
  };

  export let audience = "public";
  let confirmSubmission;

  const DEFAULT_DURATION = 60;

  $: thinkin_status = thinkin ? thinkin.status : "Draft";
  $: back_status =
    thinkin_status == "Completed" ? "?status=completed" : thinkin_status == "Draft" ? "?status=draft" : "";
  $: back_link = (thinkin ? thinkin.audience : audience) == "team" ? "/host/meetings" : `/host/thinkin${back_status}`;
  let partner_ticket_ids = (thinkin.ticket_types || []).filter(t => t.type_name == "Partner").map(t => t.id);
  let sponsor_ticket_ids = (thinkin.ticket_types || []).filter(t => t.type_name == "Sponsor").map(t => t.id);

  $: if (thinkin.audience == "private") {
    const networkStudentTicketIds = (thinkin.ticket_types || [])
      .filter(t => t.type_name == "Network" || t.type_name == "Student")
      .map(t => t.id);
    partner_ticket_ids = [...partner_ticket_ids, ...networkStudentTicketIds];
  }
  let timestamp;
  let display_status = "";
  let pending_changes = false;
  let options_menu_open = false;
  let guests;
  let hosts;
  let readoutAuthor = {};
  let spotifyUrl = thinkin.spotify_url || "";
  let attendees;
  let attendeesList = [];
  let attendees_changed = false;
  let poster_image = thinkin.poster_url;
  let add_joining_instructions = false;
  let emailSettings;
  let showNewRecap = thinkin.recording_path !== null;

  const fieldErrors = {};

  $: is_meeting = thinkin.audience == "team";

  if (thinkin.status) {
    display_status = thinkin.status + " " + DateTime.fromISO(thinkin.updated_at || thinkin.created_at).toRelative();
    if ($activeTab.startsWith("recap") && thinkin.status !== "Completed") {
      activeTab.set("settings");
    }
  } else {
    activeTab.set("settings");
  }

  // Choose next best action when thinkin state changes
  function nextBestAction(status) {
    if (!thinkin.id) {
      return "Create now";
    }
    if (pending_changes) {
      return "Save changes";
    }
    if (status == "Completed") {
      return thinkin.platform == "Tortoise" ? "Restart" : "Save changes";
    }
    if (is_meeting && status == "Live") {
      return "Join now";
    }
    if (is_meeting) {
      return "Start now";
    }
    if (status == "Draft") {
      return "Publish";
    }
    if (status == "Published") {
      return thinkin.platform == "Tortoise" ? "Start now" : "Save changes";
    }
    if (status == "Green room") {
      return "Join now";
    }
    if (status == "Live") {
      return "Join now";
    }
    return "Save draft";
  }

  $: default_action = nextBestAction(thinkin.status);

  function handleDefaultAction(event) {
    const action = event.target.innerText;
    switch (action) {
      case "Save changes":
        saveChanges();
        break;

      case "Create now":
        createThinkin();
        break;

      case "Save draft":
        saveDraft();
        break;

      case "Publish":
        publish();
        break;

      case "Start now":
        // temp - need to route through Green room to get to Live
        // or won't get tokbox session
        if (is_meeting) {
          saveChanges("Green room").then(launch);
        } else {
          openGreenRoom();
        }
        break;

      case "Open to all":
      case "Restart":
        launch();
        break;

      case "Archive":
        archive();
        break;

      case "Unpublish":
        unpublish();
        break;

      case "Join now":
        join();
        break;

      case "End now":
        end();
        break;

      default:
        console.error("Unrecognised action", action);
    }
  }

  function saveDraft() {
    // Explicitly force state to draft
    saveChanges("Draft");
  }

  function createThinkin() {
    saveChanges("Draft").then(() => {
      $goto(`/host/thinkin/${thinkin.id}/settings`);
    });
  }

  function openGreenRoom() {
    saveChanges("Green room").then(join);
  }

  function handleChange() {
    if (thinkin.id) {
      pending_changes = true;
      default_action = "Save changes";
    }
  }

  function sanitizeHTML(html) {
    return !html
      ? ""
      : html
          .replace(/&nbsp;/g, " ")
          .replace(/<\/?(font|div|span|meta)[^>]*>/gi, "")
          .replace(/ style="[^"]*"/gi, "")
          .trim();
  }

  function computeThinkInType() {
    const { type, in_person_ticket_count } = thinkin;
    const thinkinHasInPersonTickets = in_person_ticket_count > 0;
    const thinkinHasDigitalTickets = !!computedDigitalTicketCount;

    if (type && type.trim()) {
      return type.trim();
    }

    if (thinkinHasInPersonTickets && thinkinHasDigitalTickets) {
      return "Hybrid";
    }

    if (thinkinHasInPersonTickets) {
      return "In person";
    }

    if (thinkinHasDigitalTickets) {
      return "Digital";
    }
  }

  $: computedDigitalTicketCount = typeof thinkin.digital_ticket_count === "number" ? thinkin.digital_ticket_count : 500;

  function willSubmit() {
    const isUserDecisionRequired =
      thinkin.status &&
      /hybrid/i.test(savedThinkIn.type) &&
      /digital/i.test(thinkin.type) &&
      Number(thinkin.booked_in_person_tickets) > 0;

    if (!isUserDecisionRequired) {
      return true;
    }

    return confirmSubmission();
  }
  function performRecapAssetAction({ asset, upload, remove, noAction }) {
    const currentAction = asset.nextAction;
    asset.nextAction = null;
    return currentAction === RECAP_ACTION.UPLOAD
      ? upload()
      : currentAction === RECAP_ACTION.DELETE
      ? remove()
      : noAction();
  }

  async function loadFakeVideo(videoFileUrl) {
    return new Promise(resolve => {
      const videoElement = document.createElement("video");
      videoElement.style.width = "300px";
      videoElement.style.height = "150px";
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      videoElement.src = videoFileUrl;
      videoElement.crossOrigin = "anonymous";

      const getVideoFrameFile = atTimeMs =>
        new Promise((resolve, reject) => {
          const newTime = Math.round(atTimeMs / 1000);
          videoElement.currentTime = Math.min(videoElement.duration, newTime);
          const captureStill = () => {
            videoElement.removeEventListener("timeupdate", captureStill);
            context.drawImage(videoElement, 0, 0, 300, 150);
            canvas.toBlob(blob => (blob ? resolve(blob) : reject("Blob could not be created")));
          };
          videoElement.addEventListener("timeupdate", captureStill);
        });

      const returnGetter = () => {
        videoElement.removeEventListener("canplaythrough", returnGetter);
        resolve(getVideoFrameFile);
      };

      videoElement.addEventListener("canplaythrough", returnGetter);
    });
  }

  async function uploadTranscriptHighlights(transcriptData) {
    const videoUrl = getFileSrc(video.file, video.url);

    if (!videoUrl || video.nextAction === RECAP_ACTION.DELETE) {
      return transcriptData;
    }

    const getVideoFrameFile = await loadFakeVideo(videoUrl);

    const transcriptsWithFrames = await transcriptData.reduce(async (processedTranscriptItems, transcriptItem) => {
      const transcriptItems = await processedTranscriptItems;
      if (!transcriptItem.isHighlighted) {
        return [...transcriptItems, transcriptItem];
      }
      const frameFile = await getVideoFrameFile(transcriptItem.time.start);
      const frame = await uploadRecapAsset(frameFile, thinkin.id);
      return [...transcriptItems, { ...transcriptItem, frame }];
    }, []);

    return transcriptsWithFrames;
  }

  async function processFiles() {
    const shouldCleanUpOldAssets = [video, comments, transcript].some(asset => asset.nextAction);

    const videoUpload = performRecapAssetAction({
      asset: video,
      upload: async () => {
        video.isUploading = true;
        const { path, url } = await uploadRecapAsset(video.file, thinkin.id);
        video = { ...video, path, url };
        video.isUploading = false;
        return path;
      },
      remove: () => null,
      noAction: () => thinkin.recording_path,
    });

    const commentsUpload = performRecapAssetAction({
      asset: comments,
      upload: async () => {
        comments.isUploading = true;
        comments.file = await makeJSONFile(comments.data);
        const { path, url } = await uploadRecapAsset(comments.file, thinkin.id);
        comments = { ...comments, path, url };
        comments.isUploading = false;
        return path;
      },
      remove: () => null,
      noAction: () => thinkin.recording_comments_path,
    });

    const transcriptUpload = performRecapAssetAction({
      asset: transcript,
      upload: async () => {
        transcript.isUploading = true;
        transcript.data = await uploadTranscriptHighlights(transcript.data);
        const transcriptJSONFile = await makeJSONFile(transcript.data);
        const { path, url } = await uploadRecapAsset(transcriptJSONFile, thinkin.id);
        transcript = { ...transcript, path, url };
        transcript.isUploading = false;
        return path;
      },
      remove: () => null,
      noAction: () => thinkin.recording_transcript_path,
    });

    const [recording_path, recording_comments_path, recording_transcript_path] = await Promise.all([
      videoUpload,
      commentsUpload,
      transcriptUpload,
    ]);
    return { recording_path, recording_comments_path, recording_transcript_path, shouldCleanUpOldAssets };
  }

  async function saveChanges(new_status) {
    closeOptionsMenu();

    if (!(await willSubmit())) {
      return;
    }

    display_status = "Saving…";
    const { recording_path, recording_comments_path, recording_transcript_path, shouldCleanUpOldAssets } =
      await processFiles();
    if (new_status) {
      thinkin.status = new_status;
    }
    if (!thinkin.key) {
      thinkin.key = makeId(5);
    }
    if (!thinkin.duration) {
      thinkin.duration = DEFAULT_DURATION;
    }

    const newTimeStamp = DateTime.fromISO(timestamp).toUTC().toString();

    const flourish_visualisation_id = getFlourishVisualisationId(thinkin.flourish_visualisation_id);

    const fields = {
      name: thinkin.name,
      type: computeThinkInType(),
      duration: thinkin.duration,
      key: thinkin.key,
      status: thinkin.status,
      description: sanitizeHTML(thinkin.description),
      flourish_visualisation_id,
      scheduled_date: newTimeStamp,
      poster_url: poster_image,
      captioning: thinkin.captioning,
      recording: thinkin.recording,
      chatting: thinkin.chatting,
      unmuting: thinkin.unmuting,
      audience: thinkin.audience,
      location: thinkin.location,
      external_url: thinkin.external_url,
      platform: thinkin.platform,
      using_external_booking_provider: thinkin.using_external_booking_provider,
      external_booking_url: thinkin.external_booking_url,
      digital_ticket_count: computedDigitalTicketCount,
      digital_ticket_non_member_price: thinkin.digital_ticket_non_member_price || 15,
      in_person_ticket_count: thinkin.in_person_ticket_count,
      in_person_ticket_member_price: thinkin.in_person_ticket_member_price,
      in_person_ticket_non_member_price: thinkin.in_person_ticket_non_member_price,
      youtube_url: thinkin.youtube_url,
      partner_ticket_ids: [...new Set(partner_ticket_ids)],
      sponsor_ticket_ids: [...new Set(sponsor_ticket_ids)],
      series_name: thinkin.series_name,
      excerpt: thinkin.excerpt,
      instructions: sanitizeHTML(thinkin.instructions),
      readout: sanitizeHTML(thinkin.readout),
      tags: thinkin.tags,
      readout_author_id: readoutAuthor.uuid,
      spotify_url: spotifyUrl,
      recording_path,
      recording_transcript_path,
      recording_comments_path,
      booking_type_description:thinkin.booking_type_description,
      timezone: thinkin.timezone,
    };

    if (thinkin.id) {
      fields.id = thinkin.id;
    }
    if (thinkin.started_at) {
      fields.started_at = thinkin.started_at;
    }
    if (thinkin.ended_at) {
      fields.ended_at = thinkin.ended_at;
    }

    if (attendees_changed) {
      attendees.save();
      attendees_changed = false;
    }

    if (emailSettings) {
      await saveEmailSettings(emailSettings);
    }

    if (poster_image && poster_image !== thinkin.poster_url) {
      const image_name = thinkin.name.replace(/\W/g, "-").replace(/(-+)/g, "-").toLowerCase();
      fields.poster_url = await uploadImage(poster_image, image_name, "thinkins-posters", makeId(4));
    }

    try {
      const method = thinkin.id ? "PUT" : "POST";

      if (method == "PUT") {
        await Promise.all([await saveGuests(), await saveHosts()]);
      }

      const apiUrlEndpoint = method === "PUT" ? `/thinkins/${thinkin.id}` : "/thinkins";
      const data = await saveResource(method, apiUrlEndpoint, fields);

      if (shouldCleanUpOldAssets) {
        await saveResource("DELETE", `/thinkins/${thinkin.id}/recap/old-assets`);
      }

      if (data) {
        if (!thinkin.id) {
          thinkin.id = data.id;
        }
        thinkin = data;
        savedThinkIn = JSON.parse(JSON.stringify(thinkin));
      }

      pending_changes = false;
      display_status = "Saved OK";
      default_action = nextBestAction(thinkin.status);

      setTimeout(() => {
        display_status = thinkin.status + " just now";
      }, 1200);
    } catch (err) {
      display_status = "Failed 😞";
      console.error("Fetch failed", err);
    }
  }

  function publish() {
    saveChanges("Published");
  }

  function unpublish() {
    // TODO: ask for confirmation if tickets have been allocated
    saveChanges("Draft");
  }

  function archive() {
    saveChanges("Archived").then(() => {
      $goto("/host/thinkin");
    });
  }

  function duplicate() {
    saveChanges()
      .then(() => {
        display_status = "Duplicating...";
        return saveResource("POST", `/thinkins/${thinkin.id}/duplicate`, {
          newData: { key: makeId(5) },
        });
      })
      .then(r => {
        display_status = "Duplicated!";
        thinkin = r;
        return $goto(`/host/thinkin/${r.id}/settings`);
      });
  }

  function launch() {
    thinkin.started_at = DateTime.utc().toString();
    thinkin.ended_at = null; // not correct if restarting?
    saveChanges("Live").then(() => {
      $goto("../");
    });
  }

  function join() {
    if (thinkin.platform !== "Tortoise" && thinkin.external_url) {
      document.location.href = thinkin.external_url;
      return;
    }
    $goto("../");
  }

  function end() {
    saveChanges("Completed");
  }
  function setReadoutAuthor(event) {
    readoutAuthor = event.detail && event.detail.readoutAuthor;
    handleChange();
  }

  function setSpotifyUrl(event) {
    spotifyUrl = event.detail.spotifyUrl;
    handleChange();
  }

  function updateHostsAndGuests(event) {
    hosts = event.detail.hosts;
    guests = event.detail.guests;
    handleChange();
  }

  async function addParticipant(participant, participantRole) {
    const { key, biography, email, first_name, last_name, job_title, isChatHost } = participant;
    const name = [first_name || "", last_name || ""].join(" ");
    let profile_picture = participant.profile_picture;
    if (profile_picture && profile_picture.includes("base64") && name) {
      const picture_name = name.replace(/\W/g, "-").replace(/(-+)/g, "-").toLowerCase();
      profile_picture = await uploadImage(profile_picture, picture_name, "thinkins-profile-pictures", key);
    }

    const exists_already = "uuid" in participant;
    const participantFetchMethod = exists_already ? "PUT" : "POST";
    const participantBody = {
      first_name,
      last_name,
      name,
      job_title,
      email,
      profile_picture,
      biography,
    };

    if (participantRole == "host") {
      participantBody.organisation_id = 1;
      participantBody.membership_type = "Staff";
    }

    if (participantRole == "guest") {
      participantBody.membership_type = "Guest";
    }
    if (!exists_already) {
      participantBody.uuid = makeId(10);
    }
    const userFetchUrl = `/users${exists_already ? "/" + participant.uuid : ""}`;
    const userData = await saveResource(participantFetchMethod, userFetchUrl, participantBody);
    const dbParticipant = await fetchData(`/thinkins/${thinkin.id}/participants/${participant.uuid}`);
    if (!dbParticipant.user || !dbParticipant.user.role) {
      participant.id = userData.id;
      participant.uuid = userData.uuid;
      const participantBody = {
        role: participantRole,
        key,
        user_id: participant.uuid,
      };
      await saveResource("POST", `/thinkins/${thinkin.id}/participants`, participantBody);
    } else if (dbParticipant.user) {
      const participant = dbParticipant.user;
      if (isChatHost && participant.role != "chat host") {
        await saveResource("PUT", `/thinkins/${thinkin.id}/participants/${participant.user_id}`, { role: "chat host" });
      } else if (!isChatHost && participant.role == "chat host") {
        await saveResource("PUT", `/thinkins/${thinkin.id}/participants/${participant.user_id}`, { role: "host" });
      }
    }
  }

  async function saveHosts() {
    if (!hosts) {
      return Promise.resolve();
    }
    await Promise.all(hosts.map(async host => await addParticipant(host, "host")));
  }

  async function saveGuests() {
    if (!guests) {
      return Promise.resolve();
    }
    await Promise.all(guests.map(async guest => await addParticipant(guest, "guest")));
  }

  function updatePartnersAndSponsors(event) {
    partner_ticket_ids = event.detail.partner_ticket_ids;
    sponsor_ticket_ids = event.detail.sponsor_ticket_ids;
    handleChange();
  }

  $: public_url = thinkin.id && updatePublicURL(thinkin.audience, thinkin.pugpig_thinkin_path);

  function updatePublicURL(audience, path) {
    if (audience == "private") {
      return `${document.location.origin}/thinkin/${thinkin.id}`;
    } else if (path) {
      return `https://tortoisemedia.com${path}`;
    } else {
      return null;
    }
  }

  function openOptionsMenu() {
    options_menu_open = !options_menu_open;
  }
  function closeOptionsMenu() {
    options_menu_open = false;
  }

  onMount(() => {
    if (thinkin.id) {
      timestamp = DateTime.fromISO(thinkin.scheduled_date).toString().split(".")[0];
      getAttendees();
    } else {
      thinkin.audience = audience;
      // set defaults
      if (audience == "team") {
        thinkin.chatting = true;
        thinkin.unmuting = true;
      } else {
        thinkin.chatting = true;
        thinkin.captioning = true;
        thinkin.recording = true;
      }
    }
    getThinkInTagOptions();
    getStaffMembers();
  });

  async function getThinkInTagOptions() {
    const tags = await fetchData("/tags");
    thinkinTagOptions = tags;
  }

  async function getAttendees() {
    const res = await fetchData(`/thinkins/${thinkin.id}/participants?tickets=true`);
    attendeesList = res.attendees;
  }
  async function getStaffMembers() {
    const res = await fetchData("/users?membership_type=Staff");
    staff = res.users;
  }

  function handlePosterSelection(e) {
    selectImageFromComputer(e, e => {
      // const { imageName } = prepareBase64ToPost(e.target.result, thinkin.key);
      poster_image = e.target.result;
    });
  }

  function clearPoster() {
    poster_image = null;
    handleChange();
  }

  function handleSeriesSelect(e) {
    thinkin.series_name = e.detail.value;
    handleChange();
  }

  function handleSeriesClear() {
    thinkin.series_name = null;
    handleChange();
  }

  function getFlourishVisualisationId(input = null) {
    // if input === null|undefined|'', id is null
    // if input === number|string, process ID inside function as a string
    // if processed string is valid, return number. If invalid, return null
    if (!input) {
      return null;
    }

    // when input comes from the textbox, it is already a string, but we save it in the db as a number, so stringify it here to be sure
    const stringifiedInput = input.toString();
    const isValid = /^\d+$|^https:\/\/(app|public)\.flourish\.studio\/visualisation\/\d+\/*\D*$/.test(stringifiedInput);
    const lastPartOfUrl = (stringifiedInput.match(/\d+\/*\D*$/) || [])[0] || "";
    const numberPartOfUrl = (lastPartOfUrl.match(/\d+/) || [])[0] || "";

    return isValid && numberPartOfUrl ? Number(numberPartOfUrl) : null;
  }

  function updateEmailSettings(e) {
    emailSettings = e.detail;
    handleChange();
  }
  async function saveEmailSettings(emailSettingsData) {
    const { sendConfirmationEmails, send2dayReminderEmails, send4hourReminderEmails } = emailSettingsData;
    const url = `/thinkins/${thinkin.id}/email-settings`;
    const payload = {
      thinkin_id: thinkin.id,
      suppress_confirmation_email: !sendConfirmationEmails,
      suppress_2d_reminder: !send2dayReminderEmails,
      suppress_4h_reminder: !send4hourReminderEmails,
    };
    return saveResource("POST", url, payload);
  }
</script>

<ConfirmModal bind:confirm={confirmSubmission}>
  <h1 class="modal-text">Wait a second!</h1>

  <h1 class="modal-text">
    It seems that you’re trying to change this from a hybrid to a digital thinkin, but some in person tickets have
    already been booked.
  </h1>

  {#if attendeesList.some(attendee => attendee.booking_type === "In person")}
    <h1>In person attendees include:</h1>
    <ul>
      {#each attendeesList.filter(({ booking_type }) => booking_type == "In person").slice(0, 2) as attendee}
        <li>{attendee.first_name} {attendee.last_name} ({attendee.email})</li>
      {/each}
    </ul>
  {/if}
</ConfirmModal>

<main>
  <header>
    <a href={back_link} class="btn lettuce home">← Back</a>
    {#if thinkin.id}
      <h1>{thinkin.name}</h1>
    {/if}
    <div>
      <span class:warning={pending_changes} class:pillbox={!!thinkin.id}>
        <span class="status">{display_status}</span>
        <button
          name="action"
          class="btn primary sans first"
          disabled={!thinkin.name || !timestamp}
          on:click={handleDefaultAction}>{default_action}</button>
        {#if thinkin.id}
          <button class="btn primary menu last" on:click={openOptionsMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#066" width="24px" height="24px">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            </svg>
          </button>
        {/if}
        {#if options_menu_open}
          <Dropdown on:click_outside={closeOptionsMenu} right="8" top="38" width="136">
            {#if thinkin.status == "Published"}
              <li on:click={unpublish} on:keydown={unpublish} >Unpublish</li>
            {:else}
              <li on:click={saveDraft} on:keydown={saveDraft}>Save as draft</li>
              <li on:click={publish} on:keydown={publish}>Publish</li>
            {/if}
            {#if thinkin.status == "Completed"}
              <li on:click={launch} on:keydown={launch}>Restart now</li>
            {:else if thinkin.status == "Green room"}
              <li on:click={launch} on:keydown={launch}>Open to all</li>
            {:else}
              <li on:click={launch} on:keydown={launch}>Start now</li>
            {/if}
            {#if thinkin.status !== "Completed"}
              <li on:keydown={() => saveChanges("Completed")} on:click={() => saveChanges("Completed")}>Completed</li>
            {/if}
            <li on:click={archive} on:keydown={archive} class="divider">Archive</li>
            <li on:click={duplicate} on:keydown={duplicate}>Duplicate</li>
          </Dropdown>
        {/if}
      </span>
    </div>
  </header>

  <aside>
    <nav>
      <button
        type="button"
        class="btn"
        class:active={$activeTab == "settings"}
        on:click={() => ($activeTab = "settings")}>General</button>

      <button
        type="button"
        class="btn"
        class:active={$activeTab == "hostsandguests"}
        on:click={() => ($activeTab = "hostsandguests")}
        disabled={!thinkin.id}>Editors &amp; guests</button>

      <button
        type="button"
        class="btn"
        class:active={$activeTab == "ticketing"}
        on:click={() => ($activeTab = "ticketing")}
        disabled={!thinkin.id}>Ticket types</button>
      <button
        type="button"
        class="btn"
        class:active={$activeTab == "emails"}
        on:click={() => ($activeTab = "emails")}
        disabled={!thinkin.id}>Emails</button>
      <button
        type="button"
        class="btn"
        class:active={$activeTab == "attendees"}
        on:click={() => ($activeTab = "attendees")}
        disabled={!thinkin.id}>Bookings</button>

      {#if !is_meeting}
        <button
          type="button"
          class="btn"
          class:active={$activeTab == "partners"}
          on:click={() => ($activeTab = "partners")}
          disabled={!thinkin.id}>Partners</button>
      {/if}

      {#if thinkin.platform == "Tortoise"}
        <button
          type="button"
          class="btn"
          class:active={$activeTab == "polls"}
          on:click={() => ($activeTab = "polls")}
          disabled={!thinkin.id}>Polls</button>
      {/if}

      {#if thinkin.status == "Completed"}
        {#if !showNewRecap}
          <button
            type="button"
            class="btn"
            class:active={$activeTab == "recap"}
            class:warn={thinkin.follow_up_emails_sent == false}
            on:click={() => ($activeTab = "recap")}>Catch up</button>
        {:else}
          <button
            type="button"
            class="btn"
            class:active={$activeTab.startsWith("recap")}
            class:warn={thinkin.follow_up_emails_sent == false}>Catch up</button>
          <ul class="subnav">
            <li>
              <button
                type="button"
                class="btn nav-subitem"
                class:active={$activeTab === "recap.video"}
                on:click={() => ($activeTab = "recap.video")}>Video &amp; chat</button>
            </li>
            <li>
              <button
                type="button"
                class="btn nav-subitem"
                class:active={$activeTab === "recap.readout"}
                on:click={() => ($activeTab = "recap.readout")}>Read out</button>
            </li>
            <li>
              <button
                type="button"
                class="btn nav-subitem"
                class:active={$activeTab === "recap.playlist"}
                on:click={() => ($activeTab = "recap.playlist")}>Spotify playlist</button>
            </li>
          </ul>
        {/if}
      {/if}
    </nav>
  </aside>

  <div class="tabbed">
    {#if $activeTab == "settings"}
      <form on:input={handleChange} id="settings">
        <fieldset>
          <figure class="poster">
            {#if poster_image}
              <img src={poster_image} alt="" />
              <button name="remove" on:click={clearPoster}>✕</button>
            {:else}
              <input type="file" accept=".jpg, .jpeg, .png" on:change={e => handlePosterSelection(e)} />
              <span>Upload an image</span>
            {/if}
          </figure>
          <p>
            <label for="thinkin_name">Name</label>
            <input required name="thinkin_name" type="text" bind:value={thinkin.name} autocomplete="off" />
          </p>
          <p>
            <label for="thinkin_type">Type</label>
            <input
              name="thinkin_type"
              type="text"
              placeholder={computeThinkInType()}
              bind:value={thinkin.type}
              maxlength="50"
              autocomplete="off" />
          </p>

          {#if !is_meeting}
            <div class="select-editable-wrapper">
              <label for="thinkin-series">Series</label>
              <div>
                <Select
                  items={thinkin.series_names || []}
                  value={thinkin.series_name}
                  isCreatable={true}
                  hideEmptyState={true}
                  showIndicator={!thinkin.series_name}
                  on:select={handleSeriesSelect}
                  on:clear={handleSeriesClear}
                  placeholder="None" />
              </div>
            </div>
            <p>
              <label for="thinkin-audience">Audience</label>
              <select size="1" name="audience" bind:value={thinkin.audience}>
                <option value="public">All members</option>
                <option value="exclusive">Friend of Tortoise</option>
                <option value="private">Private</option>
                <option value="team">Staff only</option>
              </select>
            </p>
          {/if}

          <p class="tags">
            <label for="tags">Tags</label>
            <Selector
              id="tags"
              bind:selection={thinkin.tags}
              on:change={handleChange}
              options={thinkinTagOptions.map(tag => tag.text)}
              canCreate={true} />
          </p>

          <div class="html-editor">
            <div>
              <HTMLEditor name="description" bind:value={thinkin.description} />
            </div>
          </div>
          <div class="html-editor">
            <div>
              {#if thinkin.instructions || add_joining_instructions}
                <HTMLEditor
                  name="instructions"
                  bind:value={thinkin.instructions}
                  height="70"
                  placeholder="These instructions will be added to confirmation and reminder emails" />
              {:else}
                <button name="addinstr" on:click={() => (add_joining_instructions = true)}
                  >Add joining instructions</button>
              {/if}
            </div>
          </div>
          <p>
            <label for="booking_type_description">Booking type description</label>
            <input type="text" name="booking_type_description" bind:value={thinkin.booking_type_description} />
          </p>
          <p>
            <label for="timezone">Time zone</label>
            <input type="text" name="timezone" bind:value={thinkin.timezone} />
          </p>
          <p class="validated-input">
            <label for="flourish_visualisation_url">Slides</label>
            <span class="error">
              <input
                type="text"
                name="flourish_visualisation_url"
                placeholder="Paste a Flourish URL or ID"
                on:change={e => {
                  fieldErrors.flourish_visualisation_url =
                    !e.target.value || getFlourishVisualisationId(e.target.value)
                      ? ""
                      : "Invalid Flourish URL. This will not be saved";
                }}
                bind:value={thinkin.flourish_visualisation_id} />
              <small>{fieldErrors.flourish_visualisation_url || ""}</small>
            </span>
          </p>
          <p>
            <label for="excerpt">Excerpt</label>
            <input type="text" name="excerpt" bind:value={thinkin.excerpt} />
          </p>
          <p>
            <label for="date">Scheduled</label>
            <input required type="datetime-local" bind:value={timestamp} />
            <input
              required
              name="duration"
              type="number"
              bind:value={thinkin.duration}
              placeholder={DEFAULT_DURATION} />
            <span>minutes</span>
          </p>

          {#if thinkin.id}
            <p>
              <label for="url">Web page</label>
              <output name="thinkin_link" id="url">
                {#if public_url}
                  <a href={public_url} target="_blank">{public_url}</a>
                {:else}
                  Not available yet
                {/if}
              </output>
            </p>
          {/if}
        </fieldset>
      </form>

    {:else if $activeTab == "emails"}
      <fieldset>
        <Emails thinkin_id={thinkin.id} on:change={updateEmailSettings} />
      </fieldset>
    {:else if $activeTab == "ticketing"}
      <fieldset>
        <Ticketing bind:thinkin on:change={handleChange} />
      </fieldset>
    {:else if $activeTab == "attendees"}
      <div id="attendees">
        <Attendees
          {thinkin}
          on:change={() => {
            attendees_changed = true;
            handleChange();
          }}
          bind:this={attendees}
          bind:attendees={attendeesList} />
      </div>
    {:else if $activeTab == "hostsandguests"}
      <div id="hostsandguests">
        <HostsAndGuests {thinkin} {staff} on:change={updateHostsAndGuests} />
      </div>
    {:else if $activeTab == "partners"}
      <fieldset id="partners">
        <Partners
          ticket_types={thinkin.ticket_types}
          thinkin_audience={thinkin.audience}
          on:change={updatePartnersAndSponsors} />
      </fieldset>
    {:else if $activeTab == "polls"}
      <div id="polls">
        <Polls {thinkin} />
      </div>
    {:else if $activeTab == "slides"}
      <Slides thinkin={thinkin.id} />
    {:else if $activeTab == "recap"}
      <fieldset id="recap">
        <Recap
          {thinkin}
          on:change={handleChange}
          on:show={() => {
            showNewRecap = true;
            $activeTab = "recap.video";
          }} />
      </fieldset>
    {:else if $activeTab === "recap.video"}
      <fieldset>
        <Video
          bind:video
          bind:transcript
          bind:comments
          on:change={handleChange}
          on:show={() => {
            showNewRecap = false;
            $activeTab = "recap";
          }} />
      </fieldset>
    {:else if $activeTab === "recap.readout"}
      <fieldset>
        <ReadOut {thinkin} {staff} readoutAuthor={thinkin.readout_author} on:change={setReadoutAuthor} />
      </fieldset>
    {:else if $activeTab === "recap.playlist"}
      <fieldset>
        <Playlist {spotifyUrl} on:change={setSpotifyUrl} />
      </fieldset>
    {:else}
      <h1>WARNING: {$activeTab}</h1>
    {/if}
  </div>
</main>

<style>
  main {
    padding: 40px 0 0 180px;
    width: 100%;
    height: 100%;
  }
  header {
    display: grid;
    grid-template-columns: 180px auto 350px;
    padding: 0 5px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 3;
  }
  aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 100%;
    padding: 41px 0 30px;
    background: #001a38;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
  }
  nav button,
  .home {
    font-family: Koopman;
    font-size: 16px;
    color: var(--lettuce);
    display: block;
    text-align: left;
    padding: 10px 20px;
    width: 100%;
    border-radius: 0;
  }
  nav button.active {
    color: #fff;
  }

  nav button.active:not(.nav-subitem) {
    background-color: #000;
  }

  nav button.warn {
    background-image: url(/assets/warning.svg);
    background-repeat: no-repeat;
    background-position: 150px 12px;
    background-size: 18px 18px;
  }
  .subnav {
    list-style: none;
    margin: 0;
    padding-left: 20px;
  }
  a.home {
    grid-column: 1;
    font-weight: normal;
    padding-left: 10px;
  }
  h1 {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    grid-column: 2;
    padding: 10px 10px 10px 30px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  header > div {
    grid-column: 3;
    text-align: right;
    padding: 3px;
  }
  .status {
    opacity: 0.6;
    margin: 0;
    font-size: 14px;
    padding: 8px;
    white-space: nowrap;
  }
  header .pillbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
  header button[name="action"] {
    height: 32px;
    margin: 0;
    min-width: 100px;
    padding-left: 14px;
    padding-right: 6px;
    white-space: nowrap;
  }
  header button[name="action"]:last-child {
    padding-right: 14px;
  }
  .warning .btn {
    opacity: 1;
    background-color: var(--orange);
    color: #fff;
  }
  button.menu {
    position: relative;
    min-width: 0;
    width: 26px;
    height: 32px;
    padding: 3px 0 0;
    margin: 0;
    border-left: 0.5px solid rgba(0, 0, 0, 0.1);
  }
  button.menu svg {
    transform: translateX(-2px);
  }

  .tabbed {
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
  }
  .tabbed > * {
    padding: 20px 40px;
    margin: 0;
    height: 100%;
    max-width: 100%;
    position: relative;
    text-align: left;
    overflow-y: auto;
  }

  #attendees {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  input[name="thinkin_name"],
  input[name="thinkin_type"],
  input[name="excerpt"],
  output {
    width: 74%;
  }

  .validated-input label {
    flex-shrink: 0;
  }

  .validated-input span {
    flex-grow: 1;
    padding-right: 10px;
  }

  .validated-input span input {
    width: 100%;
  }

  .poster {
    width: 240px;
    height: 135px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 30px;
    left: 30px;
    margin: 0;
  }
  .poster img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .poster input {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .poster button[name="remove"] {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    opacity: 0;
    transition: opacity 200ms;
  }
  .poster:hover button[name="remove"] {
    opacity: 1;
  }
  .poster span {
    position: absolute;
    pointer-events: none;
    text-align: center;
    width: 100%;
    top: 50%;
    margin-top: -15px;
    opacity: 0.5;
  }
  .poster:hover span {
    opacity: 1;
  }

  #partners {
    width: 100%;
  }
  .divider {
    border-top: 1px solid #ddd;
  }
  #settings fieldset {
    padding-left: 200px;
    width: 900px;
  }
  #hostsandguests {
    max-width: 100%;
  }
  #partners {
    max-width: 1200px;
  }
  .html-editor {
    margin-bottom: 5px;
  }
  .html-editor > div {
    margin-left: 25%;
    margin-right: 10px;
  }
  .select-editable-wrapper {
    --height: 28px;
    --inputColor: #333;
    --clearSelectTop: 0;
    --clearSelectBottom: 0;
    --indicatorColor: #066;
    --indicatorTop: 4px;
    --indicatorRight: 3px;
    --itemColor: #333;
    --itemHoverBG: #e0e6e6;
    --selectedItemPadding: 0 3px;
    --listBackground: #fff;
    --itemIsActiveBG: rgba(0, 102, 102, 0.2);
    --itemIsActiveColor: var(--midnight);
    --border: none;
    --borderRadius: 0;
    --inputPadding: 6px 3px;
    --inputFontSize: 16px;
    --clearSelectRight: 2px;
    --clearSelectHoverColor: red;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .select-editable-wrapper > div {
    width: 200px;
    color: #333;
    z-index: 5;
  }
  :global(.selectContainer) {
    --padding: 0 !important;
  }

  select[name="audience"],
  input[name="thinkin_type"] {
    width: 200px;
  }

  button[name="addinstr"] {
    color: var(--lettuce);
    font-size: 14px;
    padding-left: 0;
  }

  output {
    color: rgba(255, 255, 255, 0.5);
  }
  output a {
    color: var(--lettuce);
  }

  .error {
    color: var(--red);
  }

  .tags {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
  }

  .modal-text {
    white-space: initial;
  }

  @media (max-width: 1100px) {
    #settings .poster {
      position: static;
      margin-bottom: 20px;
      margin-left: 25%;
    }
    #settings fieldset {
      padding-left: 0;
      width: 100%;
    }
  }
</style>
