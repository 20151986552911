<script lang="ts">
  // interface PersonForCaterpillar {
  //   name: string;
  //   image?: string;
  // }
  // interface $$Props {
  //   people: PersonForCaterpillar[];
  // }

  export let people;
</script>

<div>
  {#each people as person (person.name)}
    {#if person.image}
      <img src={person.image} alt={person.name} />
    {/if}
  {/each}
  <p>{people.map(({ name }) => name).join(", ")}</p>
</div>

<style>
  img {
    margin-top: var(--spacer-medium);
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: 50%;
  }

  img + img {
    margin-left: -10px;
  }

  p {
    margin: var(--spacer-tiny) 0 0 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    font-family: koopman;
  }

  @media only screen and (max-width: 400px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
</style>
