<script>
  import { createEventDispatcher } from "svelte";
  import { Duration } from "luxon";
  import { linkify } from "../../../common/helpers";
  const dispatch = createEventDispatcher();
  export let time = {};
  export let speaker = "";
  export let utterance = "";
  $: displayUtterance = linkify(utterance);
  export let isHighlighted = false;
  export let doesVideoExist;
  let utteranceBox;
  let speakerBox;

  function handleEditStart() {
    dispatch("begin-editing");
  }

  function getValueFromContentEditable(element) {
    element.blur();
    return element.textContent;
  }

  function emitRenameSpeaker() {
    const from = speaker;
    const to = speakerBox.textContent;
    dispatch("rename-speaker", { from, to });
  }

  function handleSpeakerKeypress(e) {
    const isSubmitKeypress = e.type === "keypress" && e.key === "Enter";
    if (isSubmitKeypress) {
      emitRenameSpeaker();
    }
  }

  function emitEditUtterance() {
    // multiple newlines don't render in Chrome's subtitles, even though I believe multiline comments with empty lines are up to spec.
    const value = getValueFromContentEditable(utteranceBox)
      .replaceAll(/\n+\s*/g, "\n")
      .trim();
    dispatch("edit-utterance", { value });
  }

  function handleUtteranceKeypress(e) {
    const isSubmitKeypress = e.type === "keypress" && e.key === "Enter" && !e.shiftKey;
    if (isSubmitKeypress) {
      emitEditUtterance();
    }
  }

  function handleResync() {
    dispatch("resync", { resyncFrom: time.start });
  }
</script>

<div class="statement">
  <span class="timestamp">
    <p>{Duration.fromMillis(time.start).toFormat("hh:mm:ss")}</p>
    {#if doesVideoExist}
      <button class="resync" on:click={handleResync}>Resync</button>
    {/if}
  </span>
  <span class="content">
    <p
      class="editable speaker"
      contenteditable
      bind:this={speakerBox}
      on:click={handleEditStart}
      on:keypress={handleSpeakerKeypress}
      on:blur={emitRenameSpeaker}>
      {speaker}
    </p>
    <p
      class="editable utterance"
      contenteditable
      bind:this={utteranceBox}
      bind:innerHTML={displayUtterance}
      on:click={handleEditStart}
      on:keypress={handleUtteranceKeypress}
      on:blur={emitEditUtterance} />
  </span>
  <span class="buttons">
    <div class="star" class:selected={isHighlighted} on:keydown={() => dispatch("highlight")} on:click={() => dispatch("highlight")} />
    <div class="delete" on:click={() => dispatch("delete-statement")} on:keydown={() => dispatch("delete-statement")} />
  </span>
</div>

<style>
  .statement {
    --timestamp-width: 100px;
    --buttons-width: 50px;
    display: flex;
    padding: 15px 10px 15px 20px;
  }

  .statement:hover {
    background-color: white;
  }

  .resync {
    display: none;
  }

  .statement:hover .resync,
  .statement:focus .resync {
    display: unset;
    font-size: 14px;
    color: var(--text-grey);
    border: 1px solid var(--text-grey);
    margin-top: 5px;
  }

  .timestamp {
    width: var(--timestamp-width);
    min-height: 55px;
  }

  .content {
    width: calc(100% - (var(--timestamp-width) + var(--buttons-width)));
  }

  .speaker {
    font-weight: bolder;
    /* can still be clicked if empty */
    min-height: 1em;
    min-width: 3em;
  }

  .utterance {
    white-space: pre-wrap;
    display: unset; /* inherits global style of flex */
  }

  .editable:focus {
    outline: none;
  }

  .buttons {
    width: var(--buttons-width);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .delete,
  .star {
    margin: 4px 0;
    width: 20px;
    height: 20px;
    background-color: lightgrey;
    mask-repeat: no-repeat;
    mask-size: 20px;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 20px;
    -webkit-mask-position: center;
    opacity: 0;
  }
  .statement:hover .delete,
  .statement:hover .star {
    opacity: 1;
  }

  .delete:hover {
    background-color: grey;
  }

  .star:hover:not(.selected) {
    background-color: var(--kelp);
    opacity: 0.6;
  }

  .star.selected {
    background-color: var(--kelp);
    opacity: 1;
  }

  .delete {
    mask-image: url("/assets/delete.svg");
    -webkit-mask-image: url("/assets/delete.svg");
  }

  .star {
    mask-image: url("/assets/grey-star.svg");
    -webkit-mask-image: url("/assets/grey-star.svg");
  }

  p {
    margin: 0;
    color: var(--text-grey);
  }
  p.utterance {
    color: #333;
  }
</style>
