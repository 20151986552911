<script>
  import { createEventDispatcher } from "svelte";
  import Select from "svelte-select";
  import { selectImageFromComputer, deleteResource } from "../../common/helpers";

  export let thinkin;
  export let person;
  export let staff;
  export let host = false;
  const dispatch = createEventDispatcher();
  const staff_names = staff.map(member => member.name);

  let image_input;
  let full_name = ((person.first_name || "") + " " + (person.last_name || "")).trim();
  let deleting = false;

  function handleUpdate() {
    splitName(full_name);
    dispatch("change", { person });
  }

  function splitName(full_name) {
    // TODO: support titles
    const names = full_name.trim().split(" ");
    if (names.length) {
      person.first_name = names.shift();
    } else {
      person.first_name = null;
    }
    if (names.length) {
      person.last_name = names.join(" ");
    } else {
      person.last_name = null;
    }
  }

  async function removePerson() {
    deleting = true;
    if (person.uuid) {
      const url = `/thinkins/${thinkin.id}/participants/${person.uuid}`;
      await deleteResource(url);
    }
    deleting = false;
    dispatch("remove", { key: person.key });
  }

  function selectImage(e) {
    selectImageFromComputer(e, event => {
      person.profile_picture = event.target.result;
    });
  }

  async function handleSelect(event) {
    full_name = event.detail.value;
    const selection = staff.filter(member => member.name == full_name)[0];
    if (selection) {
      Object.assign(person, selection);
    }
    handleUpdate();
  }

  function copyGuestLink() {
    const key = person.key;
    const url = `${document.location.origin}/thinkin/${thinkin.id}?key=${encodeURIComponent(key)}`;
    navigator.clipboard.writeText(url);
  }
</script>

<form class="person" class:host class:deleting on:input={handleUpdate}>
  <figure class="thumbnail" title="Click to replace image">
    {#if person.profile_picture}
      <img
        src={person.profile_picture}
        on:click={() => {
          image_input.click();
        }}
        on:keydown={() => {
          image_input.click();
        }}
        alt="" />
    {:else}
      <img
        src="/assets/person.svg"
        alt=""
        class="placeholder"
        on:click={() => {
          image_input.click();
        }} 
        on:keydown={() => {
          image_input.click();
        }} />
    {/if}
    <input
      style="display:none"
      type="file"
      accept=".jpg, .jpeg, .png"
      on:change={selectImage}
      bind:this={image_input} />
  </figure>

  <fieldset>
    {#if host && !full_name}
      <div class="select-editable-wrapper">
        <Select
          items={staff_names}
          isClearable={false}
          hideEmptyState={false}
          showIndicator={false}
          value={full_name}
          isCreatable={true}
          placeholder="Full name"
          on:select={handleSelect} />
      </div>
    {:else}
      <input type="text" placeholder="Full name" autocomplete="off" bind:value={full_name} />
    {/if}

    <input type="email" placeholder="Email" bind:value={person.email} />
    <input type="text" placeholder="Role" bind:value={person.job_title} />
    <textarea placeholder="Biographical note" bind:value={person.biography} />
    <slot />
    {#if !host && thinkin.audience !== "team" && thinkin.platform == "Tortoise"}
      <button type="button" class="btn" name="copy-link" on:click={copyGuestLink}>Copy invite link</button>
    {/if}

    <button type="button" name="remove" on:click={removePerson}>✕</button>
  </fieldset>
</form>

<style>
  .person {
    width: 400px;
    max-width: 100%;
    background: #fff;
    position: relative;
    margin: 10px;
    padding: 0 0 0 120px;
  }
  .thumbnail {
    height: 100px;
    width: 100px;
    background: #ddd;
    text-align: center;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
  }
  .thumbnail > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .thumbnail:hover {
    opacity: 0.9;
  }

  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #eee;
    padding: 4px 8px;
  }
  textarea {
    width: 100%;
    height: 70px;
    padding: 8px;
    resize: none;
  }
  button[name="remove"] {
    position: absolute;
    top: 0;
    right: 0;
    color: #333;
    opacity: 0;
    transition: opacity 200ms;
  }
  .person:hover button[name="remove"] {
    opacity: 1;
  }
  button[name="remove"]:hover {
    color: var(--error);
  }

  button[name="copy-link"] {
    font-family: Koopman;
    color: var(--kelp);
    border-color: var(--kelp);
    border-radius: 0;
    margin: 4px 6px;
    padding: 2px 5px 2px 28px;
    background-image: url(/assets/link-kelp.svg);
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 14px;
    height: 24px;
    white-space: nowrap;
  }
  button[name="copy-link"]:active {
    color: var(--lettuce);
  }
  img.placeholder {
    opacity: 0.5;
  }
  input:focus,
  textarea:focus {
    background-color: var(--stone);
  }
  .select-editable-wrapper {
    --height: 36px;
    --inputColor: #333;
    --clearSelectTop: 0;
    --clearSelectBottom: 0;
    --indicatorColor: #066;
    --indicatorTop: 4px;
    --indicatorRight: 3px;
    --itemColor: #333;
    --itemHoverBG: #eee;
    --selectedItemPadding: 0 3px;
    --listBackground: #fff;
    --itemIsActiveBG: rgba(0, 102, 102, 0.2);
    --itemIsActiveColor: var(--midnight);
    --border: none;
    --borderRadius: 0;
    --inputPadding: 6px 10px;
    --inputFontSize: 16px;
    color: #333;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
  }
  .deleting {
    opacity: 0.5;
    pointer-events: none;
  }
</style>
