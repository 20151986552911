// I wish I could tell you that these were all the membership types, but that's not right.
// These are all the member facing membership type names that you can find in this spreadhseet.
// Multiple membership types can have the same friendly name, and a membership type friendly name can have multiple membership types.
// What even is a membership 'type'? At time of writing I do not know.
// The spreadsheet describes entitlements but in discussion with the membership team, I've noticed they don't think a type is the same as an entitlement.
// https://docs.google.com/spreadsheets/d/1wtDLIPX1UWWicu4DjuA1Cc4k8IMU3_VsqHPMLTXwB5I/edit#gid=0
export var MEMBERSHIP_TYPE_PUBLIC_FACING_NAME;
(function (MEMBERSHIP_TYPE_PUBLIC_FACING_NAME) {
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["DIGITAL"] = "Digital";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["NEWSROOM"] = "Newsroom";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["FOT"] = "Friend of Tortoise";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["FOUNDING"] = "Founding";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["STUDENT"] = "Student";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["COMMUNITY_NETWORK"] = "Community Network";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["GUEST"] = "Guest";
    MEMBERSHIP_TYPE_PUBLIC_FACING_NAME["STAFF"] = "Staff";
})(MEMBERSHIP_TYPE_PUBLIC_FACING_NAME || (MEMBERSHIP_TYPE_PUBLIC_FACING_NAME = {}));
export var USER_ENTITLEMENT_CATEGORY;
(function (USER_ENTITLEMENT_CATEGORY) {
    USER_ENTITLEMENT_CATEGORY["DIGITAL"] = "Digital";
    USER_ENTITLEMENT_CATEGORY["DIGITAL_PLUS"] = "Digital + 12 ThinkIn tickets";
    USER_ENTITLEMENT_CATEGORY["NEWSROOM"] = "Newsroom";
    USER_ENTITLEMENT_CATEGORY["FOT"] = "Friend of Tortoise";
})(USER_ENTITLEMENT_CATEGORY || (USER_ENTITLEMENT_CATEGORY = {}));
