<script>
  import Message from "./Message";

  export let threads;
  export let host;
  export let right;

</script>

  {#if threads.length > 0}
    {#each threads as message}
    <div class="thread-message" class:right>
      <Message {...message} {host}  />
    </div>
    {/each}
  {/if}


<style>
 .thread-message{
    margin-left: 40px;
  }
  .right {
    margin: initial;
    margin-left: 40px;
  }
</style>

