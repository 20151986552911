import { writable, derived } from "svelte/store";

export const messages = writable([]);

export const privateMessages = writable(getPrivateMessages());
privateMessages.subscribe(value => localStorage.setItem("privatemessages", JSON.stringify(value)));

export const totalUnreadMessages = derived(privateMessages, ($privateMessages) => countUnreadMessages($privateMessages));

function getPrivateMessages() {
	let messages = localStorage.getItem("privatemessages");
	if (messages) return(JSON.parse(messages));
	else return {};
}

function countUnreadMessages(privateMessages) {
	let count = 0;
	Object.keys(privateMessages).map((user_id) => {
		count += privateMessages[user_id].unreadMessages;
	});
	return count;
}

export const activeChatTab = writable("All messages");
export const selectedUserFromDropdown = writable(null);
