<script>
  export let error = "It doesn’t look like you’re a member or a subscriber yet.";
</script>

<div>
  <p>{error}</p>
  <p>Please sign in. Or if you think this is a mistake, <a href="mailto:members@tortoisemedia.com">contact us</a></p>
</div>

<style>
  div {
    min-height: 100vh;
    min-width: 100vw;
    background-color: var(--app-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }

  p {
    font-family: Pegasus;
    color: var(--text-grey);
    font-weight: lighter;
    margin: 5px 0;
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (min-width: 645px) {
    p {
      font-size: 24px;
      line-height: 34px;
    }
  }
</style>
