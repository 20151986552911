<script>
  import { onMount } from "svelte";
  import Auth from "@aws-amplify/auth";
  import { params, goto } from "@sveltech/routify";

  import { fetchData, saveResource } from "../../../common/helpers";
  import { thinkin, user } from "../../../stores/sessionStore";
  import { checkUser } from "../../../common/authoriser";
  
  import Lobby from "../../../components/lobby/Lobby";
  import Live from "../../../components/live/Live";

  $: thinkin_id = $params.id;
  $: canUnmute = $thinkin && ($thinkin.audience == "team" || $thinkin.unmuting);
  $: is_live = $thinkin.status == "Live" || $thinkin.status == "Green room";

  let user_joined = false;
  let user_left = false;

  // temp 
  if ($params.id == 993) {
    $goto("/thinkin/992" + document.location.search);
  }
  
  async function fetchThinkin() {
    let url = `/public/thinkins/${thinkin_id}`;
    const data = await fetchData(url);
    // TODO: better error handling
    if (typeof data == "string") throw data;
    return data;
  }

  $: if (thinkin_id) {
    fetchThinkin().then((data) => {
      thinkin.set(data);
      document.title = "🐢 " + data.name;
    });
  }

  function joinThinkin(event) {
    console.log("guest index: joinThinkin");
    user_joined = true;
    user_left = false;
  }

  function rejoinThinkin() {
    console.log("rejoining");
    user_left = false;
    user_joined = true;
  }

  async function leftThinkin() {
    user_left = true;
    await saveResource("PUT", `/thinkins/${ $thinkin.id }/participants/${$user.uuid}`, { left_at: new Date() } );
    // Need to force a page reload to get the camera to switch off on Safari
    document.location.href = `${ document.location.origin }${ document.location.pathname }/exit`;
  }

  async function handleGoogleSignIn() {
    localStorage.setItem("thinkinRedirectPath", window.location.pathname)
    Auth.federatedSignIn({ provider: "Google" });
  }

  function fixScreenHeight() {
    document.body.style.height = window.innerHeighht + "px";
  }
  window.addEventListener("resize", fixScreenHeight);
  fixScreenHeight();

</script>

{#if !$thinkin.id}
  <div class="fill-screen center-all loading">
    <p>Loading…</p>
  </div>
{:else if $thinkin.status == "Draft" || $thinkin.status == "Archived"}
  <div class="fill-screen center-all">
    <h2>Page not found</h2>
  </div>
{:else}

  {#if $thinkin.audience == "team"}
    {#await checkUser()}
      <p>Loading…</p>
    {:then userData}
      {#if user_joined}
        <Live {canUnmute} on:leave={leftThinkin} />
      {:else if userData}
        <Lobby {thinkin} on:join={joinThinkin} />
      {:else}
        <div class="fill-screen center-all">
          <div>
            <button name="member-login" value="googleLogin" class="btn primary" on:click={handleGoogleSignIn}>Sign in with Google</button>
            <p>Use your company account</p>
          </div>
        </div>
      {/if}
    {:catch error}
      <h1 class="error">Sorry, something has gone amiss</h1>
      <p>{error}</p>
    {/await}
  
  {:else}
    <!-- Not a meeting -->
    {#if is_live && user_joined && !user_left}
      <Live {canUnmute} on:leave={leftThinkin} />
    {:else}
      <Lobby {thinkin} on:join={joinThinkin} />
    {/if}
  {/if}
{/if}

<style>
  .center-all {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
