import { get } from "svelte/store";
import mic from "microphone-stream"; // collect microphone input as a stream of raw bytes
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const marshaller = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON

import { session } from "./session";
import { thinkin, user } from "../../stores/sessionStore";
import { fetchData } from "../../common/helpers";

const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);

let sampleRate = 8000;
let inputSampleRate;
let transcription = "";
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

export function getUserAudioInput() {
  window.navigator.mediaDevices
    .getUserMedia({
      video: false,
      audio: true,
    })
    // ...then we convert the mic stream to binary event stream messages when the promise resolves
    .then((audio) => {
      return streamAudioToWebSocket(audio);
    });
}

let streamAudioToWebSocket = async function (userMediaStream) {
  //let's get the mic input from the browser, via the microphone-stream module
  micStream = new mic();

  micStream.on("format", function (data) {
    inputSampleRate = data.sampleRate;
  });
  
  micStream.setStream(userMediaStream);
  
  // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
  // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
  let url = await fetchData(`/getSignedUrl`);
  //open up our WebSocket connection
  socket = new WebSocket(url);
  socket.binaryType = "arraybuffer";
  
  let sampleRate = 0;
  
  // when we get audio data from the mic, send it to the WebSocket if possible
  socket.onopen = function () {
    micStream.on("data", function (rawAudioChunk) {
      // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
      let binary = convertAudioToBinaryMessage(rawAudioChunk);

      if (socket.readyState === socket.OPEN) socket.send(binary);
    });
  };

  // handle messages, errors, and close events
  wireSocketEvents();
};

function wireSocketEvents() {
  // handle inbound messages from Amazon Transcribe
  socket.onmessage = function (message) {
    //convert the binary event stream message to JSON
    let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
    let messageBody = JSON.parse(
      String.fromCharCode.apply(String, messageWrapper.body)
    );
    if (messageWrapper.headers[":message-type"].value === "event") {
      handleEventStreamMessage(messageBody);
    } else {
      transcribeException = true;
      showError(messageBody.Message);
    }
  };

  socket.onerror = function () {
    socketError = true;
    showError("WebSocket connection error. Try again.");
  };

  socket.onclose = function (closeEvent) {
    micStream.stop();

    // the close event immediately follows the error event; only handle one.
    if (!socketError && !transcribeException) {
      if (closeEvent.code != 1000) {
        showError(
          "</i><strong>Streaming Exception</strong><br>" + closeEvent.reason
        );
      }
    }
  };
}

export function handleEventStreamMessage(messageJson) {
  let results = messageJson.Transcript.Results;
  if (results.length > 0) {
    if (results[0].Alternatives.length > 0) {
      const speaker = get(user)
      const speakerName = speaker.name
      const speakerId = speaker.id
      
      let transcript = results[0].Alternatives[0].Transcript;

      let signalData = {transcript, speakerName, speakerId}
      session.signal("startTranscribe", {signalData})
    }
  }
}

function convertAudioToBinaryMessage(audioChunk) {
  let raw = mic.toRaw(audioChunk);

  if (raw == null) return;

  // downsample and convert the raw audio bytes to PCM
  let downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate);
  let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

  // add the right JSON headers and structure to the message
  let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

  //convert the JSON object + headers into a binary event stream message
  let binary = eventStreamMarshaller.marshall(audioEventMessage);

  return binary;
}

function getAudioEventMessage(buffer) {
  // wrap the audio data in a JSON envelope
  return {
    headers: {
      ":message-type": {
        type: "string",
        value: "event",
      },
      ":event-type": {
        type: "string",
        value: "AudioEvent",
      },
    },
    body: buffer,
  };
}

export function closeSocket() {
  if (socket && socket.readyState === socket.OPEN) {
    micStream.stop();

    // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
    let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
    let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
    socket.send(emptyBuffer);
  }
}

function pcmEncode(input) {
  var offset = 0;
  var buffer = new ArrayBuffer(input.length * 2);
  var view = new DataView(buffer);
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
}

function downsampleBuffer(
  buffer,
  inputSampleRate = 44100,
  outputSampleRate = 8000
) {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }
  var sampleRateRatio = inputSampleRate / outputSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Float32Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;

  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

    var accum = 0,
      count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
}

function showError(message) {
  console.log("Error Message:", message);
}
