<script>
    import {createEventDispatcher} from "svelte";
    import {THINKIN_DEFAULT_LOCATION} from "@galapagos/shared";

    export let thinkin;

    console.log(thinkin);

    const dispatch = createEventDispatcher();

    $: public_url = `${document.location.origin}/thinkin/${thinkin.id}`;

    let digital_available = !!thinkin.digital_ticket_count;
    $: setDefaultDigitalTickets(digital_available);

    let in_person_available = !!thinkin.in_person_ticket_count;
    $: in_person_member_available = in_person_available;
    $: in_person_non_member_available = in_person_available;

    const memberPriceFollowsTicketAvailability = (availability) => {
        if(availability) {
            if(thinkin.in_person_ticket_member_price === null) {
                thinkin.in_person_ticket_member_price = 0;
            }
        } else {
            thinkin.in_person_ticket_member_price = null;
        }
    };

    const nonMemberPriceFollowsTicketAvailability = (availability) => {
        if(availability) {
            if(thinkin.in_person_ticket_non_member_price === null) {
                thinkin.in_person_ticket_non_member_price = 15;
            }
        } else {
            thinkin.in_person_ticket_non_member_price = null;
        }
    };
    
    $: memberPriceFollowsTicketAvailability(in_person_member_available);
    $: nonMemberPriceFollowsTicketAvailability(in_person_non_member_available);

    $: setDefaultInPersonTickets(in_person_available);

    let copied_to_clipboard = false;

    function setDefaultDigitalTickets(available) {
        if (!available) {
            thinkin.digital_ticket_count = 0;
        } else if (!thinkin.digital_ticket_count) {
            thinkin.digital_ticket_count = 500;
        }
    }

    function setDefaultInPersonTickets(available) {
        if (!available) {
            thinkin.in_person_ticket_count = 0;
        } else if (!thinkin.in_person_ticket_count) {
            thinkin.in_person_ticket_count = 50;
        }
    }

    function copyURLToClipboard() {
        navigator.clipboard.writeText(public_url).then(
            () => {
                copied_to_clipboard = true;
                setTimeout(() => {
                    copied_to_clipboard = false;
                }, 2000);
            },
            err => {
                console.error(err);
            }
        );
    }

    function handleChange() {
        dispatch("change");
    }

    function toggleDigital() {
        digital_available = !digital_available;
        setDefaultDigitalTickets(digital_available);
        thinkin.using_external_booking_provider = !(in_person_available || digital_available);
    }

    function toggleInPerson() {
        in_person_available = !in_person_available;
        setDefaultInPersonTickets(in_person_available);
        thinkin.using_external_booking_provider = !(in_person_available || digital_available);
        if (in_person_available && !thinkin.location) {
            thinkin.location = THINKIN_DEFAULT_LOCATION;
        }
    }

    function toggleInPersonMember() {
        in_person_member_available = !in_person_member_available;
    }

    function setInPersonMemberPrice(e) {
        const price = Math.round(Number(e.target.value) || 0);

        thinkin.in_person_ticket_member_price = price;
    }

    function toggleInPersonNonMember() {
        in_person_non_member_available = !in_person_non_member_available;
    }

    function setInPersonNonMemberPrice(e) {
        const price = Math.round(Number(e.target.value) || 0);
        thinkin.in_person_ticket_non_member_price = price;
    }

    function toggleExternal() {
        thinkin.using_external_booking_provider = !thinkin.using_external_booking_provider;
        if (thinkin.using_external_booking_provider) {
            digital_available = !thinkin.using_external_booking_provider;
            in_person_available = !thinkin.using_external_booking_provider;
            setDefaultDigitalTickets(digital_available);
            setDefaultInPersonTickets(digital_available);
        }
    }
</script>

<form on:input={handleChange} class="ticket-types">
    <p class="ticket-category">
        <label>
            <input type="checkbox" name="digital_available" checked={digital_available} on:click={toggleDigital}/>
            Digital tickets
            {#if !digital_available}not{/if} available
        </label>
    </p>

    {#if digital_available}
        <p>
            <label for="platform">Platform</label>
            <select size="1" name="platform" bind:value={thinkin.platform}>
                <option value="Zoom">Zoom</option>
                <option value="Teams">Teams</option>
                <option value="Twitter">Twitter</option>
                <option value="Clubhouse">Clubhouse</option>
                <option value="">None</option>
            </select>
        </p>
        <p>
            {#if thinkin.platform && thinkin.platform !== "Tortoise"}
                <label for="external_url">Live URL</label>
                <input
                    required
                    name="external_url"
                    type="url"
                    bind:value={thinkin.external_url}
                    placeholder="https://"
                    autocomplete="off"/>
            {:else if thinkin.id}
                <label for="thinkin_link">Live URL</label>
                <output id="thinkin_link"><a href={public_url} target="_blank">{public_url}</a></output>
                <span id="copy-to-clipboard" class={copied_to_clipboard ? "copied" : ""}>
          <img
              src="/assets/link.svg"
              alt="Copy to clipboard"
              on:click={copyURLToClipboard}
              on:keydown={copyURLToClipboard}/>
        </span>
            {/if}
        </p>
        <p>
            <label for="target">Target</label>
            <input required name="target" type="number" bind:value={thinkin.digital_ticket_count} placeholder={500}/>
            <span>attendees</span>
        </p>
    {/if}

    <p class="ticket-category in-person">
        <label>
            <input type="checkbox" name="in_person_available" checked={in_person_available} on:click={toggleInPerson}/>
            In-person tickets
            {#if !in_person_available}not{/if} available
        </label>
    </p>

    {#if in_person_available}
        <p>
            <label for="location">Location</label>
            <input type="text" name="location" bind:value={thinkin.location} placeholder={THINKIN_DEFAULT_LOCATION}/>
        </p>
        <p>
            <label for="in_person_tickets">Capacity</label>
            <input
                required
                name="in_person_tickets"
                type="number"
                bind:value={thinkin.in_person_ticket_count}
                placeholder={0}/>
            <span>maximum</span>
        </p>
        <p class="ticket-category">
            <label>
                <input type="checkbox" checked={in_person_non_member_available} on:click={toggleInPersonNonMember}/>
                Non-member tickets
                {#if !in_person_non_member_available}not{/if} available
            </label>
        </p>
        {#if in_person_non_member_available}
            <p>
                <label for="non_member_pricing">£</label>
                <input
                    required
                    name="non_member_digital_tickets"
                    type="number"
                    value={thinkin.in_person_ticket_non_member_price}
                    on:change={setInPersonNonMemberPrice}/>
                <span>standard price (if available)</span>
            </p>
        {/if}
        <p class="ticket-category">
            <label>
                <input type="checkbox" checked={in_person_member_available} on:click={toggleInPersonMember}/>
                Member tickets
                {#if !in_person_member_available}not{/if} available
            </label>
        </p>
        {#if in_person_member_available}
            <p>
                <label for="member_pricing">£</label>
                <input
                    required
                    name="member_pricing"
                    type="number"
                    value={thinkin.in_person_ticket_member_price}
                    on:change={setInPersonMemberPrice}/>
                <span>member price</span>
            </p>
        {/if}
    {/if}

    <p class="ticket-category managed-externally">
        <label>
            <input
                type="checkbox"
                name="in_person_available"
                checked={thinkin.using_external_booking_provider}
                on:click={toggleExternal}/>
            Tickets are{thinkin.using_external_booking_provider ? " " : " not "}managed through an external provider.
        </label>
    </p>
    {#if thinkin.using_external_booking_provider}
        <p>
            <label for="external_bookng_url">Booking URL</label>
            <input type="text" name="location" bind:value={thinkin.external_booking_url}/>
        </p>
    {/if}
</form>

<style>
    .ticket-category {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .ticket-category label {
        margin-left: calc(25% - 70px);
    }

    .in-person,
    .managed-externally {
        margin-top: 40px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    output a {
        color: var(--lettuce);
        padding-left: 0;
    }

    input[type="url"],
    input[name="location"] {
        max-width: 500px;
    }

    #copy-to-clipboard {
        position: relative;
    }

    .copied::after {
        content: "Copied to clipboard";
        font-size: 14px;
        opacity: 0.5;
        position: absolute;
        margin-left: 8px;
        margin-top: 2px;
        white-space: nowrap;
    }
</style>
