<script>
import { createEventDispatcher } from 'svelte';

export let color;
export let name = "color";

const dispatch = createEventDispatcher();
const colors = ["#006666", "#dce459", "#e04600", "#002850", "#ffd932", "#F2A5B1", "#33A6C8", "#fff", "#000"];

function pick(value) {
  color = value;
  dispatch("pick", { name: name, color: value });
}
</script>

<div class="palette">
{#each colors as swatch}
  <span class:selected={color == swatch} 
    style={"background-color:" + swatch} 
    on:keydown={() => {pick(swatch);}}
    on:click={() => {pick(swatch);}}></span>
{/each}
</div>

<style>
.palette {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
span {
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  transition: transform 100ms;
  cursor: pointer;
}
span:hover {
  transform: scale(1.1);
}
span.selected {
  outline: 1px solid rgba(255,255,255,.3);
  transform: scale(1.1);
}
</style>
