// A wrapper around Tokbox, to make it easier to swap out for another platform
import { writable } from "svelte/store";

let tokbox_session;
let listeners = {};

let current_uuid;
let auth_token;
let is_host = false;

let session_started = false;
let session_joined = false;
let connected = false;
let participants = new Map();
let publisher;
let screenshare_publisher;
let screenshare_element;
let camera_id;
let mic_id;
let is_streaming = false;
let publisher_parent;
let me;

function connect(thinkin, uuid) {
  if (OT.checkSystemRequirements() !== 1) {
  	// TODO: need public facing error
    throw "Your browser doesn’t support webRTC";
    return;
  }
	if (tokbox_session) {
		// already connected
		console.warn("trying to connect when already connected");
		return Promise.resolve(); 
	}
	OT.setLogLevel(OT.INFO);

	current_uuid = uuid;
	auth_token = thinkin.token;
	tokbox_session = OT.initSession(thinkin.apiKey, thinkin.session_id);
	if (tokbox_session.connection) console.warn("ALREADY HAVE A CONNECTION!");

  return new Promise((resolve, reject) => {
		tokbox_session.connect(auth_token, (error) => {
			if (error) {
				console.warn("connecting to session failed", error);
				reject(error);
        return;
      }
      connected = true;
		  startListeners();
      console.debug("loading initial connections", tokbox_session.connections.length());
      console.log("capabilities", tokbox_session.capabilities);
      tokbox_session.connections.forEach((connection) => addConnection(connection, true));
      tokbox_session.streams.forEach((stream) => addStream(stream, true));
      resolve();
    });
  });
}

function getAuthToken() {
	return auth_token;
}

function startListeners() {
  tokbox_session.on({
    "connectionCreated": (event) => addConnection(event.connection),
    "connectionDestroyed": removeConnection,
    "streamCreated": (event) => addStream(event.stream),
    "streamDestroyed": (event) => removeStream(event.stream),
    "streamPropertyChanged": streamPropertyChanged,
    "signal": handleSignal,
    "sessionDisconnected": sessionDisconnected,
    "exception": handleError,
  });
}

function fireEvent(type, data, from) {
	if (type.indexOf(":") !== -1) {
		type = type.split(":")[1];
	}
	if (listeners[type]) {
		listeners[type](data, from);
	}
}

function join(hasCamera, hasMic, as_host) {
	console.debug("session.join()", hasCamera, hasMic);
	is_host = as_host;
	session_joined = true;
	me = participants.get(current_uuid);
	if (!me) console.error("trying to join when not yet a participant");
	me.hasCamera = hasCamera;
	me.hasMic = hasMic;

	session.signal("join", { uuid: me.uuid, hasCamera, hasMic, host: is_host });

  return Promise.resolve(
  	Array.from(participants.values()).map((participant) => {
  		const camera = participant.streams.camera;
  		return {
	    	uuid: participant.user.uuid,
				name: participant.user.name,
				job_title: participant.user.job_title,
				location: participant.user.location,
				profile_picture: participant.user.profile_picture,
	    	hasVideo: camera && camera.hasVideo,
	    	hasAudio: camera && camera.hasAudio,
	    	hasScreenshare: !!participant.streams.screen,
	    	streaming: !!camera,
	    }
	  })
	);
}

function handleSignal(event) {
	// Have received a signal from another connection
	// TODO: validate event.from is legit
	const data = event.data ? JSON.parse(event.data) : null;
	const uuid = JSON.parse(event.from.data).uuid;
	console.debug(`%csignal received ${event.type} from ${uuid}`,"color:#999");
	fireEvent(event.type, data, uuid);
}

function addConnection(connection, silent) {
  // Parse out participant from connection.data
  let user = JSON.parse(connection.data);
  if (participants.get(user.uuid)) {
		console.warn(user.uuid, "already have connection", participants.get(user.uuid).connection.id, "vs", connection.id);
  	return;
  }
  console.debug("adding participant connnection", user);
  let participant = {
		uuid: user.uuid,
		connection,
		user,
		streams: {},
	};
	if (user.uuid == current_uuid) {
		me = participant;
	}

	participants.set(user.uuid, participant);

  if (!silent) {
  	fireEvent("added", user);
  }
}

function removeConnection(event) {
  // A user who is not you has disconnected from the session
  console.debug("session.removeConnection()", event.reason);
  let uuid = JSON.parse(event.connection.data).uuid;
  // TODO: just mark as disconnected
  participants.delete(uuid);
  fireEvent("removed", { uuid });
}

function addStream(stream, silent) {
  // retrieve the participant
  console.debug("addStream()", stream);
  let type = stream.videoType;
  let uuid = stream.name;
  let participant = participants.get(uuid);
  participant.streams[type] = stream;
  if (type == "screen") {
		console.log(`%cscreenshare stream added ${ uuid }`, "color:#234A5D");
	  fireEvent("screenshareStarted", { uuid });
  } else {
	  fireEvent("updated", { 
			uuid,
			type,
			hasAudio: stream.hasAudio,
			hasVideo: stream.hasVideo,
			streaming: true,
	  });
	}
}

function streamPropertyChanged(event) {
  if (event.stream.videoType === "screen") {
    console.warn("TODO: screenshare property changed", event);
    return;
  }
  const uuid = event.stream.name;
  const type = event.stream.videoType;
  const name = event.changedProperty;
  const value = event.newValue;

  if (!uuid) return console.warn("ignoring nameless stream event", event);

  switch (name) {
    case "hasAudio":
    case "hasVideo":
			let participant = participants.get(uuid);
			//const has_changed = participant.streams[type][name] !== value;
			//if (!has_changed) return console.warn("nothing changed", name, value);
			participant.streams[type][name] = value;
			let update = { uuid };
			update[name] = value;
			fireEvent("updated", update);
			break;

    case "videoDimensions":
      // not interesting
      break;

    default:
      console.warn(
        "ignored property change",
        event.changedProperty,
        event.newValue
      );
  }
}

function removeStream(stream) {
  console.debug("%csession.removeStream():", "color:red;", stream);
  const uuid = stream.name;
  const type = stream.videoType;
	delete participants.get(uuid).streams[type];
  if (type === "screen") {
	  fireEvent("screenshareEnded", { uuid });
  } else {
  	fireEvent("updated", { uuid, hasVideo: false, hasAudio: false, streaming: false });
  }
}

function getDevices() {
	let video_source = publisher.getVideoSource();
	let audio_source = publisher.getAudioSource();
	return {
		camera_id: video_source ? video_source.deviceId : null,
		mic_id: audio_source ? audio_source.deviceId : null,
	};
}

function startCamera(element, camera, preview_only) {
	console.debug("session.startCamera()", is_streaming, preview_only);
	if (element) publisher_parent = element;
	if (camera) camera_id = camera;
  if (publisher) {
  	console.log("relocating existing publisher element", publisher.element, element);
		publisher_parent.appendChild(publisher.element);
  	publisher.publishVideo(true);
	  // ideally only signal hosts
	  if (session_joined && !preview_only) {
			console.debug("signalling camera has started");
			signal("updated", { uuid: current_uuid, hasCamera: true });
	  }
	  return Promise.resolve(getDevices());
  }
  return startPublisher(true).then(() => {
		return getDevices();
  });
}

function startPublisher(with_camera) {
	console.debug("session.startPublisher", with_camera, camera_id, me);
  return new Promise((resolve, reject) => {
	  let options = {
	    name: current_uuid,
	    insertMode: "append",
	    width: "100%",
	    height: "100%",
	    publishVideo: with_camera,
	    publishAudio: false,
	    resolution: "1280x720", // look at screen size/res?
	    showControls: false,
	    style: {
				backgroundImageURI: (me && me.user && me.user.profile_picture) || "/assets/person-fff.svg"
	    }
	  };
	  if (camera_id) {
	    options.videoSource = camera_id;
	  }
	  console.debug("creating new publisher");

	  publisher = OT.initPublisher(publisher_parent, options, (err) => {
	  	if (err) {
		  	if (err.code == 1500 && err.name == "OT_CONSTRAINTS_NOT_SATISFIED") {
		  		// this probably means a device has been updated
		  		reject("Missing camera or mic");
		  	} else {
		  		reject(err);
		  	}
	  		return;
		  }
		  resolve();
	  });

	  publisher.on("streamDestroyed", (event) => {
	  	console.warn("publisher stream destroyed");
	  	event.preventDefault();
	  });

	  publisher.on("destroyed", (event) => {
			console.warn("publisher destroyed");
			publisher = null;
	  });
	});
}

function startSharingScreen() {
	screenshare_element = document.createElement("div");
	return new Promise((resolve, reject) => {
	  let options = {
	    videoSource: "screen",
	    name: current_uuid,
	    maxResolution: { width: 1920, height: 1080 },
	    insertMode: "append",
	    showControls: false,
	    width: "100%",
	    height: "100%",
	    videoContentHint: "text"
	  };
	  screenshare_publisher = OT.initPublisher(
	    screenshare_element,
	    options,
	    function (err) {
	      if (err) reject(err);
	      else {
		      console.debug("publishing screenshare to session", screenshare_publisher);
		      tokbox_session.publish(screenshare_publisher, handleError);
					console.log(`%cscreenshare stream started ${current_uuid}`, "color:#234A5D");
				  fireEvent("screenshareStarted", { uuid: current_uuid });
		      resolve();
		    }
	    }
	  );
	  screenshare_publisher.on("streamDestroyed", () => {
			console.debug("screenshare stream destroyed");
	    fireEvent("screenshareEnded", { uuid: current_uuid });
	  });
	});
}

function getCameraElement() {
	return publisher && publisher.element;
}

function setCameraParent(element) {
	publisher_parent = element;
	if (publisher && publisher.element) {
		publisher_parent.appendChild(publisher.element);
	}
}

function setCamera(device_id) {
	console.debug("setting camera", device_id);
	camera_id = device_id;
	if (!publisher) return console.warn("no publisher");
	if (camera_id) {
		publisher.setVideoSource(camera_id)
			.then(() => {
				// TODO: is this necessary?
		    publisher.publishVideo(true);
			})
			.catch((err) => {
				console.error("error setting camera", camera_id, err);
			})
  } else {
		publisher.publishVideo(false);
	}
}

function getCamera() {
	if (camera_id) return camera_id;
	if (publisher) {
		let video_source = publisher.getVideoSource();
		if (video_source) return video_source.deviceId;
	}
	return null;
}

function setMic(device_id) {
	mic_id = device_id;
	if (!publisher) return;
	if (mic_id) {
		publisher.setAudioSource(mic_id);
  } else {
		publisher.publishAudio(false);
	}
}

function getMic() {
	if (mic_id) return mic_id;
	if (publisher) {
		let audio_source = publisher.getAudioSource();
		if (audio_source) return audio_source.deviceId;
	}
	return null;
}

function stopCamera() {
	console.debug("session.stopCamera()", is_streaming);
	if (!publisher) return console.warn("no publisher to stop");
	const audio_source = publisher.getAudioSource();
	if (audio_source && audio_source.enabled) {
		publisher.publishVideo(false);
	} else {
		publisher.destroy();
	}
  if (session_joined) {
  	console.debug("signalling camera has stopped");
		signal("updated", { uuid: current_uuid, hasCamera: false });
  }
	return Promise.resolve();
}

function startStreamingCamera() {
	return new Promise((resolve, reject) => {
		if (!publisher) return reject("cannot stream video when haven't started camera");
		if (is_streaming) {
			console.warn("already streaming - republishing");
			publisher.publishVideo(true);
			resolve();
			return;
		}
		is_streaming = true;
	  tokbox_session.publish(publisher, null, (error) => {
	    if (error) {
				is_streaming = false;
	    	reject(error);
	    } else {
	    	resolve();
	    }
	  });

	  publisher.on("streamCreated", (event) => addStream(event.stream));

	  publisher.on("streamDisconnected", (event) => {
	  	console.warn("publisher stream disconnected");
	  });

	  publisher.on("streamDestroyed", (event) => {
			console.warn("%cmy own publisher stream destroyed", "color:blue", event);
	  	event.preventDefault(); // do not remove from DOM
	  	is_streaming = false;
	  	// TODO: is this redundant?
	    fireEvent("updated", { uuid: event.stream.name, hasVideo: false, hasAudio: false });
	  });
	});
}

function subscribe(uuid, element, type) {
  console.debug("session.subscribe():", uuid, element, type);
  return new Promise((resolve, reject) => {
  	if (uuid == current_uuid) {
  		reject("tried to subscribe to own stream");
  		return;
  	}
  	if (!element) {
  		console.warn("NO ELEMENT FOR SUB");
  		return resolve();
  	} else
  	if (!element.closest(".tile, .screen")) {
  		console.error("NOT IN A TILE");
  		return resolve();
  	}
  	const participant = participants.get(uuid);
	  if (!participant) return reject("could not find participant in pool", uuid);

	  const stream = participant.streams[type];
	  if (!stream) return reject("stream not available yet");

	  let subscribers = tokbox_session.getSubscribersForStream(stream);
	  if (subscribers.length) {
			console.warn("already subscribing to this stream");
	  	element.appendChild(subscribers[0].element);
	  	resolve();
	  	return;
	  }
	  let subscriber = tokbox_session.subscribe(
	    stream,
	    element,
	    {
	      insertMode: "append",
	      showControls: false,
	      mirror: false,
	      width: "100%",
	      height: "100%",
	    },
	    (error) => {
	      if (error) reject(error);
	      else {
		    	if (subscriber.isAudioBlocked()) fireEvent("audioblocked");
	      	resolve();
	      }
	    }
	  );

	  // TODO: stop listening to audio level if not displaying
	  subscriber.on("destroyed", (event) => {
	  	console.warn("subscriber destroyed");
	  });

	  let level;
	  let moving_avg = null;

	  subscriber.on("audioLevelUpdated", (event) => {
		  if (!subscriber.stream.hasAudio) return;
		  if (moving_avg === null || moving_avg <= event.audioLevel) {
		    moving_avg = event.audioLevel;
		  } else {
		    moving_avg = 0.7 * moving_avg + 0.3 * event.audioLevel;
		  }
			level = checkAudioLevel(uuid, level, moving_avg);
	  });
	});
}

function unblockAudio() {
	OT.unblockAudio();
}

function checkAudioLevel(uuid, prev_level, moving_avg) {
  // 1.5 scaling to map the -30 - 0 dBm range to [0.8 ,1.3]
  let level = Math.log(moving_avg) / Math.LN10 / 1.5 + 1.5;
  level = Math.round(Math.min(Math.max(level, 0), 1) * 10) / 10;
  if (prev_level !== level) {
		fireEvent("audioLevel", { uuid, level });
  }
  return level;
}

function unsubscribe(uuid, type) {
	console.debug("unsubscribe", uuid, type);
	//let participant = participants.get(uuid);
	return Promise.resolve();
}

function endSession() {
	console.warn("session ended");
}

function sessionDisconnected(event) {
	console.warn("session disconnected", event.reason);
	tokbox_session = null;
	connected = false;
	if (event.reason == "forceDisconnected") {
		fireEvent("end", { reason: "You have been ejected from the ThinkIn" });
	}
}

function disconnect() {
	session_started = false;
	if (publisher) {
		publisher.destroy();
		publisher = null;
	}
	if (tokbox_session) tokbox_session.disconnect();
}

function eject(uuid) {
	console.warn("ejecting", uuid);
	let participant = participants.get(uuid);
	tokbox_session.forceDisconnect(participant.connection, (err) => {
		if (err) console.error(err);
		else console.log("forced disconnection");
	});
}

function cycleCamera() {
	if (publisher) publisher.cycleVideo((camera) => {
		camera_id = camera.deviceId;
	});
}

function mute(uuid) {
	if (!uuid) uuid = current_uuid;
	let participant = participants.get(uuid);
	if (uuid == current_uuid) {
		console.debug("session setting publisher audio off", publisher.hasAudio);
    publisher.publishAudio(false);
    return;
	}
  signal("mute", { uuid }, uuid);
}

function unmute(uuid) {
	if (!uuid) uuid = current_uuid;
	let participant = participants.get(uuid);
	if (uuid == current_uuid) {
		console.debug("session setting publisher audio ON");
		(publisher ? Promise.resolve(): startPublisher(false)).then(() => {
	    publisher.publishAudio(true);
		  let level;
		  let moving_avg = null;
		  publisher.on("audioLevelUpdated", (event) => {
			  if (!publisher.stream.hasAudio) return;
			  if (moving_avg === null || moving_avg <= event.audioLevel) {
			    moving_avg = event.audioLevel;
			  } else {
			    moving_avg = 0.7 * moving_avg + 0.3 * event.audioLevel;
			  }
				level = checkAudioLevel(uuid, level, moving_avg);
		  });
		})
    return;
	}
  signal("unmute", { uuid }, uuid);
}

function getScreenshareElement() {
	return screenshare_element;
}

function stopSharingScreen() {
	console.debug("stopping screenshare");
	if (screenshare_publisher) {
		screenshare_publisher.publishVideo(false);
		tokbox_session.unpublish(screenshare_publisher);
	}
	// TODO: should destroy it!
	screenshare_publisher = null;
	return Promise.resolve();
}

function signal(type, data, uuid) {
  if (!connected)
    return console.warn(
      "tried to signal before session started",
      type,
      data
    );
	console.debug(`%csignal sent ${type} to ${uuid || "everybody"}`,"color:#999");
  data = JSON.stringify(data || {});

  return new Promise((resolve, reject) => {
    let message = { type, data };
    if (uuid) {
    	message.to = participants.get(uuid).connection;
    }
    tokbox_session.signal(message,
      function (error) {
        if (error) reject(error);
        else resolve();
      }
    );
  });
}

function listen(arg, listener) {
	if (arguments.length == 1) {
		for (let name in arg) {
			listeners[name] = arg[name];
		}
	} else {
		listeners[arg] = listener;
	}
}

function listDevices() {
	console.warn("LIST DEVICES");
	return new Promise((resolve, reject) => {
		OT.getUserMedia().then(() => {
    	console.log("session.getuserMedia");
      return OT.getDevices((err, devices) => {
      	if (err) reject(err);
				else resolve(devices);
			});
		}).catch((err) => reject(err));
	});
}

function handleError(err) {
	if (!err) return;
	console.error("Session error", err);
	fireEvent("error", err);
}

function getStats() {
	return new Promise((resolve, reject) => {
		if (!publisher) resolve();
		else publisher.getStats((error, statsArray) => {
			if (error) reject(error);
			else resolve(statsArray[0].stats);
		});
	})
}

function getSnapshot(uuid) {
	let participant = participants.get(uuid);
	if (!participant) return console.warn("could not find participant for snapshot", uuid);
	let pub_or_sub;
	if (uuid == current_uuid) {
		pub_or_sub = publisher;
	} else {
		let subscribers = tokbox_session.getSubscribersForStream(participant.streams["camera"]);
		if (subscribers.length) pub_or_sub = subscribers[0];
		if (subscribers.length > 1) console.error("multiple subscribers for stream", uuid);
	}
	if (!pub_or_sub) return console.warn("connection has not subscriber for snapshot", uuid);
	let image_data = pub_or_sub.getImgData();
	return "data:image/png;base64," + image_data;
}

window.debug = function () {
  return { 
  	session: tokbox_session,
  	publisher,
  	participants: Array.from(participants.values()),
  };
};


function createSession() {
	const { set, update } = writable({});

	return {
		set,
		update,

		connect,
		join,
		disconnect,
		getAuthToken,

		listDevices,
		startCamera,
		stopCamera,
		setCamera,
		setMic,
		cycleCamera,
		startStreamingCamera,
		getCamera,
		getCameraElement,
		getMic,
		getSnapshot,
		setCameraParent,

		mute,
		unmute,

		subscribe,
		unsubscribe,
		unblockAudio,

		signal,
		listen,

		startSharingScreen,
		stopSharingScreen,
		getScreenshareElement,

		// hosts only
		eject,
		end: endSession,
		getStats,
	};
}

export const session = createSession();
