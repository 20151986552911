<script>import { fade } from "svelte/transition";
import Spinner from "./_Spinner.svelte";
import HighlightBox from "../molecules/HighlightBox.svelte";
import Hoverable from "./_Hoverable.svelte";
import { fetchJSONFile, transcriptFileStringFromData, getFileSrc, transcriptFileFromString } from "@galapagos/shared";
import back from "../assets/videoicons/back-15s.svg";
import playIcon from "../assets/videoicons/play-circle.svg";
import pauseIcon from "../assets/videoicons/pause-circle.svg";
import volume from "../assets/videoicons/volume.svg";
import volumeOff from "../assets/videoicons/muted.svg";
import captionsOn from "../assets/videoicons/captions-green.svg";
import captionsOff from "../assets/videoicons/captions.svg";
import fullscreen from "../assets/videoicons/fullscreen.svg";
import minimize from "../assets/videoicons/minimize.svg";
export let videoFileUrl;
export let posterFileUrl;
export let transcriptUrl;
export let isFloating = false;
let transcript;
let highlights;
if (transcriptUrl) {
    fetchJSONFile(transcriptUrl).then(videoMetaData => {
        const vttString = transcriptFileStringFromData(videoMetaData);
        const file = transcriptFileFromString(vttString);
        highlights = getHighlights(videoMetaData);
        transcript = getFileSrc(file);
    });
}
let video;
let time = 0;
let duration = video === null || video === void 0 ? void 0 : video.duration;
let player;
let elapsed;
let volumeSlider;
let controlBar;
let paused = true;
let volumeOn = false;
let showSlider = false;
let isCaptionsOn = true;
let bar;
let loading = false;
let sliderValue = 0;
let isFullscreen = false;
$: track = video === null || video === void 0 ? void 0 : video.textTracks[0];
let activeCue;
$: if (track) {
    track.oncuechange = () => {
        var _a, _b;
        const cues = track.activeCues; // array of current cues
        const activeCueText = ((_a = cues[0]) === null || _a === void 0 ? void 0 : _a.text) || "";
        const [speaker, ...utterance] = activeCueText && activeCueText.split(":");
        activeCue = {
            speaker: speaker,
            utterance: (_b = utterance[0]) === null || _b === void 0 ? void 0 : _b.trim(),
        };
        track.mode = "hidden";
    };
}
function getHighlights(transcriptData) {
    return transcriptData
        .filter(statement => statement.frame && statement.frame.path)
        .map(statement => {
        const truncateUtterance = utterance => {
            if (utterance.length > 75) {
                return utterance.substring(0, 75) + "...";
            }
            return utterance.substring(0, 75);
        };
        return {
            time: statement.time.start / 1000,
            description: truncateUtterance(statement.utterance),
            image: statement.frame.url,
        };
    });
}
function format(seconds) {
    let timestamp = "";
    let secs = seconds;
    secs = Math.round(secs);
    const hours = Math.floor(secs / 3600);
    secs -= hours * 3600;
    const minutes = Math.floor(secs / 60);
    secs -= minutes * 60;
    if (hours < 10) {
        timestamp = "0";
    }
    timestamp += hours + ":";
    if (minutes < 10) {
        timestamp += "0";
    }
    timestamp += minutes + ":";
    if (secs < 10) {
        timestamp += "0";
    }
    timestamp += secs;
    return timestamp;
}
function updateTimeElapsed() {
    elapsed.textContent = format(time);
    setTimeout(() => {
        bar.style.width = Math.floor((time / duration) * 100) + "%";
        if (bar.style.width !== Math.floor((time / duration) * 100) + "%") {
            loading = true;
        }
        loading = false;
    }, 50);
    if (!duration) {
        duration = time;
        positionHighlights(duration);
    }
}
function rewind() {
    const updatedTime = Math.max(0, time - 15);
    time = updatedTime;
}
function fastForward() {
    const updatedTime = Math.max(0, time + 15);
    time = updatedTime;
}
function openFullscreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    }
    else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
    else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
}
function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        clearInterval(interval);
    }
    else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        clearInterval(interval);
    }
    else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        clearInterval(interval);
    }
}
let interval;
function handleMousemove() {
    if (isFullscreen === true) {
        interval = setInterval(() => {
            controlBar.style.opacity = "0";
        }, 4000);
        ["mousemove", "click"].forEach(event => {
            player.addEventListener(event, () => {
                controlBar.style.opacity = "1";
                controlBar.style.transition = "1s";
            });
        });
    }
    else if (isFullscreen === false) {
        return clearInterval(interval);
    }
}
function setVolumeWithButton() {
    video.muted = !video.muted;
    volumeOn = !volumeOn;
    showSlider = !showSlider;
    if (volumeOn) {
        sliderValue = 50;
        displaySliderFill();
        trackEvent("RecapPlayedAudio");
    }
    else {
        sliderValue = 0;
    }
}
function setVolumeWithSlider() {
    video.muted = false;
    volumeOn = true;
    video.volume = volumeSlider.value / 100;
    sliderValue = volumeSlider.value;
    displaySliderFill();
}
function displaySliderFill() {
    volumeSlider.style.background = `linear-gradient(to right, #066 0%, #066 ${sliderValue}%, #b8b8b8 ${sliderValue}%, #b8b8b8 ${sliderValue}%`;
}
export function jumpInTime(timeInMilliseconds) {
    time = timeInMilliseconds / 1000;
}
function jump(event) {
    event.stopImmediatePropagation();
    const rect = this.getBoundingClientRect();
    const position = (event.pageX - rect.left) / this.offsetWidth;
    time = position * duration;
    loading = true;
    if (paused) {
        return !paused;
    }
}
function displayCaptions() {
    isCaptionsOn = !isCaptionsOn;
    if (isCaptionsOn) {
        video.textTracks.mode = "showing";
        trackEvent("RecapShowCaptions");
    }
    else {
        video.textTracks.mode = "hidden";
    }
}
function positionHighlights(timestamp) {
    const percent = (timestamp / duration) * 100;
    return `${percent}%`;
}
function activateHighlight(index) {
    if ("ontouchstart" in window) {
        return;
    }
    jumpToHighlight(index);
}
function jumpToHighlight(index) {
    const highlight = highlights[index];
    jumpInTime(highlight.time * 1000);
    trackEvent("RecapJumpToHighlight");
}
function escape() {
    if (!document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement) {
        clearInterval(interval);
        isFullscreen = false;
    }
}
function keyboardNav(e) {
    if (e.code === "Space") {
        paused = !paused;
    }
    if (e.code === "ArrowLeft") {
        rewind();
    }
    if (e.code === "ArrowRight") {
        fastForward();
    }
}
function trackEvent(eventName) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: eventName,
    });
}
$: if (controlBar) {
    if (isFloating) {
        controlBar.style.display = "none";
    }
    else {
        controlBar.style.display = "flex";
    }
}
</script>

<svelte:window on:fullscreenchange={escape} on:keyup={keyboardNav} />

<div class={isFullscreen ? "player-fullscreen" : "player"} bind:this={player}>
  <div class="video-wrapper">
    {#if loading}
      <div class="spinner">
        <Spinner />
      </div>
    {/if}
    <video
      src={videoFileUrl}
      poster={posterFileUrl}
      preload="auto"
      muted
      crossorigin="anonymous"
      on:timeupdate={updateTimeElapsed}
      on:click={() => (paused = !paused)}
      on:waiting={() =>
        setTimeout(() => {
          loading = true;
        }, 400)}
      bind:currentTime={time}
      bind:duration
      bind:paused
      bind:this={video}>
      <track default srclang="en" kind="captions" src={isCaptionsOn && transcript} />
      <p>
        Your browser doesn’t support this video. Here is a <a href={videoFileUrl}> link to the video</a> instead.
      </p>
    </video>
    {#if isCaptionsOn && activeCue && activeCue.utterance && activeCue.speaker}
      <span class={isFloating ? "captions-hide" : "captions-wrapper"}>
        <span class="captions-utterance">{activeCue.utterance}</span>
        <br />
        <span class="captions-speaker">{activeCue.speaker}</span>
      </span>
    {/if}
  </div>

  <div bind:this={controlBar} class={isFullscreen === true ? "controls-fullscreen" : "controls"}>
    <button name="play" on:click={() => (paused = !paused)} aria-label="play pause toggle">
      <img class="play-pause" src={paused ? playIcon : pauseIcon} alt="" />
    </button>

    <div class="scrubber">
      {#if highlights && duration}
        {#each highlights as { time, description, image }, i}
          <Hoverable let:hovering>
            <div transition:fade class="highlight-container" style="left:{positionHighlights(time)};">
              <HighlightBox {hovering} on:click={() => jumpToHighlight(i)}>
                <img src={image} class="highlight-image" alt="" />
                <p class="highlight-caption">{description}</p>
              </HighlightBox>
            </div>
            <span
              class="pin"
              style="left:{positionHighlights(time)};"
              on:click={() => activateHighlight(i)}
              on:keydown={() => activateHighlight(i)} />
          </Hoverable>
        {/each}
      {/if}
      <div class="progress-bar" on:keydown={jump} on:click={jump}>
        <div bind:this={bar} id="bar" />
      </div>
      <div class="timer-wrapper">
        <div class="time" id="elapsed" bind:this={elapsed}>{format(time)}</div>
        <div class="time" id="duration" bind:this={duration}>{duration ? format(duration) : "00:00:00"}</div>
      </div>
    </div>

    <div class="buttons">
      <button name="rewind" on:click={rewind}><img src={back} alt="" /></button>
      <!-- svelte-ignore a11y-mouse-events-have-key-events -->
      <div
        class="volumeControl"
        on:mousemove={displaySliderFill}
        on:mouseenter={() => (showSlider = true)}
        on:mouseleave={() => (showSlider = false)}>
        {#if showSlider}
          <div class="slider-wrapper" transition:fade>
            <input
              class:sliderAtFifty={() => sliderValue === 50}
              bind:this={volumeSlider}
              on:change={setVolumeWithSlider}
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              step="1" />
            <div class="triangle" />
          </div>
        {/if}
        <button name="volume" on:click={setVolumeWithButton}>
          {#if !volumeOn}
            <img src={volumeOff} alt="" />
          {:else}
            <img src={volume} alt="" />
          {/if}
        </button>
      </div>
      <button name="captions" class="captions" on:click={displayCaptions}>
        {#if !isCaptionsOn}
          <img src={captionsOff} alt="" />
        {:else}
          <img src={captionsOn} alt="" />
        {/if}
      </button>
      <button
        name="fullscreen"
        class="fullscreen"
        on:click={() => {
          if (isFullscreen === true) {
            isFullscreen = false;
            closeFullscreen();
            handleMousemove();
          } else {
            isFullscreen = true;
            openFullscreen(player);
            handleMousemove();
          }
        }}>
        {#if isFullscreen === true}
          <img class="minimize" src={minimize} alt="" />
        {:else}
          <img src={fullscreen} alt="" />
        {/if}
      </button>
    </div>
  </div>
</div>

<style>@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Italic"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf") format("truetype");
  font-weight: bold; }

/* use Koopman for fixed labels (button) */
@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.tt") format("truetype"); }

@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.tt") format("truetype");
  font-weight: bold; }

* {
  box-sizing: border-box;
  font-family: Pegasus,serif; }

.player {
  position: relative; }

.player-fullscreen {
  display: flex;
  justify-content: center; }

.player > div {
  display: flex; }

.video-wrapper {
  position: relative; }

.spinner {
  position: absolute; }

.captions-hide {
  display: none; }

.captions-wrapper {
  background-color: #000000b3;
  position: absolute;
  width: calc(100% - 20vw);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  line-height: 28px;
  padding: 16px 24px;
  font-size: 24px;
  font-family: Pegasus;
  text-align: left; }

.captions-speaker {
  line-height: 20px;
  font-size: 18px;
  font-family: Koopman;
  font-weight: bold; }

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  background: #000;
  max-height: calc(100vh - 160px); }

video:hover {
  cursor: pointer; }

button {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer; }

::-webkit-media-controls {
  display: none; }

.controls {
  padding: 0;
  align-items: center;
  justify-content: space-between;
  background: white; }

.controls-fullscreen {
  bottom: 0;
  position: fixed;
  width: 100%;
  align-items: center;
  background: white;
  display: flex; }

button[name="play"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #066; }

button[name="play"] img {
  width: 5vw; }

.buttons {
  display: flex;
  flex-wrap: nowrap;
  padding: 4px 0 0;
  align-items: center; }

button[name="rewind"] > img {
  width: 1.8vw; }

button[name="volume"] > img {
  height: 2vw; }

button[name="captions"] > img {
  width: 2.2vw; }

button[name="fullscreen"] > img {
  height: 2.3vw; }

.fullscreen .minimize {
  height: 1.6vw; }

.buttons button {
  padding-left: 0; }

.captions,
.fullscreen {
  padding-right: 16px; }

.buttons button:first-child {
  padding-left: 0; }

.scrubber {
  width: calc(100% - 200px);
  position: relative;
  margin: 0 2vw; }

.timer-wrapper {
  display: flex;
  justify-content: space-between; }

.time {
  overflow: hidden;
  font-family: Koopman, sans-serif;
  font-size: 14px;
  color: #333; }

.progress-bar {
  position: relative;
  height: 6px;
  width: 100%;
  background: #ebebeb;
  cursor: pointer; }

#bar {
  position: absolute;
  left: 0;
  top: 16;
  height: 6px;
  width: 0;
  background: #066;
  transition: all 250ms ease-out;
  -webkit-transition: all 250ms ease-out; }

.pin {
  /* This one isn't in the styleguide but it is in the app */
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 1;
  border-radius: 50%;
  background-color: #006666;
  border: 2px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: all 160ms ease-in-out;
  top: 0;
  transform: translateY(-2.5px);
  transform-origin: center center; }

.pin:hover {
  transform: scale(1.5) translateY(-25%); }

.volumeControl {
  margin: 0 15px; }

.slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: left;
  transform: translate(8px, -45px) rotate(-90deg);
  background: white;
  width: 100px;
  height: 20px;
  position: absolute;
  padding-bottom: 14px;
  padding-top: 14px; }

button[name="volume"] {
  position: relative; }

.slider-wrapper input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 88px;
  height: 2px; }

.sliderAtFifty {
  background: linear-gradient(to right, #066 0%, #066 50%, #b8b8b8 50%, #b8b8b8 100%); }

.slider-wrapper input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #066;
  cursor: pointer;
  z-index: 100; }

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 1vw solid transparent;
  border-bottom: 1vw solid transparent;
  border-right: 2vw solid white;
  left: -0.5vw;
  z-index: -1; }

.highlight-container {
  position: absolute;
  bottom: 100%;
  width: 190px;
  z-index: 99;
  transform: translateX(calc(-50% + 5px)); }

.highlight-image {
  width: 100%; }

.highlight-caption {
  line-height: initial;
  text-align: initial;
  font-family: Koopman, sans-serif;
  font-size: 14px;
  color: #333;
  margin: 0; }

@media only screen and (min-width: 900px) {
  .captions-wrapper {
    line-height: 36px;
    font-size: 26px; }
  .captions-speaker {
    line-height: 24px;
    font-size: 18px; }
  .slider-wrapper {
    width: 130px;
    height: 40px;
    padding-bottom: 14px;
    padding-top: 14px;
    transform: translate(13px, -65px) rotate(-90deg); }
  .slider-wrapper input[type="range"] {
    width: 100px;
    height: 2px; }
  .slider-wrapper input[type="range"]::-webkit-slider-thumb {
    width: 10px;
    height: 10px; }
  .triangle {
    left: -8px;
    z-index: -1; } }

@media only screen and (max-width: 670px) {
  .captions-wrapper {
    line-height: normal;
    font-size: 20px; }
  .fullscreen .minimize {
    height: 1.9vw; }
  .captions-speaker {
    line-height: normal;
    font-size: 16px; }
  .time {
    font-size: 12px; }
  button[name="play"] {
    padding: 5px; }
  button[name="play"] img {
    width: 9vw; }
  .progress-bar,
  #bar {
    height: 4px; }
  button[name="rewind"] {
    display: none; }
  button[name="volume"] > img,
  button[name="captions"] > img,
  button[name="fullscreen"] > img {
    height: 24px;
    width: 24px; }
  button[name="fullscreen"] {
    padding-right: 0; }
  .scrubber {
    width: 100%;
    margin: 0 2vw;
    top: 10px; }
  .highlight-caption {
    font-size: 12px; }
  .slider-wrapper {
    width: 70px;
    height: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    transform: translate(8px, -35px) rotate(-90deg); }
  .slider-wrapper input[type="range"] {
    width: 50px;
    height: 2px; }
  .slider-wrapper input[type="range"]::-webkit-slider-thumb {
    width: 7px;
    height: 7px; }
  .triangle {
    left: -6px; } }

@media only screen and (max-width: 420px) {
  .captions-wrapper {
    width: 100%;
    padding: 8px 12px; }
  .captions-wrapper {
    font-size: 16px; }
  .captions-speaker {
    font-size: 12px; }
  .highlight-container {
    width: 150px; } }
</style>
