<script>
  import testimonials from "../data/testimonials";

  export let benefits;
  export let membershipGuide;
  export let displayMemberType;

  let isVideoPlaying = false;
  let video;

  const videoPlayPause = () => {
    const isPlaying = !video.paused;

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }

    isVideoPlaying = !isPlaying;
  };
</script>

<div class="page">
  <section class="hero">
    <div class="titlebox">
      <div class="titlebox__text">
        <h1>Membership entitlements</h1>
        <h2>As a {displayMemberType}, here’s what you get:</h2>
        <ul>
          {#each benefits as benefit (benefit)}
            <li>
              <img src="/assets/checkmark-white.svg" alt="checkmark" class="checkmark" />
              {@html benefit}
            </li>
          {/each}
        </ul>
        <p>
          We’d love to help you make the most of your membership, so we’ve put together this little how-to guide to help
          you on your way:
        </p>
      </div>
      <div />
    </div>
    <div class="imagebox">
      <img src="/assets/front-desk.png" alt="The editor's desk in the Tortoise newsroom" />
    </div>
  </section>

  <section class="membership-guide">
    <ul class="membership-guide-list">
      {#each membershipGuide as { title, subtitle, img } (img.src)}
        <li>
          <img src={img.src} alt={img.alt} class="membership-guide-image" />
          <div>
            <h3 class="membership-guide-title">{title}</h3>
            <p>{@html subtitle}</p>
          </div>
        </li>
      {/each}
      <li>
        <img src="/assets/6-member-journey.png" alt="gift-box" class="membership-guide-image" />
        <div>
          <h3 class="membership-guide-title">Enjoy 20% off in the Tortoise shop</h3>
          <p>
            Members can enjoy an exclusive 20 per cent discount on almost everything in the Tortoise shop – this
            includes gift sets and memberships, The Tortoise Quarterly and signed books. Simply head to the shop and use
            the code SHELLOUT at checkout to receive your discount.
          </p>
          <a href="https://www.tortoisemedia.com/shop/" target="_blank">Visit the Tortoise shop →</a>
        </div>
      </li>
    </ul>
  </section>

  <section class="app-advert">
    <h2>Download the Tortoise app</h2>
    <div class="app-advert__content">
      <div class="text">
        <p>
          Take Tortoise with you on the go! It's the best way to listen to the Slow Newscast and Sensemaker podcasts,
          because in the app they're ealy and ad-free. It's also by far the easiest way to book your ThinkIn tickets,
          and watch them back on catch up.
        </p>
        <p>
          Just point your smartphone camera or QR scanner app at this QR code and you should be taken to the <a
            target="_top"
            href="https://tortoisemedia.onelink.me/Cx9d/c770bd87?">app download page</a
          >.
        </p>
        <a target="_top" href="https://tortoisemedia.onelink.me/Cx9d/c770bd87?">
          <img
            class="qr-code"
            src="/assets/app-qr-code.png"
            alt="A QR code to install the app. The QR code can also be pressed." />
        </a>
        <p>
          Once you've downloaded the app, login using the same credentials you used to purchase your membership and you
          should be all set.
        </p>
      </div>
      <div class="video">
        <div class="video-button" class:hide={isVideoPlaying}><img src="/assets/play.png" alt="play icon" /></div>
        <!-- svelte-ignore a11y-media-has-caption -->
        <video bind:this={video} on:click={videoPlayPause}>
          <source src={process.env.GALAPAGOS_ASSETS_ORIGIN + "/promo.mp4"} type="video/mp4" />
        </video>
      </div>
    </div>
  </section>

  <section class="testimonials">
    {#each testimonials as testimonial (testimonial.person)}
      <span>
        <img src="/assets/quotes.svg" alt="A very big quote mark" />
        <p class="content">{testimonial.opinion}</p>
        <p class="person">{testimonial.person}</p>
        <p class="university">{testimonial.university}</p>
      </span>
    {/each}
  </section>
</div>

<style>
  .page {
    --left-pad: var(--spacer-s1);
    background-color: var(--stone);
    min-height: 100vh;
  }

  section + section:not(.membership-guide) {
    margin-top: var(--spacer-s1);
  }

  .membership-guide {
    margin-top: var(--spacer-s3);
  }

  h1,
  h2,
  h3,
  p,
  li {
    font-family: pegasus;
    text-align: left;
  }

  a {
    color: var(--kelp);
  }

  p,
  li {
    font-size: 24px;
    line-height: 34px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #033;
  }

  .titlebox {
    display: grid;
  }

  .titlebox :is(h1, h2, p, li) {
    color: var(--white);
  }

  .titlebox__text {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: var(--spacer-s3) var(--spacer-s3) var(--spacer-s3) var(--left-pad);
  }

  .titlebox__text h1 {
    font-size: 64px;
    line-height: 76px;
    margin: 0 0 var(--spacer-large) 0;
  }

  .imagebox {
    overflow: hidden;
  }

  .imagebox img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .membership-guide ul {
    margin: 0 var(--spacer-s3) 0 var(--left-pad);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .membership-guide li {
    max-width: 308px;
  }

  .membership-guide li {
    margin-right: var(--spacer-s3);
  }

  .membership-guide-title {
    font-size: 32px;
    line-height: 40px;
  }

  .membership-guide-image {
    width: 170px;
    height: 150px;
  }

  .membership-guide a {
    text-decoration: none;
  }

  .app-advert {
    color: var(--white);
    background-color: #033;
    padding: var(--spacer-s4) var(--spacer-s3);
    margin-left: var(--spacer-s2);
    margin-right: var(--spacer-s2);
  }

  .app-advert h2 {
    font-size: 52px;
    line-height: 60px;
    margin-top: 0;
    margin-bottom: var(--spacer-large);
  }

  .app-advert__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacer-large);
  }

  .app-advert__content .text {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .app-advert__content .text *:first-child {
    margin-top: 0;
  }

  .app-advert a {
    color: var(--white);
    text-align: left;
  }

  .video {
    width: 100%;
    min-width: 300px;
    position: relative;
    height: fit-content;
  }

  .video-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .video-button.hide {
    visibility: hidden;
  }

  .video video {
    max-width: 100%;
  }
  .qr-code {
    height: 132px;
    width: auto;
  }

  .testimonials {
    background-color: var(--lettuce);
    display: flex;
    padding: var(--spacer-s2);
    padding-left: var(--left-pad);
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .testimonials span {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: var(--spacer-medium);
    max-width: 400px;
    margin-right: var(--spacer-medium);
  }

  .testimonials p {
    margin: 0;
  }

  .testimonials .content {
    font-style: italic;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: var(--spacer-medium);
  }

  .testimonials :is(.person, .university) {
    font-size: 20px;
    line-height: 24px;
  }

  .testimonials .person {
    font-weight: bold;
  }

  @media only screen and (max-width: 1100px) {
    .page {
      --left-pad: var(--spacer-s4);
    }
    .hero {
      display: flex;
      flex-direction: column-reverse;
    }

    .hero img {
      max-height: 33vh;
    }

    .app-advert {
      margin: var(--spacer-medium);
    }

    .app-advert__content {
      display: flex;
      flex-direction: column-reverse;
    }
  }
</style>
