<script>
  import { createEventDispatcher } from 'svelte';
  import Dropdown from "../dropdown/Dropdown";

  import { participants, thinkin, user } from "../../stores/sessionStore";

  export let uuid;
  export let host = false;
  export let top = 35;
  export let left;
  export let right;

  const dispatch = createEventDispatcher();

  $: participant = $participants.find((p) => p.uuid == uuid);

  function viewProfile() {
    dispatch("view", { uuid })
    dispatch("click_outside", {});
  }

  function handleSpotlight() {
    dispatch("spotlight", { uuid })
    dispatch("click_outside", {});
  }

  function handleMute() {
    if (participant.hasAudio) dispatch("mute", uuid);
    else dispatch("unmute", uuid);
    dispatch("click_outside", {});
  }

  function handlePinning() {
    if (participant.pinned) dispatch("unpin", uuid);
    else dispatch("pin", uuid);
    dispatch("click_outside", {});
  }
  
  function handleInvite() {
    console.warn("handleInvite");
    dispatch("invite", uuid);
    dispatch("click_outside", {});
  }

  function stopCamera() {
    console.warn("TODO: stopVideo");
  }

  function handleQueue() {
    if (participant.queued) dispatch("queue", uuid);
    else dispatch("dequeue", uuid);
    participant.queued = !participant.queued;
    dispatch("click_outside", {});
  }

  function ejectParticipant() {
    dispatch("click_outside", {});
    dispatch("eject", { uuid });
  }

  function handlePrivateMessage() {
    console.log("PSST PRIVATE!");
    dispatch("message", { uuid });
    dispatch("click_outside", {});
  }

  $: streaming = participant.hasVideo || participant.hasAudio;

  $: invitable = $thinkin.status == "Green room" && (!participant.joined_at || participant.left_at); // for now
</script>

<Dropdown on:click_outside {top} {left} {right} width=160 icons={true}>
  {#if host}
    {#if invitable}
      <li class="invite" on:keydown|stopPropagation={handleInvite} on:click|stopPropagation={handleInvite}><img src="/assets/invite.svg" alt="">Invite in</li>
    {:else}
      <li class="spotlight" class:inactive={!streaming} on:keydown|stopPropagation={handleSpotlight} on:click|stopPropagation={handleSpotlight}>
        {#if participant.spotlit}
          <img src="/assets/remove-spotlight.svg" alt="">Remove spotlight
        {:else}
          <img src="/assets/spotlight.svg" alt="">Spotlight
        {/if}
      </li>
      {#if participant.spotlit || participant.pinned}
      <li class="pinning" class:inactive={!streaming} on:click|stopPropagation={handlePinning} on:keydown|stopPropagation={handlePinning}>
        {#if participant.pinned}
          <img src="/assets/unpin.svg" alt="">Remove pin
        {:else}
          <img src="/assets/pin.svg" alt="">Pin
        {/if}
      </li>
      {/if}
      <!--li class="queue" class:inactive={!streaming} on:click|stopPropagation={handleQueue}>
        {#if participant.queued}
          <img src="/assets/remove-from-queue.svg" alt="">Remove from queue
        {:else}
          <img src="/assets/add-to-queue.svg" alt="">Add to queue
        {/if}
      </li-->
      <li class="mute" class:inactive={!streaming} on:click|stopPropagation={handleMute} on:keydown={handleMute} ><img src="/assets/mic.svg" alt="">{participant.hasAudio ? 'Mute' : 'Unmute'}</li>
    {/if}
  {/if}
  {#if participant.uuid !== $user.uuid}
    <li class="message" on:click|stopPropagation={handlePrivateMessage} on:keydown|stopPropagation={handlePrivateMessage}><img src="/assets/message.svg" alt="">Private message</li>
  {/if}
  <li on:click|stopPropagation={viewProfile} on:keydown|stopPropagation={viewProfile}><img src="/assets/person.svg" alt="">View profile</li>
  {#if host && participant.uuid !== $user.uuid}
    <li class="eject" on:click|stopPropagation={ejectParticipant} on:keydown|stopPropagation={ejectParticipant}><img src="/assets/eject.svg" alt="">Kick out…</li>
  {/if}
</Dropdown>

