<script>
  import { url } from '@sveltech/routify';
  import Navigation from "../../../components/Navigation";
  import {fetchData} from '../../../common/helpers';

  let archive_list = fetchData("/archives");
</script>
  
<main>

  <header>
    <span class="logotype">Galapagos</span>
  </header>

  <Navigation />

  <ul>
    {#await archive_list then { archives }}
    {#each archives as archive}
    <li>
      <a href={$url(`https://tortoisearchive.s3.eu-west-2.amazonaws.com/46958944/${archive.id}/archive.mp4`)} target="_blank">Archive name - {archive.name}, Duration - {archive.duration} seconds</a>
    </li>
    {/each}
    {/await}
  </ul>

</main>

<style>
  header {
    display: grid;
    grid-template-columns: 180px auto 130px;
    padding: 0;
    border-bottom: 1px solid rgba(255,255,255,.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:40px;
    z-index: 3;
  }
</style>