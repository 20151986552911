<script>
  import { user } from "../../stores/sessionStore";

  import { DateTime } from "luxon";
  import { SquareNumberBadge } from "@galapagos/svelte-components";

  export let newsroomExpiry;

  const dbRemainingTickets =
    ($user.galapagos_in_person_thinkin_allocation || 0) - ($user.galapagos_in_person_thinkin_allocation_used || 0);

  const remainingTickets = Math.max(0, dbRemainingTickets);

  function formatExpiry() {
    const prefix = remainingTickets
      ? "Use before"
      : "Purchase more ThinkIn tickets or wait for your allocation to be renewed on";
    const time = DateTime.fromSeconds(newsroomExpiry).toFormat("DD");

    return `${prefix} ${time}`;
  }

  const expiryMessage = newsroomExpiry ? formatExpiry() : "";
</script>

<div class="ticket-count">
  <div class="ticket-count__number">
    <SquareNumberBadge size="large">
      {remainingTickets}
    </SquareNumberBadge>
  </div>
  <div>
    <p class="text desktop-body-text">Tickets remaining</p>
    <p class="text ticket-count__subtitle">{expiryMessage}</p>
  </div>
</div>

<style>
  .ticket-count {
    display: flex;
    margin-top: var(--spacer-s5);
    margin-bottom: var(--spacer-tiny);
    align-items: flex-start;
  }

  .ticket-count__number {
    margin-right: var(--spacer-tiny);
  }

  .text {
    all: unset;
    display: block;
    text-align: start;
  }

  .ticket-count__subtitle {
    font-family: Koopman, serif;
    font-size: 14px;
    line-height: 22px;
    color: var(--text-grey);
    margin-top: var(--spacer-tiny);
  }

  .desktop-body-text {
    font-family: Pegasus, serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
  }
</style>
