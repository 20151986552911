<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { fly, fade } from "svelte/transition";
  import { DateTime } from "luxon";

  import Dropdown from "../dropdown/Dropdown";
  import { cleanupRichText, saveResource } from "../../common/helpers";

  import { participants, thinkin, user } from "../../stores/sessionStore";
  import { privateMessages } from "../../stores/chatStore";
  import { session } from "../live/session";

  import { setUnreadToZero } from "./Chat";

  export let recipient;
  export let in_reply_to;

  const EVERYONE = { name: "everyone", uuid: "everyone" };

  const dispatch = createEventDispatcher();

  let show_participant_menu = false;

  $: sorted_participants = $participants
    .filter(participant => {
      return participant.uuid !== $user.uuid;
    })
    .sort((p1, p2) => (p1.name < p2.name ? -1 : 1));

  let message;
  let textArea;
  let error;
  let sending = false;

  $: if (error) {
    setTimeout(function () {
      error = "";
    }, 2000);
  }

  function handleKeydown(event) {
    if (!message || !message.length > 0) {
      return;
    }
    if (event.key === "Enter") {
      sendMessage();
    }
  }

  async function sendMessage() {
    if (!message || !message.length > 0) {
      error = "You can not send an empty message";
      return;
    }
    sending = true;

    if (recipient) {
      const privateMessage = {
        name: $user.name,
        message,
        timestamp: DateTime.utc().toString(),
        user_id: $user.uuid,
        type: "private",
      };
      privateMessages.update(privateMessages => {
        let user_id = recipient.uuid;
        let name = recipient.name;
        if (privateMessages[user_id]) {
          privateMessages[user_id].messages.push(privateMessage);
        } else {
          privateMessages[user_id] = {};
          privateMessages[user_id].name = name;
          privateMessages[user_id].messages = [privateMessage];
          privateMessages[user_id].unreadMessages = 0;
        }
        return privateMessages;
      });
      session
        .signal("message", { ...privateMessage }, recipient.uuid)
        .then(messageSent)
        .catch(err => {
          error = "There was an error sending your message.";
          console.error(err);
        });
    } else {
      const savedMessage = await saveMessage(message);
      if (!message) return;
      session
        .signal("message", { ...savedMessage, name: $user.name })
        .then(messageSent)
        .catch(err => {
          error = "There was an error sending your message.";
          console.error(err);
        });
    }
  }

  function messageSent() {
    error = "";
    message = "";
    textArea.value = "";
    sending = false;
    textArea.focus();
  }

  async function saveMessage(message) {
    const user_id = $user.uuid;
    message = cleanupRichText(message);

    const messageBody = {
      message,
      user_id,
      in_reply_to,
    };

    const messageJson = await saveResource("POST", `/thinkins/${$thinkin.id}/messages`, messageBody);

    return messageJson;
  }

  function handleFocus() {
    if (recipient) {
      setUnreadToZero(privateMessages, recipient.id);
    }
  }

  function selectParticipant(uuid) {
    if (!uuid) {
      recipient = null;
    } else {
      recipient = $participants.find(p => p.uuid == uuid);
    }
    show_participant_menu = false;
  }

  function close() {
    dispatch("close");
  }

  onMount(() => {
    textArea.focus();
  });
</script>

{#if error}
  <div in:fly={{ y: 20, duration: 1000 }} out:fade>
    <p class:error>
      {error}
    </p>
  </div>
{/if}

<div class="composer">
  <textarea
    bind:this={textArea}
    on:focus={handleFocus}
    bind:value={message}
    on:keydown={handleKeydown}
    placeholder={recipient ? "Send a private message" : "Have your say"} />

  <footer>
    <div class="pillbox" class:sending>
      <button type="button" class="primary" class:disabled={!message} on:click={sendMessage}>
        {#if sending}
          Sending…
        {:else if recipient}
          Send to {recipient.name}
        {:else}
          Send your message
        {/if}
      </button>
      <button class="btn primary menu last" on:click={() => (show_participant_menu = true)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" width="24" height="24">
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>
      {#if show_participant_menu}
        <Dropdown right="70" bottom="55" width="136" on:click_outside={() => (show_participant_menu = false)}>
          <li on:keydown={() => selectParticipant()} on:click={() => selectParticipant()}>Everyone</li>
          {#each sorted_participants as participant}
            <li on:keydown={() => selectParticipant(participant.uuid)} on:click={() => selectParticipant(participant.uuid)}>{participant.name}</li>
          {/each}
        </Dropdown>
      {/if}
    </div>
    <button class="btn" name="cancel" on:click={close}>✕</button>
  </footer>
</div>

<style>
  .composer {
    background-color: #fff;
  }
  footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    padding: 0 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
  }
  textarea {
    appearance: none;
    -webkit-appearance: none;
    font-family: Pegasus, serif;
    width: calc(100% - 19px);
    height: 80px;
    margin: 0 10px;
    resize: none;
    outline: none;
    background: #fff;
    font-size: 18px;
    padding: 8px;
  }
  footer button {
    background-color: var(--kelp);
    color: #fff;
    margin: 0 6px 0 0;
    padding: 3px 12px 4px;
    height: 40px;
    font-size: 18px;
  }
  .error {
    color: #333;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
  }
  .pillbox {
    display: flex;
    font-family: Pegasus;
  }
  .pillbox.sending button {
    background-color: var(--moss);
    pointer-events: none;
  }
  .pillbox > button:first-child {
    white-space: nowrap;
    border-radius: 33px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 0;
    padding-right: 10px;
    width: 180px;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pillbox > button.menu {
    width: 28px;
    min-width: 32px;
    margin: 0;
    padding: 8px 4px 8px 0;
    white-space: nowrap;
  }
  button.disabled {
    color: rgba(255, 255, 255, 0.7);
    pointer-events: none;
  }
  button[name="cancel"] {
    background-color: #999;
    margin-right: 0;
    width: 40px;
    min-width: 40px;
  }
</style>
