<script>export let hovering = false;
</script>

{#if hovering}
  <div class="highlightBox" on:click on:keydown>
    <slot />
  </div>
  <div class="highlight-extension">
    <svg viewBox="0 0 24 24" version="1.1" height="8" width="8" fill="white">
      <polygon points="24 0 0 0 12 24" />
    </svg>
    <span class="rectangle" />
  </div>
{/if}

<style>@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Italic"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf") format("truetype");
  font-weight: bold; }

/* use Koopman for fixed labels (button) */
@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.tt") format("truetype"); }

@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.tt") format("truetype");
  font-weight: bold; }

* {
  box-sizing: border-box;
  font-family: Pegasus,serif; }

/* This one isn't in the styleguide but it is in the app */
.highlightBox {
  position: relative;
  width: 100%;
  padding: 8px;
  bottom: 100%;
  background-color: #ffffff; }

.highlight-extension {
  display: flex;
  left: 50%;
  flex-direction: column;
  align-items: center; }

.rectangle {
  width: 2px;
  height: 47px;
  background-color: #006666; }

@media only screen and (max-width: 420px) {
  .rectangle {
    height: 25px; } }
</style>
