<script>
  export let checkboxes = [];
  import { createEventDispatcher } from "svelte";
  import { user } from "../../../stores/sessionStore";
  import Checkbox from "./checkbox.svelte";
  const dispatch = createEventDispatcher();

  function toggleCheckbox(checkbox) {
    const correctValue = checkbox.value === "checked" ? "unchecked" : "checked";
    return { ...checkbox, value: correctValue };
  }

  function handleInnerChange(outerCheckboxId, innerCheckboxId) {
    const newCheckboxes = checkboxes.map(parentCheckbox => {
      if (outerCheckboxId === undefined || parentCheckbox.id === undefined) {
        return;
      }
      if (outerCheckboxId !== parentCheckbox.id) {
        return parentCheckbox;
      }

      const newChildren = parentCheckbox.children.map(childCheckbox => {
        if (innerCheckboxId !== childCheckbox.id) {
          return childCheckbox;
        }
        return toggleCheckbox(childCheckbox);
      });

      const childrenAllChecked = newChildren.every(child => child.value === "checked");
      const childrenAllUnchecked = newChildren.every(child => child.value === "unchecked");

      const correctOuterValue = childrenAllChecked ? "checked" : childrenAllUnchecked ? "unchecked" : "indeterminate";

      return {
        ...parentCheckbox,
        value: correctOuterValue,
        children: newChildren,
      };
    });
    checkboxes = newCheckboxes;
    dispatch("updateCheckboxes", checkboxes);
  }
  let isMember;

  $: if ($user) {
    const userResourceAccesseArray = $user.resourceAccesses.sort(
      (resourceAccessA, resourceAccessB) => resourceAccessB.start_date - resourceAccessA.start_date
    );

    isMember = userResourceAccesseArray.length > 0;
  }
</script>

<ul class="parent-ul">
  {#each checkboxes as outerCheckbox (outerCheckbox.id)}
    <li>
      {#if outerCheckbox.children}
        <ul>
          {#each outerCheckbox.children as childCheckbox (childCheckbox.id)}
            {#if childCheckbox.showToAllUsers || isMember}
              <li>
                <Checkbox
                  label={childCheckbox.title}
                  value={childCheckbox.value}
                  on:keydown={() => handleInnerChange(outerCheckbox.id, childCheckbox.id)}
                  on:click={() => handleInnerChange(outerCheckbox.id, childCheckbox.id)} />
              </li>
            {/if}
          {/each}
        </ul>
      {/if}
    </li>
  {/each}
</ul>

<style>
  .parent-ul ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }

  .parent-ul ul li {
    margin-top: 16px;
  }

  .parent-ul {
    list-style-type: none;
    margin-top: 0;
    padding-left: 0;
  }
</style>
