<script>
  import { Modal } from "@galapagos/svelte-components";
  import * as Sentry from "@sentry/browser";

  import { thinkin, user } from "../../stores/sessionStore";

  let errorMessage = false;
  $: if ($thinkin.audience == "private" && tp.pianoId.isUserValid()) {
    tp.pianoId.logout();
    Sentry.configureScope(scope => scope.setUser(null));
  }
</script>

{#if $user}
  <button type="button" class="btn primary solid" id="tm-booking-link-galapagos">Book my place →</button>
{:else}
  <button type="button" class="btn primary solid" id="tm-signup-link-galapagos">Book a ticket →</button>
{/if}

{#if errorMessage}
  <Modal>
    <p>{errorMessage}</p>
  </Modal>
{/if}

<style>
  .btn.primary {
    background-color: var(--kelp);
    height: 40px;
    border-radius: 20px;
    color: #fff;
  }
</style>
