import { onDestroy } from 'svelte';

export function onInterval(callback, milliseconds) {
	const interval = setInterval(callback, milliseconds);

	onDestroy(() => {
		clearInterval(interval);
	});
}

export function tpAPI(route, options) {
  return new Promise((resolve, reject) => {
    try {
      return tp.api.callApi(route, options, (res) => resolve(res));
    } catch (e) {
      reject(e);
    }
  });
}