<script>
  import { createEventDispatcher } from 'svelte';
  import { fade } from "svelte/transition";
  import { DateTime } from "luxon";
  import Dropdown from "../dropdown/Dropdown";

  import { thinkin, user } from "../../stores/sessionStore";
  import { session } from "../live/session";
  import { saveResource } from "../../common/helpers";

  export let id;
  export let user_id;
  export let starred;
  export let host;
  export let message;
  export let timestamp;
  export let name;
  export let type;
  
  const dispatch = createEventDispatcher();

  let accordionIsOpen = false;
  let dropdown_open = false;

  async function handleStarClick(message_id, starred) {
    if (!host) return;
    starred = !starred;
    await saveResource("PUT", `/thinkins/${$thinkin.id}/messages/${message_id}`, {starred} )
    session.signal("starred", {message_id, starred});
  }

  function displayTime(timestamp) {
    let dt = DateTime.fromISO(timestamp)
    return dt.toFormat("T"); 
  }

  function showHostMenu() {
    if (!host) return;
    dropdown_open = !dropdown_open;
  }

  function toggleAccordion() {
    accordionIsOpen = !accordionIsOpen;
  }

  function hideMessage() {
    // TODO - this is basically deletion except want to save for hosts
    alert("not implemented yet");
    dropdown_open = false;
  }

  function spotlightMessage() {
    dispatch("spotlight", { id, name, message, time: displayTime(timestamp) })
    dropdown_open = false;
  }

  function viewProfile() {
    dispatch("view", { uuid: user_id })
    dropdown_open = false;
  }
</script>

<li class="message"
    class:mine={user_id === $user.uuid}
    class:others={user_id !== $user.uuid}
    class:starred 
    class:host
    class:private={type == "private"}
    on:click|stopPropagation={showHostMenu}
    on:keydown|stopPropagation={showHostMenu}
    transition:fade|local>
  {#if host}
    {#if type !== "private"}
      <button name="star" on:click|stopPropagation={() => handleStarClick(id, starred)} class:starred />
    {/if}
  {:else}
    <button name="star" class:starred />
  {/if}
  <p>
    {@html message}
  </p>
  <svg viewBox="0 0 24 24" version="1.1"> <polygon points="24 0 24 24 0 0"></polygon></svg>
  <span>{displayTime(timestamp)} <a href="/" on:click|preventDefault|stopPropagation={viewProfile}>{name}</a></span>

  {#if dropdown_open}
    <Dropdown on:click_outside={() => dropdown_open = false} bottom={-50} left=14>
      {#if type !== "private"}<li on:click|stopPropagation={spotlightMessage} on:keydown|stopPropagation={spotlightMessage}>Spotlight message</li>{/if}
      <li on:click|stopPropagation={hideMessage} on:keydown|stopPropagation={hideMessage}>Hide message</li>
    </Dropdown>
  {/if}
</li>

{#if accordionIsOpen}
  <div class="panel" transition:fade|local>
    <slot>
    </slot>
  </div>
{/if}

<style>
  .message {
    list-style: none;
    margin: 0;
    padding: 8px 10px;
    color: rgba(255,255,255,.8);
    position: relative;
    text-align: left;
    position: relative;
    width: 100%;
    transition: 0.4s;
    cursor: default;
  }
  .message.host {
    cursor: pointer;
  }
  p {
    padding: 0.5em 1em 0.5em 25px;
    margin: 0;
    position: relative;
    font-family: Pegasus,serif;
    line-height: 1.1;
    max-width: 100%;
    overflow: hidden;
    background-color: var(--water);
    color: #111;
  }
  svg {
    width: 20px;
    height: 20px;
    pointer-events: none;
    position: absolute;
    left: 10px;
    fill: var(--water);
  }

  .message.mine p { 
    background-color: #fff; 
  }
  .message.mine span {
    padding-left: 10px;
  }
  .message.mine svg { 
    fill: #fff;
    transform: rotate(-90deg);
    left: auto;
    right: 10px;
  }
  .message.private p {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
  .message.private svg {
    fill: var(--midnight);
    stroke: white;
  }

  p :global(a) {
    color: inherit;
  }

  .message span {
    font-size: 13px;
    display: block;
    padding: 6px 30px 0 30px;
  }

  button[name="star"] {
    position: absolute;
    left: 15px;
    top: 18px;
    cursor: pointer;
    opacity: 0;
    background-image: url(/assets/black-star.svg);
    width: 16px;
    height: 16px;
    background-size: contain;
    z-index: 4;
    pointer-events: none;
  }
  .host button[name=star] {
    pointer-events: auto;
  }
  .message.starred button[name="star"] {
    opacity: 1;
  }
  .host.message:not(.starred):hover button[name="star"] {
    opacity: .3;
  }

  .panel {
    display: block;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
</style>
