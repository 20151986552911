<script>
  import { user } from "../../stores/sessionStore";
  import { onMount } from "svelte";
  import { saveResource } from "../../common/helpers";
  import { Modal } from "@galapagos/svelte-components";

  let inputField;
  let email;
  let submitted = false;
  let error = false;
  let empty = true;
  let typing = false;
  let friendCode;
  let copyFriendCode;

  function copy() {
    friendCode.select();
    friendCode.setSelectionRange(0, 99999);
    document.execCommand("copy");
    copyFriendCode.textContent = "Copied to clipboard";
  }

  function handleSubmit() {
    if (email === undefined || !email.includes("@")) {
      error = true;
    } else {
      submitted = true;
      error = false;
      {
        window.open(
          `mailto:${email}?subject=${encodeURIComponent("Become a member at Tortoise")}&body=${encodeURIComponent(
            $user && $user.mgm_referral_code
              ? `I'm inviting you to become a member of Tortoise, like me. I think you'll like it. If you use my code ${
                  $user && $user.mgm_referral_code
                } you can get a whole year's digital membership for half price.\n
  Follow this link to join: https://www.tortoisemedia.com/invite/`
              : `I'm inviting you to become a member of Tortoise, like me. I think you'll like it.\n
  Follow this link to sign up: https://www.tortoisemedia.com/invite/`
          )}&output=embed`,
          "_blank"
        );
      }
    }
  }

  function handleFacebookShare() {
    const doScriptTagsExist = !!document.querySelectorAll(".facebook-script-refer-friend").length;
    const isFbInitialised = !!window.FB && window.FB.initialised;

    if (!doScriptTagsExist || !$user) {
      // the user is no longer on a page that wants to make use of the script tags, or they're not logged in
      return;
    } else if (!isFbInitialised) {
      // the user is on the right page, but it hasn't initialised yet - recurse
      setTimeout(handleFacebookShare, 500);
    } else {
      // the page is ready and the user is on the page
      window.FB.ui({
        method: "share",
        href: "https://www.tortoisemedia.com/invite",
        quote:
          $user && $user.mgm_referral_code
            ? `Use my code ${$user.mgm_referral_code} to get £50 off a Digital Membership at Tortoise.`
            : "Join me as a member of Tortoise. Sign up here: https://www.tortoisemedia.com/invite",
      });
    }
  }

  const links = [
    {
      name: "WhatsApp",
      url:
        $user && $user.mgm_referral_code
          ? `https://wa.me/?text=Use%20my%20code%20${
              $user && $user.mgm_referral_code
            }%20to%20get%20£50%20off%20a%20Digital%20Membership%20at%20Tortoise.%20https://www.tortoisemedia.com/invite`
          : `https://wa.me/?text=Join%20me%20as%20a%20member%20of%20Tortoise.%20Sign%20up%20here:%20https://www.tortoisemedia.com/invite`,
    },
    {
      name: "Twitter",
      url:
        $user && $user.mgm_referral_code
          ? `https://twitter.com/intent/tweet?text=Use%20my%20code%20${
              $user && $user.mgm_referral_code
            }%20to%20get%20£50%20off%20a%20Digital%20Membership%20at%20Tortoise.%20https://www.tortoisemedia.com/invite`
          : `https://twitter.com/intent/tweet?text=Join%20me%20as%20a%20member%20of%20Tortoise.%20Sign%20up%20here:%20https://www.tortoisemedia.com/invite`,
    },
    {
      name: "LinkedIn",
      url: null,
      handleClick: () => (linkedInModalState = "sharing"),
    },
    {
      name: "Facebook",
      url: null,
      handleClick: handleFacebookShare,
    },
  ];

  //Linkedin variables
  let shareContent;
  if ($user && $user.mgm_referral_code) {
    shareContent = `Use my code ${$user.mgm_referral_code} to get £50 off a Digital Membership at Tortoise.`;
  } else {
    shareContent = `Join me as a member of Tortoise. Sign up here:`;
  }
  let shareUrl = `https://www.tortoisemedia.com/invite`;
  let linkedInModalState = "closed";

  function getLinkedinAuth() {
    const state = btoa(Math.random().toString());
    const redirectUri = window.origin + window.location.pathname;
    const url = new URL("https://www.linkedin.com/oauth/v2/authorization");
    url.searchParams.set("response_type", "code");
    url.searchParams.set("client_id", "7855p9rg1feg4e");
    url.searchParams.set("redirect_uri", redirectUri);
    url.searchParams.set("state", state);
    url.searchParams.set("scope", "w_member_social r_liteprofile");

    localStorage.setItem("state", state);
    localStorage.setItem("message", shareContent);
    location.href = url.href;
  }

  if (location.href.includes("code=")) {
    const searchParams = new URLSearchParams(window.location.search);
    const storedState = localStorage.getItem("state");
    const storedMessage = localStorage.getItem("message");
    const redirected = window.origin + window.location.pathname;
    shareOnLinkedin(storedState, storedMessage, redirected, searchParams);
  }

  function shareOnLinkedin(state, message, redirectUri, searchParams) {
    const returnedState = searchParams.get("state");
    const code = searchParams.get("code");

    if (returnedState !== state) {
      console.error("CSRF");
      return;
    }

    const data = {
      code,
      redirectUri,
      content: message,
      url: shareUrl,
    };
    try {
      linkedInModalState = "shared";
      saveResource("POST", `/users/linkedin/share`, data);
    } catch {
      linkedInModalState = "share-failed";
    }
  }

  let page;
  onMount(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0",
      });
      window.FB.initialised = true;
    };

    const script = document.createElement("script");
    script.setAttribute("async", "true");
    script.setAttribute("defer", "true");
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js");
    script.setAttribute("class", "facebook-script-refer-friend");
    page.appendChild(script);
  });
</script>

<!-- header -->
<header>
  <div>
    {#if $user && $user.mgm_referral_code}
      <p class="body">
        Membership is better shared. Use your personal code to invite friends to Tortoise for £50 off a Digital
        Membership.
      </p>
    {:else}
      <p class="body">Membership is better shared.</p>
    {/if}
  </div>
</header>

<!-- friend code -->
<main class="main-section" bind:this={page}>
  {#if $user && $user.mgm_referral_code !== undefined}
    <div class="friend-code">
      <button on:click={copy}> <img src="/assets/copy.svg" alt="" /></button>
      <input readonly class="code-input" bind:this={friendCode} type="text" value={$user && $user.mgm_referral_code} />
      <div class="copyFriendCode" bind:this={copyFriendCode} />
    </div>
  {/if}
  <!-- invite input -->
  <div class="invite">
    {#if submitted}
      <div class="invite-left-padding">
        <p>Thanks for sharing.</p>
      </div>
    {:else}
      <form novalidate class="invite-form invite-left-padding" on:submit|preventDefault={handleSubmit}>
        <label for="invite-form" class="label-text"> Share via email: </label>

        <div class="flex">
          <div class="input-div">
            <input
              class={error === true ? "error-border" : ""}
              type="email"
              placeholder="Your friend’s email"
              bind:this={inputField}
              bind:value={email}
              on:keyup={() => {
                typing = true;
                empty = false;
              }} />
            {#if typing}
              <span
                class={empty === false ? "close-visible" : "close-hidden"}
                on:keydown={() => {
                  inputField.value = "";
                  empty = true;
                  error = false;
                }}
                on:click={() => {
                  inputField.value = "";
                  empty = true;
                  error = false;
                }}>
                <img src="/assets/close.svg" alt="" />
              </span>
            {/if}

            <button type="submit">Send invite</button>
          </div>
        </div>

        <!-- error message -->
        <div class={error === true ? "error-visible" : "error-hidden"}>
          <div><img class="error-icon" src="/assets/error.svg" alt="" /></div>
          <span>You must enter a valid email address</span>
        </div>
      </form>
    {/if}
  </div>

  <!-- social links -->
  <p class="text_small">Invite in one click via:</p>

  <div class="social">
    {#each links as link}
      <div class="social_links">
        <a href={link.url} class="social_buttons" on:click={link.handleClick} target="_parent">
          {link.name}
        </a>
      </div>
    {/each}
  </div>

  {#if linkedInModalState !== "closed"}
    <Modal on:close={() => (linkedInModalState = "closed")}>
      {#if linkedInModalState === "sharing"}
        <div class="modal">
          <textarea bind:value={shareContent} />
          <div>
            <label>
              Share URL:
              <input bind:value={shareUrl} disabled />
            </label>
            <button on:click={getLinkedinAuth} class="btn primary"> Share to LinkedIn </button>
          </div>
        </div>
      {:else if linkedInModalState === "shared"}
        <div class="modal">
          <p>Thanks for sharing.</p>
          <a href="https://www.linkedin.com" class="btn primary">Go to LinkedIn</a>
        </div>
      {:else if linkedInModalState === "share-failed"}
        <div class="modal">
          <p>Failed to share post. Please contact members@tortoisemedia.com.</p>
        </div>
      {/if}
    </Modal>
  {/if}
</main>

<style>
  * {
    text-align: left;
    font-family: Pegasus, serif;
    font-weight: 100;
    color: #333333;
  }

  header {
    background: var(--stone);
  }

  header > div {
    margin-left: 64px;
    margin-right: 16px;
  }

  main {
    margin-left: 64px;
    margin-right: 16px;
    margin-bottom: 80px;
    padding-bottom: 180px;
  }

  p,
  label {
    font-size: 20px;
    line-height: 28px;
  }

  p:not(.modal > p) {
    padding-top: 24px;
    margin-bottom: 0;
    margin-top: 0;
  }

  p.body {
    padding-bottom: 32px;
  }

  .friend-code {
    padding-top: 32px;
  }

  .copyFriendCode {
    font-family: Koopman, sans-serif;
  }

  .code-input {
    border: none;
    font-family: Koopman, sans-serif;
    font-weight: 600;
    font-size: 18px;
    width: 70%;
    background-color: transparent;
  }

  .code-input:focus {
    outline: none;
  }

  .invite {
    margin-top: 28px;
  }

  .error-visible {
    display: block;
    font-family: Koopman, sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 10px;
    display: flex;
    gap: 8px;
    justify-content: left;
  }

  .error-visible div {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-color: #e04600;
    position: relative;
    text-align: center;
  }

  .error-visible span {
    font-family: Koopman, sans-serif;
  }

  .error-visible .error-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .error-hidden {
    display: none;
  }
  input[type="email"]:not(:placeholder-shown).error-border {
    border-bottom: 4px solid #e04600;
  }

  .input-div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  button[type="submit"] {
    width: fit-content;
  }
  input[type="email"] {
    width: 100%;
    border: none;
    border-bottom: 1px solid #333333;
    border-radius: 0;
    background-color: var(--stone);
    margin-top: 8px;
    margin-bottom: 12px;
    font-family: inherit;
    font-size: 20px;
    padding: 8px 16px 12px;
    box-sizing: border-box;
  }

  input[type="email"]:focus {
    outline: 3px solid rgb(177, 177, 177);
    border-bottom: none;
  }

  input[type="email"]:not(:placeholder-shown) {
    outline: 0;
    border-bottom: 2px solid #333333;
    padding-bottom: 10px;
  }

  p.text_small {
    padding-bottom: 8px;
  }

  .invite-form {
    padding-bottom: 16px;
  }

  .close-visible {
    display: block;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    right: 28px;
    transform: translateY(20px);
  }

  .close-hidden {
    display: none;
  }

  .social {
    display: flex;
    flex-wrap: wrap;
  }

  .social_links,
  button[type="submit"] {
    border: 1px solid #333333;
    text-decoration: none;
    padding: 8px 20px 13px 20px;
    font-size: 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    height: 48px;
  }

  .social_links:hover {
    cursor: pointer;
    background-color: #4947471a;
  }

  .social_links:focus,
  button[type="submit"]:focus {
    outline: none;
    border: 3px solid rgb(177, 177, 177);
  }

  .social_buttons {
    text-decoration: none;
  }

  .modal {
    margin: 1rem;
  }

  .modal * {
    font-family: Koopman, sans-serif;
    font-size: 16px;
  }
  .modal textarea,
  .modal input {
    width: 90%;
    height: 32px;
    margin-top: 0.2rem;
    background: var(--stone);
    border: none;
  }
  .modal textarea {
    height: 100px;
    padding: 8px;
    border-bottom: 1px solid #333333;
    border-radius: 0;
    transition: all 0.3s ease;
  }
  .modal textarea:focus {
    outline: none;
    border: 3px solid rgb(177, 177, 177);
  }
  .modal button,
  .modal a {
    color: #fff;
    margin-top: 1rem;
  }
  .modal textarea::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 654px) {
    header > div,
    .main-section {
      width: calc(50% + 118px);
      margin: auto;
    }
    p:not(.text_small):not(.modal > p) {
      font-size: 24px;
      line-height: 34px;
      padding-top: 91px;
    }
    .close-visible {
      right: 20%;
    }
  }

  @media screen and (min-width: 1280px) {
    header > div,
    .main-section {
      width: calc(50% + 118px);
      margin: auto;
    }
    input[type="email"] {
      width: 60%;
    }
    header > div > p {
      width: 60%;
    }
    .input-div {
      flex-direction: row;
      gap: 16px;
      align-items: baseline;
    }
    .close-visible {
      right: 45%;
    }
  }
</style>
