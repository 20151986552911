<script>
  import { createEventDispatcher } from "svelte";
  import { saveResource } from "../../common/helpers";
  import HTMLEditor from "../htmleditor/HTMLEditor.svelte";
  import Select from "svelte-select";

  export let thinkin;
  export let staff = [];
  export let readoutAuthor = {};

  $: readoutAuthorName = readoutAuthor && readoutAuthor.name;
  const staffNames = staff.map(member => member.name);

  const dispatch = createEventDispatcher();

  let follow_up_email_status = "Send follow up emails";

  async function handleSendingFollowUpEmails() {
    follow_up_email_status = "Sending…";
    const followUpUrl = `/thinkins/${thinkin.id}/booking?send_follow_ups=true`;
    const emailRes = await saveResource("POST", followUpUrl, {});
    if (emailRes && emailRes.followUpEmailsSent) {
      const thinkinRes = await saveResource("PUT", `/thinkins/${thinkin.id}`, { follow_up_emails_sent: true });
      thinkin.follow_up_emails_sent = thinkinRes.follow_up_emails_sent;
    } else {
      follow_up_email_status = "Failed to send emails";
    }
  }

  async function handleSelect(event) {
    readoutAuthorName = event.detail.value;
    const readoutAuthor = staff.filter(member => member.name == readoutAuthorName)[0];
    if (readoutAuthor) {
      dispatch("change", { readoutAuthor });
    }
  }
</script>

<form on:input={() => dispatch("change", { readoutAuthor })}>
  <p class="byline-wrapper">
    <label for="thinkin-byline">Byline</label>
    <Select
      items={staffNames}
      isClearable={false}
      hideEmptyState={false}
      showIndicator={false}
      value={readoutAuthorName}
      isCreatable={false}
      placeholder="Full name"
      on:select={handleSelect} />
  </p>
  <!-- <label for="">Additional text for follow up emails</label> -->
  <div class="html-editor">
    <HTMLEditor name="instructions" bind:value={thinkin.readout} placeholder="Optional" />
  </div>
  {#if thinkin.follow_up_emails_sent}
    <p class="sent">
      <label for="">
        <img src="/assets/checkmark-white.svg" alt="" />
        <span>Follow up emails have been sent</span>
      </label>
    </p>
  {:else}
    <p class="follow-up">
      <label for="" />
      <span class:warning={follow_up_email_status.includes("Failed")}>
        <button class="btn primary follow-up" on:click|preventDefault={handleSendingFollowUpEmails}
          >{follow_up_email_status}</button>
        <span>Add additional text to email</span>
      </span>
    </p>
  {/if}
</form>

<style>
  p {
    display: block;
  }
  label {
    text-align: left;
    padding-left: 0;
    margin-bottom: 8px;
  }

  form {
    padding-left: 25%;
    padding-right: 10%;
    position: relative;
  }
  label {
    display: block;
    color: white;
  }
  .sent label {
    margin-left: 0;
  }

  .follow-up {
    margin-top: 0;
    margin-right: 20px;
  }

  .byline-wrapper {
    --height: 36px;
    --width: 100%;
    --inputColor: #333;
    --clearSelectTop: 0;
    --clearSelectBottom: 0;
    --indicatorColor: #066;
    --indicatorTop: 4px;
    --indicatorRight: 3px;
    --itemColor: #333;
    --itemHoverBG: #eee;
    --selectedItemPadding: 0 3px;
    --listBackground: #fff;
    --itemIsActiveBG: rgba(0, 102, 102, 0.2);
    --itemIsActiveColor: var(--midnight);
    --border: none;
    --borderRadius: 0;
    --inputPadding: 6px 10px;
    --inputFontSize: 16px;
    color: #333;
  }
</style>
