<script>
import { thinkin } from "../../stores/sessionStore";
export let labels = true;
export let hosts = true;
export let guests = true;
</script>

<div class="hosts-and-guests" class:labels>
  {#if hosts}
    {#each $thinkin.hosts as { name, job_title, profile_picture}}
      <div class="host">
        {#if profile_picture}<img src={profile_picture} alt="">{/if}
        <b>{ name }</b>
        {#if job_title}<span>{ job_title }</span>{/if}
      </div>
    {/each}
  {/if}
  {#if guests}
    {#each $thinkin.invitedGuests as { name, job_title, profile_picture}}
      <div class="guest">
        {#if profile_picture}<img src={profile_picture} alt="">{/if}
        <b>{ name }</b>
        {#if job_title}<span>{ job_title }</span>{/if}
      </div>
    {/each}
  {/if}
</div>

<style>
.hosts-and-guests {
  display: flex;
  flex-wrap: wrap;
}
.labels {
  padding-top: 40px;  
}
.hosts-and-guests div {
  flex: 1;
  position: relative;
  padding-right: 20px;
  min-width: 200px;
}
.guest::after, .host::after {
  font-variant: small-caps;
  position: absolute;
  top: -28px;
  left: 0;
  font-family: Pegasus;
  color: #666;
  font-size: 18px;
}
.labels .host:first-child::after {
  content: "editors";
}
.labels .guest:first-child::after, .host + .guest::after {
  content: "invited experts";
}
.hosts-and-guests img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
}
.hosts-and-guests b, .hosts-and-guests span {
  display: block;
}

@media (max-width: 500px) {
  .hosts-and-guests {
    flex-direction: column;
  }
  .hosts-and-guests div {
    width: calc(100% - 40px);
    margin: 20px;
  }
}
</style>
