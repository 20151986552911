<script>
  import { DateTime } from "luxon";
  import { beforeUpdate, onMount } from "svelte";
  import { fetchData, resizeImage, saveResource, uploadImage } from "../../common/helpers";

  import { thinkin } from "../../stores/sessionStore";
  import { session } from "./session";

  export let open = false;

  let clips = [];
  let participants = [];
  let timeline = [];
  let elapsed_time = 0;

  export async function add(started_at, participant) {
    let previous_clip = currentClip();
    if (previous_clip && previous_clip.user_id == participant.uuid) return;
    console.log("creating new clip");

    let clip = {
      user_id: participant.uuid,
      description: participant.role,
      started_at,      
    };
    let snapshot = session.getSnapshot(participant.uuid);
    if (snapshot) {
      const downsized_snapshot = await resizeImage(snapshot, 100);
      clip.snapshot = await uploadImage(downsized_snapshot, `${$thinkin.id}-${participant.uuid}-${ Date.now() }`, "thinkins-posters", participant.uuid);
    }

    let createdClip = await saveResource("POST", `/thinkins/${$thinkin.id}/clips`, clip);
    clips.push(createdClip);
    updateTimeline();

    if (previous_clip) {
      saveResource("PUT", `/thinkins/${$thinkin.id}/clips/${previous_clip.id}`, { ended_at: DateTime.utc().toString() });
    }
  }

  async function fetchClips() {
    let data = await fetchData(`/thinkins/${$thinkin.id}/clips`);
    clips = data.clips;
  }

  async function fetchParticipants() {
    let data = await fetchData(`/thinkins/${$thinkin.id}/participants`);
    participants = data.participants;
  }

  function currentClip() {
    return clips.find((clip) => { return clip.started_at && !clip.ended_at });
  }

  function updateTimeline() {
    if (!clips.length) return;
    let speakers = [];
    let min_time = DateTime.fromISO(clips[0].started_at).ts
    let max_time = $thinkin.status == "Completed" && $thinkin.ended_at ? DateTime.fromISO($thinkin.ended_at).ts : DateTime.now().ts;
    elapsed_time = Math.max(max_time - min_time, ($thinkin.duration || 60) * 60 * 1000);

    clips.forEach((clip, index) => {
      const uuid = clip.user_id;
      let speaker = speakers.find((p) => p.uuid == uuid);
      if (!speaker) {
        let participant = participants.find((p) => p.uuid == uuid);
        if (!participant) {
          console.warn("could not find participant for clip", uuid);
          return;
        }
        speaker = {
          uuid,
          participant,
          clips: [],
          snapshot: clip.snapshot,
          description: clip.description,
          total: 0
        };
        speakers.push(speaker);
      }
      let new_clip = {
        started_at: clip.started_at,
        ended_at: clip.ended_at,
        snapshot: clip.snapshot,
      };
      let duration;
      const started_at = DateTime.fromISO(clip.started_at).ts;
      const next_clip = clips[index + 1];
      if (next_clip) {
        duration = DateTime.fromISO(next_clip.started_at).ts - started_at;
      } else
      if (clip.ended_at) {
        duration = DateTime.fromISO(clip.ended_at).ts - started_at;
      }
      if (duration) {
        new_clip.time = formatTime(duration);
        speaker.total += duration;
      } else {
        duration = 1;
        new_clip.time = "";    
      }
      const left = ((started_at - min_time) / elapsed_time) * 100;
      const width = (duration / elapsed_time) * 100;
      new_clip.style = `left:${left}%;width:${width}%;`;
      speaker.clips.push(new_clip);
    });

    speakers.forEach((speaker) => {
      if (speaker.total) speaker.total = formatTime(speaker.total);
    });

    timeline = speakers;
  }

  function formatTime(milliseconds) {
    const seconds = milliseconds / 1000;
    if (seconds < 60) {
      return Math.round(seconds) + "s";
    } else {
      return Math.floor(seconds / 60) + "m " + Math.round(seconds % 60) + "s";
    }
  }

  onMount(async () => {
    await Promise.all([fetchClips(), fetchParticipants()]);
    updateTimeline();
  });

</script>

{#if open && timeline.length}
  <div class="timelines">
  {#each timeline as { uuid, participant, clips, snapshot, total, description }}
    <div class="timeline {description}">
      <figure>
        <figcaption>
          <h2>{participant.name}</h2>
          {#if total}<p>{total}</p>{/if}
        </figcaption>
        {#if snapshot || participant.profile_picture} 
          <img src={snapshot || participant.profile_picture} alt="" class="avatar">
        {:else}
          <img src="/assets/avatar.png" alt="" class="avatar faceless">
        {/if}
      </figure>
      {#if elapsed_time}
        <div class="clips">
          {#each clips as { time, style }}
          <span style={style} title={time}></span>
          {/each}
        </div>
      {/if}
    </div>
  {/each}
  </div>
{/if}

<style>
  .timelines {
    padding: 10px 20px;
    height: 100%;
    overflow: auto;
  }
  .timeline {
    width: 100%;
    height: 60px;
    text-align: left;
    border-bottom: 1px solid rgba(255,255,255,.2);
    padding: 10px;
    position: relative;
  }
  figure {
    width: 180px;
    padding: 0 50px 0 0;
    margin: 0;
    position: relative;
  }
  figcaption {
    text-align: right;
  }
  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #222;
    object-fit: cover;
  }
  h2 {
    font-weight: normal;
    margin: 0;
    font-size: 14px;
    color: #ccc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 14px;
    margin: 0;
    color: #fff;
  }
  .clips {
    position: absolute;
    left: 200px;
    top: 30px;
    right: 20px;
    height: 2px;
    border-top: 1px solid #D6D5D5;
  }
  .clips span {
    display: block;
    position: absolute;
    top: -11px;
    height: 20px;
    background: #D6D5D5;
    border-left: 1px solid black;
    min-width: 3px;
  }
  .clips.host span, .clips.master span {
    background: var(--kelp);
  }
  .clips.guest span {
    background: var(--lettuce);
  }
</style>
