<script>
    import {onDestroy, onMount, createEventDispatcher} from "svelte";
    import {DateTime} from "luxon";

    import FlourishEmbed from "./FlourishEmbed.svelte";
    import {thinkin, user} from "../../stores/sessionStore";
    import {session} from "../live/session";
    import {adoptOrphans, fetchData, saveResource} from "../../common/helpers";
    import {showPianoLogin} from "../../common/authoriser";

    import AddToCalendar from "./AddToCalendar";
    import Booking from "./Booking";
    import ChatHighlights from "./ChatHighlights";
    import Promotions from "./Promotions.svelte";
    import {CustomPlayer, AuthorByline, Modal} from "@galapagos/svelte-components";

    const dispatch = createEventDispatcher();

    const scheduled_date = DateTime.fromISO($thinkin.scheduled_date);
    const display_date = scheduled_date.toFormat("ccc d LLL y");
    const end_time = scheduled_date.plus({minutes: $thinkin.duration || 60});
    const display_time = `${scheduled_date.toFormat("T")}–${end_time.toFormat("T")}`;
    const luxonScheduledDt = DateTime.fromISO(new Date(scheduled_date).toISOString()).setZone("Europe/London");
    const timezone = luxonScheduledDt.toFormat("ZZZZ").includes("GMT+1") ? "BST" : "GMT"; // fix me

    let innerWidth;
    $: authorByliner = innerWidth < 1280 ? "column" : "row";
    let inviteModalOpen = false;
    let guestModalOpen = true;
    const inviteCodeVerified = false;
    let videoPoster;
    if ($thinkin.status === "Completed" && $thinkin.recording_url && $thinkin.audience === "private") {
        videoPoster = $thinkin.poster_url;
        delete $thinkin.poster_url;
    }
    $: can_join = ($thinkin.status == "Live" || $thinkin.status == "Green room") && $user;

    $: youtube_id = $thinkin.youtube_url && $thinkin.youtube_url.split("?v=")[1];
    $: readoutAuthor = $thinkin.readout_author || {};
    $: spotifyUrl = $thinkin.spotify_url;

    let watch_interval;

    function watchStatus() {
        clearInterval(watch_interval);
        watch_interval = setInterval(() => {
            fetchThinkin().then(data => {
                if (data.status == "Live") {
                    console.log("now we’re live!");
                    thinkin.set(data);
                }
            });
        }, 30 * 1000);
    }

    function invited(event) {
        console.log("received invite signal:", event);
        inviteModalOpen = true;
        guestModalOpen = false;
        // joining = true;
    }

    function started(event) {
        console.log("received start signal:", event);
        $thinkin.status = "Live";
        // joining = true;
    }

    async function fetchThinkin() {
        const loggedIn = $user && $user.uuid;
        let url = `/thinkins/${$thinkin.id}`;
        if (loggedIn) {
            url += `?userid=${$user.uuid}`;
        }
        const data = await fetchData(url);
        // TODO: better error handling
        if (typeof data == "string") {
            throw data;
        }
        thinkin.set(data);
        console.log("THINKIN>>", data);
        return data;
    }

    async function joinThinkin(with_camera) {
        if (!$user.name) {
            console.error("Your name is required");
            return;
        }
        try {
            await addUserToTheThinkinSession();
            // Refetch thinkin to get session token
            await fetchThinkin();
            if ($thinkin.platform !== "Tortoise") {
                document.location.href = $thinkin.external_url;
            } else {
                dispatch("join", {with_camera});
            }
        } catch (err) {
            return console.error(err);
        }
    }

    async function addUserToTheThinkinSession() {
        const ticket_type_id = $user.ticket_type_id;
        let url = `/thinkins/${$thinkin.id}/participants`;
        let method;
        await fetchData(`/thinkins/${$thinkin.id}/participants/${$user.uuid}`).then(participant => {
            if (participant.user) {
                method = "PUT";
                console.log("updating existing participant");
                url += "/" + $user.uuid;
            } else {
                console.warn("creating new participant");
                method = "POST";
            }
        });

        await saveResource(method, url, {
            user_id: $user.uuid,
            joined_at: DateTime.utc().toString(),
            left_at: null,
            ticket_type_id: ticket_type_id,
            tokbox_client_id: localStorage.getItem("opentok_client_id"),
        });
    }

    $: if ($thinkin.status == "Green room" && $user && $thinkin.apiKey) {
        session.connect($thinkin, $user.uuid).then(() => {
            session.listen({invite: invited, start: started});
        });
    }

    $: if ($user && $user.role === "guest") {
        fetchThinkin();
    }

    $: if ($thinkin.status == "Published") {
        const time_to_start = new Date($thinkin.scheduled_date).getTime() - Date.now();
        // if less than 10 minutes to scheduled start,
        // start watching the status
        if (time_to_start < 10 * 60 * 1000 && $user) {
            watchStatus();
        }
    }

    function getUser() {
        const tp = window.tp || [];
        if (!tp.user || !tp.user.isUserValid()) {
            return console.warn("tp.user not found");
        }

        tp.user.getProvider().loadExtendedUser({
            extendedUserLoaded: async function (data) {
                const user_data = {
                    uuid: data.uid,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    name: data.first_name + " " + data.last_name,
                    email: data.email,
                    token: tp.user.getProvider().getToken(),
                };
                if (data.custom_field_values) {
                    data.custom_field_values.forEach(field => {
                        user_data[field.field_name] =
                            field.value && field.value !== "unlimited" ? parseInt(field.value) : field.value;
                    });
                }
                user.set(user_data);
            },
        });
    }

    onMount(() => {
        if (!$user) {
            getUser();
        }
    });

    onDestroy(() => {
        if (watch_interval) {
            clearInterval(watch_interval);
        }
    });

    let main;
    let player;
    let recapContainer;
    let isFloating = false;
    const htmlRegexG = /<[^>]*>/g;

    function floatVideo() {
        const playerHeight = recapContainer.offsetHeight; // height of player element
        const playerOffsetTop = recapContainer.offsetTop + playerHeight; // player element position from top of main
        recapContainer.style.height = playerHeight + "px";

        if (main.scrollTop > playerOffsetTop) {
            isFloating = true;
        } else {
            isFloating = false;
            recapContainer.style.height = "auto";
        }

        if (!isFloating) {
            player.style.display = "block";
        }
    }

    function closeFloatingPlayer() {
        if (isFloating) {
            player.style.display = "none";
        }
    }
</script>

<svelte:window bind:innerWidth/>

{#if inviteModalOpen}
    <Modal>
        <div class="guest-modal-wrapper">
            <p>The host has invited you to join green room</p>
            <div class="modal-buttons">
                <button type="button" class="btn large camera arrow-right" on:click={() => joinThinkin(true)}>
                    Enter now
                </button>
            </div>
        </div>
    </Modal>
{/if}

<header>
    <div class="logo">
        <span>ThinkIn <i>with</i></span>
        <img alt="Tortoise" src="/assets/tortoise-wordmark-moss.svg" class="wordmark"/>
    </div>
    <div class="booking-options">
        <div class="buttons">
            {#if $thinkin.status == "Completed"}
                {#if $user}
                    <p>Hello {$user.first_name}</p>
                {:else}
                    <button name="login" on:click={showPianoLogin}> Sign in</button>
                {/if}
            {:else if $thinkin.audience == "public" && !can_join}
                {#if $user && $user.membership_type == "Guest"}
                    {#if guestModalOpen}
                        <Modal on:close={() => (guestModalOpen = false)}>
                            <div class="guest-modal-wrapper">
                                <p>
                                    <b>Thank you for joining us today.</b> <br/> Once the host starts the thinkin you
                                    will be invited to join
                                    the green room.
                                </p>
                            </div>
                        </Modal>
                    {/if}
                {:else}
                    {#if $user}
                        <p>{$user.first_name}</p>
                    {:else}
                        <button name="login" on:click={showPianoLogin}> Already a member?</button>
                    {/if}
                    <Booking/>
                {/if}
            {:else if $thinkin.audience == "private"}
                {#if inviteCodeVerified}
                    <button type="button" class="btn large external"> You are booked</button>
                {:else}
                    <Booking/>
                {/if}
            {:else if can_join}
                <button type="button" class="btn large arrow-right golive" on:click={() => joinThinkin(true)}>
                    Enter now
                </button>
            {/if}
        </div>
    </div>
</header>

<main bind:this={main} on:scroll={floatVideo}>
    <section class="hero" style={$thinkin.poster_url && "height: 100vh"}>
        {#if $thinkin.poster_url}
            <figure class="poster">
                <img src={$thinkin.poster_url} alt=""/>
            </figure>
        {/if}
        <div class="heading">
            <div class="time-and-partners">
                <div class="scheduled-time tm-article__info">
                    <p class="small-caps">Thinkin</p>
                    <span>|</span>
                    {#if $thinkin.status == "Live"}
                        <p class="live">Live now</p>
                    {:else}
                        {#if $thinkin.audience == "private"}
                            <p>
                                {display_date}&nbsp;&nbsp;•&nbsp;&nbsp;{display_time}
                                <span class="tz">{$thinkin.timezone}</span>
                            </p>
                        {:else}
                            <p>
                                {display_date}&nbsp;&nbsp;•&nbsp;&nbsp;{display_time}
                                <span class="tz">{timezone}</span>
                            </p>
                        {/if}
                        {#if $thinkin.status == "Published"}
                            <div class="calendar">
                                <AddToCalendar/>
                            </div>
                        {/if}
                    {/if}
                </div>
            </div>
            <h1 class="tm-article__headline">{adoptOrphans($thinkin.name)}</h1>
        </div>
    </section>

    {#if $thinkin.status === "Completed" && $thinkin.recording_url}
        <div class="recap-container" bind:this={recapContainer}>
            <div class={isFloating ? "recap-floating" : "recap-wrapper"} bind:this={player}>
                <CustomPlayer
                    videoFileUrl={$thinkin.recording_url}
                    posterFileUrl={videoPoster}
                    transcriptUrl={$thinkin.recording_transcript_url}
                    bind:isFloating/>
                <div class="close-video" on:keydown={closeFloatingPlayer} on:click={closeFloatingPlayer}>&#x2715</div>
            </div>
        </div>
    {/if}

    <section class="main-section">
        {#if $thinkin.status !== "Completed"}
            <aside class="booking-types">
                {#if $thinkin.audience == "exclusive"}
                    <p>This event is exclusively for Friend of Tortoise members.</p>
                {:else if $thinkin.audience == "private"}
                    {$thinkin.booking_type_description}
                {:else if $thinkin.platform !== ""}
                    {#if $thinkin.in_person_ticket_count && $thinkin.digital_ticket_count}
                        <p>
                            This is a newsroom
                            ThinkIn{$thinkin.location ? ` taking place at ${$thinkin.location}.` : "."} In-person and
                            digital-only tickets are available.
                        </p>
                    {:else if $thinkin.in_person_ticket_count}
                        <p>This is an in-person
                            ThinkIn{$thinkin.location ? ` taking place at ${$thinkin.location}.` : "."}</p>
                    {:else if $thinkin.digital_ticket_count}
                        <p>This is a digital-only ThinkIn.</p>
                    {/if}
                {/if}
            </aside>
        {/if}

        {#if $thinkin.sponsors.length}
            <div class="partners">
                <h6 class="small-caps">In partnership with</h6>
                {#each $thinkin.sponsors as {partner_logo_url, partner_name}}
                    {#if partner_logo_url}
                        <img alt={partner_name} src={partner_logo_url}/>
                    {:else}
                        <p class="partner-name">
                            {partner_name}
                        </p>
                    {/if}
                {/each}
            </div>
        {/if}

        <section class="details">
            <article>
                {#if $thinkin.description}
                    <div class="description">
                        <h5>About this ThinkIn</h5>
                        {@html $thinkin.description}
                    </div>
                {/if}

                {#if $thinkin.hosts.length || $thinkin.invitedGuests.length || $thinkin.chat_hosts.length}
                    <aside class="hosts">
                        <!-- <p class="kicker small-caps">
                        Editor {#if $thinkin.invitedGuests.length} and invited guests{/if}
                      </p> -->
                        {#if $thinkin.hosts.length}
                            {#each $thinkin.hosts as host}
                                <AuthorByline
                                    {...host}
                                    profilePicture={host.profile_picture}
                                    jobTitle={host.job_title}
                                    orientation={authorByliner}/>
                            {/each}
                        {/if}
                        {#if $thinkin.invitedGuests.length}
                            <h5>
                                Invited expert
                                {#if $thinkin.invitedGuests.length > 1}s{/if}
                            </h5>
                            <div class="guests">
                                {#each $thinkin.invitedGuests as guest}
                                    <div>
                                        <AuthorByline
                                            {...guest}
                                            profilePicture={guest.profile_picture}
                                            jobTitle={guest.job_title}
                                            orientation={authorByliner}/>
                                    </div>
                                {/each}
                            </div>
                        {/if}
                        {#if $thinkin.chat_hosts.length}
                            <h5>
                                Chat host
                                {#if $thinkin.chat_hosts.length > 1}s{/if}
                            </h5>
                            {#each $thinkin.chat_hosts as chatHost}
                                <AuthorByline
                                    {...chatHost}
                                    profilePicture={chatHost.profile_picture}
                                    jobTitle={chatHost.job_title}
                                    orientation={authorByliner}/>
                            {/each}
                        {/if}
                    </aside>
                {/if}

                <!-- pull out any non-whitespace text that is not an html tag, and then see if that is an empty string or not -->
                {#if $thinkin.readout && $thinkin.readout.replace(htmlRegexG, "") !== ""}
                    <h5>Read out</h5>
                    <div class="readout-wrapper">
                        {@html $thinkin.readout}
                    </div>
                    {#if $thinkin.readout_author}
                        <svg viewBox="0 0 30 40" version="1.1">
                            <polygon points="30 0 30 40 0 0"/>
                        </svg>
                        <div class="author-byline-wrapper">
                            <AuthorByline
                                jobTitle={readoutAuthor.job_title}
                                profilePicture={readoutAuthor.profile_picture}
                                name={readoutAuthor.name}/>
                        </div>
                    {/if}
                {/if}

                {#if $thinkin.recording_comments_url}
                    <ChatHighlights path={$thinkin.recording_comments_path}/>
                {/if}

                {#if $thinkin.flourish_visualisation_id}
                    <h5>Sensemaker</h5>
                    <FlourishEmbed flourishId={$thinkin.flourish_visualisation_id}/>
                {/if}

                {#if spotifyUrl}
                    <h5>Music played</h5>
                    <iframe
                        src={spotifyUrl}
                        width="300"
                        height="380"
                        frameborder="0"
                        allowtransparency="true"
                        allow="encrypted-media"
                        title="recap-playlist"/>
                {/if}
                {#if youtube_id && !$thinkin.recording_url}
                    <hr/>
                    <p class="kicker">Catch up</p>
                    <div class="video">
                        <iframe
                            width="100%"
                            src="https://www.youtube.com/embed/{youtube_id}?controls=0"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen/>
                    </div>
                {/if}

                <img class="agatha" src="/assets/agatha.svg" alt=""/>
            </article>

            <aside class="explanation">
                {#if $thinkin.audience == "private"}
                    <h5>A bit about Tortoise.</h5>
                    <p>
                        Now with more than 115,000 members, Tortoise Media is the news start-up founded by James
                        Harding, former
                        Director of News at the BBC, Katie Vanneck-Smith of the Wall Street Journal, and Matthew Barzun,
                        President
                        Obama’s Ambassador to the UK.
                    </p>
                {/if}
                <p>
                    If you need help, please read <a href="https://www.tortoisemedia.com/about-us/faqs/">our FAQs </a>,
                    or write
                    to us at
                    <a href="mailto:thinkin@tortoisemedia.com">thinkin@tortoisemedia.com</a>.
                </p>
            </aside>
        </section>
    </section>
    {#if $thinkin.status === "Completed"}
        <div class="promotions">
            <Promotions id={$thinkin.id}/>
        </div>
    {/if}
</main>

<style>
    header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px 5px 15px;
        color: #fff;
        background: var(--kelpDark);
        font-size: 20px;
        z-index: 4;
    }

    .logo {
        padding: 5px;
        font-size: 24px;
        line-height: 34px;
        font-family: Pegasus, serif;
    }

    .logo img {
        height: 17px;
    }

    main {
        background: #fff;
        color: #111;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        padding: 54px 0 0;
        overflow-x: hidden;
    }

    .hero {
        width: 100vw;
        padding: 0;
        margin: 0;
        position: relative;
    }

    .close-video {
        color: white;
        position: absolute;
        right: 8px;
        top: 0;
        cursor: pointer;
        opacity: 0;
    }

    .close-video:hover {
        opacity: 1;
        transition: 500ms ease-in-out;
    }

    .recap-wrapper {
        margin: 0 24px;
    }

    .recap-floating {
        position: fixed;
        width: 30%;
        max-width: 100%;
        height: auto;
        top: 54px;
        right: 0;
        z-index: 100;
        margin: 0;
        animation: fade-in-up 0.75s ease forwards;
    }

    @keyframes fade-in-up {
        0% {
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .scheduled-time > span {
        display: none;
    }

    .poster {
        width: 100%;
        padding: 0;
        margin: 0;
        z-index: 1;
    }

    .poster img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .heading,
    .details {
        background: #fff;
        z-index: 2;
        /*position: relative;*/
        text-align: left;
        margin-left: 16px;
        margin-right: 16px;
        overflow-y: visible;
    }

    .details {
        margin: 0;
    }

    .main-section:not(.promotions) {
        margin: 0 16px;
    }

    .booking-types {
        padding: 17px 16px;
        border-top: 1px solid #b8b8b8;
        border-bottom: 1px solid #b8b8b8;
    }

    .booking-types > p {
        text-align: center;
    }

    .booking-options {
        text-align: right;
    }

    button.large {
        margin: 0;
        padding: 0 48px 0 24px;
        height: 40px;
        font-size: 20px;
        border-radius: 30px;
        transform-origin: center;
        transition: transform 200ms;
        font-family: Pegasus;
    }

    button.camera,
    button.external {
        background-color: var(--kelp);
        color: #fff;
    }

    button.large:active {
        transform: scale(1.1);
    }

    article,
    aside {
        width: 100%;
        text-align: left;
        margin: 0 auto;
        font-family: Pegasus;
        font-size: 24px;
        line-height: 34px;
        color: #333;
    }

    .explanation {
        border-top: 1px solid #eee;
        padding-bottom: 60px;
    }

    h1 {
        font-family: Pegasus, serif;
        font-size: 40px;
        line-height: 48px;
        font-weight: normal;
        margin: 4px 0 20px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h1:first-child {
        margin-top: 50px;
    }

    h5 {
        font-size: 32px;
        line-height: 40px;
        font-weight: normal;
        margin: 64px 0 16px;
    }

    .description h5 {
        margin-top: 0;
    }

    .scheduled-time {
        font-family: Koopman, sans-serif;
        font-size: 16px;
        line-height: 22px;
        position: relative;
        color: #333;
        margin: 8px 0;
    }

    .scheduled-time p {
        margin: 0;
    }

    .calendar {
        z-index: 2000;
        position: relative;
    }

    .description {
        margin: 20px 0;
    }

    aside,
    .explanation {
        font-family: Koopman;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
        margin: 0 auto;
    }

    aside h5 {
        font: inherit;
        font-weight: bold;
        margin: 16px 0 0;
        color: #333;
    }

    .hosts h5 {
        margin-bottom: 12px;
    }

    .hosts h5::before {
        content: "";
        display: block;
        width: 138px;
        max-width: 100%;
        height: 1px;
        background-color: #b8b8b8;
        margin-bottom: 12px;
    }

    aside p {
        margin: 0 0 8px;
    }

    article :global(p),
    article :global(li) {
        margin: 0 0 24px;
        font-size: 20px;
        line-height: 28px;
    }

    p a {
        color: var(--kelp);
    }

    .readout-wrapper {
        position: relative;
        background-color: var(--mouse);
        padding: 8px 24px 24px;
    }

    .readout-wrapper :global(p) {
        margin: 16px 0 0;
    }

    .readout-wrapper :global(ul) {
        margin: 0;
        padding-left: 20px;
    }

    .readout-wrapper :global(li) {
        margin: 0;
    }

    svg {
        width: 30px;
        height: 40px;
        pointer-events: none;
        position: relative;
        left: 10px;
        fill: var(--mouse);
        bottom: 0px;
    }

    .author-byline-wrapper {
        position: relative;
        left: 10px;
    }

    .video {
        position: relative;
        height: 0;
        padding: 56.25% 0 0 0;
    }

    .video > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .partner-name {
        margin: 0;
        color: var(--kelp);
        font-size: 24px;
        font-weight: 400;
        font-family: "Pegasus";
    }

    .time-and-partners {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .partners img {
        max-width: 140px;
        max-height: 64px;
        margin-right: 24px;
        margin-bottom: 24px;
    }

    .partners {
        text-align: left;
        margin-top: 40px;
    }

    .small-caps,
    .live {
        text-transform: lowercase;
        font-variant: small-caps;
        font-size: 19px;
        line-height: 20px;
        margin: 0 0 6px !important;
        font-family: Pegasus;
        font-weight: bold;
        color: #333;
        letter-spacing: 1px;
    }

    .live {
        color: #fff;
        background-color: #e04600;
        padding: 0 5px 2px;
    }

    .agatha {
        margin: 20px 0;
        width: 24px;
    }

    .guest-modal-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        flex-direction: column;
        align-items: center;
    }

    .guest-modal-wrapper > p {
        width: 70%;
    }

    .hosts {
        text-align: left;
        font-family: Koopman;
        font-size: 16px;
        line-height: 22px;
        margin: 47px 0 40px;
    }

    .golive {
        background-color: #ff7846;
    }

    .golive::after {
        background-image: url(/assets/arrow-plain-right-black.svg);
    }

    button[name="login"] {
        color: #fff;
        font-size: 16px;
        opacity: 0.8;
    }

    @media (max-width: 654px) {
        h5 {
            font-size: 28px;
            line-height: 36px;
        }

        .small-caps {
            font-size: 17px;
            line-height: 18px;
        }

        button[name="login"],
        .logo span {
            display: none;
        }
    }

    @media (min-width: 655px) {
        main {
            position: relative;
        }

        .heading {
            padding: 12px 24px;
            width: calc(66.6% + 118px);
            margin: 0;
            position: relative;
            z-index: 3;
        }

        article,
        aside {
            width: calc(66.6% + 118px);
            margin: auto;
        }

        article,
        .explanation {
            padding: 0 16px;
        }

        .hosts {
            margin: 31px 16px 0 0;
        }

        .main-section {
            position: relative;
            z-index: 2;
            padding-top: 64px;
            background: white;
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        h1 {
            font-size: 52px;
            line-height: 60px;
            margin-top: 12px;
        }

        .hero {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }

        .poster {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
        }

        .scheduled-time {
            display: flex;
            gap: 16px;
            margin: 0;
            align-items: baseline;
        }

        .scheduled-time > span {
            display: block;
            color: #b8b8b8;
            font-size: larger;
            font-family: Pegasus, serif;
        }

        article p:not(.kicker) {
            font-size: 24px;
            line-height: 34px;
        }

        .partners {
            width: calc(66.6% + 118px);
            padding: 0 16px;
        }

        .partners img {
            max-width: 176px;
            max-height: 80px;
            margin-right: 40px;
            margin-bottom: 40px;
        }

        .explanation {
            margin-bottom: 60px;
        }
    }

    @media (min-width: 655px) and (max-width: 1280px) {
        aside.hosts {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 48px 0 0;
            width: 100%;
        }

        .guests {
            display: flex;
            flex-wrap: wrap;
        }

        .guests > div {
            width: 50%;
            padding-right: 24px;
        }
    }

    @media (max-width: 655px) {
        .readout-wrapper {
            padding: 8px 8px 8px 16px;
        }

        .recap-wrapper {
            margin: 0 16px;
        }
    }

    @media (min-width: 1280px) {
        .main-section {
            position: relative;
            top: 0;
            align-items: flex-start;
            padding-top: 0;
            padding-left: 25.33vw;
            margin: 0 !important;
        }

        .hero {
            position: relative;
        }

        .recap-wrapper {
            margin: 0 8vw;
        }

        .recap-floating {
            width: 16vw;
        }

        .heading {
            width: 50vw;
            padding: 32px 48px;
            position: relative;
            top: 0;
        }

        .partners {
            width: 50vw;
            padding: 0 48px;
            margin-top: 64px;
        }

        article,
        .explanation {
            width: 50vw;
            padding: 36px 48px 12px;
        }

        .booking-types {
            position: absolute;
            right: 4vw;
            width: 250px;
            z-index: 3;
            top: 64px;
            border: none;
            padding: 0 !important;
        }

        .hosts {
            position: absolute;
            top: 64px;
            left: 5vw;
            width: 23vw;
            border-top: none;
            margin: 0;
            z-index: 3;
            padding: 0 !important;
            display: block;
        }
    }
</style>
