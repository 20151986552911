<script>
  export let label;
  export let value;
</script>

<div class="container">
  <label class="label-conatiner">
    <input type="checkbox" checked={value === "checked"} on:click on:keydown />
    <span class="label" />
  </label>
  <div class="checkbox-label">
    {label}
  </div>
</div>

<style>
  input[type="checkbox"] {
    display: none;
  }
  .label {
    width: 48px;
    height: 48px;
    display: inline-block;
    background: url("/assets/email-preferences/unchecked.png");
  }
  input[type="checkbox"]:checked + .label {
    width: 48px;
    height: 48px;
    background-image: url("/assets/email-preferences/checked.png");
  }
  .checkbox-label {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
  }
  .container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
  .label-conatiner {
    max-width: 48px;
    max-height: 48px;
    cursor: pointer;
  }
</style>
