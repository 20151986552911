<script>import { createEventDispatcher } from "svelte";
import { fade } from "svelte/transition";
const dispatch = createEventDispatcher();
$: ({ width = 450, height, dismissable = true, background = "#fff", overflow = "auto" } = $$props);
export let closed = false;
export function open() {
    closed = false;
}
$: if (closed) {
    dispatch("close", { closed });
}
$: style = `background:${background};overflow:${overflow};width:${width}px;` + (height ? `height:${height}px` : ``);
</script>

{#if !closed}
  <div
    out:fade
    on:click={() => {
      if (dismissable) {
        closed = true;
      }
    }}
    on:keydown={() => {
    }}
    class="modal-wrapper">
    <div class="modal" class:shadow={background !== "transparent"} {style} on:keydown on:click|stopPropagation>
      {#if dismissable}<button class="btn close" on:click={() => (closed = true)}>&times;</button>{/if}
      <slot />
    </div>
  </div>
{/if}

<style>@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Italic"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf") format("truetype");
  font-weight: bold; }

/* use Koopman for fixed labels (button) */
@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.tt") format("truetype"); }

@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.tt") format("truetype");
  font-weight: bold; }

* {
  box-sizing: border-box;
  font-family: Pegasus,serif; }

/* This one isn't in the styleguide but it is in the app */
.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10; }

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 90vw;
  min-height: 120px;
  max-height: 90vh;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 0.2em;
  background: #fff;
  color: #333;
  font-family: Koopman;
  font-size: 16px;
  line-height: normal;
  text-align: left;
  z-index: 20; }

.shadow {
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px; }

.close {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  font-family: Pegasus, serif;
  opacity: 0.9;
  text-decoration: none;
  border-radius: 20px;
  white-space: nowrap;
  color: #444;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 32px;
  min-width: 32px;
  height: 30px;
  margin: 0;
  padding: 0; }

.close:hover {
  color: #006666; }
</style>
