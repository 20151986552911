<script>
  import { createEventDispatcher } from "svelte";

  import { fetchData } from "../../common/helpers";
  import { Selector } from "@galapagos/svelte-components";

  const dispatch = createEventDispatcher();

  export let ticket_types;
  export let thinkin_audience;

  const partnerIds = ticket_types
    .filter(t => {
      if (thinkin_audience == "private") {
        return t.type_name == "Partner" || t.type_name == "Network" || t.type_name == "Student";
      }
      return t.type_name == "Partner";
    })
    .map(t => t.id);
  const sponsorIds = ticket_types.filter(t => t.type_name == "Sponsor").map(t => t.id);

  let allPartners;

  //  single quotes are needed or Database query will trow an error.
  const fetchUrl = `/tickets?columnToFilter=type_name&filterValues=${
    thinkin_audience == "private" ? "'Partner','Network','Student'" : "'Partner'"
  }`;
  let selectedSponsors = [];
  let selectedPartners = [];
  const ticketDataPromise = fetchPartnerData();
  async function fetchPartnerData() {
    const data = await fetchData(fetchUrl);
    allPartners = data.ticket_types || [];
    selectedSponsors = getActiveItems(sponsorIds);
    selectedPartners = getActiveItems(partnerIds);
  }
  $: selectableOptions = allPartners ? allPartners.map(p => p.partner_name) : [];

  function getActiveItems(itemIds) {
    const activeItems = allPartners
      .map(item => {
        if (itemIds.includes(item.id)) {
          return item.partner_name;
        }
      })
      .filter(itemName => itemName !== undefined)
      .sort((itemAName, itemBName) => itemAName.localeCompare(itemBName));
    return activeItems;
  }

  function findItemID(name) {
    const id = allPartners.filter(p => p.partner_name == name)[0].id;
    return id;
  }

  function handleChange() {
    const sponsor_ticket_ids = selectedSponsors.map(sponsor => findItemID(sponsor)) || [];
    const partner_ticket_ids = selectedPartners.map(partner => findItemID(partner)) || [];
    dispatch("change", { sponsor_ticket_ids, partner_ticket_ids });
  }
</script>

{#await ticketDataPromise then promise}
  {#if allPartners}
    <div class="select-wrapper">
      <div class="sponsors">
        <h3>Sponsors</h3>
        <p>Sponsor logos will appear on the ThinkIn page, and their invite codes will be accepted.</p>
        <Selector options={selectableOptions} bind:selection={selectedSponsors} on:change={handleChange} />
      </div>

      <div class="partners">
        <h3>Partners</h3>
        <p>Partner invite codes will be accepted, but their logos will not be shown.</p>
        <Selector options={selectableOptions} bind:selection={selectedPartners} on:change={handleChange} />
      </div>
    </div>
  {/if}
{/await}

<style>
  .select-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .sponsors,
  .partners {
    width: 50%;
    padding-right: 40px;
    position: relative;
  }
  h3 {
    margin: 0 0 5px;
    font-weight: normal;
  }
  h3 + p {
    max-width: 500px;
    opacity: 0.6;
    margin: 0 0 10px;
    padding: 0 100px 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
</style>
