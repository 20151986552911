import Cookies from "js-cookie";
import { DateTime } from "luxon";

export function timestamp(ms) {
  const date = ms ? DateTime.fromMillis(ms) : DateTime.now();
  return date.toFormat("F");
}

export function handleTimeFormatting(timestamp) {
  const dt = DateTime.fromISO(timestamp);
  const WD = dt.toFormat("ccc");
  const DD = dt.toFormat("d");
  const MMM = dt.toFormat("LLL");
  const YYYY = dt.toFormat("y");
  const ZZ = dt.toFormat("ZZ");
  const Z = dt.toFormat("z");

  const TIMEZONE = Z + " " + ZZ;
  const DATE = `${DD} ${MMM}`;
  const TIME = dt.toFormat("T");
  const DATEYYYYTIME = `${DATE} ${YYYY} ${TIME}`;
  const DDMMMYYY = `${DD} ${MMM} ${YYYY}`;

  return { DATE, TIME, WD, YYYY, DATEYYYYTIME, DDMMMYYY, TIMEZONE, DD, MMM };
}

export function handleMinutesFormatting(minutes) {
  const integerMinutes = Math.round(minutes);

  const hours = Math.floor(integerMinutes / 60);
  const minutesRemaining = integerMinutes - hours * 60;

  const readableDuration = [];

  if (hours) {
    readableDuration.push(hours > 1 ? `${hours}hrs` : `${hours}hr`);
  }

  if (minutesRemaining) {
    readableDuration.push(minutesRemaining > 1 ? `${minutesRemaining}mins` : `${minutesRemaining}min`);
  }

  return readableDuration.join(" ");
}

export function sameDate(prev, next) {
  const PREV = DateTime.fromISO(prev);
  const NEXT = DateTime.fromISO(next);

  return NEXT.hasSame(PREV, "day") && NEXT.hasSame(PREV, "year");
}

export function makeId(keyLength) {
  let result = DateTime.now().ts + "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < keyLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/*!
 * @param  {Array}           arr      The array to group items from
 * @param  {String|Function} criteria The criteria to group by
 * @return {Object}                   The grouped object
 */
export function groupBy(arr, criteria) {
  return arr.reduce(function (obj, item) {
    // Check if the criteria is a function to run on the item or a property of it
    const key = typeof criteria === "function" ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
}

/**
 * FETCH HELPERS
 */
export async function fetchData(url) {
  const authToken = Cookies.get("thinkin_authToken");
  if (!authToken && !url.includes("public")) {
    console.error("Attempting fetch without authorization", authToken);
  }
  let requestInfo;

  if (!url.includes("public")) {
    requestInfo = {
      headers: { Authorization: authToken },
    };
  }
  const res = await fetch(`${process.env.BASE_API_URL}${url}`, requestInfo);
  const data = await res.json();
  if (res.ok) {
    return data;
  } else {
    return new Error(data);
  }
}

export async function saveUser(attributes) {
  const { name, email, uuid, organisation_id, job_title, location, membership_type, first_name, last_name } =
    attributes;

  const body = {
    name,
    email,
    uuid,
    organisation_id,
    job_title,
    location,
    membership_type,
    first_name,
    last_name,
  };

  const fetchOptions = {
    headers: {
      "content-type": "application/JSON",
    },
    method: "POST",
    body: JSON.stringify(body),
  };

  const res = await fetch(`${process.env.BASE_API_URL}/public/users`, fetchOptions);
  const user = await res.json();
  if (!res.ok) {
    console.error(timestamp(), "Error:", user);
  }
  return user;
}

export async function saveResource(method, url, payload) {
  const fetchOptions = {
    headers: {
      "content-type": "application/JSON",
      "Authorization": Cookies.get("thinkin_authToken"),
    },
    method,
    body: JSON.stringify({ ...payload }),
  };
  const res = await fetch(`${process.env.BASE_API_URL}${url}`, fetchOptions);
  if (!res.ok) {
    console.error("saveResource fetch failed");
    throw "Could not save";
  }
  const updatedResource = await res.json();
  return updatedResource;
}

export async function deleteResource(url, body) {
  const deleteOptions = {
    method: "DELETE",
    headers: {
      Authorization: Cookies.get("thinkin_authToken"),
    },
  };
  if (body) {
    deleteOptions.body = JSON.stringify({ ...body });
  }
  const res = await fetch(`${process.env.BASE_API_URL}${url}`, deleteOptions);
  const data = await res.json();
  if (!res.ok) {
    return { error: data };
  }
  return data;
}

/**
 * IMAGE HELPERS
 */

export function selectImageFromComputer(e, callback) {
  const image = e.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(image);
  reader.onload = callback;
}

export async function uploadImage(imageString, name, subFolder, uuid) {
  const { contentType, imageData, imageName } = prepareBase64ToPost(imageString, name, uuid);
  const imageDetails = {
    bufferString: imageData,
    imageName: `${subFolder}/${imageName}`,
    contentType,
  };
  const imageUploadData = await saveResource("POST", "/uploadimage", imageDetails);
  return imageUploadData.s3url;
}

export function prepareBase64ToPost(b64String, name, uid) {
  const block = b64String.split(";"); // Split the base64 string in data and contentType
  const contentType = block[0].split(":")[1]; // Get the content type of the image
  const imageData = block[1].split(",")[1]; //image data as a string
  const ext = contentType.split("/")[1];
  if (!name) {
    name = "image-" + Math.round(Math.random() * 100000);
  }
  const imageName = `${name.replace(/ /g, "-")}${uid ? "-" + uid : ""}${Math.random()}.${ext}`;
  return { contentType, imageData, imageName };
}

export function resizeImage(image, width) {
  return new Promise((resolve) => {
    return createImage(image).then(img => {
      const height = (img.height / img.width) * width;
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL("image/jpeg"));
    });
  });
}

function createImage(image_string) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.src = image_string;
  });
}

export function adoptOrphans(text) {
  text = text.trim();
  const i = text.lastIndexOf(" ");
  if (i == -1) {
    return text;
  }
  return text.substr(0, i) + " " + text.substr(i + 1);
}

export function clickOutside(node) {
  const handleClick = event => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      node.dispatchEvent(new CustomEvent("click_outside", node));
    }
  };

  document.addEventListener("click", handleClick, true);

  return {
    destroy() {
      document.removeEventListener("click", handleClick, true);
    },
  };
}

export function formatPencePrice(pence) {
  return typeof pence === "number" ? `£${(pence / 100).toFixed(0)}` : "";
}

export function cleanupRichText(text) {
  const URL_MATCH = /(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

  // Do not allow random HTML markup
  let cleanText = text.trim().replace(/</g, "&lt;").replace(/>/g, "&gt;");

  // Smarten up apostrophes and quotes
  cleanText = cleanText
    .replace(/(\s)'(\w)/g, "$1‘$2")
    .replace(/'/g, "’")
    .replace(/(\s)"(\w)/g, "$1“$2")
    .replace(/"/g, "”");

  // Convert urls to links
  cleanText = cleanText.replace(URL_MATCH, function (matchedUrl) {
    let url = matchedUrl;
    let match = matchedUrl;
    if (/^https?:\/\//.test(url)) {
      match = match.substr(match.indexOf("/") + 2);
    } else {
      url = "https://" + url;
    }
    if (match.lastIndexOf("?") !== -1) {
      match = match.substr(0, match.lastIndexOf("?"));
    }
    match = match.replace(/\//g, "/<wbr>"); // to avoid breaking the page width
    return `<a href="${url}" target="_blank">${match}</a>`;
  });
  return cleanText;
}

export function linkify(content) {
  return content.replaceAll(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gm,
    link => `<a href="${"//" + link.replace(/https?:\/\//, "")}" contenteditable="false" target="_blank">${link}</a>`
  );
}
