<script>
import { user } from "../../stores/sessionStore";
let element;

export function getElement() {
	return element;
}
</script>

<button bind:this={element}
  class="tooltip bottom right"
  data-text="Settings"
  on:click>
  {#if $user.profile_picture}
    <img src={$user.profile_picture} alt="">
  {/if}
</button>

<style>
  button {
    position: absolute;
    top: 9px;
    left: 10px;
    width: 32px;
    height: 32px;
    padding: 0;
    border: 1px solid rgba(255,255,255,.2);
    background-image: url(/assets/avatar.png);
    background-size: contain;
    border-radius: 50%;
    overflow: hidden;
  }
  button :global(.OT_video-poster) {
    display: none!important;
  }
  button :global(.OT_widget-container) {
    background-color: transparent;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>