<script>
  import { createEventDispatcher } from 'svelte';
  import { slide } from "svelte/transition";

  import { saveResource } from "../../common/helpers";
  import { thinkin, user } from "../../stores/sessionStore";
  import { session } from "./session";

  export let poll;
  export let votes = [];
  export let host = false;

  const dispatch = createEventDispatcher();

  let voted = false;
  let selected_option;
  let results = poll.options.map((option) => {
    return { response: option, percent: 0, total: 0 }
  });

  function vote(option) {
    selected_option = option;
    voted = true;
    session.signal("pollvote", { uuid: $user.uuid, poll_id: poll.id, response: selected_option });
    saveResource("POST", `/thinkin/${ $thinkin.id }/polls/${ poll.id }/responses`, {
      response: selected_option,
      user_id: $user.uuid,
    });
  }

  $: updateResults(votes);

  function updateResults() {
    if (!votes.length) return;
    let totals = {};
    votes.forEach((vote) => {
      if (vote.poll_id !== poll.id) return;
      if (!totals[vote.response]) totals[vote.response] = 1;
      else totals[vote.response]++;
    });
    poll.options.forEach((option, index) => {
      if (totals[option]) {
        results[index].percent = Math.round(100 * totals[option] / votes.length);
        results[index].total = totals[option];
      }
    });
  }

  function end() {
    session.signal("pollend", { poll_id: poll.id });
  }
</script>

<div class="wrapper" transition:slide>
  {#if votes.length}
    <div class="results">
      {#each results as result}
        <div class="result" class:selected={result.response == selected_option}>
          {#if result.total}
            <div class="label">{ result.percent }%{#if host} ({ result.total }){/if}</div>
            <div class="bar" style={ `height:${ result.percent }%` }></div>
          {/if}
        </div>
      {/each}
    </div>
  {/if}
  <div class="poll">
    <div class="options">
      {#each poll.options as option}
        <button name="vote"
          class:selected={selected_option == option}
          disabled={voted} 
          on:click={() => vote(option)}>{ option }</button>
      {/each}
    </div>
    <h2>{ poll.question }</h2>
    {#if host}
      <button name="end" on:click={end} class="btn small outlined">End</button>
    {/if}
  </div>
</div>

<style>
.wrapper {
  position: absolute;
  width: 300px;
  left: calc(50vw - 150px);
  bottom: 120px;
  z-index: 20;
  box-shadow: 2px 2px 10px rgba(0,0,0,.5);
  padding: 10px 10px 0 10px;
  background-color: #fff;
}
.options {
  display: flex;
  justify-content: stretch;
  padding: 0 10px 5px 10px;
}
h2 {
  font-family: Pegasus;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
  color: #333;
  padding: 8px 15px 30px;
  margin: 0;
  text-align: left;
}
button[name=vote] {
  color: var(--kelp);
  padding: 10px;
  border: 1px solid var(--kelp);
  width: 100%;
  pointer-events: auto;
  font-weight: bold;
  margin: 0 0 0 20px;
  opacity: .8;
}
button[name=vote]:not(.selected):hover {
  opacity: 1;
}
button[name=vote]:first-child {
  margin-left: 0;
}
button.selected, 
button[disabled] {
  pointer-events: none;
  opacity: 1;
  color: #666;
  border-color: #fff;
  background-color: #fff;
}
button[name=end] {
  position: absolute;
  right: 10px;
  bottom: 12px;
  color: var(--kelp);
  border-color: var(--kelp);
}
.results {
  display: flex;
  justify-content: stretch;
  flex-wrap: nowrap;
  height: 400px;
  max-height: 50vh;
  color: #222;
}
.result {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.label {
  text-align: center;
  padding: 5px;
}
.bar {
  background-color: var(--kelp);
  transition: height 200ms;
}
.selected .bar {
  background-color: var(--moss);
}
</style>
