<script>
  let rating;
  let feedback;
  let feedback_sent = false;

  function sendFeedback() {
    console.log("TODO", rating, feedback);
    feedback_sent = true;
  }
</script>

{#if !feedback_sent}
<form on:submit|preventDefault={sendFeedback}>
  <p>Thanks for joining us. We’d love to know what you thought and hear your suggestions for follow-up.</p>
  <div class="rating">
    <span></span>
    {#each Array(10) as entry, index}
      <input type="radio" name="rating" bind:group={rating} value={index} class:selected={rating >= index}>
    {/each}
  </div>
  <textarea name="feedback" bind:value={feedback} placeholder="How did we do?"></textarea>
  <button type="submit" class="btn primary">Send</button>
</form>
{:else}
  <div class="success">
    <p>Thanks so much. We really appreciate your feedback.</p>
    <!-- TODO: upcoming bookings -->
  </div>
{/if}

<style>
form, .success {
  text-align: left;
  width: 600px;
  max-width: 90%;
  margin: 20px auto;
}
p, textarea {
  font-family: Pegasus;
  font-size: 20px;
}
.rating {
  margin-bottom: 20px;
}
input {
  width: 32px;
  height: 32px;
  background-image: url(/assets/grey-star.svg);
  background-size: cover;
  margin-right: 5px;
}
input::before, input::after {
  content: none;
}
input.selected {
  background-image: url(/assets/bright-star.svg);
}
textarea {
  background: #fff;
  height: 120px;
  width: 100%;
  margin-bottom: 12px;
}
</style>