<script context="module">export {};
</script>

<script>export let header;
export let bodyPanes;
// selection is read only. Could be made writable
export let selection = 0;
$: currentBodyPane = bodyPanes[selection];
const onNewSelection = (index) => {
    selection = index;
    headerProps.selection = index;
};
const headerProps = {
    ...header.props,
    selection,
    onNewSelection,
};
</script>

<div class="tab-browser">
  <svelte:component this={header.component} {...headerProps} />
  <div class="body">
    <svelte:component this={currentBodyPane.component} {...currentBodyPane.props} />
  </div>
</div>

<style>
  .tab-browser {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
  }

  .body {
    flex: 2;
    display: flex;
    justify-content: center;
    overflow: auto;
  }
</style>
