<script>
  import { Router } from "@sveltech/routify";
  import { routes } from "@sveltech/routify/tmp/routes";
  import Amplify from "aws-amplify";
  import config from "./aws-exports";
  import * as Sentry from "@sentry/browser";

  if (process.env.APP_ENV !== "development") {
    try {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        // release: process.env.APP_ENV + "-" + `${Date()}`,
        environment: process.env.APP_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        whitelistUrls: [/.*\.tortoisemedia\.com/, /.*\.amplifyapp.com/],
      });
    } catch (e) {
      console.error("FAILED TO INITIALISE SENTRY");
      console.error(e);
    }
  }

  const oauth = {
    domain: config.oauth.domain,
    scope: config.oauth.scope,
    redirectSignIn: config.oauth.redirectSignIn,
    redirectSignOut: config.oauth.redirectSignOut,
    responseType: config.oauth.responseType,
  };
  const hasLocalhost = hostname =>
    Boolean(hostname.match(/localhost/) || hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/));
  // const hasHostname = hostname => Boolean(hostname.includes(window.location.hostname));
  const isLocalhost = hasLocalhost(window.location.hostname);

  const isHostRoute = window.location.pathname.includes("/host");

  const domain = isLocalhost ? "http://localhost:8080" : window.location.origin;

  if (isHostRoute) {
    oauth.redirectSignIn = `${domain}/host/thinkin/`;
    oauth.redirectSignOut = `${domain}/host/`;
  } else {
    oauth.redirectSignIn = window.location;
    oauth.redirectSignOut = window.location;
  }

  const configUpdate = config;
  configUpdate.oauth = oauth;
  Amplify.configure(configUpdate);
</script>

<Router {routes} />
