<script>
  import { goto } from "@sveltech/routify";

  import { deleteResource, handleTimeFormatting, makeId, saveResource, formatPencePrice } from "../../common/helpers";
  import { csvGenerator } from "../../common/csvGenerator";

  import Searchbar from "../searchbar/Searchbar.svelte";
  import { Modal } from "@galapagos/svelte-components";

  export let attendees = [];
  export let thinkinId;
  export let thinkinName;
  export let ticketTypes;

  const membership_types = [
    "Digital",
    "Newsroom",
    "Friend of Tortoise",
    "Guest",
    "Network",
    "Coalition member",
    "Student",
  ];

  let deleteModalOpen = false;
  let newBookingModalOpen = false;
  let newBookingTarget = {};
  const sortBy = { col: "first_name", ascending: true };
  let booking_error;
  let booking = false;

  $: filteredUsers = attendees;

  $: partners =
    ticketTypes &&
    ticketTypes.filter(t1 => !!t1.partner_name).sort((t1, t2) => (t1.partner_name < t2.partner_name ? -1 : 1));

  $: sort = col => {
    if (sortBy.col == col) {
      sortBy.ascending = !sortBy.ascending;
    } else {
      sortBy.col = col;
      sortBy.ascending = true;
    }

    // Modifier to sorting function for ascending or descending
    const sortModifier = sortBy.ascending ? 1 : -1;
    const makeComparable = a => (typeof a === "number" ? a : a.toString().toLowerCase());
    const sort = (a, b) => {
      if (a[col] == null) {
        return -1 * sortModifier;
      } else if (b[col] == null) {
        return 1 * sortModifier;
      }
      const aValue = makeComparable(a[col]);
      const bValue = makeComparable(b[col]);

      if (aValue < bValue) {
        return -1 * sortModifier;
      } else {
        if (aValue > bValue) {
          return 1 * sortModifier;
        } else {
          return 0;
        }
      }
    };
    filteredUsers = attendees.sort(sort);
  };

  function gotoUsersDetails(uuid) {
    $goto("/host/people/:uuid", { uuid });
  }

  async function handleSearchTerm(event) {
    if (!event.detail.searchTerm) {
      filteredUsers = attendees;
    }
    const filterValue = event.detail.searchTerm.toLowerCase();
    filteredUsers = attendees.filter(
      a =>
        a.first_name.toLowerCase().includes(filterValue) ||
        a.last_name.toLowerCase().includes(filterValue) ||
        a.email.toLowerCase().includes(filterValue)
    );
  }

  async function deleteBooking(uuid, i) {
    deleteModalOpen = false;

    const participantPath = `/thinkins/${thinkinId}/participants/${uuid}`;
    await deleteResource(participantPath);

    attendees.splice(i, 1);
    filteredUsers = attendees;
  }

  function handleCsvDownload() {
    const tableHeader = [
      "First name",
      "Last Name",
      "Email",
      "Booked at",
      "Booking type",
      "Membership type",
      "Inviter email",
      "Invite code name",
      "Organisation name",
      "Job title",
    ];
    const tableKeys = [
      "first_name",
      "last_name",
      "email",
      "booked_at",
      "booking_type",
      "membership_type",
      "inviter_email",
      "partner_name",
      "organisation_name",
      "job_title",
    ];
    csvGenerator(attendees, tableKeys, tableHeader, `${thinkinName}.csv`);
  }

  async function handleManualBooking() {
    booking = true;
    newBookingTarget.manual_booking = true;
    const ticketTypeIndex = ticketTypes.findIndex(t => t.id == newBookingTarget.ticket_type_id);
    const invite_code = ticketTypes[ticketTypeIndex] && ticketTypes[ticketTypeIndex].invite_code;
    newBookingTarget.invite_code = invite_code;
    newBookingTarget.user_id = makeId(5);
    // newBookingTarget.booking_type =  ticket_types[ticketTypeIndex].type_name == "In person" ?  "In person" : "Digital";
    console.log({ newBookingTarget });

    try {
      const res = await saveResource("POST", `/thinkins/${thinkinId}/booking`, newBookingTarget);
      if (res) {
        newBookingModalOpen = false;
        newBookingTarget.uuid = newBookingTarget.user_id;
        filteredUsers.unshift(newBookingTarget);
        filteredUsers = filteredUsers;
      }
    } catch (err) {
      booking = false;
      booking_error = "Sorry, something went wrong";
    }
  }

  function openBookingModal() {
    newBookingTarget = {};
    newBookingModalOpen = true;
    booking = false;
  }

  $: can_save_booking = newBookingTarget.first_name && newBookingTarget.last_name && newBookingTarget.email;

  function handleMouseOver(event) {
    const inviterEmailElement = event.target.childNodes[2];
    if (!inviterEmailElement || !inviterEmailElement.style) {
      return;
    }
    inviterEmailElement.style.display = "block";
  }

  function handleMouseOut(event) {
    const inviterEmailElement = event.target.childNodes[2];
    if (!inviterEmailElement || !inviterEmailElement.style) {
      return;
    }
    inviterEmailElement.style.display = "none";
  }
</script>

{#if newBookingModalOpen}
  <Modal on:close={() => (newBookingModalOpen = false)}>
    <div class="user-modal-wrapper">
      <form on:submit|preventDefault={handleManualBooking}>
        <fieldset>
          <p>
            <input
              type="text"
              name="guest_first_name"
              required
              placeholder="First name"
              autocomplete="off"
              bind:value={newBookingTarget.first_name} />
          </p>
          <p>
            <input
              type="text"
              name="guest_last_name"
              required
              placeholder="Last name"
              autocomplete="off"
              bind:value={newBookingTarget.last_name} />
          </p>
          <p>
            <input type="email" name="guest_email" required placeholder="Email" bind:value={newBookingTarget.email} />
          </p>
          <p>
            <input type="text" name="guest_job_title" placeholder="Job title" bind:value={newBookingTarget.job_title} />
          </p>
          <p>
            <input
              type="text"
              name="guest_company"
              placeholder="Company name"
              bind:value={newBookingTarget.organisation_name} />
          </p>
          <p class="select-ticket-type">
            <label for="membership_type">Membership:</label>
            <select size="1" name="membership_type" bind:value={newBookingTarget.membership_type}>
              {#each membership_types as membership_type}
                <option value={membership_type}>{membership_type}</option>
              {/each}
            </select>
          </p>
          {#if newBookingTarget.membership_type == "Network"}
            <p class="select-ticket-type activation">
              <input
                type="checkbox"
                id="activate_network_membership"
                bind:checked={newBookingTarget.activate_network_membership} />
              <label for="activate_network_membership">Activate Network Membership</label>
            </p>
          {/if}
          <p class="select-ticket-type">
            <label for="booking_type">Booking type:</label>
            <select size="1" name="booking_type" bind:value={newBookingTarget.booking_type}>
              <option>Digital</option>
              <option>In person</option>
            </select>
          </p>
          <p class="select-ticket-type">
            <label for="ticket_type">Partner:</label>
            <select size="1" name="ticket_type" bind:value={newBookingTarget.ticket_type_id}>
              <option value={null} />
              {#each partners as { id, partner_name, type_name }, i (id)}
                <option value={id}>{partner_name || type_name}</option>
              {/each}
            </select>
          </p>
        </fieldset>
        <div class="booking-buttons">
          {#if booking_error}<span class="error">{booking_error}</span>{/if}
          <button class="btn primary" type="submit" disabled={!can_save_booking || booking}>Save</button>
        </div>
      </form>
    </div>
  </Modal>
{/if}

<div class="header">
  <Searchbar
    on:setSearchTerm={handleSearchTerm}
    placeholder={`Search ${attendees.length} booking` + (attendees.length !== 1 ? "s" : "")} />
  <div>
    <button on:click={handleCsvDownload} name="downloadCSV" class="btn">Export CSV</button>
    <button on:click={openBookingModal} name="add_booking" class="btn">Add a new booking</button>
  </div>
</div>
<div class="list-wrapper">
  <table class="list">
    <thead>
      <tr>
        <th class="attendee-name isSortable" on:click={sort("first_name")}>First name</th>
        <th class="isSortable" on:click={sort("last_name")}>Last name</th>
        <th class="isSortable" on:click={sort("email")}>Email</th>
        <th class="isSortable" on:click={sort("booked_at")}>Booked at</th>
        <th class="isSortable booking-type" on:click={sort("booking_type")}>In person?</th>
        <th class="isSortable" on:click={sort("membership_type")}>Member type</th>
        <th class="isSortable" on:click={sort("organisation_name")}>Company</th>
        <th class="isSortable" on:click={sort("payment_amount_pence")}>Price</th>
        <th class="delete" />
      </tr>
    </thead>
    <tbody>
      {#if filteredUsers}
        {#each filteredUsers as { first_name, last_name, email, booked_at, uuid, partner_name, membership_type, booking_type, organisation_name, profile_picture, inviter_email, payment_amount_pence }, i (uuid)}
          <tr on:click={() => gotoUsersDetails(uuid)}>
            <td class="attendee-name"
              >{#if profile_picture}<img src={profile_picture} alt="" />{/if}{first_name || ""}</td>
            <td class="attendee-name">{last_name || ""}</td>
            <td class="email">{email || ""}</td>
            <td class="booked">
              {#if booked_at}
                {handleTimeFormatting(booked_at).DATE}, {handleTimeFormatting(booked_at).TIME}
              {/if}
            </td>
            <td class="booking-type">
              <span
                title={booking_type == "In person" ? "Attending in person" : "Digital"}
                class:in-person={booking_type == "In person"}>{booking_type}</span>
            </td>
            <td class="membership-type">
              <span
                on:mouseover={handleMouseOver}
                on:focus={handleMouseOver}
                on:mouseout={handleMouseOut}
                on:blur={handleMouseOut}>
                {membership_type == "Guest"
                  ? partner_name
                    ? `Guest - ${partner_name}`
                    : "Guest"
                  : membership_type || ""}
                {#if membership_type == "Plus one"}
                  <span class="plus-one-hover" style="display: none;">Invited by: <br /> {inviter_email}</span>
                {/if}
              </span>
            </td>
            <td>{organisation_name || ""}</td>
            <td>{formatPencePrice(payment_amount_pence)}</td>

            <td class="delete">
              <button type="button" name="remove" on:click|stopPropagation={() => (deleteModalOpen = uuid)}> ✕ </button>
            </td>
            {#if deleteModalOpen == uuid}
              <Modal>
                <div class="modal-wrapper">
                  <p>Are you sure you want to delete <b>{first_name} {last_name}</b>’sbooking?</p>
                  <div class="btn-grp">
                    <button class="btn" name="cancel" on:click={() => (deleteModalOpen = false)}>No</button>
                    <button class="btn danger" on:click={() => deleteBooking(uuid, i)}>Yes</button>
                  </div>
                </div>
              </Modal>
            {/if}
          </tr>
        {/each}
      {:else}
        <span>None found</span>
      {/if}
    </tbody>
  </table>
</div>

<style>
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .header button {
    margin-left: 10px;
  }
  .list-wrapper {
    margin: 0;
    height: initial;
    padding: 0;
  }
  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .attendee-name {
    position: relative;
    padding-left: 28px;
  }
  .attendee-nbame img {
    position: absolute;
    top: 7px;
    left: 0;
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
  }
  .booked {
    min-width: 120px;
  }

  .plus-one-hover {
    position: absolute;
    background-color: white;
    color: #333;
    padding: 4px 8px;
  }
  .delete {
    width: 40px;
  }
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .modal-wrapper p {
    padding-left: 30px;
    padding-right: 30px;
  }
  .btn-grp {
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;
  }

  button {
    font-family: Koopman;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }

  button[name="remove"] {
    opacity: 0;
  }
  tr:hover button[name="remove"] {
    opacity: 1;
  }
  button[name="remove"]:hover {
    color: var(--error);
  }
  button[name="cancel"] {
    color: var(--kelp);
    border: 1px solid var(--kelp);
  }

  button[name="downloadCSV"],
  button[name="add_booking"] {
    font-size: 14px;
    padding: 0 8px;
    height: 35px;
    border: 1px solid white;
    opacity: 0.8;
  }

  button[name="downloadCSV"]:hover,
  button[name="add_booking"]:hover {
    opacity: 1;
  }

  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #eee;
    padding: 4px 8px;
  }

  .select-ticket-type > label:first-child {
    padding-left: 5px;
    width: 120px;
    color: #666;
  }
  select {
    width: 290px;
    border-bottom: 1px solid #eee;
  }
  .activation {
    padding-left: 110px;
    margin-top: 0;
  }
  .activation label {
    margin-left: 0;
    padding-left: 0;
    font-size: 14px;
    white-space: nowrap;
    width: auto;
  }
  .booking-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 10px 15px;
  }
  .booked {
    padding-right: 20px;
  }
  .booked + td {
    position: relative;
    overflow: visible;
  }
  .booking-type span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 5px auto;
    border-radius: 50%;
    overflow: hidden;
    text-indent: 20px;
  }
  th.booking-type span {
    border: 1px solid rgba(255, 255, 255, 0.7);
    margin-top: 0;
  }
  .booking-type span.in-person {
    background: rgba(255, 255, 255, 0.7);
  }

  input::before {
    background: gray;
  }

  input:checked::before {
    background: var(--forest);
  }
  .error {
    width: 100%;
    padding-left: 10px;
    color: red;
  }
</style>
