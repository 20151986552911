<script>

  import { goto } from "@sveltech/routify";
  import { onMount } from "svelte";
  
  import { fetchData, handleTimeFormatting, deleteResource } from "../../common/helpers";
  
  import { Modal } from "@galapagos/svelte-components";
  import Searchbar from "../searchbar/Searchbar.svelte";

  let listWrapper;
  let deleteModalOpen = false;


  let users = [];
  $: filteredUsers = users;

  let totalAmount;
  let totalPages;
  let page = 0;
  let size = 32;
  let columnToFilter = "name";
  let filterValue = "";
  let orderBy;
  let membership_type = "Members";
  let ascending = true;

  
  async function getNewBatch() {
    let fetchUrl = `/users?page=${page}&size=${size}${orderBy ? "&orderBy=" + orderBy : ""}&membership_type=${membership_type}&ascending=${ascending}`;
    let data = await fetchData(fetchUrl);

    console.log({data})
    totalAmount = data.totalItems;
    totalPages = data.totalPages;
    users = [...users, ...data.users];
    return data;
  }

  onMount(() => {
    getNewBatch();
  });


  async function handleLoadMore() {
    page = page + 1;
    await getNewBatch();
  }

  async function handleSort(sortBy) {
    console.log({orderBy, sortBy})
    if (orderBy == sortBy) {
      ascending = !ascending
    } else {
      orderBy = sortBy;
      ascending = true
    }
    let fetchUrl = `/users?page=${page}&size=${size}${orderBy ? "&orderBy=" + orderBy : ""}&membership_type=${membership_type}&ascending=${ascending}`;
    let data = await fetchData(fetchUrl);
    totalAmount = data.totalItems;
    totalPages = data.totalPages
    users = data.users;
  }

  function gotoUsersDetails(uuid) {
    $goto("../:uuid", { uuid });
  }

  async function handleSearchTerm(event) {
    if (!event.detail.searchTerm) {
      filteredUsers = users;
    }
    filterValue = event.detail.searchTerm;
    let filterBy = filterValue.indexOf("@") == -1 ? "name" : "email";
    let fetchUrl = `/users?page=${page}&size=${size}&columnToFilter=${filterBy}&filterValue=${filterValue}&membership_type=${membership_type}&ascending=${ascending}`;
    let searchedUsers = await fetchData(fetchUrl);
    filteredUsers = searchedUsers.users;
    totalPages = searchedUsers.totalPages;
  }

  async function handleFiltering(event, sortBy) {
    if (sortBy) {
      orderBy = sortBy;
    }
    membership_type = event.target.value;
    let fetchUrl = `/users?page=${page}&size=${size}&columnToFilter=${columnToFilter}&filterValue=${filterValue}&membership_type=${membership_type}${orderBy ? "&orderBy=" + orderBy : ""}&ascending=${ascending}`;
    let data = await fetchData(fetchUrl);
    page = 0;
    totalAmount = data.totalItems;
    totalPages = data.totalPages;
    users = data.users;
    return data;
  }
  
	async function deleteBooking(uuid, i) {
    deleteModalOpen = false
    await deleteResource(`/users/${uuid}`)
    users.splice(i, 1)
    filteredUsers = users
	}

</script>

<div class="search">
  <Searchbar
  on:setSearchTerm={handleSearchTerm}
  placeholder={`Search ${totalAmount} users`}/>
</div>


<div class="list-wrapper">

  <nav>
    <button value="Members" on:click={handleFiltering} class:active={membership_type == "Members"}>Members</button>
    <button value="Staff" on:click={handleFiltering} class:active={membership_type == "Staff"}>Tortoises</button>
    <button value="Guest" on:click={handleFiltering} class:active={membership_type == "Guest"}>Guests</button>
   </nav>
 
  <table class="list" bind:this={listWrapper}>
    <thead>
      <tr>
        <th class="name isSortable" on:click={() => handleSort("name")}>Name</th>
        <th class="isSortable" on:click={() => handleSort("email")}>Email</th>
        <th>Job title</th>
        <th>Membership type</th>
        <th class="isSortable" on:click={() => handleSort("created_at")}>Created at</th>
      </tr>
    </thead>
    <tbody>
      {#if filteredUsers}
        {#each filteredUsers as { id, name, job_title, email, membership_type, created_at, uuid, profile_picture }, i (id)}
          <tr on:click={() => gotoUsersDetails(uuid)}>
            <td class="name">{#if profile_picture}<img src={profile_picture} alt="">{/if}{name}</td>
            <td class="email">{email || ""}</td>
            <td class="title">{job_title || ""}</td>
            <td class="type">{membership_type || ""}</td>
            <td class="created">{handleTimeFormatting(created_at).DDMMMYYY || ""}</td>
            <td class="delete">
              <button type="button" name="remove" on:click|stopPropagation={() =>  deleteModalOpen = uuid}>
                ✕
              </button>
            </td>
            {#if deleteModalOpen === uuid}
              <Modal>
                <div class="modal-wrapper">
                  <p>Do you want to delete <b>{name}</b>'s profile and user related data?</p>
                  <div class="btn-grp">
                    <button class="btn danger" on:click={() => deleteBooking(uuid,i)}>Yes</button>
                    <button class="btn primary" on:click={() => deleteModalOpen = false}>No</button>
                  </div>
                </div>
              </Modal>
            {/if}
        </tr>
      {/each}
      {#if totalPages !== 1 && (+totalAmount > users.length)}
        <button class="btn sans" name="more" on:click={handleLoadMore}>Load more</button>
      {/if}
    {:else}
    <span>None found</span>
    {/if}
  </tbody>
  </table>
</div>



<style>
.search {
  position: fixed;
  top: 0;
  left: 185px;
  z-index: 5;
}
nav {
  padding-left: 28px;
}
.list-wrapper {
   margin-top: 50px;
   height: calc(100vh - 80px);
   padding: 0 20px 30px;
   width: 100%;
   overflow-y: auto;
}
td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.created {
  min-width: 120px;
}

.delete {
  width: 40px;
}
.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 0 12px;
  text-align: center;
}

.btn-grp {
  justify-content: space-between;
  width: 50%;
}
button[name=more] {
  padding-left: 28px;
}
button[name=remove] {
  opacity: 0;
}
tr:hover button[name=remove] {
  opacity: 1;
}
button[name="remove"]:hover {
  color: var(--orange);
}
.name {
  position: relative;
  padding-left: 28px;
}
.name img {
  position: absolute;
  top: 7px;
  left: 0;
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
