<script>
  import { onMount, tick } from "svelte";
  import { DateTime } from "luxon"
  import { onInterval } from "../../common/utils.js";

  export let duration;
  export let started;

  let thinkinTotalSeconds = duration * 60; // this will give us length of thinkin in seconds

  let bar,
    marker,
    timerWrapper,
    markerStopPosition,
    timerAnimation,
    timeup = false,
    reset_x = 0;

  $: secondsPassed = (Date.now() - new Date(started).getTime()) / 1000;
  $: markerStopPosition = timerWrapper - 51;
  $: resetTimer(started);

  onInterval(() => (secondsPassed += 1), 1000);

  function draw() {
    if (secondsPassed > thinkinTotalSeconds) {
      timeup = true;
    } else {
      let percentageToMove = (secondsPassed / thinkinTotalSeconds) * 100;
      bar.style.width = percentageToMove + "%";
      let barWidth = bar.parentNode.offsetWidth * percentageToMove / 100;
      if (barWidth > 20 && barWidth < markerStopPosition) {
        barWidth -= 15;
        marker.style.transform = `translateX(${barWidth}px)`;
      } else if (barWidth > markerStopPosition) {
        barWidth -= 30;
        marker.style.transform = `translateX(${barWidth}px)`;
      } else {
        marker.style.transform = `none`;
      }
      timerAnimation = requestAnimationFrame(draw);
    }
  }

  onMount(() => {
    draw();
    const compStyles = window.getComputedStyle(timerWrapper);
    timerWrapper = +compStyles.getPropertyValue("width").replace("px", "");
    return () => cancelAnimationFrame(timerAnimation);
  });

  async function resetTimer() {
    timeup = false;
    if (!timerAnimation) {
      await tick();
      draw();
    }
    else console.log("already have timer in play");
  }
</script>

<style>
  .timer-wrapper {
    position: absolute;
    bottom: 2px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  .marker {
    position: relative;
    left: 0;
    width: 20px;
    height: 20px;
  }
  .progress-bar {
    height: 3px;
    background: rgba(255,255,255,.5);
  }
  .progress-bar div{
    height: 3px;
    background: var(--moss);
  }
  .timeup .progress-bar::after {
    content: "";
    display: block;
    width: 20px;
    height: 30px;
    position: absolute;
    right: 13px;
    bottom: 0;
    background-image: url(/assets/flag.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .timeup .marker {
    display: none;
  }
</style>

<div class="timer-wrapper" class:timeup bind:this={timerWrapper}>
  <svg
    bind:this={marker}
    class="marker"
    width="51"
    height="22"
    viewBox="0 0 51 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon
        id="path-1"
        points="0.27223734 0.233223913 10.8368286 0.233223913 10.8368286 11.4782609 0.27223734 11.4782609" />
      <polygon
        id="path-3"
        points="0.000204180602 0.276368816 34.8129968 0.276368816 34.8129968 19.9545211 0.000204180602 19.9545211" />
    </defs>
    <g stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd">
      <g id="top-bar/pp" transform="translate(-162.000000, -55.000000)">
        <g transform="translate(162.000000, 55.000000)">
          <g transform="translate(40.173913, 10.521739)">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1" />
            </mask>
            <path
              d="M9.30765422,1.84735435 C10.3271939,2.92559348 10.8369637,4.25850652 10.8369637,5.85828913 C10.8369637,7.45735435 10.3271939,8.79098478 9.30765422,9.86420217 C8.28811458,10.9417239 7.03833453,11.4783326 5.55223734,11.4783326 C4.13365934,11.4783326 2.89805831,10.9266587 1.84948542,9.81757174 C0.796861381,8.71422391 0.27223734,7.39135435 0.27223734,5.85828913 C0.27223734,4.32020217 0.796861381,3.00163696 1.84948542,1.89326739 C2.89805831,0.789202174 4.13365934,0.233223913 5.55223734,0.233223913 C7.03833453,0.233223913 8.28811458,0.769115217 9.30765422,1.84735435"
              fill="#0c6"
              mask="url(#mask-2)" />
          </g>
          <g>
            <mask id="mask-4" fill="white">
              <use xlink:href="#path-3" />
            </mask>
            <path
              d="M17.4066005,0.276230285 C0.000204180602,0.276230285 0.000204180602,19.9545211 0.000204180602,19.9545211 L34.8129968,19.9545211 C34.8129968,19.9545211 34.8129968,0.276230285 17.4066005,0.276230285 L17.4066005,0.276230285 Z M8.49752023,14.8233427 C8.58940151,12.9538705 8.96781622,11.2173877 9.62187475,9.61458711 C10.3923162,7.73541769 11.4976139,6.10698891 12.9466156,4.72583748 C13.0820554,4.72583748 13.3978547,4.68635622 13.8974166,4.59769655 C14.3962978,4.51388546 14.9149166,4.46955562 15.458037,4.46955562 C17.6774801,4.46955562 19.6022226,4.98211934 21.2309032,6.01832924 C22.8650286,7.05453913 24.1193781,8.51465307 25.0021189,10.4139094 C25.6132995,11.7209469 26.0107711,13.1914507 26.204062,14.8233427 L8.49752023,14.8233427 Z"
              fill="#0c6"
              mask="url(#mask-4)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
  <div class="progress-bar"><div bind:this={bar} /></div>
</div>
