<script>
  import { beforeUpdate, afterUpdate, onMount } from "svelte";

  import { user } from "../../stores/sessionStore";
  import { font_size } from "../../stores/settings";
  import Message from "./Message";
  import Thread from "./Thread";

  export let messages;
  export let threads
  export let host;
  export let filter;

  let ol;
  let autoScroll;
  let unseen = 0;

  $: filtered_messages = messages.filter((message) => {
    return filter == "all" || (filter == "highlights" && message.starred) || (filter == "private" && message.type == "private");
  });

  beforeUpdate(() => {
    autoScroll = ol && (ol.offsetHeight + ol.scrollTop) > (ol.scrollHeight - 300);
  });

  $: console.warn("autoscroll changed to", autoScroll);

  afterUpdate(() => {
    //if (autoScroll) ol.scrollTo(0, ol.scrollHeight);
    updateCount();
  });

  onMount(() => {
    ol.scrollTo(0, ol.scrollHeight);
  });

  function replyCount(message) {
    return threads && threads[message.id] && threads[message.id].length;
  }

  function updateCount() {
    unseen = 0;
    const offset = ol.scrollTop + ol.offsetHeight + 10;
    Array.from(ol.querySelectorAll("li"))
         .forEach((el) => {
            if (el.offsetTop > offset) {
              unseen++;
            }
         });
  }

  function showMore() {
    // Find the first message that is not entirely in view
    const offset = ol.scrollTop + ol.offsetHeight;
    const els = Array.from(ol.querySelectorAll("li"));
    for (let i = 0; i < els.length; i++) {
      if (els[i].offsetTop + els[i].offsetHeight > offset) {
        els[i].scrollIntoView({ behaviour: "smooth", alignToTop: true });
        break;
      }
    }
    updateCount();
  } 
</script>

<ol class="scrollable" data-size={$font_size} bind:this={ol} on:scroll={updateCount}>
  {#if filtered_messages.length > 0}
    {#each filtered_messages as message}
    <Message {...message} {host} replies={replyCount(message)} on:view on:spotlight>
      {#if threads && threads[message.id]}
        <Thread threads={threads[message.id]} {host} right={message.user_id == $user.uuid ? true : false} />
      {/if} 
    </Message>
    {/each}
  {/if}
  <button name="jump" class:hidden={unseen == 0} on:click={showMore}>{ unseen } more</button>
</ol>

<style>
  .scrollable {
    flex: 1 1 auto;
    margin: 0;
    overflow-y: auto;
    padding: 0;
    height: calc(100vh - 260px);
    min-width: 300px;
  }
  [data-size='1'] { font-size: 14px; }
  [data-size='2'] { font-size: 16px; }
  [data-size='3'] { font-size: 18px; }
  [data-size='4'] { font-size: 24px; }
  [data-size='5'] { font-size: 36px; line-height: 1; }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button[name=jump] {
    font-size: 14px;
    color: var(--kelp);
    background: #f9f9f9;
    border-radius: 3px;
    position: absolute;
    right: 5px;
    bottom: 135px;
    box-shadow:rgba(0,0,0,.5) 2px 1px 4px;
    padding: 5px 10px 5px 24px;
    transition: transform 400ms;
  }
  button[name=jump]:hover {
    background: #fff;
  }
  button[name=jump]::before {
    content: "↓";
    position: absolute;
    left: 8px;
    top: 5px;
  }
  button[name=jump].hidden {
    transform: translateX(300px);
  }

</style>
