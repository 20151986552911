<script>
import { onMount } from "svelte";
import { slide } from "svelte/transition";
import HostsAndGuests from "../lobby/HostsAndGuests";
import { thinkin } from "../../stores/sessionStore";

const scheduled_date = new Date($thinkin.scheduled_date);
const time_of_day = scheduled_date.getHours() > 17 ? "Tonight" : "Today";

let current = "about";
let slideshow;
let slides;
let slide_count = 0;
let slide_index = 0;

console.log("THINK", $thinkin);

onMount(() => {
  slides = Array.from(slideshow.querySelectorAll(".slide"));
  slide_count = slides.length;
  slides[0].classList.add("active");
});

function nextSlide() {
  slideshow.querySelector(".slide.active").classList.remove("active");
  slide_index++;
  if (slide_index >= slide_count) slide_index = 0;
  slides[slide_index].classList.add("active");
}
</script>

<div class="slides" in:slide out:slide bind:this={slideshow}>

  <nav>
    <ul>
      <li class:selected={current == "about"} on:click={() => current = "about"} on:keydown={() => current = "about"}>About</li>
      {#if $thinkin.hosts.length || $thinkin.invitedGuests.length}
        <li class:selected={current == "people"} on:click={() => current = "people"} on:keydown={() => current = "people"}>Editors &amp; guests</li>
      {/if}
      <li class:selected={current == "coc"} on:click={() => current = "coc"} on:keydown={() => current = "coc"}>Code of conduct</li>
      <li class:selected={current == "help"} on:click={() => current = "help"} on:keydown={() => current = "help"}>Help</li>
    </ul>
  </nav>

  {#if current == "about"}
    <div class="slide description">
      <div class="content">
        <h2>{ $thinkin.name }</h2>
        {@html $thinkin.description }
      </div>
    </div>
  {/if}

  {#if current == "people"}
    <div class="slide guests">
      <HostsAndGuests />
    </div>
  {/if}

  {#if current == "coc"}
    <div class="slide code-of-conduct">
      <div class="content">
        <p>ThinkIns are a forum for civilised disagreement. If everybody agreed about everything, there’d be no point in having a ThinkIn. All opinions, even those that are ‘unpopular’, are treated with courtesy and respect.</p>

        <h3>Pass the mic</h3>
        <p>Public speaking is daunting for lots of us. We encourage everyone to share the time fairly, and actively make space for everyone to have their say, especially those who don’t often get the chance to be heard.</p>

        <h3>Be honest</h3>
        <p>Please use your real name on screen. We encourage all members (and invited experts) to contribute honestly and openly, both in person, on screen, or in the chat.</p>

        <h3>Be aware</h3>
        <p>All contributions – in person, on screen, or in the chat – are ‘on the record’. ThinkIns are recorded and chat messages are saved. The best bits may be published in the Tortoise app, website and/or on social media.</p>

        <h3>Be civil</h3>
        <p>Of course, we do not tolerate abusive or offensive language, intimidation or harassment in any form. If you experience or witness any behaviour during a ThinkIn that makes you feel uncomfortable, please alert the chat moderator by sending a private message, or write to our Members’ Editor in confidence by email on <a href="mailto:liz@tortoisemedia.com">liz@tortoisemedia.com</a>. Members and guests who Tortoise believe to behave in an uncivilised way may be removed, and in persistent cases, prevented from joining future ThinkIns.</p>

        <h3>Not an expert? That’s OK!</h3>
        <p>If you think you don’t know enough to comment, don’t worry. We all feel that way. Your personal anecdotes and reflections are valuable parts of our journalistic process.</p>

        <h3>Enjoy yourself</h3>
        <p>Even when the conversations focus on challenging, difficult or uncomfortable subjects, a ThinkIn should be a welcoming, convivial experience.</p>
        <p>If you have ideas about how we can make ThinkIns a more welcoming environment in which everybody is able to have a say, please let us know.</p>
      </div>
    </div>
  {/if}

  {#if current == "help"}
    <div class="slide help">
      <div class="content">
        <p>Our ThinkIns are not panel events. They’re live and unscripted conversations held in the newsroom that you can come along to and have your say, in-person or online. Join us and our expert guests to investigate the stories that matter. We call it organised listening, and it’s at the heart of what we do.</p>
        <ul>
          <li>Your microphone will be automatically muted when you join. This makes it easier to hear what’s going on.</li>
          <li>You can choose to have your webcam switched on or off. If you’d like to share a point of view in writing, you can type it in the chat function.</li>
          <li>You can also ‘raise your hand’ if you’d like to speak. The editor will bring you in when s/he can.</li>
        </ul>
      </div>
    </div>
  {/if}
</div>

<style>
.slides {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background: #fff;
  color: #333;
  text-align: left;
  padding: 0 0 0 260px;
  box-shadow: 0 5px 10px rgba(0,0,0,.7);
  font-size: 18px;
  z-index: 60;
}
nav {
  position: absolute;
  top: 30px;
  left: 10px;
  width: 160px;
  font-family: Koopman;
}
nav ul {
  list-style: none;
  font-size: 16px;
  margin: 20px 0 0;
  padding: 0;
}
nav li {
  color: var(--kelp);
  padding: 8px 0 8px 40px;
  font-weight: medium;
  cursor: pointer;
}
nav li:not(.selected):hover {
  text-decoration: underline;
}
li.selected {
  color: #333;
  pointer-events: none;
}
.slide {
  width: 100%;
  height: 100%;
  padding: 55px 40px 40px 0;
  font-family: Pegasus;
  overflow-y: auto;
}

h2 {
  font-weight: normal;
  color: var(--kelp);
  margin: 0 0 20px;
  font-size: 200%;
  line-height: 1.1;
}
h3 {
  font-size: 32px;
  line-height: 40px;
  color: var(--kelpDark);
  font-weight: normal;
  margin: 20px 0 0;
}
.content, p {
  font-size: 24px;
  line-height: 34px;
}
p {
  margin: 0 0 8px;
}
.content {
  width: 800px;
  max-width: 90%;
}
.content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.content li {
  position: relative;
  padding: 0 0 0 30px;
  margin: 10px 0;
}
.content li::before {
  content: "–";
  position: absolute;
  top: 0;
  left: 0;
}
.code-of-conduct a {
  color: var(--kelp);
  text-decoration: none;
}
.code-of-conduct p:last-child {
  margin-bottom: 30px;
}
@media (max-width: 599px) {
  nav {
    position: static;
    width: 100%;
  }
  nav ul {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    padding-right: 10px;
  }
  nav li {
    padding: 15px 10px 15px 0;
    font-size: 16px;
    line-height: 22px;
  }
  .slides {
    padding-left: 20px;
  }
  .slide {
    padding-top: 15px;
  }
  .content p, .content li {
    font-size: 20px;
    line-height: 28px;
  }
  h3 {
    font-size: 28px;
    line-height: 36px;
  }
}
</style>
