<script>
  import { params } from "@sveltech/routify";
import { fetchData } from "../../../../common/helpers";
  import ThinkinDetail from "../../../../components/edit/ThinkinDetail"
  import { thinkin } from "../../../../stores/sessionStore";

  async function fetchThinkin(id) {
  
    let data = await fetchData(`/thinkins/${id}`);
    thinkin.set(data)
    return data;
  }

  $: thinkin_name = $thinkin && $thinkin.name;

  let fetchedThinkin = fetchThinkin($params.id);
</script>

<svelte:head>
  <title>Galapagos :: {thinkin_name}</title>
</svelte:head>

{#await fetchedThinkin then response}
  <ThinkinDetail thinkin={response}/>
{/await} 