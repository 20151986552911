<script>
  export let data = [];
  export let size = 120;
  export let sorted = true;
  export let palette = [
    "#066",
    "#dce459",
    "#cc3366",
    "#aa64e0",
    "#00cc66",
    "#e1b672",
    "#ffe051",
    "#cee3f2",
    "#f78400",
    "#f2a4be",
    "#0099cc",
  ];

  const RADIUS = 30;
  let label;

  $: style = `width:${size}px; height:${size}px;`;
  $: arcs = calculateArcs(data);

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  // x,y is the center
  function describeArc(x, y, radius, startAngle, endAngle) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");
    return d;
  }

  function calculateArcs() {
    const keyValues = Object.entries(data);
    if (sorted) {
      keyValues.sort((t1, t2) => (t1[1] < t2[1] ? 1 : -1));
    }
    const grandTotal = keyValues.reduce((previous, current) => previous + current[1], 0);
    let startAngle = 0,
      endAngle = 0;
    const arcs = keyValues.map((dataPoint, index) => {
      startAngle = endAngle;
      endAngle = startAngle + (dataPoint[1] / grandTotal) * 360;
      return {
        label: dataPoint[0],
        value: dataPoint[1],
        color: palette[index],
        path: describeArc(50, 50, RADIUS, startAngle, endAngle),
      };
    });
    return arcs;
  }
</script>

<div class="donut" {style}>
  <svg viewBox="0 0 100 100">
    {#if arcs.length === 1}
      <!-- https://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path  -->
      <circle
        on:mouseover={() => (label = `${arcs[0].label}<br><b>${arcs[0].value}</b>`)}
        on:mouseout={() => (label = "")}
        on:blur={() => {}}
        on:focus={() => {}}
        r={RADIUS}
        cx="50%"
        cy="50%"
        stroke-width="15"
        fill="none"
        data-title={arcs[0].label}
        stroke={arcs[0].color} />
    {:else}
      {#each arcs as arc}
        <path
          on:mouseover={() => (label = `${arc.label}<br><b>${arc.value}</b>`)}
          on:mouseout={() => (label = "")}
          on:blur={() => {}}
          on:focus={() => {}}
          stroke-width="15"
          fill="none"
          data-title={arc.label}
          stroke={arc.color}
          d={arc.path} />
      {/each}
    {/if}
  </svg>
  <div class="label">
    <span>{@html label || ""}</span>
  </div>
</div>

<style>
  .donut {
    position: relative;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  path {
    transform-origin: center center;
    transition: transform 100ms;
  }
  path:hover {
    transform: scale(1.1);
  }
  .label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    padding: 15%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
</style>
