<script>
import { fetchData, saveResource } from "../../common/helpers";
import { thinkin, user } from "../../stores/sessionStore";

let exit_polls;
let sent = false;

async function fetchPolls() {
	const data = await fetchData(`/thinkins/${ $thinkin.id }/polls`);
	exit_polls = (data.polls || []).filter((poll) => poll.usage == "exit")
		.map((poll) => {
			return Object.assign({ response: null, other_response: null }, poll);
		});
}

function sendFeedback() {
	exit_polls.forEach((poll) => {
		const response = poll.other_response || poll.response;
		if (!response) return
		saveResource("POST", `/thinkin/${ $thinkin.id }/polls/${ poll.id }/responses`, {
			response: response,
			user_id: $user.uuid,
		});
	});
	sent = true;
}

fetchPolls();

</script>

{#if exit_polls}
<form>
	{#if sent}
		<h2>Your response has been sent. Thank you.</h2>
	{:else if !exit_polls.length}
		<h2>Thanks for coming. We hope to see you again soon.</h2>
	{:else}
		<h2>Thanks for coming. We’d love to hear what you think.</h2>
		{#each exit_polls as poll}
			<div class="poll" data-id={ poll.id }>
				<h3>{ poll.question }</h3>
				{#each poll.options as option}
					<p><label><input type="radio" value={option} bind:group={poll.response}> { option }</label></p>
				{/each}
				{#if (poll.options.length == 0 || poll.allow_other)}
					<textarea name="feedback" bind:value={poll.other_response} placeholder="Write in your answer"></textarea>
				{/if}
			</div>
		{/each}
		<p class="btns">
			<button type="submit" class="btn primary" on:click={sendFeedback}>Send</button>
		</p>
	{/if}
</form>
{/if}

<style>
form {
	text-align: left;
	padding: 0 30px 20px;
	color: #333;
	max-width: 100%;
	margin: 0 auto;
}
.poll {
	font-family: Pegasus;
}
h2 {
	font-family: Pegasus;
	font-weight: normal;
	margin: 20px 0 10px;
}
textarea {
	appearance: none;
	height: 100px;
	border:none;
	border-bottom: 1px solid #333;
	width: 100%;
	resize: none;
}
textarea:focus {
	background-color: var(--stone);
}
h3 {
	font-weight: normal;
	font-family: Pegasus;
	color: var(--kelp);
}
p {
	margin: 0;
	padding: 5px 0;
}
.btns {
	margin-top: 20px;
}
button {
	margin: 0;
}
input[type=radio] {
  top: 3px;
  margin-right: 5px;
}
input[type=radio]:not(:checked)::before {
  background-color: #ddd;
}
</style>
