<script>
  import { SelectionHeader } from "@galapagos/svelte-components";

  export let thinkinSets = [];
  export let selection;
  export let onNewSelection;
</script>

<div class="header mobile-left-margin">
  {#each thinkinSets as thinkinSet, index (thinkinSet.name)}
    <SelectionHeader
      title={thinkinSet.name}
      count={thinkinSet.thinkins.length}
      isSelected={selection === index}
      on:select={() => onNewSelection(index)} />
  {/each}
</div>

<style>
  .header {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacer-large);
    margin-bottom: var(--spacer-large);
  }

  .mobile-left-margin {
    margin-left: var(--spacer-s3);
  }

  @media screen and (min-width: 645px) {
    .mobile-left-margin {
      margin-left: 0;
    }
  }
</style>
