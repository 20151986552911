<script>
  import { DateTime } from "luxon";
  import { clickOutside } from "../../common/helpers";
  import { generateICS } from "./ics";
  import { thinkin } from "../../stores/sessionStore";

  const dt = DateTime.fromISO($thinkin.scheduled_date);

  let showDropDown = false;
  let calendarLinks = generateAddToCalendarLinksAndFile();

  function formatDatesForCalendar(timestamp) {
    let dateTime = timestamp.split(".")[0];

    let startWithISO = dateTime.replace(/[:-\s]/g, "") + "Z";
    let dt = DateTime.fromISO(timestamp).setZone("UTC");
    let offset = dt.toFormat("ZZ");
    dt = dt.plus({ minutes: $thinkin.duration });
    let endISO = dt.toISO();
    let endWithISO = endISO.split(".")[0].replace(/[:-\s]/g, "") + "Z";

    let startWithOffset = dateTime + offset;
    let endWithOffset = dt.toString().split(".")[0] + offset;

    return { startWithISO, endWithISO, startWithOffset, endWithOffset };
  }

  function generateAddToCalendarLinksAndFile() {
    const description = encodeURIComponent(
      $thinkin.description.replace(/(<([^>]+)>)/gi, "")
    );
    const location = encodeURIComponent($thinkin.external_url || "");
    const thinkin_name = encodeURIComponent($thinkin.name);
    const scheduled_date = $thinkin.scheduled_date;
    const { startWithISO, endWithISO, startWithOffset, endWithOffset } =
      formatDatesForCalendar(scheduled_date);

    const timestamp =
      DateTime.now()
        .setZone("UTC")
        .toISO()
        .split(".")[0]
        .replace(/[:-\s]/g, "") + "Z";
    const googleCalendar = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startWithISO}%2F${endWithISO}Z&details=${description}&location=${location}&text=${thinkin_name}`;
    const outlookCalendar = `https://outlook.live.com/calendar/0/deeplink/compose?body=${description}&enddt=${encodeURIComponent(
      endWithOffset
    )}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${encodeURIComponent(
      startWithOffset
    )}&subject=${thinkin_name}`;
    const yahooCalendar = `https://calendar.yahoo.com/?desc=${description}&et=${endWithISO}&in_loc=${location}&st=${startWithISO}&title=${thinkin_name}&v=60`;
    const icsFile = generateICS($thinkin, {
      startWithISO,
      endWithISO,
      timestamp,
    });

    let links = [
      {
        platform: "Apple",
        link: icsFile,
        icon: "/assets/calendar_icons/apple.svg",
        download: true,
      },
      {
        platform: "Google",
        link: googleCalendar,
        icon: "/assets/calendar_icons/google.svg",
        download: false,
      },
      {
        platform: "Outlook",
        link: outlookCalendar,
        icon: "/assets/calendar_icons/outlookcom.svg",
        download: false,
      },
      {
        platform: "Yahoo",
        link: yahooCalendar,
        icon: "/assets/calendar_icons/yahoo.svg",
        download: false,
      },
      {
        platform: "Download ICS file",
        link: icsFile,
        icon: "/assets/calendar_icons/calendar.svg",
        download: true,
      },
    ];
    return links;
  }
</script>

<button
  class="add"
  title="Add to calendar"
  on:click={() => (showDropDown = !showDropDown)}>Add to calendar</button
>

{#if showDropDown}
  <div
    class="action-panel"
    use:clickOutside
    on:click_outside={() => (showDropDown = false)}
  >
    <ul>
      {#each calendarLinks as link, i}
        <a
          href={link.link}
          target="_blank"
          download={link.download ? $thinkin.name : false}
          on:click={() => (showDropDown = false)}
        >
          <li class="page link">
            <div class="icon">
              <img src={link.icon} alt="" />
            </div>
            <span>
              {link.platform}
            </span>
          </li>
        </a>
      {/each}
    </ul>
    <button
      class="close"
      on:click|stopPropagation={() => (showDropDown = false)}>✕</button
    >
  </div>
{/if}

<style>
  button {
    font-family: Koopman;
    font-size: 16px;
    line-height: 22px;
    font-variant: normal;
    color: #333;
    padding: 4px 0;
  }
  button.add {
    text-decoration: underline;
    margin: 8px 0 0;
  }
  button.close {
    position: absolute;
    color: #333;
    top: 0;
    right: 0;
    padding: 5px 8px;
    margin: 0;
  }
  button.close:hover {
    color: var(--kelp);
  }
  .action-panel {
    position: absolute;
    left: 45%;
    top: 25%;
    background-color: var(--white);
    border-radius: 4px;
    z-index: 2000;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 40px 0px;
  }
  .action-panel ul {
    font-family: Koopman;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .action-panel li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--white);
    transition: background-color 0.15s ease-in-out;
    box-sizing: border-box;
    width: 200px;
    height: 42px;
    padding: 0 16px;
    font-size: 16px;
    text-transform: none;
    font-variant: normal;
    cursor: pointer;
  }
  .action-panel li:hover {
    background-color: var(--stone);
  }
  .action-panel li span {
    -webkit-user-select: none;
    margin-left: 20px;
  }
  a {
    text-decoration: none;
    color: #333;
  }
  li .icon {
    width: 18px;
    height: 18px;
  }

  @media screen and (min-width: 654px) {
    .action-panel {
      left: 0;
      top: 100%;
    }
  }

  @media screen and (min-width: 1280px) {
    .action-panel {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
</style>
