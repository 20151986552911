<script>
  import { Duration } from "luxon";
  import { cleanupRichText, fetchData } from "../../common/helpers";

  export let path;

  let comments = [];

  async function fetchComments() {
    if (!path) {
      return;
    }
    comments = await fetchData(`/public/thinkins/recap/asset/highlights?chat_path=${path}`);
  }

  fetchComments();
</script>

{#if comments.length}
  <h5>Chat highlights</h5>
  {#each comments as comment}
    <div>
      <p>{Duration.fromMillis(comment.time.start).toFormat("hh:mm:ss")}, {comment.speaker}</p>
      <p>{@html cleanupRichText(comment.utterance)}</p>
    </div>
  {/each}
{/if}

<style>
  h5 {
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    margin: 64px 0 24px;
  }
  div {
    border-top: 1px solid #b8b8b8;
    padding: 10px 0 16px;
  }
  p:first-child {
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    font-family: Koopman, san-serif;
    margin: 0 0 8px;
  }
  p:last-child {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }
  @media (max-width: 654px) {
    h5 {
      font-size: 28px;
      line-height: 36px;
    }
  }
</style>
