<script>
  import { VirtualList } from "@galapagos/svelte-components";

  import { fetchData, handleTimeFormatting } from "../../common/helpers";

  export let uuid;

  const usersDetails = fetchUser();
  const userAttendedThinkins = fetchAttendedThinkins();

  async function fetchUser() {
    const data = await fetchData(`/users/${uuid}`);
    return data.user;
  }

  async function fetchAttendedThinkins() {
    const data = await fetchData(`/users/${uuid}/thinkins`);
    return data.attendedThinkins.sort((t1, t2) => (t1.scheduled_date < t2.scheduled_date ? 1 : -1));
  }

  function displayAttendance(thinkin) {
    if (thinkin.joined_at) {
      return handleTimeFormatting(thinkin.joined_at).TIME;
    }

    if (
      thinkin.platform == "Tortoise" &&
      thinkin.scheduled_date &&
      new Date(thinkin.scheduled_date).getTime() < new Date().getTime()
    ) {
      return "–";
    }

    return "";
  }
</script>

{#await usersDetails}
  <p>Loading…</p>
{:then { name, profile_picture, biography, location, joined, job_title, email, membership_type }}
  <fieldset class="user-details-wrapper">
    <figure class="poster">
      <img class:empty={!profile_picture} src={profile_picture ? profile_picture : "/assets/person-fff.svg"} alt="" />
    </figure>

    <p>
      <span>Name</span>
      <output>{name}</output>
    </p>
    <p>
      <span>Email</span>
      {#if email}
        <a href={"mailto:" + email}>{email}</a>
      {/if}
    </p>
    <p>
      <span>Membership</span>
      <output>{membership_type || ""}</output>
    </p>
    <p>
      <span>Role</span>
      <output>{job_title || ""}</output>
    </p>
    <p>
      <span>Location</span>
      <output>{location || ""}</output>
    </p>
    <p>
      <span />
      <output class="bio">{biography || ""}</output>
    </p>
  </fieldset>

  <div class="thinkins">
    {#await userAttendedThinkins then thinkins}
      <h5 class="side-title">
        Booked {thinkins.length} ThinkIn{#if thinkins.length !== 1}s{/if}
      </h5>

      <VirtualList height="calc(100vh - 350px)" items={thinkins} let:item>
        <div class="row" class:noshow={!item.joined_at}>
          <span class="date">{handleTimeFormatting(item.scheduled_date).DATEYYYYTIME}</span>
          <span class="name">{item.name}</span>
          <span class="joined-at">{displayAttendance(item)}</span>
          <span class="left-at"
            >{#if item.left_at}{handleTimeFormatting(item.left_at).TIME}{/if}</span>
        </div>
      </VirtualList>
    {/await}
  </div>
{/await}

<style>
  h5 {
    margin: 0;
    font-weight: normal;
    text-align: left;
    grid-column: 2;
    text-transform: uppercase;
    font-size: 14px;
    opacity: 0.6;
    padding-left: 10px;
  }
  .user-details-wrapper {
    width: 100%;
    background-color: var(--midnight);
    color: white;
    margin: 20px 0;
    padding-left: 230px;
    position: relative;
  }
  .poster {
    width: 200px;
    height: 200px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    left: 0;
    top: 0;
  }
  .poster img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .poster img.empty {
    opacity: 0.3;
  }
  .user-details-wrapper a {
    color: var(--lettuce);
  }
  .user-details-wrapper p {
    margin: 0 0 15px;
  }
  .user-details-wrapper span {
    display: inline-block;
    text-align: right;
    opacity: 0.7;
    width: 150px;
    padding-right: 12px;
  }
  .bio {
    width: 400px;
    max-height: 80px;
    overflow-y: auto;
  }
  .thinkins {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px 30px;
    height: calc(100vh - 235px);
  }
  .row {
    display: flex;
    margin: 0;
    padding: 2px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
  }
  .row span {
    display: inline-block;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name {
    width: 40%;
  }
  .date,
  .joined-at,
  .left-at {
    width: 20%;
  }
  .noshow {
    opacity: 0.75;
  }
</style>
