<script>
  function scrollIntoView() {
    const el = document.getElementById("section-3");
    if (!el) {
      return
    };
    el.scrollIntoView({
      behavior: "smooth",
    });
  }
</script>

<div class="body">
  <button class="button" on:click={scrollIntoView}>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M19.25 19.27L16.5 22.02L16.5 8.01L15.5 8.01L15.5 22.01L12.75 19.26L12 20.01L16.01 24.01L20 20.02L19.25 19.27ZM32 16C32 7.16 24.84 -3.12974e-07 16 -6.99382e-07C7.16 -1.08579e-06 -3.12974e-07 7.16 -6.99382e-07 16C-1.08579e-06 24.84 7.16 32 16 32C24.84 32 32 24.84 32 16ZM0.999999 16C1 7.72 7.72 0.999999 16 0.999999C24.28 1 31 7.72 31 16C31 24.28 24.28 31 16 31C7.72 31 0.999999 24.28 0.999999 16Z"
        fill="#333333" />
    </svg>
  </button>

  Sign up to Tortoise’s newsletters
</div>

<style>
  .body {
    font-family: Wolpe Pegasus, serif;
    font-size: 24px;
    font-style: normal;
    line-height: 34px;
    display: flex;
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .button {
    min-width: 80px;
    min-height: 80px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
