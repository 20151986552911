<script>
  import { DateTime } from "luxon";
  import { fetchData } from "../../common/helpers";
  import { csvGenerator } from "../../common/csvGenerator";
  import Dropdown from "../dropdown/Dropdown";
  import Donut from "../charting/Donut";

  export let period = "Today";
  let fetched = false;
  let error;

  let grand_total = 0;
  let in_person_total = 0;
  let unique_total = 0;
  let purchase_total = 0;
  let by_member_type;
  let purchases;

  let show_date_picker = false;
  let is_custom_period = false;
  let exporting = false;
  let export_error;

  let start;
  let end;

  $: updateBookings(period);

  function setPeriod(value) {
    if (value == "custom") {
      is_custom_period = true;
    } else {
      is_custom_period = false;
      period = value;
    }
    show_date_picker = false;
  }

  function setDate(event) {
    const { name, value } = event.target;
    if (name == "start") {
      start = value;
    } else {
      end = value;
    }
    fetched = false;
    updateBookings();
  }

  function clearCustomPeriod() {
    start = null;
    end = null;
    is_custom_period = false;
  }

  function formatDateTime(dateTime) {
    return dateTime.toSQL({ includeOffset: false, includeZone: false });
  }

  async function fetchBookings() {
    const url = makeInitialURL("/stats/booking");
    const data = await fetchData(url);
    while (data.clipped) {
      const mostRecentEntry = formatDateTime(DateTime.fromISO(data.bookings[data.bookings.length - 1].booked_at));
      const latestDesiredEntry = is_custom_period && end ? end : formatDateTime(DateTime.now());
      const newUrl = `/stats/booking?start=${mostRecentEntry}&end=${latestDesiredEntry}`;
      // unfortunately businees logic does require us to syncronously await
      // eslint-disable-next-line no-await-in-loop
      const newData = await fetchData(newUrl);
      data.bookings = [...data.bookings, ...newData.bookings];
      data.clipped = newData.clipped;
    }
    return data.bookings;
  }

  async function updateBookings() {
    fetched = false;
    try {
      const bookings = await fetchBookings();
      analyze(bookings);
    } catch (err) {
      console.error(err);
      error = "There was an error fetching the data";
    } finally {
      fetched = true;
    }
  }

  function makeInitialURL(path) {
    const today = DateTime.now();
    if (is_custom_period) {
      if (!end) {
        end = formatDateTime(today);
      }
    } else {
      switch (period) {
        case "Today":
          start = formatDateTime(today);
          break;
        case "Past week":
          start = formatDateTime(today.minus({ days: 7 }));
          break;
        case "This month":
          start = formatDateTime(today.startOf("month"));
          break;
        default:
          start = "2010-01-01";
      }
      end = formatDateTime(today);
    }
    return `${path}?start=${start}&end=${end}`;
  }

  const isBookingFromBeforePricesWereRecorded = iso => {
    const bookingMs = DateTime.fromISO(iso).toMillis();
    const startedRecordingPricesMs = 1650101094000;
    return bookingMs < startedRecordingPricesMs;
  };

  function analyze(bookings) {
    grand_total = bookings.length;
    in_person_total = bookings.filter(booking => booking.booking_type == "In person").length;
    unique_total = bookings.reduce((previous, current, index) => {
      if (bookings.findIndex(booking => booking.email == current.email) == index) {
        return previous + 1;
      } else {
        return previous;
      }
    }, 0);

    by_member_type = bookings.reduce((previous, current) => {
      if (!previous[current.membership_type]) {
        previous[current.membership_type] = 1;
      } else {
        previous[current.membership_type]++;
      }
      return previous;
    }, {});
    // Some legacy bookings do not have a type
    if (by_member_type["null"]) {
      by_member_type["Unknown"] = by_member_type["null"];
      delete by_member_type["null"];
    }

    purchases = bookings.reduce((purchasesSummary, booking) => {
      const key = isBookingFromBeforePricesWereRecorded(booking.booked_at)
        ? "Before 16/04/2022 (unknown)"
        : booking.payment_amount_pence
        ? "Purchased"
        : "Other";
      return {
        ...purchasesSummary,
        [key]: (purchasesSummary[key] || 0) + 1,
      };
    }, {});
    purchase_total = purchases.Purchased || 0;
  }

  function exportCSV() {
    exporting = true;
    export_error = null;
    fetchBookings()
      .then(bookings => {
        const formattedBookings = bookings.map(booking => {
          return {
            ...booking,
            payment_amount_pounds: isBookingFromBeforePricesWereRecorded(booking.booked_at)
              ? "unknown"
              : ((booking.payment_amount_pence || 0) / 100).toFixed(2),
          };
        });
        const headers = [
          "Order ID",
          "ThinkIn ID",
          "ThinkIn name",
          "First name",
          "Last name",
          "Email",
          "Booking type",
          "Booked at",
          "Membership type",
          "Invite code",
          "Partner name",
          "Payment amount (£)",
          "Inviter email",
          "Organisation",
          "Job title",
          "Checked in at",
        ];
        const keys = [
          "order_id",
          "thinkin_id",
          "thinkin",
          "first_name",
          "last_name",
          "email",
          "booking_type",
          "booked_at",
          "membership_type",
          "invite_code",
          "partner_name",
          "payment_amount_pounds",
          "inviter_email",
          "organisation",
          "job_title",
          "checked_in_at",
        ];
        let name = "bookings";
        if (start) {
          name += "-" + start;
        }
        if (end) {
          name += "-" + end;
        }
        csvGenerator(formattedBookings, keys, headers, `${name}.csv`);
      })
      .catch(err => {
        console.error(err);
        export_error = "Sorry, something went wrong";
      })
      .finally(() => {
        exporting = false;
      });
  }
</script>

<div class="summary">
  <div class="controls">
    <span>
      {#if is_custom_period}
        <label for="period-start-date">From </label><input
          type="date"
          id="period-start-date"
          name="start"
          on:change={setDate} />
        <label for="period-end-date">to </label><input
          type="date"
          id="period-end-date"
          name="end"
          on:change={setDate} />
        <button name="clear" on:click={clearCustomPeriod}>Clear</button>
      {:else}
        <button name="period" on:click={() => (show_date_picker = !show_date_picker)}>{period}</button>
      {/if}
    </span>
    {#if export_error}<span class="error">{export_error}</span>{/if}
    <button class="btn primary" disabled={exporting} on:click={exportCSV}>Export</button>
  </div>

  {#if show_date_picker}
    <Dropdown left={-20} on:click_outside={() => (show_date_picker = false)}>
      <li on:click={() => setPeriod("Today")}  on:keydown={() => setPeriod("Today")}>Today</li>
      <li on:click={() => setPeriod("Past week")} on:keydown={() => setPeriod("Past week")}>Past week</li>
      <li on:click={() => setPeriod("This month")} on:keydown={() => setPeriod("This month")}>This month</li>
      <li on:click={() => setPeriod("All time")} on:keydown={() => setPeriod("All time")}> All time</li>
      <li on:click={() => setPeriod("custom")} on:keydown={() => setPeriod("custom")}>Select dates…</li>
    </Dropdown>
  {/if}
  <div class="results">
    <ul class="totals">
      {#if error}
        <li class="error">{error}</li>
      {:else if !fetched}
        <li>Fetching data…</li>
      {:else}
        <li class="booked">
          <span class="total">{grand_total.toLocaleString("en")}</span>
          <span>booked</span>
        </li>
        <li class="uniques">
          <span class="total">{unique_total.toLocaleString("en")}</span>
          <span>uniques</span>
        </li>
        <li class="in-person">
          <span class="total">{in_person_total.toLocaleString("en")}</span>
          <span>in person</span>
        </li>
        <li class="purchases">
          <span class="total">{purchase_total.toLocaleString("en")}</span>
          <span>purchased (after 16/04/22)</span>
        </li>
      {/if}
    </ul>
    {#if by_member_type}
      <div>
        <Donut data={by_member_type} size="200" />
        <table class="legend">
          {#each Object.entries(by_member_type).sort((t1, t2) => (t1[1] < t2[1] ? 1 : -1)) as type}
            <tr><td>{type[0]}</td><td>{type[1].toLocaleString("en")}</td></tr>
          {/each}
        </table>
      </div>
    {/if}
    {#if purchases}
      <div>
        <Donut data={purchases} size="200" />
        <table class="legend">
          {#each Object.entries(purchases).sort((t1, t2) => (t1[1] < t2[1] ? 1 : -1)) as type}
            <tr><td>{type[0]}</td><td>{type[1].toLocaleString("en")}</td></tr>
          {/each}
        </table>
      </div>
    {/if}
  </div>
</div>

<style>
  .summary {
    height: 120px;
    margin-bottom: 30px;
    padding-left: 4px;
    color: #fff;
    position: relative;
  }
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Pegasus;
    font-size: 24px;
    height: 60px;
  }
  button[name="period"] {
    font: inherit;
    color: #fff;
    padding-left: 0;
    padding-right: 30px;
    margin: 5px 0 8px;
    background-image: url(/assets/caret-white.svg);
    background-position: right center;
    background-repeat: no-repeat;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  input[type="date"] {
    background: transparent;
    color: #fff;
    font: inherit;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: none;
    outline: none;
    margin-right: 10px;
  }
  label {
    padding-right: 10px;
  }
  .results {
    width: 1000px;
    max-width: 100%;
    display: flex;
  }
  .totals {
    list-style: none;
    text-align: left;
    margin-top: 10px;
    margin-right: 50px;
    padding: 0;
  }
  li {
    padding: 4px 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
  }
  .total {
    color: #fff;
  }
  .error {
    font-size: 18px;
    font-family: Koopman;
  }
  .error::before {
    content: "⚠️ ";
  }
  button[name="clear"] {
    color: #fff;
    font-family: Koopman;
    font-size: 14px;
    opacity: 0.5;
  }
  button[name="clear"]:hover {
    opacity: 1;
  }
  table {
    margin-top: 15px;
    margin-left: 15px;
  }
  td {
    padding: 2px 20px 2px 0;
  }
  td:last-child {
    text-align: right;
  }
</style>
