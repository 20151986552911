var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cookies from "js-cookie";
import { PIANO_RID_GROUPS } from "./constants";
export function mobileAndTabletCheck() {
    let check = false;
    (function (a) {
        if (
        // eslint-disable-next-line no-useless-escape
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) ||
            // eslint-disable-next-line no-useless-escape
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor);
    return check;
}
export const makeId = (keyLength) => {
    let result = Math.round(Date.now() / 1000) + "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < keyLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
/**
 * FETCH HELPERS
 */
export function fetchData(url) {
    return __awaiter(this, void 0, void 0, function* () {
        const auth_token = Cookies.get("thinkin_authToken");
        if (!auth_token && !url.includes("public")) {
            throw new Error("Attempting fetch without authorization");
        }
        let requestInfo;
        if (!url.includes("public")) {
            requestInfo = {
                headers: { Authorization: auth_token },
            };
        }
        const res = yield fetch(`${process.env.BASE_API_URL}${url}`, requestInfo);
        const data = yield res.json();
        if (res.ok) {
            return data;
        }
        else {
            return new Error(data);
        }
    });
}
export function saveResource(method, url, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const auth_token = Cookies.get("thinkin_authToken");
        if (!auth_token) {
            throw new Error("Attempting fetch without authorization");
        }
        const fetchOptions = {
            headers: {
                "content-type": "application/JSON",
                "Authorization": auth_token,
            },
            method,
            body: JSON.stringify(Object.assign({}, payload)),
        };
        const res = yield fetch(`${process.env.BASE_API_URL}${url}`, fetchOptions);
        if (!res.ok) {
            console.error("saveResource fetch failed");
            throw "Could not save";
        }
        const updatedResource = yield res.json();
        return updatedResource;
    });
}
// DON'T TRUST THIS FUNCTION. I'M SORRY, I HAVEN'T HAD TIME TO REMOVE IT BEFORE LEAVING. THE MEMBERSHIP LOGIC IS MESSED UP. YOU NEED TO TALK TO THE MEMBERSHIP TEAM ABOUT WHAT TO DO.
export function membershipTypeFromRID(pianoEnvironment, userActiveRIDs) {
    const { NEWSROOM_RID_GROUP, FOT_RID_GROUP } = PIANO_RID_GROUPS[pianoEnvironment];
    const isUserFoT = userActiveRIDs.some(rid => FOT_RID_GROUP.includes(rid));
    const isUserNewsroomMember = userActiveRIDs.some(rid => NEWSROOM_RID_GROUP.includes(rid));
    return isUserFoT ? "Friend of Tortoise" : isUserNewsroomMember ? "Newsroom" : userActiveRIDs.length ? "Digital" : "";
}
