<script>
  import { createEventDispatcher } from "svelte";
  import { DateTime } from "luxon";

  import { handleTimeFormatting, fetchData, saveResource } from "../../common/helpers";
  import Clips from "../live/Clips";
  import HTMLEditor from "../htmleditor/HTMLEditor";
  import { participants } from "../../stores/sessionStore";

  export let thinkin;

  const dispatch = createEventDispatcher();

  const start_time = handleTimeFormatting(thinkin.started_at);
  const end_time = handleTimeFormatting(thinkin.ended_at);

  const duration = DateTime.fromISO(thinkin.ended_at).diff(DateTime.fromISO(thinkin.started_at), ["hours", "minutes"]);
  const duration_hours = duration.hours;
  const duration_minutes = Math.round(duration.minutes);

  const archive_urls = thinkin.archive_status == "uploaded" ? thinkin.recording_urls : "";

  $: participants_fetched = $participants.length !== 0;
  $: youtube_id = thinkin.youtube_url && thinkin.youtube_url.split("?v=")[1];

  let follow_up_email_status = "Send follow up emails";
  let messages = [];

  function fetchParticipants() {
    fetchData(`/thinkins/${thinkin.id}/participants`).then(data => {
      participants.set(data.participants);
    });
  }
  if (thinkin.platform == "Tortoise" && !$participants.length) {
    fetchParticipants();
  }

  function loadChat() {
    return fetchData(`/thinkins/${thinkin.id}/messages`).then(data => {
      messages = data.messages.sort((m1, m2) => {
        return m1.timestamp < m2.timestamp ? -1 : 1;
      });
    });
  }
  if (thinkin.platform == "Tortoise") {
    loadChat();
  }

  async function handleDownload() {
    const url = `/thinkins/download-recording?archiveId=${thinkin.archive_id}&archiveName=${encodeURIComponent(
      thinkin.name
    )}`;
    const data = await fetchData(url);
    const a = document.createElement("a");
    a.href = data.url;
    a.click();
  }

  function displayTime(timestamp) {
    return DateTime.fromISO(timestamp).toFormat("T");
  }

  async function handleSendingFollowUpEmails() {
    follow_up_email_status = "Sending…";
    const followUpUrl = `/thinkins/${thinkin.id}/booking?send_follow_ups=true`;
    const emailRes = await saveResource("POST", followUpUrl, {});
    console.log({ emailRes });
    if (emailRes && emailRes.followUpEmailsSent) {
      const thinkinRes = await saveResource("PUT", `/thinkins/${thinkin.id}`, { follow_up_emails_sent: true });
      thinkin.follow_up_emails_sent = thinkinRes.follow_up_emails_sent;
    } else {
      follow_up_email_status = "Failed to send emails";
    }
  }
</script>

{#if thinkin.platform == "Tortoise"}
  <section class="first">
    <figure class="poster" class:empty={!archive_urls}>
      {#if archive_urls}
        {#each archive_urls as archive_url}
          <!-- svelte-ignore a11y-media-has-caption -->
          <video src={archive_url} controls />
          <button class="btn outlined" name="download" on:click={handleDownload}>Download video</button>
        {/each}
      {/if}
    </figure>

    <div class="summary">
      {#if thinkin.started_at}
        <p>{start_time.WD}, {start_time.DD} {start_time.MMM} {start_time.YYYY}</p>
        <p class="times">
          {start_time.TIME} – {end_time.TIME}
          <span
            >{#if duration_hours}{duration_hours}h {/if}{#if duration_minutes}{duration_minutes}m{/if}</span>
        </p>
      {/if}
      {#if participants_fetched}
        <p>Attended: {$participants.length}</p>
      {/if}
    </div>
  </section>

  <section class="clips">
    {#if participants_fetched}
      <Clips open={true} />
    {/if}
  </section>

  {#if messages.length}
    <section class="messages">
      <h3>Chat</h3>
      {#each messages as { id, message, name, timestamp }}
        <div>
          <p>{@html message}</p>
          <span>{name} at {displayTime(timestamp)}</span>
        </div>
      {/each}
    </section>
  {/if}
{:else}
  <form on:input={() => dispatch("change")}>
    <div class="catchup-video">
      {#if youtube_id}
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/{youtube_id}?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen />
      {/if}
    </div>
    <p class="youtube-url">
      <label for="thinkin_recording">Youtube URL</label>
      <input
        name="thinkin_recording"
        type="text"
        placeholder="https://www.youtube.com/watch?v="
        bind:value={thinkin.youtube_url}
        autocomplete="off" />
    </p>

    {#if thinkin.follow_up_emails_sent}
      {#if thinkin.readout}
        <output name="follow-up-details">
          {thinkin.readout}
        </output>
      {/if}
      <p class="sent">
        <label for="">
          <img src="/assets/checkmark-white.svg" alt="" />
          <span>Follow up emails have been sent</span>
        </label>
      </p>
    {:else}
      <label for="">Additional text for follow up emails</label>
      <div class="html-editor">
        <div>
          <HTMLEditor name="instructions" bind:value={thinkin.readout} placeholder="Optional" />
        </div>
      </div>

      <p class="follow-up">
        <span class:warning={follow_up_email_status.includes("Failed")}>
          <button class="btn primary follow-up" on:click|preventDefault={handleSendingFollowUpEmails}
            >{follow_up_email_status}</button>
        </span>
      </p>
    {/if}
  </form>

  <button on:click={() => dispatch("show")} class="newRecap">Try the new catch up editor &#8594;</button>
{/if}

<style>
  section.first {
    width: 100%;
    display: flex;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .poster {
    width: 240px;
    margin: 0 30px 10px 0;
    padding: 0;
  }
  .poster.empty {
    background-color: #111;
    background-image: url(/assets/camera_off.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 48px 48px;
    opacity: 0.5;
  }
  video {
    width: 100%;
    height: 135px;
    margin-bottom: 10px;
  }
  button[name="download"] {
    font-size: 14px;
  }
  h3 {
    text-transform: uppercase;
    font-size: 14px;
    opacity: 0.8;
  }
  p {
    display: block;
  }
  label {
    text-align: left;
    padding-left: 0;
    margin-bottom: 8px;
  }
  .summary {
    text-align: left;
  }
  .times span {
    opacity: 0.6;
  }
  .messages {
    text-align: left;
    padding: 0 20px 20px;
  }
  .messages div {
    padding: 5px 0;
  }
  .messages p {
    font-family: Pegasus;
    font-size: 18px;
    margin: 0;
  }
  .messages span {
    font-size: 14px;
    opacity: 0.8;
  }
  .messages :global(a) {
    color: inherit;
  }
  form {
    padding-left: 25%;
  }
  label {
    display: block;
  }
  .sent label {
    margin-left: 0;
  }
  .html-editor > div {
    width: 70%;
  }
  .catchup-video iframe {
    width: 400px;
    height: 225px;
  }
  .follow-up {
    margin-top: 8px;
  }
  .newRecap {
    color: #fff;
    opacity: 0.8;
    padding-left: 25%;
    margin-top: 32px;
  }
</style>
