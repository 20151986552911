<script>
  import { goto, params } from "@sveltech/routify";
  import Flourish from "@flourish/live-api";

  import ColorPicker from "../colorpicker/ColorPicker";

  import {thinkin} from "../../stores/sessionStore"
  import { selectImageFromComputer, uploadImage, saveResource, makeId, fetchData } from "../../common/helpers";

  
  export let id; // the slide id

  const DEFAULT_PALETTE = ["#006666", "#dce459", "#e1b672", "#002850", "#ffe051", "#cee3f2"];

  let layouts = [{
    name: "default",
  }, {
    name: "text",
  }, {
    name: "chart",
  }, {
    name: "media",
  }, {
    name: "gallery",
  }, {
    name: "blank",
  }];

  let styles = [{
    name: "Heading",
    selector: "h1",
  },{
    name: "Subtitle",
    selector: "h2",
  },{
    name: "Kicker",
    selector: "h3",
  },{
    name: "Fineprint",
    selector: "p.fineprint",
  },{
    name: "Paragraph",
    selector: "p",
  }];

  let visualisations = [{
    name: "Bar",
    "template": "@flourish/line-bar-pie",
    "version": "20.2.8",
    "api_key": "YOUR API KEY",
    "container": "YOUR CONTAINER SELECTOR",
    "state": {
      "chart_type": "bar_grouped",
      "column_padding_inner": 10,
      "margin_bottom": "0",
      "margin_top": "0",
      "layout": {
        "background_color_enabled": false,
        "body_font": {
          "name": "FS Koopman",
          "url": "https://public.flourish.studio/fonts/a1ec044a-f942-4497-bf59-ff2e1b72765d/stylesheet.css"
        },
        "margin_top": 0,
        "margin_right": 0,
        "margin_bottom": 0,
        "margin_left": 0,
        "space_between_sections": "custom",
        "space_between_sections_custom": 1.5,
        "title_styling": true
      },
      "color": {
        "categorical_palette": DEFAULT_PALETTE,
        "palette": DEFAULT_PALETTE
      },
      "x": {
        "line_visible": false,
        "tick_mode": "number",
        "tick_padding": 0.5,
        "tick_label_size": 1,
        "tick_label_angle": "0",
        "tick_label_line_height": 1.2,
        "tick_label_styling": true
      },
      "y": {
        "line_visible": false,
        "tick_mode": "number",
        "tick_label_size": 1,
        "tick_label_line_height": 1.2,
        "gridline_color": "#aaaaaa",
        "gridline_width": 0.15,
        "gridline_style": "dot",
        "gridlines_styling": true,
        "tick_label_styling": true
      },
      "legend_categorical": {
        "swatch_width": 0.7,
        "swatch_height": 0.7,
        "swatch_radius": 0
      },
      "legend_continuous": {
        "color_band_width": 7.5
      },
      "colors": {
        "categorical_palette": DEFAULT_PALETTE,
        "palette": DEFAULT_PALETTE
      },
      "map_categorical_colors": {
        "palette": DEFAULT_PALETTE
      },
      "point_categorical_colors": {
        "palette": DEFAULT_PALETTE
      },
      "point_colors": {
        "categorical_palette": DEFAULT_PALETTE
      },
      "map_colors": {
        "categorical_palette": DEFAULT_PALETTE
      },
      "legend_categorical_points": {
        "swatch_width": 0.7,
        "swatch_height": 0.7,
        "swatch_radius": 0
      },
      "legend_categorical_map": {
        "swatch_width": 0.7,
        "swatch_height": 0.7,
        "swatch_radius": 0
      },
      "legend_continuous_points": {
        "color_band_width": 7.5
      },
      "legend_continuous_map": {
        "color_band_width": 7.5
      }
    },
    "bindings": {
      "data": {
        "label": "Year",
        "value": [
          "Column 1",
          "Column 2",
          "Column 3",
          "Column 4"
        ]
      }
    },
    "data": {
      "data": [
        {
          "Year": "2017",
          "Column 1": "42600",
          "Column 2": "32600",
          "Column 3": "22600",
          "Column 4": "12600"
        }
      ]
    }
  }, {
    name: "Column",
    template: "@flourish/bar",
  }, {
    name: "Donut",
    template: "@flourish/donut",
  }, {
    name: "Line",
    template: "@flourish/line",
  }, {
    name: "Scatter",
    template: "@flourish/scatter",
    version: "4",
    bindings: {
      data: {
        x: "pos_x",
        y: "pos_y",
        metadata: ["country"]
      }
    },
    data: {
      data: [
        { country: "Argentina", pos_x: 1, pos_y: 2 },
        { country: "Brazil", pos_x: 2, pos_y: 4 },
        { country: "Colombia", pos_x: 3, pos_y: 9 },
      ]
    },
    state: {
      layout: {
        title: "This is an API Example"
      }
    }
  }, {
    name: "Table",
    template: "@flourish/table",
  }];

  let slide;
  let focused;
  let layout = "default";
  let slide_fill = "#fff";
  let slide_color = "#000";

  let undos = [];
  let redos = [];

  let content = {};

  if (id) {  
    fetchSlide(id);
  }
  $: console.log({content})

  async function fetchSlide(slide_id) {
    let slideData = await fetchData(`/thinkins/${$params.id}/slides/${slide_id}`)
    slide = slideData
    return true
  }

  function undo() {

  }

  function redo() {

  }

  function chooseLayout(name) {
    layout = name;
  }

  function chooseVisualisation(name) {
    if (!focused) return console.warn("can’t choose template with no visualisation focused");
    if (focused.type !== "chart") return console.warn("can’t add a template when focused element is not a chart", focused);
    let options = visualisations.find((v) => v.name == name);
    focused.visualisation = name;
    let viz = new Flourish.Live(Object.assign({}, options, { container: focused.element, api_key: process.env.FLOURISH_API_KEY, height: "100%" }));
    console.log("entries", Object.entries(options.data));
    let csv = [];
    Object.entries(options.data)[0][1].forEach((row, index) => {
      if (index == 0) csv.push(Object.keys(row).join(","));
      csv.push(Object.values(row).join(",")); // FIXME quoting
    });
    focused.data = csv.join("\n");
    focused.element.setAttribute("data-template", name);
  }

  function insert(type) {
    console.log("inserting", type);
  }

  function restyle() {

  }

  function format() {

  }

  function link() {

  }

  function onclick(event) {
    const element = event.target;
    if (element.classList.contains("focused")) return;
    clearFocus();
    console.log("click on", element.tagName);
    if (["H1","H2","H3","P"].includes(element.tagName)) {
      focusText(element);
    } else
    if (["IMG","VIDEO"].includes(element.tagName)) {
      focusMedia(element);
    } else
    if (element.classList.contains("chart")) {
      focusChart(element);
    } else
    if (element.classList.contains("col")) {
      focusColumn(element);
    } else {
      console.log("nothing to focus on");
      focused = null;
      return;
    }
    element.classList.add("focused");
  }

  function clearFocus() {
    if (focused) {
      focused.element.classList.remove("focused");
    }
  }

  function focusText(element) {
    let style = styles.find(s => element.matches(s.selector));
    focused = {
      type: "text",
      style: style.name,
      element: element,
      color: element.style.color, // TODO: prob need to normalise
    };
  }

  function focusMedia(element) {
    focused = {
      type: "media",
      element: element,
    }
  }

  function focusChart(element) {
    // TODO: parse out settings from data attributes
    focused = {
      type: "chart",
      template: element.getAttribute("data-template"),
      element: element,
    };
  }

  function focusColumn(element) {
    focused = {
      type: "column",
      element: element,
    }
  }

  function pickColor(event) {
    let name = event.detail.name;
    let color = event.detail.color;
    if (!focused) {
      slide_fill = color;
    } else {
      focused[name] = color;
      focused.element.style[name] = color;
    }
  }


  $: slide_style = `color:${slide_color};background-color:${slide_fill}`;

  console.log("Flourish", Flourish);

  function handleImgSelection(e) {
    selectImageFromComputer(e, (e) => {
      // const { imageName } = prepareBase64ToPost(e.target.result, thinkin.key);
      content.image = e.target.result;
    });
  }

  async function handleSave() {
    const subFolder =  "thinkins-slide-images";
    const imageUrl = await uploadImage(content.image, `slide ${makeId(2)}`, subFolder, $thinkin.key);
    content.image = imageUrl;
    
    const slidePayload = {
      html: generateHtml(content)
    };
    const  savedSlide = await saveResource( "POST", `/thinkins/${$params.id}/slides`, slidePayload);
    const slide_id = savedSlide.id;
    $goto("../../settings");
  }

  function generateHtml(content) {
    let html = "";
    if (content.image) {
      html += `<img src=${content.image} alt="" style="height: 100%; width: 100%">`;
    }
    return html;
  }

  

</script>

<header class="primary">
  <div class="btn-grp">
    <a href="../settings" class="btn home">← Back</a>
  </div>
  <div class="btn-grp undo-buttons">
    <button name="undo" on:click={undo} class="tooltip" data-text="Undo" disabled={undos.length == 0}><img alt="Undo" src="/assets/undo.svg"></button>
    <button name="redo" on:click={redo} class="tooltip" data-text="Redo" disabled={redos.length == 0}><img alt="Redo" src="/assets/redo.svg"></button>
  </div>
  <div class="btn-grp insert-buttons">
  {#if layout == "blank"}
    <button on:click={() => insert("text")} class="tooltip" data-text="Insert text"><img alt="Insert text" src="/assets/slideicons/text.svg"></button>
    <button on:click={() => insert("media")} class="tooltip" data-text="Insert media"><img alt="Insert media" src="/assets/slideicons/media.svg"></button>
    <button on:click={() => insert("chart")} class="tooltip" data-text="Insert visualisation"><img alt="Insert chart" src="/assets/slideicons/chart.svg"></button>
    <button on:click={link} class="tooltip" data-text="Link" disabled><img alt="Link" src="/assets/link.svg"></button>
  {/if}
  </div>
  <button class="primary btn" on:click={handleSave}>Save</button>
</header>

<section class="container" on:keydown={onclick} on:click={onclick}>
  <div class="slide" style={slide_style}>
  {#if layout == "default"}
    <div class="cols">
      <div class="col first third">
        <h1 contenteditable="true" bind:innerHTML={content.heading}>Title</h1>
        <p contenteditable="true" bind:innerHTML={content.description}>A paragraph of text</p>
        <p class="fineprint" contenteditable="true" bind:innerHTML={content.footer}>Source</p>
      </div>
      <div class="col last twothirds">
        <div class="chart"></div>
      </div>
    </div>
  {:else if layout == "media"}
    <figure class="fullscreen" class:placeholder={!content.image}>
      {#if content.image}
        <img src={content.image} alt="">
      {:else}
        <img src="/assets/slideicons/media.svg" alt="">
      {/if}
    </figure>
  {/if}
  </div>
</section>

<aside>
  {#if !focused}
  <fieldset>
    <h2>Layout</h2>
    <div class="tiled layouts">
    {#each layouts as { name }}
      <button class:selected={layout == name} 
        style={`background-image:url(/assets/slideicons/layout-${name}.svg)`} 
        on:click={() => chooseLayout(name)}></button>
    {/each}
    </div>
  </fieldset>

  <fieldset>
    <h2>Background</h2>
    <ColorPicker name="fill" bind:color={slide_fill} on:pick={pickColor}/>
  </fieldset>

  {:else if focused.type == "text"}
  <fieldset>
    <h2>Text</h2>
    <p>
      <select size="1" bind:value={focused.style} on:blur={restyle}>
      {#each styles as { name }}
        <option>{name}</option>
      {/each}
      </select>
    </p>
    <p>
      <button on:click={() => format("b")}><img alt="Bold" src="/assets/slideicons/bold.svg"></button>
      <button on:click={() => format("i")}><img alt="Italics" src="/assets/slideicons/italic.svg"></button>
      <button on:click={() => format("u")}><img alt="Underline" src="/assets/slideicons/underline.svg"></button>
    </p>
    <ColorPicker name="color" bind:color={focused.color} on:pick={pickColor} />
  </fieldset>

  {:else if focused.type == "media"} 
  <fieldset>
    <h2>Media</h2>
    <button class="btn outlined" on:click={() => content.image.click()}>Upload file
      <input 
      style="display:none"
      type="file"
      accept=".jpg, .jpeg, .png"
      on:change={(e) => handleImgSelection(e)} 
      bind:this={content.image}>
    </button>
  </fieldset>

  {:else if focused.type == "chart"}
  <fieldset>
    <h2>Visualisation</h2>
    <div class="tiled visualisations">
    {#each visualisations as visualisation }
      <button class:selected={focused.visualisation == visualisation.name} 
        style={`background-image:url(/assets/slideicons/chart-${visualisation.name}.svg)`} 
        on:click={() => chooseVisualisation(visualisation.name)}>
        {visualisation.name}
      </button>
    {/each}
    </div>
    <button class="btn outlined">Import from Flourish</button>
  </fieldset>
  <fieldset>
    <h2>Data</h2>
    <textarea placeholder="Paste CSV here" bind:value={focused.data}></textarea>
    <button class="btn outlined">Upload data</button>
  </fieldset>

  {:else if focused.type == "column"}
  <fieldset>
    <h2>Column</h2>
    <p>
      <label for="setting-width">Width</label>
      <input id="setting-width" type="text" placeholder="auto">
    </p>
  </fieldset>
  {/if}
</aside>

<style>
  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    border-bottom: 1px solid rgba(255,255,255,.2);
    display: grid;
    grid-template-columns: 200px 72px 1fr 100px;
    padding: 5px;
    z-index: 5;
  }
  header .btn-grp:first-child {
    width: 180px;
  }
  .home {
    color: var(--lettuce);
    min-width: 0;
  }
  header .undo-buttons button {
    margin: 0;
  }
  header .insert-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button img {
    width: 24px;
    height: 24px;
  }
  .insert-buttons button {
    margin: 0 10px;
  }
  aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    background: #001a38;
    color: #fff;
    padding-top: 40px;
    text-align: left;
  }
  aside h2 {
    font-size: 13px;
    opacity: .8;
    margin: 0 0 5px;
    font-weight: normal;
    text-transform: uppercase;
  }
  aside p {
    margin: 10px 0;
  }
  aside select {
    width: 100%;
  }
  fieldset {
    margin: 0;
    padding: 10px;
  }
  fieldset:not(:first-child) {
    border-top: 1px solid rgba(255,255,255,.2);
  }
  fieldset textarea {
    width: 100%;
    height: 50px;
    resize: none;
  }
  fieldset .outlined {
    width: 100%;
  }
  .tiled {
    width: 100%;
    flex-wrap: wrap;
  }
  .tiled button {
    width: 87px;
    height: 50px;
    margin: 0 6px 6px 0;
    border: 1px solid rgba(255,255,255,.2);
    color: var(--midnight);
    background-color: #fff;
    opacity: .5;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 13px;
    padding-top: 30px;
  }
  .tiled button:nth-child(even) {
    margin-right: 0;
  }
  .tiled button.selected {
    background-color: #fff;
    color: var(--midnight);
    opacity: 1;
  }
  .visualisations button {
    background-position: center 5px;
    background-size: 24px 24px;
  }
  .container {
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
  .slide {
    margin: 0 auto;
    width: 80vw;
    height: 45vw;
    background: #fff;
    position: relative;
    text-align: left;
    font-family: Pegasus, serif;
  }
  .cols {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .col {
    padding: 10px;
  }
  .col.third {
    width: 33.33%;
  }
  .col.twothirds {
    width: 66.66%;
  }
  .slide h1 {
    font-weight: normal;
    font-size: 4rem;
    margin: 0;
  }
  .slide p {
    font-size: 2.3rem;
    margin: 0 0 .5rem;
  }
  .slide p.fineprint {
    font-size: 1rem;
    font-family: Koopman,sans-serif;
  }
  .chart {
    width: 100%;
    height: 100%;
  }
  .chart:not([data-template]) {
    background-color: rgba(70,70,70,.1);
    background-image: url(/assets/slideicons/chart.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20%;
  }
  .chart :global(iframe) {
    pointer-events: none;
  }
  .fullscreen {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .fullscreen img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  figure.placeholder {
    background-color: rgba(70,70,70,.1);
  }
  .slide *[contenteditable] {
    outline: none;
  }
  :global(.slide .focused) {
    outline: 1px dotted rgba(40,180,240,.8) !important;
  }
</style>
