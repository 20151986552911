export default [
  {
    person: "Ruby Ettie",
    university: "University of Birmingham",
    opinion:
      "I've been able to meet people from all different backgrounds to talk about different and contemporary topics with invitied speakers that I'd have never otherwise met; which isn't something that other media outlets offer.",
  },
  {
    person: "Gianni Sarra",
    university: "King's College London",
    opinion:
      "The most impressive thing about Tortoise is the sheer variety of subjects that you will learn about - stories from every continent, about every field, from every perspective.",
  },
  {
    person: "Sam Butler-Sloss",
    university: "University of Edinburgh",
    opinion:
      "There's a palpable sense, among young people especially, that things need to change. Tortoise's model is a vehicle for us, as students, to put forward our ideas and perspectives on the challenges that will define the coming decades.",
  },
];
