<script>
export let count;
</script>
  
<span class="badge">{count}</span>

<style>
  .badge { 
    position: absolute;
    height: 16px;
    width: 16px;
    top: -2px;
    right: -2px;
    background-color: var(--orange);
    color: white;
    border-radius: 50%; 
    font-size: 12px;
  }
</style>
