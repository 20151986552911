<script>
  import { goto } from "@sveltech/routify";
  import { onMount } from "svelte";

  import {
    handleTimeFormatting,
    sameDate,
    fetchData
  } from "../../common/helpers";

  export let filterValue = "team";
  export let size = 32;
  export let columnToFilter = "audience";

  let listWrapper;

  let meetings = [];
  let totalAmount;
  let page = 0;
  let orderBy;
  
  async function getNewBatch() {
    let fetchUrl = `/thinkins?page=${page}&size=${size}&columnToFilter=${columnToFilter}&filterValue=${filterValue}${orderBy ? "&orderBy=" + orderBy : ""}`;
    let data = await fetchData(fetchUrl);
    totalAmount = data.totalItems;
    meetings = [...meetings, ...data.thinkins.filter((m) => m.status !== "Completed")];
    return data;
  }

  onMount(() => {
    getNewBatch();
  })

  function handleThinkinJoin(id) {
     $goto("/host/thinkin/:id/settings", { id });
  }

  function handleLoadMore() {
    page = page + 1;
    getNewBatch();
  }

  async function handleSort(sortBy) {
    orderBy = sortBy;
    let sortedBatch = await getNewBatch();
    meetings = sortedBatch.thinkins;
  }

  async function handleFiltering(event) {
    filterValue = event.target.value;
    let fetchUrl = `/thinkins?page=${page}&size=${size}&columnToFilter=${columnToFilter}&filterValue=${filterValue}${orderBy ? "&orderBy=" + orderBy : ""}`;
    let data = await fetchData(fetchUrl);
    page = 0;
    totalAmount = data.totalItems;
    meetings = data.thinkins;
  }
</script>

<div class="list-wrapper">

  <table class="list" bind:this={listWrapper}>
    <thead>
      <tr>
        <th class="date isSortable" on:click={() => handleSort("scheduled_date")}>Date</th>
        <th></th>
        <th class="isSortable" on:click={() => handleSort("name")}>Name</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {#if meetings}
        {#each meetings as { id, scheduled_date, name, status }, i (id)}
          <tr on:click={() => handleThinkinJoin(id)} class={`status-${status.replace(" ","-")}`}>
            <td class="date">
            {#if i == 0 || !sameDate(scheduled_date, meetings[i - 1].scheduled_date)}
              {handleTimeFormatting(scheduled_date).DATE}
            {/if}
            </td>
            <td class="time">{handleTimeFormatting(scheduled_date).TIME}</td>
            <td class="event-name">{name}</td>
            <td class="status">
              <span>{#if status == "Live"}In progress{/if}</span>
            </td>
        </tr>
      {/each}
      {#if totalAmount > meetings.length}
        <button class="btn" name="more" on:click={handleLoadMore}>Load more</button>
      {/if}
    {:else}
    <span>None found</span>
    {/if}
  </tbody>
  </table>
</div>