<script>
  import BrowserHeader from "./TabbedThinkinBrowser/BrowserHeader.svelte";
  import BrowserPane from "./TabbedThinkinBrowser/BrowserPane.svelte";
  import { TabBrowser } from "@galapagos/svelte-components";

  export let thinkinSets = [];
  $: bodyPanes = thinkinSets.map(thinkinSet => ({
    component: BrowserPane,
    props: {
      thinkIns: thinkinSet.thinkins,
      fallbackText: thinkinSet.fallbackText,
    },
  }));
</script>

<div class="tabbed-thinkins-browser">
  <h2 class="text section-title mobile-extra-text mobile-left-margin">Booked ThinkIns</h2>
  <TabBrowser
    header={{
      component: BrowserHeader,
      props: {
        thinkinSets,
      },
    }}
    {bodyPanes} />
</div>

<style>
  .tabbed-thinkins-browser {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .text {
    all: unset;
    display: block;
    text-align: start;
  }

  .section-title {
    color: var(--text);
    font-family: Pegasus, serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    margin-top: var(--spacer-medium);
  }

  .mobile-left-margin {
    margin-left: var(--spacer-s3);
  }

  @media screen and (min-width: 645px) {
    .mobile-extra-text {
      display: none;
    }

    .mobile-left-margin {
      margin-left: 0;
    }
  }
</style>
