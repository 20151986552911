<script>
  import { fade } from "svelte/transition";
  import { clickOutside } from "../../common/helpers";
  export let top;
  export let left;
  export let right;
  export let width;
  export let bottom;
  export let grid = false;
  export let icons = false;
  export let checkable = false;

  let style = "";
  $: {
    let s = "";
    if (bottom || bottom == 0) s += `bottom:${bottom}px;`;
    if (top || top == 0) s += `top:${top}px;`;
    if (left || left == 0) s += `left:${left}px;`;
    if (right || right == 0) s += `right:${right}px;`;
    if (width) s += `min-width:${width}px;`;
    style = s;
  }
</script>

<style>
  /* Dropdown content (hidden by default) */
  .dropdown-content {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: rgba(255,255,255,.9); /*#f1f1f1;*/
    box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.5);
    z-index: 55;
    font-size: 15px;
    border-radius: 8px;
    overflow: hidden;
    font-family: var(--system-font);
  }
  .dropdown-content.grid {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
  }

  /* Links inside the dropdown */
  .dropdown-content :global(li)  {
    list-style: none;
    color: #333;
    padding: 10px 20px 10px 30px;
    text-decoration: none;
    display: block;
    text-align:left;
    cursor: pointer;
    transition: background-color 50ms;
    position: relative;
    white-space: nowrap;
  }

  .dropdown-content.icons :global(li)  {
    padding-left: 44px;
  }

  .dropdown-content.icons :global(li img)  {
    position: absolute;
    left: 10px;
    top: 8px;
    width: 24px;
    height: 24px;
    opacity: .8;
  }
  .dropdown-content.icons :global(li:hover img)  {
    filter: invert(100%);
  }
  .dropdown-content :global(.inactive) {
    opacity: 50%;
    cursor: default;
    pointer-events: none;
  }

  .dropdown-content :global(li:not(.inactive):hover) {
    background-color: var(--menu-highlight-fill);
    color: var(--menu-highlight-text);
  }
  .checkable :global(li) {
    padding-left: 36px;
  }
  :global(li.checked) {
    background-image: url(/assets/checkmark.svg);
    background-repeat: no-repeat;
    background-position: 10px 12px;
  }
  :global(li.checked:hover) {
    background-image: url(/assets/checkmark-white.svg);
  }
</style>

<ul use:clickOutside on:click_outside class="dropdown-content" class:grid class:icons class:checkable style={style} in:fade>
  <slot />
</ul>

