import Cookies from "js-cookie";
import { Auth } from "aws-amplify";

import { user } from "../stores/sessionStore";
import { fetchData, saveResource, saveUser } from "./helpers";
import { DESCRIBE_RID, selectBestRIDForUpgrade } from "@galapagos/shared";

export async function checkUser() {
  let userData;
  try {
    userData = await Auth.currentAuthenticatedUser();
    const token = userData.signInUserSession.idToken.jwtToken;
    const uuid = JSON.parse(userData.attributes.identities)[0].userId;
    Cookies.set("thinkin_authToken", token);

    let member = await fetchData(`/users/${uuid}`);
    const first_name = userData.attributes.given_name;
    const last_name = userData.attributes.family_name;

    if (member.user) {
      if (!member.user.first_name || !member.user.last_name) {
        const updated_user = await saveResource("PUT", `/users/${uuid}`, {
          last_name,
          first_name,
        });
        member = { user: updated_user };
      }
      user.set(member.user);
    } else {
      const newUser = await saveUser({
        ...userData.attributes,
        uuid,
        membership_type: "Staff",
        first_name,
        last_name,
      });
      user.set(newUser);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return userData;
}

export async function initialiseTP(token) {
  return new Promise((resolve, reject) => {
    const sandbox_state = process.env.SET_SANDBOX === "stage";
    if (!window.tp) {
      window.tp = [];
    }
    window.tp.push(["setAid", process.env.PIANO_AID]); // application ID
    window.tp.push(["setSandbox", sandbox_state]); // is it sandbox?
    window.tp.push(["setUsePianoIdUserProvider", true]);
    window.tp.push(["setUseTinypassAccounts", false]);

    window.tp.push([
      "init",
      function () {
        let user_logged_in = false;
        window.tp.experience.init();
        window.tp.pianoId.init({
          loggedIn: async function (data) {
            _paq.push(["setUserId", data.user.sub]);
            if (user_logged_in) {
              return;
            }
            user_logged_in = true;
            loadExtendedUser(data.user, token);
          },
          loggedOut: function () {
            user.set(null);
            user_logged_in = false;
          },
          loginFailed() {
            // haven't managed to actually get this one called
            reject(new Error("Login failed"));
          },
        });

        if (window.tp.pianoId.isUserValid()) {
          user_logged_in = true;
          loadExtendedUser(window.tp.pianoId.getUser(), token).then(user => {
            resolve(user);
          });
        } else {
          console.warn("user is not valid");
          return resolve();
        }
      },
    ]);
  });
}

async function loadExtendedUser(data, token) {
  return new Promise(resolve => {
    const first_name = data.firstName;
    const last_name = data.lastName;
    const uuid = data.sub;
    const email = data.email;
    if (token) {
      if (document.location.host.includes("localhost")) {
        Cookies.set("thinkin_authToken", token, { path: "/", samesite: "none", secure: true });
      } else {
        const hostArray = document.location.host.split(".");
        const domain = "." + hostArray.slice(Math.max(hostArray.length - 2, 0)).join(".");
        Cookies.set("thinkin_authToken", token, { path: "/", samesite: "none", secure: true, domain: domain });
      }
    }

    window.tp.user.getProvider().loadExtendedUser({
      formName: "booking-fields",
      extendedUserLoaded: async function (extendedUserData) {
        const { user: dbUser = {} } = await fetchData(`/users/${uuid}`);
        delete dbUser.membership_type;
        const pianoUser = {
          ...dbUser,
          first_name,
          last_name,
          uuid,
          email,
        };

        extendedUserData.custom_field_values.forEach(field => {
          if (field.field_name === "is_founding_member") {
            pianoUser[field.field_name] = typeof field.value === "boolean" ? field.value : field.value === "true";
            if (field.value === "true") {
              pianoUser.amnesty_applied = true;
            }
          } else {
            if (field.value !== "unlimited" || field.field_name !== "mgm_referral_code") {
              pianoUser[field.field_name] = field.value;
            } else {
              pianoUser[field.field_name] = parseInt(field.value);
            }
          }
        });

        if (!pianoUser.is_founding_member) {
          pianoUser.is_founding_member = false;
        }

        const user = await checkAccess(pianoUser);
        resolve(user);
      },
    });
  });
}

function checkAccess(pianoUser) {
  return new Promise(resolve => {
    window.tp.api.callApi("/access/list", {}, response => {
      const activeResourceRids = response.data.map(r => r.resource.rid);
      pianoUser.resourceAccesses = response.data;

      const bestRIDForUpgrade = selectBestRIDForUpgrade(activeResourceRids);
      const upgradeMembershipType = DESCRIBE_RID[bestRIDForUpgrade];
      pianoUser.upgrade_membership_type = upgradeMembershipType;
      user.set(pianoUser);
      resolve(user);
    });
  });
}

export function showPianoLogin() {
  window.tp.pianoId.show({
    displayMode: "modal",
    disableSignUp: true,
    screen: "login",
    loggedIn: async function (data) {
      const first_name = data.user.firstName;
      const last_name = data.user.lastName;
      const name = first_name + " " + last_name;
      const email = data.user.email;
      const uuid = data.user.sub;
      const token = data.token;

      Cookies.set("thinkin_authToken", token);

      let member = await fetchData(`/users/${uuid}`);

      if (member.user) {
        if (!member.user.first_name || !member.user.last_name) {
          const updatedMember = await saveResource("PUT", `/users/${uuid}`, {
            last_name,
            first_name,
          });
          member = updatedMember;
        }
        user.set(member.user);
      } else {
        const newPianoUser = await saveUser({
          name,
          email,
          uuid,
          first_name,
          last_name,
        });
        user.set(newPianoUser);
      }
    },
    loggedOut: function () {},
  });
}

export async function checkGuest() {
  const urlParams = new URLSearchParams(window.location.search);
  const pathname = window.location.pathname.split("/");
  const thinkinId = pathname[pathname.length - 1];
  const key = urlParams.get("key");

  let guest;

  const userData = await fetchData(`/public/thinkins/${thinkinId}?key=${key}`);
  if (userData.authToken) {
    guest = userData;
    user.set(guest);
    Cookies.set("thinkin_authToken", guest.authToken);
  } else {
    // error = "The invite link is not valid any more";
    await initialiseTP();
  }
  return guest;
}
