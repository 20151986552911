<script>
  import { fade } from "svelte/transition";
  import { tweened } from "svelte/motion";
  import { quintOut } from "svelte/easing";
  import { participants, speakers, screen } from "../../stores/sessionStore";
  import ParticipantsDropdown from "./ParticipantsDropdown";
  import Profile from "./Profile";
  import Searchbar from '../searchbar/Searchbar'

  export let host = false;

  let isParticipantsDropdownOpen = false;
  let searchTerm;
  let searchResults = [];

  let height;
  let page_count = 0;
  let current_page = 0;

  function handleSearchTerm(event) {
    searchTerm = event.detail.searchTerm;
  }

  $: active_participants = $participants.filter((p) => !p.left_at);

  $: {
    if (!searchTerm) {
      searchResults = active_participants;
    } else
    if (active_participants.length > 0) {
      searchResults = active_participants.filter((participant) => {
        return (
          participant.name &&
          participant.name.toLowerCase().includes(`${searchTerm}`)
        );
      });
    }
  }

  function handleParticipantClick(participant_id) {
    if (host) isParticipantsDropdownOpen = participant_id;
    else {
      profile_uuid = participant_id;
    }
  }
  function handleDropdownClose() {
    isParticipantsDropdownOpen = false;
  }

  const spacing = 15;
  const cellWidth = 110;
  const containerWidth = 2 * cellWidth + spacing;
  const xRange = [cellWidth, containerWidth];
  const yRange = [cellWidth, (cellWidth - spacing) / 2];
  const opacityRange = [1, 0];

  const x = tweened(xRange[1], {
    duration: 300,
    easing: quintOut,
  });

  const y = tweened(yRange[1], {
    duration: 300,
    easing: quintOut,
  });

  const opacity = tweened(opacityRange[1], { duration: 200 });

  let profile_uuid;

  function viewProfile(event) {
    profile_uuid = event.detail.uuid;
  }

  function updateTiles() {
    if (isGrid) {
      // TODO: work out how many tiles max can fit, ie. page size
      let page_size = 6;
      // TODO: prob depends on whether search string or not
      let pool = searchResults.filter((participant) => {
        return participant.hasVideo || participant.hasAudio;
      });
      page_count = Math.ceil(pool.length / page_size);
      if (page_size * current_page > pool.length) {
        current_page = 0; // FIXME:
      }
      tiles = pool.slice(current_page * page_size, page_size);
    } else {
      tiles = searchResults;
    }
  }

</script>

<style>
 .container {
    width: 100%;
    padding: 0 10px;
    background: rgba(0, 0, 0, .1);
 }
  ul {
    margin: 0;
    padding: 0 0 20px;
    height: calc(100vh - 160px);
    overflow: hidden auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  li {
    font-size: 16px;
    text-align: center;
    list-style: none;
    margin: 0 0 8px;
    width: 32%;
    color: #fff;
    padding: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: .6;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
  }
  p {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
  }
  p.title {
    color: rgba(255,255,255,.7);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .joined {
    opacity: 1;
  }
  figure {
    width: 48px;
    height: 48px;
    margin: 0 2px 4px;
    padding: 0;
    border: 1px solid rgba(255,255,255,.1);
    border-radius: 50%;
    overflow: hidden;
    transition: border-color 100ms;
  }
  li:hover figure {
    border-color: rgba(255,255,255,.5);
  }
  figure img {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;   
  }
  .host .pinned {
    background-image: url(/assets/pin.svg);
  }
  .host .spotlit {
    border-color: var(--lettuce);
  }
  .host .handUp::before {
    content: "";
    display: block;
    background-image: url(/assets/hand-up.svg);
    background-size: contain;
    background-color: #E04600;
    position: absolute;
    right: 3px;
    top: 3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid #e04600;
  }
  @media (max-width: 599px) {
    .container {
      height: 100%;
    }
    .search {
      position: fixed;
      left: 0;
      bottom: 0;
      padding: 10px;
    }
    ul {
      padding-top: 20px;
    }
  }
</style>

{#if active_participants.length > 0}
  <div 
    class:host 
    class="container" 
    bind:offsetHeight={height}>

    <div class="search">
      <Searchbar on:setSearchTerm={handleSearchTerm} placeholder="Search participants"></Searchbar>
    </div>

    <ul>
      {#each searchResults as { uuid, joined_at, left_at, name, job_title, location, profile_picture, handUp, hasVideo, hasAudio, hasCamera, pinned, spotlit } (uuid)}
        <li
          class:hasVideo
          class:hasAudio
          class:hasCamera
          class:joined={!left_at}
          class:handUp={host && handUp}
          class:pinned
          class:spotlit
          on:click={handleParticipantClick(uuid)}
          on:keydown={handleParticipantClick(uuid)}
          transition:fade|local>
          {#if isParticipantsDropdownOpen == uuid}
            <ParticipantsDropdown 
              {host}
              left={5}
              on:pin
              on:unpin
              on:mute
              on:unmute
              on:spotlight
              on:invite
              on:message
              on:eject
              on:view={viewProfile}
              on:click_outside={handleDropdownClose}
              {uuid} />
          {/if}
          <figure>
            {#if profile_picture}
              <img src={profile_picture} alt="" />
            {:else}
              <img src="/assets/avatar.png" alt="" />
            {/if}
          </figure>
          <p class="name">{name}</p>
          <p class="title">
            {#if job_title}
              {job_title}
            {:else if location}
              {location}
            {/if}
          </p>
        </li>
      {/each}
    </ul>
  </div>

{/if}

{#if profile_uuid}
  <Profile uuid={profile_uuid} on:close={() => profile_uuid = null} />
{/if}
