<script>
  import { DropZone, VirtualList } from "@galapagos/svelte-components";
  export let isLoading = false;
  export let handleFileDrop;
  export let fallbackText;
  export let statements;
  export let doesVideoExist;
  export let updateStatements;
  export let resyncStatements;
  export let handleBeginEditing;
  export let highlightsOnly;

  import Spinner from "../../spinner/Spinner.svelte";
  import Statement from "./Statement.svelte";

  $: filteredStatements = statements.filter(statement => {
    return !highlightsOnly || statement.isHighlighted;
  });

  function renameSpeaker(from, to) {
    const newStatements = statements.map(statement => ({
      ...statement,
      speaker: statement.speaker === from ? to : statement.speaker,
    }));
    updateStatements(newStatements);
  }

  function editUtterance(index, newUtterance) {
    const newStatements = [...statements];
    newStatements[index].utterance = newUtterance;
    updateStatements(newStatements);
  }

  function deleteStatement(indexToRemove) {
    const newStatements = (statements = statements.filter((_, index) => index !== indexToRemove));
    updateStatements(newStatements);
  }

  function highlightStatement(indexToFavourite) {
    const newStatements = [...statements];
    newStatements[indexToFavourite].isHighlighted = !newStatements[indexToFavourite].isHighlighted;
    if (!newStatements[indexToFavourite].isHighlighted) {
      delete newStatements[indexToFavourite].frame;
    }
    updateStatements(newStatements);
  }
</script>

<div class="pane">
  {#if !statements.length}
    <DropZone text={fallbackText} on:filedrop={handleFileDrop} />
  {:else}
    <VirtualList items={filteredStatements || []} let:item let:index>
      <Statement
        {...item}
        {doesVideoExist}
        on:begin-editing={handleBeginEditing}
        on:edit-utterance={e => editUtterance(index, e.detail.value)}
        on:rename-speaker={e => renameSpeaker(e.detail.from, e.detail.to)}
        on:delete-statement={() => deleteStatement(index)}
        on:resync={e => resyncStatements(e.detail.resyncFrom)}
        on:highlight={() => highlightStatement(index)} />
    </VirtualList>
  {/if}
  {#if isLoading}
    <div class="overlay">
      <Spinner />
    </div>
  {/if}
</div>

<style>
  .pane,
  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .pane {
    position: relative;
    background-color: #f4f5f7;
    flex-grow: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  }
</style>
