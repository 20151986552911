<script>
  import { DateTime } from "luxon";
  import { deleteResource, fetchData, saveResource } from "../../common/helpers";

  export let thinkin;

  let polls = [];

  function fetchPolls() {
    fetchData(`/thinkins/${thinkin.id}/polls`).then(data => {
      polls = data.polls.sort((p1, p2) => p1.created_at < p2.created_at ? -1 : 1);
      console.log("POLLS", polls);
    });
  }

  function addPoll() {
    let new_poll = {
      id: Math.random(),
      thinkin_id: thinkin.id,
      question: "",
      options: [],
      allow_other: false,
      usage: "during",
      new: true,
    };
    polls = [...polls, new_poll];
  }

  function removePoll(id) {
    const index = polls.findIndex(p => p.id == id);
    const is_new = polls[index].new;
    polls.splice(index, 1);
    polls = polls;
    if (!is_new) {
      deleteResource(`/thinkins/${ thinkin.id }/polls/${ id }`);
    }
  }

  async function savePoll(id) {
    let index = polls.findIndex((p) => p.id == id);
    let poll = polls[index];
    let url, method;
    if (poll.new) {
      delete poll["id"];
      delete poll["new"];
      url = `/thinkins/${ thinkin.id }/polls`;
      method = "POST";
    } else {
      url = `/thinkins/${ thinkin.id }/polls/${ poll.id }`;
      method = "PUT";
    }
    delete poll["updated"];
    let options = document.getElementById(`options-${ id }`).value
      .split("\n")
      .map(s => s.trim())
      .filter(s => s.length > 0);
    poll.options = options;
    console.log("saving", poll);
    let json = await saveResource(method, url, poll);
    poll.id = json.id;
    polls[index] = poll;
  }

  function updateUsage(id) {
    polls.find(p => p.id == id).updated = true;
  }

  fetchPolls();
</script>

<div class="polls">
  {#each polls as poll (poll.id)}
    <div class="poll">
      <label for="usage-input">Ask this question </label>
      <select id="usage-input" bind:value={ poll.usage } on:input={() => updateUsage(poll.id)}>
        <option value="entry">when booking</option>
        <option value="exit">when leaving</option>
        <option value="during">during the ThinkIn</option>
      </select>
      <p>
        <input type="text" name="question" placeholder="The question is…" bind:value={ poll.question } on:input={() => poll.updated = true}>
      </p>
      <textarea name="options" placeholder="One possible answer per line" id={`options-${ poll.id }`} on:input={() => poll.updated = true}>{ poll.options.join("\n")}</textarea>
      <p class="options">
        <label><input type="checkbox" name="allow_other" bind:checked={ poll.allow_other } on:change={() => poll.updated = true}> Allow other answers</label>
      </p>
      <button class="icon" name="remove" on:click={() => removePoll(poll.id)}>✕</button>
      {#if poll.new || poll.updated}
        <button name="save" class="btn alt" on:click={() => savePoll(poll.id)}>Save</button>
      {/if}
    </div>
  {/each}
  <p>
    <button name="add" class="btn outlined" on:click={addPoll}>New question</button>
  </p>
</div>

<style>
.polls {
  text-align: left;
  padding: 20px 0;
  max-width: 800px;
}
.poll {
  background: #fff;
  color: #222;
  font-size: 14px;
  position: relative;
  padding: 10px 20px;
  margin-bottom: 15px;
}
p {
  margin: 0;
}
.options {
  padding: 5px 0;
}
select {
  width: auto;
  background-image: none;
  border-bottom: 1px solid #666;
}
textarea {
  height: 80px;
  resize: none;
}
input[type=text], textarea {
  font-size: 18px;
  font-family: Pegasus;
  appearance: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #666;
  outline: none;
  padding: 8px 2px;
}
input[type=text]:focus, textarea:focus {
  background: var(--stone);
}
input[type=checkbox] {
  top: 3px;
}
button[name=remove] {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--kelp);
  padding: 5px 10px;
  opacity: 0;
  transition: opacity 200ms;
}
.poll:hover button[name=remove] {
  opacity: 1;
}
button[name=save] {
  position: absolute;
  right: 15px;
  bottom: 6px;
}
input[type=checkbox] {
  margin-right: 5px;
}
input[type=checkbox]:not(:checked)::before {
  background-color: #ddd;
}
label[for="usage-input"] {
  padding-left: 2px;
}
</style>
