<script>
  export let image;
  export let name;
  export let title;
</script>

<div class="main-container">
  <img src={image} alt={name} />
  <div class="text-container">
    <div class="name">{name}</div>
    <div class="title">{title}</div>
  </div>
</div>

<style>
  .main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 352px;

    & img {
      width: 72px;
      height: 72px;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    line-height: 28px;
  }
  .title {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 100%;
    line-height: 22px;
  }

  @media only screen and (max-width: 825px) {
    .main-container {
      flex-direction: column;
      width: 130px;
    }
  }
</style>
