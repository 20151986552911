<script>
  import AttendeesList from "../list/AttendeesList.svelte";
  import { fetchData, groupBy, formatPencePrice } from "../../common/helpers";
  export let thinkin;

  export let attendees = [];
  let ticketTypes = [];
  const ticketTypeIds = (thinkin.ticket_types || []).map(t => t.id);

  let bookingTypes = [];
  let memberTypes = [];
  let partners = [];

  $: paymentsSummary = Object.values(
    attendees.reduce((uniquePayments, attendee) => {
      const { payment_amount_pence, membership_type } = attendee;
      if (typeof payment_amount_pence !== "number") {
        return uniquePayments;
      }

      const paymentType = membership_type + " " + payment_amount_pence;

      if (!uniquePayments[paymentType]) {
        uniquePayments[paymentType] = {
          membership_type,
          payment_amount_pence,
          count: 1,
        };
      } else {
        uniquePayments[paymentType] = { ...uniquePayments[paymentType], count: uniquePayments[paymentType].count + 1 };
      }
      return uniquePayments;
    }, {})
  );

  function processAttendees() {
    const bookingTypeGroups = groupBy(attendees, "booking_type");
    bookingTypes = [];
    for (const name in bookingTypeGroups) {
      bookingTypes.push({
        name,
        total: bookingTypeGroups[name].length,
      });
    }
    bookingTypes.sort((m1, m2) => (m1.name < m2.name ? -1 : 1));

    const memberTypeGroups = groupBy(attendees, "membership_type");
    memberTypes = [];
    for (const name in memberTypeGroups) {
      if (name !== "null") {
        memberTypes.push({
          name,
          total: memberTypeGroups[name].length,
        });
      }
    }
    memberTypes.sort((m1, m2) => (m1.name < m2.name ? -1 : 1));

    const partnerGroups = groupBy(attendees, "partner_name");
    partners = [];
    for (const name in partnerGroups) {
      if (name !== "null") {
        partners.push({
          name,
          total: partnerGroups[name].length,
        });
      }
    }
    partners.sort((m1, m2) => (m1.name < m2.name ? -1 : 1));
  }

  async function fetchTicketTypes() {
    const tickets = await await fetchData(`/tickets?columnToFilter=id&filterValues=${ticketTypeIds}`);
    ticketTypes = tickets.ticket_types;
  }

  if (thinkin && thinkin.id) {
    fetchTicketTypes()
      .then(processAttendees)
      .catch(err => console.log(err));
  }
</script>

<main>
  <div class="attendee-list">
    <AttendeesList {attendees} thinkinId={thinkin.id} thinkinName={thinkin.name} {ticketTypes} />
  </div>

  <aside class="ticket-types">
    {#if bookingTypes.length}
      <div class="booking-types">
        {#each bookingTypes as { name, total }}
          <p>
            <span>{name}</span>
            <span><b>{total}</b></span>
          </p>
        {/each}
        <p class="total"><span>Total</span><span><b>{attendees.length}</b></span></p>
      </div>
    {/if}

    {#if memberTypes.length}
      <div class="member-types">
        <h3>Membership type</h3>
        {#each memberTypes as { name, total }}
          <p>
            <span>{name}</span>
            <span><b>{total}</b></span>
          </p>
        {/each}
      </div>
    {/if}

    {#if paymentsSummary.length}
      <div>
        <h3>Payments</h3>
        {#each paymentsSummary as { count, membership_type, payment_amount_pence }}
          <p>
            <span>{membership_type} x {count}</span>
            <span>{formatPencePrice(payment_amount_pence)}</span>
          </p>
        {/each}
        <p class="total">
          <span>Total</span><span
            ><b
              >{formatPencePrice(
                paymentsSummary.reduce(
                  (total, paymentSummary) => total + paymentSummary.payment_amount_pence * paymentSummary.count,
                  0
                )
              )}</b
            ></span>
        </p>
      </div>
    {/if}

    {#if partners.length}
      <div class="partner-bookings">
        <h3>Partner guests</h3>
        {#each partners as { name, total }}
          <p>
            <span>{name}</span>
            <span><b>{total}</b></span>
          </p>
        {/each}
      </div>
    {/if}
  </aside>
</main>

<style>
  main {
    position: relative;
    padding-right: 250px;
    height: 100%;
  }
  .attendee-list {
    padding: 10px 30px;
  }
  aside {
    position: absolute;
    right: 0;
    top: 0;
    width: 240px;
    height: 100%;
    padding: 10px 10px 20px;
    overflow-y: auto;
    background: #001a38;
  }
  aside > div {
    background: var(--midnight);
    margin-bottom: 10px;
  }
  aside p {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 15px 5px;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }
  aside p.total {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 15px;
    color: #fff;
  }
  h3 {
    font-weight: normal;
    padding: 8px 15px 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 10px;
  }
</style>
