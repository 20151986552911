<script>
  import PersonCaterpillar from "../../../components/checkbox/PersonCaterpillar.svelte";
  export let title;
  export let schedule;
  export let subtitle;
  export let people;
  export let showToAllUsers;
</script>

<box class="body" class:body-exclusive={!showToAllUsers}>
  {#if !showToAllUsers}
    <div class="exclusive-box">member exclusive</div>
  {/if}
  <h1>{title}</h1>
  <div class="schedule-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
      <path
        d="M0 0.5V15.5H22V0.5H0ZM1 4.59412L7.07 8L1 11.4059V4.58529V4.59412ZM21 13.7353H1V12.2882L7.86 8.44118L11 10.2059L14.14 8.44118L21 12.2882V13.7353ZM21 11.4059L14.93 8L21 4.59412V11.4147V11.4059ZM21 3.62353L11 8.43235L1 3.63235V1.38235H21V3.63235V3.62353Z"
        fill="#333333" />
    </svg>
    {schedule}
  </div>
  <h3 class="subtitle">
    {subtitle}
  </h3>
  <div>
    {#if people && people.length}
      <PersonCaterpillar {people} />
    {/if}
  </div>
</box>

<style>
  .body {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 370px;
  }
  .body h1 {
    font-size: 28px;
    font-weight: 700 !important;
    line-height: 36px;
  }
  .schedule-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    gap: 8px;
    font-family: FS Koopman !important;
  }
  .subtitle {
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
  }
  .exclusive-box {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    font-variant: all-small-caps;
    letter-spacing: 1px;
    background-color: #dce459;
    padding: 8px 16px;
    width: fit-content;
  }

  .body-exclusive {
    padding-top: 0;
  }

  @media only screen and (max-width: 600px) {
    .body {
      width: 330px;
    }
  }
</style>
