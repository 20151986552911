<script>
import Cookies from "js-cookie";
import { Modal } from "@galapagos/svelte-components";
import { session } from "./session";
import { video_on, captions_on, astons_on, audio_on, chat_on, chat_max, font_size } from "../../stores/settings";

let cameras = [];
let mics = [];
let preview;
let parent;

function displayLabel(text) {
  if (text.indexOf("(") == -1) return text;
  else return text.substr(0, text.indexOf("("));
}
</script>

<Modal width=400 on:close>

  <div class="settings">
    <div>
      <h2>Watching</h2>
      <p>
        <input type="checkbox" class="switch" id="video-on" bind:checked={$video_on}>
        <label for="video-on"> Play video</label>
      </p>
      <p>
        <input type="checkbox" class="switch" id="captions-on" bind:checked={$captions_on}>
        <label for="captions-on"> Show captions if available</label>
      </p>
      <p>
        <input type="checkbox" class="switch" id="astons-on" bind:checked={$astons_on}>
        <label for="astons-on"> Always show names on video</label>
      </p>
    </div>
    <div>
      <h2>Chat</h2>
      <p>
        <input type="checkbox" class="switch" id="chat-max" bind:checked={$chat_max}>
        <label for="chat-max"> Maximize chat panel</label>
      </p>
      <p class="range font-sizing">
        <button name="font_size" on:click={() => $font_size = 1}>A</button>
        <input type="range" name="font-size" min="1" max="5" bind:value={$font_size}>
        <button name="font_size" on:click={() => $font_size = 5}>A</button>
      </p>
    </div>
  </div>
</Modal>

<style>
.settings {
  background-color: #fff;
}
.settings div {
  padding: 16px 30px;
  border-top: 1px solid #ddd;
}
.settings div:first-child {
  border-top: none;
}
h2 {
  font-size: 18px;
  font-family: Pegasus,serif;
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: bold;
  margin: 0;
  color: #333;
}
p {
  display: flex;
  align-items: center;
}
label {
  margin-left: 10px;
}
input[type=range] {
  width: 100%;
}
input[type=checkbox]:not(:checked)::before {
  background-color: #ccc;
}
button[name=font_size]:last-child {
  font-size: 24px;
}
</style>
