<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Dropdown from "../dropdown/Dropdown";
  import { mic_id } from "../../stores/settings";
  import { session } from "./session";

  export let disabled = false;
  export let on = false;

  let show_menu = false;
  let mics = [];

  $: selected_device_id = $mic_id;
  $: tooltip = disabled ? "Only a host can unmute you" : (on ? "Mute me" : "Unmute me");

  function displayLabel(text) {
    if (text.indexOf("(") == -1) return text;
    else return text.substr(0, text.indexOf("("));
  }

  function selectMic(event) {
    selected_device_id = event.target.getAttribute("data-value");
    mic_id.set(selected_device_id);
    setTimeout(() => { show_menu = false }, 330);
  }

  function showMenu() {
    show_menu = true;
    setTimeout(() => { show_menu = false; }, 3000);
  }

  onMount(() => {
    session.listDevices().then((devices) => {
      mics = devices.filter((d) => d.kind == "audioInput");
    });
  });

</script>

<div>
  <button
    on:click
    class="tooltip roundel"
    class:on
    class:disabled
    data-text={tooltip}>
    {#if on}
      <img src="/assets/mic_on.svg" alt="" />
    {:else}
      <img src="/assets/mic_off.svg" alt="" />
    {/if}
  </button>

  {#if mics.length > 1 && !disabled}
    <button class="disclosure" on:click={showMenu}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000" width="14" height="14"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="#003333"/></svg>
    </button>
  {/if}

  {#if show_menu}
    <Dropdown checkable bottom="54" on:click_outside={() => show_menu = false}>
      {#if !mics.length}
        <li>No microphones available</li>
      {/if}
      {#each mics as { deviceId, label }}
        <li data-value={deviceId}
          class:checked={deviceId == selected_device_id}
          on:keydown={selectMic}
          on:click={selectMic}>{displayLabel(label)}</li>
      {/each}
    </Dropdown>
  {/if}
</div>

<style>
  div {
    position: relative;
    margin: 0;
  }
  button {
    font-family: Koopman;
    margin: 0;
  }
  .on {
    background-color: var(--moss);
  }
  .disabled {
    cursor: default;
  }
  .disclosure {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 8px;
    padding: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: rgba(255,255,255,.8);
    opacity: 0;
    transition: opacity 200ms;
    z-index: 51;
  }
  div:hover .disclosure {
    opacity: 1;
  }
  .disclosure:hover {
    background: #fff;
  }
</style>