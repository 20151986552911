<script>
  import { handleTimeFormatting, handleMinutesFormatting } from "../../common/helpers";

  import ThinkinBookingSummary from "./ThinkinBookingSummary.svelte";

  export let thinkin;
  const { WD, DDMMMYYY, TIME: startTime } = handleTimeFormatting(thinkin.scheduled_date);
  const endDate = new Date(thinkin.scheduled_date).getTime() + thinkin.duration || 0 * 60000;
  const { TIME: endTime } = handleTimeFormatting(new Date(endDate).toISOString());

  const duration = handleMinutesFormatting(thinkin.duration);
</script>

<a
  target="_parent"
  href={thinkin.pugpig_thinkin_path &&
    `https://${process.env.DEEP_LINK_DOMAIN}${thinkin.pugpig_thinkin_path}content.html`}
  class="card">
  <img class="card__hero" src={thinkin.poster_url} alt={`Banner for ${thinkin.name}`} />
  <div class="card__body">
    <h3 class="text card-subtitle-text">{thinkin.type || ""}</h3>
    <h2 class="text card-title-text"><span class="focus-styling">{thinkin.name}</span></h2>
    <div class="spacer-tiny" />
    <p class="text card-meta-text">{WD} {DDMMMYYY}</p>
    <p class="text card-meta-text">
      {startTime} - {endTime} UTC ⸱ {duration}
    </p>
    <div class="spacer-small" />
    <ThinkinBookingSummary ticketCount={thinkin.plus_one_invited ? 2 : 1} ticketType={thinkin.booking_type} />
  </div>
</a>

<style>
  .card {
    display: block;
    text-decoration: none;
    outline: none;
  }
  .card__hero {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  .card:focus .focus-styling {
    box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.24);
  }

  .card__body {
    margin: var(--spacer-tiny) var(--spacer-medium) var(--spacer-s4) var(--spacer-s3);
  }

  .text {
    all: unset;
    display: block;
    text-align: start;
  }

  .card-title-text {
    color: var(--text);
    font-family: Pegasus, serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
  }

  .card-subtitle-text {
    font-family: Pegasus;
    text-transform: capitalize;
    color: var(--hundred-year-life);
    font-size: 19px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: var(--spacer-tiny);
  }

  .card-meta-text {
    font-family: Koopman, serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-grey);
  }

  .spacer-tiny {
    margin-bottom: var(--spacer-tiny);
  }
  .spacer-small {
    margin-bottom: var(--spacer-medium);
  }

  @media screen and (min-width: 645px) {
    .card__body {
      margin: unset;
    }
  }
</style>
