<script>
  import { createEventDispatcher } from "svelte";

  export let dark = false;
  
  const dispatch = createEventDispatcher();

  let searchTerm;

  export let placeholder = "Search...";

  function setSearchTerm() {
    dispatch("setSearchTerm", {
      searchTerm,
    });
  }

</script>

<style>
  .search-bar-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    position: relative;
  }
  img {
    margin: 0 5px;
    width: 22px;
    height: 22px;
  }
  input {
    width: 250px;
  }
  input:focus {
    outline: none;
    box-shadow: none;
  }
  input, label {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: white;
    font-family: Koopman;
    font-size: 14px;
  }
  input::placeholder {
    color: white;
    opacity: 50%;
  }
  .dark input, .dark input::placeholder {
    color: #111;
  }
</style>

<div class="search-bar-wrapper" class:dark>
  <img src="/assets/search.svg" alt="Search" />
  <label for="search-bar">
    <input bind:value={searchTerm} on:input={setSearchTerm} type="text" name="search-bar" autocomplete="off" {placeholder} />
  </label>
  <slot />
</div>
