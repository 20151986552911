<script>
  import Spinner from "./Spinner.svelte";
  export let visible = false;
  export let backgroundColour = "#FFF";
  export let foregroundColour = "#006666";
  export let text = "";
</script>

{#if visible}
  <div style={`--bg-colour: ${backgroundColour}; --fg-colour: ${foregroundColour}`}>
    <Spinner colour={foregroundColour} />
    <p>{text}</p>
  </div>
{/if}

<style>
  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4000;
    background-color: var(--bg-colour);
  }

  p {
    color: var(--fg-colour);
  }
</style>
