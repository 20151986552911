import { DESCRIBE_RID } from "./constants";
import { MEMBERSHIP_TYPE_PUBLIC_FACING_NAME } from "./types";
// make sure these are in the caller's process.env! Also some calling environments do not support destructuring from the process.env object, because they replace process.env with a string rather than adding to a global variable.
const PIANO_THIRD_BIRTHDAY_RID = process.env.PIANO_THIRD_BIRTHDAY_RID;
const PIANO_12_EXTRA_TICKETS_RID = process.env.PIANO_12_EXTRA_TICKETS_RID;
const NON_MEMBERSHIP_RIDS = [PIANO_THIRD_BIRTHDAY_RID, PIANO_12_EXTRA_TICKETS_RID];
const MEMBERSHIP_TYPE_VALUE = {
    [MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT]: 2,
    [MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM]: 1,
    [MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL]: 0,
};
const valueRID = rid => MEMBERSHIP_TYPE_VALUE[(DESCRIBE_RID[rid] || {}).upgradeFrom] || 0;
// this isn't going to be totally reliable but in practice it should cover most cases
export const selectBestRIDForUpgrade = rids => {
    const hasUserUpgraded = rids.includes(process.env.PIANO_THIRD_BIRTHDAY_RID);
    const sortedRIDs = rids.filter(rid => !NON_MEMBERSHIP_RIDS.includes(rid)).sort((a, b) => valueRID(a) - valueRID(b));
    if (hasUserUpgraded) {
        const originalRID = sortedRIDs[0];
        return originalRID;
    }
    else {
        const bestRID = sortedRIDs[sortedRIDs.length - 1];
        return bestRID;
    }
};
