export const layouts = [{
  id: "layout-auto",
  size: 12,
  aspect: 4 / 3,
  auto: true,
},{
  id: "layout-1x1",
  size: 1,
  aspect: 1,
}, {
  id: "layout-2x2",
  size: 4,
  aspect: 1,
},{
  id: "layout-2x1",
  size: 2,
  aspect: 2 / 1,
}, {
  id: "layout-3x",
  size: 3,
  aspect: 1,
}, {
  id: "layout-pip",
  size: 2,
  aspect: 1,
}, {
  id: "layout-2",
  size: 5,
  aspect: 5 / 4, 
}, {
  id: "layout-9",
  size: 10,
  aspect: 1,
}, {
  id: "layout-6",
  size: 12,
  aspect: 5 / 4,
}, {
  id: "layout-screenshare",
  size: 2,
  aspect: 16 / 9,
  type: "screen",
}];

export const skinny_layout = {
  id: "layout-skinny",
  size: 4,
  aspect: 1 / 4,
};
