<script>
  import { createEventDispatcher } from "svelte";
  import { DropZone } from "@galapagos/svelte-components";
  import {
    getFileSrc,
    transcriptFileFromString,
    parseTranscript,
    parseZoomCommentsFile,
    fetchJSONFile,
    resyncStatementList,
    RECAP_ACTION,
    transcriptFileStringFromData,
  } from "@galapagos/shared";

  const dispatch = createEventDispatcher();
  import { filedrop } from "filedrop-svelte";
  import TabbedStatementBrowser from "./Video/TabbedStatementBrowser.svelte";
  import BlockLevelSpinner from "../spinner/BlockLevelSpinner.svelte";
  export let video;
  export let transcript;
  export let comments;
  let isTranscriptLoading = true;
  let areCommentsLoading = true;
  let videoPaused = true;
  let videoCurrentTime = 0;
  let videoDuration = 0;

  async function fetchTranscript(transcriptFileUrl) {
    isTranscriptLoading = true;
    if (!transcriptFileUrl) {
      isTranscriptLoading = false;
      return;
    }

    transcript.data = await fetchJSONFile(transcriptFileUrl);
    const vttString = transcriptFileStringFromData(transcript.data);
    transcript.file = transcriptFileFromString(vttString);

    isTranscriptLoading = false;
  }

  async function fetchComments(commentsFileUrl) {
    areCommentsLoading = true;
    if (!commentsFileUrl) {
      areCommentsLoading = false;
      return;
    }

    comments.data = await fetchJSONFile(commentsFileUrl);
    areCommentsLoading = false;
  }

  fetchTranscript(transcript.url);
  fetchComments(comments.url);

  async function processNewFile(file) {
    const [extension] = file.name.match(/\....$/);
    if (extension === ".vtt") {
      transcript.file = file;
      const transcriptFileContent = await file.text();
      transcript.data = parseTranscript(transcriptFileContent);
      transcript.nextAction = RECAP_ACTION.UPLOAD;
    } else if (extension === ".txt") {
      const commentFileContent = await file.text();
      comments.data = parseZoomCommentsFile(commentFileContent);
      comments.nextAction = RECAP_ACTION.UPLOAD;
    } else if (extension === ".mp4") {
      video.file = file;
      video.nextAction = RECAP_ACTION.UPLOAD;
      if (transcript.data) {
        // transcript highlights take stills from the video so need to be updated
        transcript.nextAction = RECAP_ACTION.UPLOAD;
      }
    }
    dispatch("change");
  }

  function handleFileDrop(e) {
    const files = e.detail.files.accepted;
    return Promise.all(files.map(processNewFile));
  }

  function handleDeleteRecap() {
    video.file = null;
    video.nextAction = video.url ? RECAP_ACTION.DELETE : null;

    transcript.data = null;
    transcript.file = null;
    transcript.nextAction = transcript.url ? RECAP_ACTION.DELETE : null;

    comments.data = null;
    comments.file = null;
    comments.nextAction = comments.url ? RECAP_ACTION.DELETE : null;

    dispatch("change");
  }

  function handleBeginEditing() {
    videoPaused = true;
  }

  function updateTranscript(newTranscripts) {
    transcript.data = newTranscripts;
    transcript.nextAction = RECAP_ACTION.UPLOAD;
    dispatch("change");
  }

  function updateComments(newComments) {
    comments.data = newComments;
    comments.nextAction = RECAP_ACTION.UPLOAD;
    dispatch("change");
  }

  function resyncStatements(resyncFromMs) {
    const resyncToMs = Math.round(videoCurrentTime * 1000);
    const upperLimitMs = Math.round(videoDuration * 1000);
    transcript.data = resyncStatementList(transcript.data, resyncFromMs, resyncToMs, upperLimitMs);
    comments.data = resyncStatementList(comments.data, resyncFromMs, resyncToMs, upperLimitMs);
    transcript.nextAction = RECAP_ACTION.UPLOAD;
    comments.nextAction = RECAP_ACTION.UPLOAD;
    dispatch("change");
  }

  $: videoSrc = getFileSrc(video.file, video.url);
  $: transcriptSrc = getFileSrc(transcript.file);
  const doesAssetExist = ({ file, data, url }) => !!(file || data || url);
  $: doesVideoExist = doesAssetExist(video);
  $: doesAnyAssetExist = [video, comments, transcript].some(doesAssetExist);
</script>

<div use:filedrop={{ accept: "video/*", clickToUpload: false, windowDrop: true }} on:filedrop={handleFileDrop}>
  <div class="video-box">
    {#if video.isUploading}
      <div
        class="video-shaped-box"
        use:filedrop={{ accept: "video/*", clickToUpload: true, windowDrop: true }}
        on:filedrop={handleFileDrop}>
        <BlockLevelSpinner visible />
      </div>
    {:else if videoSrc}
      <video
        src={videoSrc}
        controls
        preload="metadata"
        height={300}
        bind:paused={videoPaused}
        bind:currentTime={videoCurrentTime}
        bind:duration={videoDuration}>
        <track kind="captions" srclang="en" src={transcriptSrc} />
      </video>
    {:else}
      <div class="video-shaped-box">
        <DropZone text="Upload video" />
      </div>
      <p class="file-instruction">
        You can download raw video and transcript files from <a href="https://zoom.us/recording" target="_blank"
          >Zoom</a>
        and then upload and edit them here.<br /><br />Click inside the box or drag and drop to upload your video.
      </p>
    {/if}
  </div>
</div>

<div class="list-box">
  <TabbedStatementBrowser
    {handleFileDrop}
    {handleBeginEditing}
    {updateTranscript}
    {updateComments}
    {resyncStatements}
    isTranscriptLoading={isTranscriptLoading || transcript.isUploading}
    areCommentsLoading={areCommentsLoading || comments.isUploading}
    {doesVideoExist}
    commentData={comments.data}
    transcriptData={transcript.data} />
</div>

<div class="button-box">
  <button on:click={() => dispatch("show")} class="oldRecap">&#8592; Switch back to the old catch up editor</button>
  {#if doesAnyAssetExist}
    <button name="delete" on:click={handleDeleteRecap}> Delete Recap </button>
  {/if}
</div>

<style>
  .list-box {
    height: calc(100% - 300px);
    min-height: 100px;
    display: flex;
    flex-direction: column;
  }
  .video-box {
    display: flex;
    align-items: center;
  }

  .video-shaped-box {
    height: 300px;
    width: 533px;
  }

  .file-instruction {
    margin-left: 30px;
    max-width: 400px;
    display: unset;
  }

  .file-instruction a {
    color: var(--lettuce);
  }

  .button-box {
    display: flex;
    justify-content: space-between;
  }

  .button-box button[name="delete"] {
    font-size: 14px;
    padding: 0 8px;
    height: 35px;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    opacity: 0.8;
  }
  .oldRecap {
    color: #fff;
    opacity: 0.8;
    margin: 5px 0;
  }
</style>
