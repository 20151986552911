<script>
  import { createEventDispatcher } from "svelte";

  import { fetchData } from "../../../common/helpers";

  import AutomaticEmails from "./AutomaticEmails.svelte";

  export let thinkin_id;

  const dispatch = createEventDispatcher();
  const promise = getEmailSettings();

  async function getEmailSettings() {
    const data = await fetchData(`/thinkins/${thinkin_id}/email-settings`);
    if (data.emailSettings) {
      const { suppress_confirmation_email, suppress_2d_reminder, suppress_4h_reminder } = data.emailSettings;
      return {
        sendConfirmationEmails: !suppress_confirmation_email,
        send2dayReminderEmails: !suppress_2d_reminder,
        send4hourReminderEmails: !suppress_4h_reminder,
      };
    } else {
      return {
        sendConfirmationEmails: true,
        send2dayReminderEmails: true,
        send4hourReminderEmails: true,
      };
    }
  }

  function handleChange(e) {
    dispatch("change", { ...e.detail });
  }
</script>

<form on:submit|preventDefault>
  <div>
    {#await promise then emailSettings}
      <AutomaticEmails {emailSettings} on:update={handleChange} />
    {/await}
  </div>
</form>

<style>
  form {
    position: relative;
  }
  div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
</style>
