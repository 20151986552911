<script>
  let isFullscreen = false;
  $: image_src = "/assets/" + (isFullscreen ? "close" : "open") + "_fullscreen.svg";

  const element = document.documentElement;

  function toggleFullscreen() {
    if (!isFullscreen) {
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
      else console.warn("unsupported fullscreen request", element);
    } else {
      if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.exitFullscreen) document.exitFullscreen();
    }
    isFullscreen = !isFullscreen;
  }
</script>


<button class="tooltip bottom" data-text="Full screen" on:click={toggleFullscreen}>
  <img src={image_src} alt="fullscreen" /> 
</button>

<style>
  button {
    width: 32px;
    height: 32px;
    margin: 0 5px;
    padding: 0;
    opacity: .9;
  }
  button:hover {
    opacity: 1;
  }
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 599px) {
    button {
      display: none;
    }
  }
</style>