<script>
  import { createEventDispatcher } from "svelte";

  export let emailSettings = {};

  const dispatch = createEventDispatcher();

  function update() {
    dispatch("update", emailSettings);
  }
</script>

<p>
  <label>
    <input
      type="checkbox"
      name="sendConfirmationEmails"
      bind:checked={emailSettings.sendConfirmationEmails}
      on:change={update} />
    Send confirmation emails
  </label>
</p>

<p>
  <label>
    <input
      type="checkbox"
      name="send2dayReminderEmails"
      bind:checked={emailSettings.send2dayReminderEmails}
      on:change={update} />
    Send 2-day reminder emails
  </label>
</p>

<p>
  <label>
    <input
      type="checkbox"
      name="send4hourReminderEmails"
      bind:checked={emailSettings.send4hourReminderEmails}
      on:change={update} />
    Send 4-hour reminder emails
  </label>
</p>

<style>
  label {
    margin-left: 0;
  }
</style>
