<script>
  const bookingAssets = process.env.BOOKING_PANEL_ASSETS;
</script>

<svelte:head>
  <title>Book your place</title>
  <link rel="stylesheet" href={bookingAssets + "/global.css"} />
  <link rel="stylesheet" href={bookingAssets + "/booking-form/booking.css"} />
  <script src={bookingAssets + "/booking-form/booking.js"}></script>
</svelte:head>

<div id="booking-form" />
