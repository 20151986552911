<script>import SquareNumberBadge from "../atoms/SquareNumberBadge.svelte";
import selectable from "../directives/selectable";
export let isSelected = false;
export let count;
export let title = "";
</script>

<span class="selectable-header" use:selectable on:select>
  <h3 class={`text selectable-header__title focus-styling`} class:selectable-header__title--selected={isSelected}>
    {title}
  </h3>
  {#if typeof count === "number"}
    <SquareNumberBadge>{count}</SquareNumberBadge>
  {/if}
</span>

<style>@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Italic"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf") format("truetype");
  font-weight: bold; }

/* use Koopman for fixed labels (button) */
@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.tt") format("truetype"); }

@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.tt") format("truetype");
  font-weight: bold; }

* {
  box-sizing: border-box;
  font-family: Pegasus,serif; }

.selectable-header {
  display: flex;
  align-items: baseline;
  outline: none;
  margin-right: 12px;
  cursor: pointer; }

.text {
  all: unset;
  display: block;
  text-align: start; }

.selectable-header:focus .focus-styling {
  box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.24); }

.selectable-header__title {
  margin-right: 8px;
  padding-bottom: 8px; }

.selectable-header__title--selected {
  border-bottom: solid black 1px; }
</style>
