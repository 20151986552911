<script>
  import { createEventDispatcher } from 'svelte';
  import { saveResource } from "../../common/helpers";
  import { Modal } from "@galapagos/svelte-components";
  import { thinkin, user } from "../../stores/sessionStore";

  const dispatch = createEventDispatcher();

  let question;
  let options;

  async function savePoll() {
    options = options.split("\n")
      .map(s => s.trim())
      .filter(s => s.length > 0);
    let poll = {
      question,
      options,
    };
    let json = await saveResource("POST", `/thinkins/${ $thinkin.id }/polls`, poll);
    poll.id = json.id;
    dispatch("open", { poll });
    closeDropdown();
  }

  $: disabled = !question || !options;
  
</script>

<Modal on:close>
  <fieldset class="poll">
    <p>
      <input type="text" name="question" placeholder="The question is…" bind:value={ question }>
    </p>
    <textarea name="options" placeholder="One possible answer per line" bind:value={ options }></textarea>
    <div class="buttons">
      <button name="save" class="btn alt" {disabled} on:click={() => savePoll()}>Start now</button>
    </div>
  </fieldset>
</Modal>

<style>
.poll input, textarea {
  font-family: Pegasus;
  border-bottom: 1px solid #555;
  width: 100%;
  padding: 10px 3px;
  font-size: 18px;
}
input:focus, textarea:focus {
  background-color: var(--stone);
}
textarea {
  resize: none;
  height: 120px;
  margin-bottom: 10px;
}
.buttons {
  margin-bottom: 0;
}
button {
  margin: 0;
}
</style>
