<script>
  const localPng = fileName => `/assets/email-preferences/${fileName}.png`;
</script>

<div class="body">
  <img src={localPng("laptop_newsletter")} alt="footer laptop" />
</div>

<style>
  .body {
    & img {
      max-width: 100%;
      max-height: 100%;
      object-fit: scale-down;
    }
  }
</style>
