<script>
  import Navigation from "../../../components/Navigation";
  import ThinkinList from "../../../components/list/ThinkinList";
</script>

<style>
  main {
    width: 100%;
    height: 100%;
    padding:0 0 0 180px;    
  }
  header {
    display: grid;
    grid-template-columns: 180px auto 130px;
    padding: 0;
    border-bottom: 1px solid rgba(255,255,255,.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:40px;
    z-index: 3;
  }
  header a {
    grid-column: 3;
    margin: 5px;
    height: 30px;
    padding: 5px 10px;
  }
</style>
  
<svelte:head>
  <title>Galapagos :: Archived ThinkIns</title>
</svelte:head>

<main>
  <header>
    <span class="logotype">Galapagos</span>
    <a class="btn primary" href="/host/thinkin/new">New ThinkIn</a>
  </header>

  <Navigation />
  <ThinkinList columnToFilter="status" filterValue="archived" />

</main>