<script>
import { DateTime } from "luxon"
import { Modal } from "@galapagos/svelte-components";
import { session } from "./session";
import { timestamp } from "../../common/helpers";
import { user } from "../../stores/sessionStore";

let show_warnings = false;
let disabled = true;
let warnings = [];
let logged_error = false;

export let connected;
export let hidden;

export function log(message, error) {
  if (typeof message !== "string") {
    message = message.detail.message;
  }
  warnings.unshift({
    ts: DateTime.now().ts,
    message,
    error
  });
  disabled = false;
  if (error) {
    logged_error = true;
    console.error(message);
  }
}

export function error(message) {
  log(message, true);
}

function collectStats() {
  session.getStats().then((stats) => {
    if (!stats) {
      log("No stats available");
    } else {
      log(`Audio sent ${stats.audio.packetsSent} packets (${stats.audio.bytesSent} bytes), lost ${stats.audio.packetsLost} packets`);
      log(`Video sent ${stats.video.packetsSent} packets (${stats.video.bytesSent} bytes), lost ${stats.video.packetsLost} packets`);
      log(`Frame rate ${stats.video.frameRate}`);
    }
    warnings = warnings;
  });
}

function exportLog() {
  const filename = $user.name.replace(" ", "-").toLowerCase() + "-" + DateTime.now().ts + ".csv";
  const log_text = "Time,Message\n" + warnings.map((warning) => `${warning.ts},"${warning.message}"`).join("\n");
  var element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(log_text));
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  show_warnings = false;
}

function showModal() {
  logged_error = false; 
  show_warnings = true;
}

$: width = Math.min(600, window.innerWidth - 20);
</script>

{#if !hidden}
<button name=debug
  class="tooltip bottom" 
  data-text="Show debug window"
  disabled={disabled}
  on:click={showModal}>
  {#if logged_error}
    <img src="/assets/debug-error.svg" alt="" />
  {:else if connected === false}
    <img src="/assets/wifi-off.svg" alt="" />
  {:else}
    <img src="/assets/debug.svg" alt="" />
  {/if}
</button>
{/if}

{#if show_warnings}
<Modal width={width} on:close={() => show_warnings = false}>
  <div class="main">
    <h2>Here’s what’s been happening</h2>
    <div class="scroll">
      <table>
      {#each warnings as { ts, message, error }}
        <tr class:error><td>{timestamp(ts).substr(12)}</td><td>{message}</td></tr>
      {/each}
      </table>
    </div>
  </div>
  <footer>
    <button name="check" on:click={collectStats}>Check connection</button>
    <button class="btn primary" on:click={exportLog}>Export</button>
  </footer>
</Modal>
{/if}

<style>
  button[name=debug] {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    padding: 0;
    opacity: .9;
  }
  button[disabled] {
    opacity: 0;
    pointer-events: none;
  }
  button:hover {
    opacity: 1;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .main {
    padding: 0 2px;
  }
  h2 {
    font-weight: normal;
    font-size: 16px;
    color: #444;
    padding: 12px 10px 10px;
    margin: 0;
  }
  .scroll {
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  table {
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    background: #f6f6f8;
  }
  tr {
    border-top: 1px solid #fff;
    color: #444;
  }
  tr.error {
    color: red;
  }
  td {
    vertical-align: top;
    padding: 4px 0 6px 0;
  }
  td:first-child {
    padding: 4px 10px 6px;
  }
  footer {
    border-top: 1px solid #eee;
    padding: 10px 8px;
    display: flex;
    justify-content: space-between;
  }
  button[name=check] {
    color: var(--kelp);
  }
</style>
