import { writable } from "svelte/store";

const DEFAULT_SETTINGS = {
	video_on: true,
	audio_on: true,
	chat_on: false,
	captions_on: true,
	astons_on: false,
	chat_max: false,
	volume: 10,
	font_size: 3,
	recording: false,
	camera_id: null,
	mic_id: null,
};

let settings = localStorage.getItem("settings");
if (settings) settings = JSON.parse(settings);
else settings = DEFAULT_SETTINGS;

function saveSettings(field) {
	Object.assign(settings, field);
	localStorage.setItem("settings", JSON.stringify(settings));
}

export const video_on = writable(settings.video_on);
video_on.subscribe(value => saveSettings({video_on: value}));

export const audio_on = writable(settings.audio_on);
audio_on.subscribe(value => saveSettings({audio_on: value}));

export const chat_on = writable(settings.chat_on);
chat_on.subscribe(value => saveSettings({chat_on: value}));

export const captions_on = writable(settings.captions_on);
captions_on.subscribe(value => saveSettings({captions_on: value}));

export const chat_max = writable(settings.chat_max);
chat_max.subscribe(value => saveSettings({chat_max: value}));

export const astons_on = writable(settings.astons_on);
astons_on.subscribe(value => saveSettings({astons_on: value}));

export const volume = writable(settings.volume);
volume.subscribe(value => saveSettings({volume: value}));

export const font_size = writable(settings.font_size);
font_size.subscribe(value => saveSettings({font_size: value}));

export const recording = writable(settings.recording)
recording.subscribe(value => saveSettings({recording: value}));

export let camera_id = writable(settings.camera_id);
camera_id.subscribe(value => saveSettings({camera_id: value}));

export let mic_id = writable(settings.mic_id);
mic_id.subscribe(value => saveSettings({mic_id: value}));
