<script>
  import { createEventDispatcher } from "svelte";
  import { handleTimeFormatting } from "../../common/helpers";

  export let isRecording = false;
  export let name;
  export let startDate;

  const dispatch = createEventDispatcher();
  const { DATE, TIME, WD, YYYY } = handleTimeFormatting(startDate);

  let is_open = false;

  function toggle() {
    is_open = !is_open;
    dispatch("click");
  }
</script>

<style>
  .title {
    font-size: 24px;
    font-family: Pegasus, serif;
    font-weight: normal;
    line-height: 1.1;
    margin: 4px 0 0;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 40px;
    position: relative;
    height: 30px;
    max-width: 100%;
    display: inline-block;
  }
  .title::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(/assets/caret-white.svg);
    position: absolute;
    right: 5px;
    top: 3px;
    transform: transition 200ms;
  }
  .open .title::after {
    transform: rotate(180deg);
  }
  .details {
    color: white;
    font-size: 14px;
    opacity: 0.8;
    margin: 0;
  }
  .recording {
    font-family: Koopman;
    background-color: red;
    color: white;
    padding: 4px 5px 3px;
    border-radius: 3px;
    font-size: 13px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
  }
  .notRecording {
    display: none;
  }
  .about {
    display: none;
    font-size: 20px;
    line-height: 20px;
  }
  @media (max-width: 599px) {
    .title-bar {
      text-align: center;
    }
    .name {
      display: none;
    }
    .about {
      display: inline-block;
    }
  }
</style>

<button class="title-bar" class:open={is_open} on:click={toggle}>
  <h1 class="title">
    <span class={isRecording ? 'recording' : 'notRecording'}>REC</span>
    <span class="name">{name}</span>
    <span class="about">About</span>
  </h1>
  {#if startDate}<p class="details">{WD} {DATE} {YYYY}, {TIME}</p>{/if}
</button>
