var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Duration } from "luxon";
import { fetchData } from "../helpers";
export const MIME_TYPE = {
    VTT: "text/vtt",
    JSON: "text/json",
    MP4: "video/mp4",
    PNG: "image/png",
};
export const RECAP_ACTION = {
    UPLOAD: "upload",
    DELETE: "delete",
};
export function getFileSrc(file, fallbackSrc = "") {
    if (!(file instanceof File)) {
        return fallbackSrc;
    }
    const url = window.URL || window.webkitURL;
    return url.createObjectURL(file);
}
function fileFromString(contents, mimeType) {
    return new File(contents.split(""), "temporary.name", { type: mimeType });
}
export function transcriptFileFromString(contents) {
    return fileFromString(contents, "transcript.vtt", MIME_TYPE.VTT);
}
export function parseTranscript(fileContent) {
    /* this parser is not up to spec in various ways
    https://www.w3.org/TR/webvtt1/
    From the top of my head:
    - assumes that cue identifiers are numbers
    - no support for comments, region blocks, style blocks etc.
    - Speakers in Zoom are just prepended as part of the text, not using voice spans. So our parser will never be able to tell the difference between these two:
    
    1
    00:00:00.000 --> 00:00:01.000
    Peter Pan: I said, I am a real boy
  
    2
    00:00:02.000 --> 00:00:03.000
    He said: I am not a real boy
  
    Zoom does serve transcripts in JSON format through the developer API, so that could be an alternative to parsing vtt
    */
    const STATEMENT_SEPARATOR = /\s*\d+\s*(?=\d{2,}:\d{2}:\d{2}\.\d{3} --> \d{2,}:\d{2}:\d{2}\.\d{3})/;
    const CUE_TIMINGS = /\d{2,}:\d{2}:\d{2}\.\d{3} --> \d{2,}:\d{2}:\d{2}\.\d{3}/;
    const WEBVTT_TIMESTAMP_G = /\d{2,}:\d{2}:\d{2}\.\d{3}/g;
    // For speaker, see issue above
    const SPEAKER = /^(\s*[^:]+:)/;
    const sliceSubstring = (searchTerm, statement) => {
        const result = (statement.match(searchTerm) || [""])[0];
        const remainder = statement.replace(result, "");
        return [result, remainder];
    };
    return fileContent
        .replace(/WEBVTT\s*/, "")
        .split(STATEMENT_SEPARATOR)
        .reduce((statements, statement) => {
        if (!statement) {
            return statements;
        }
        const [cueTimings, remainingStatement] = sliceSubstring(CUE_TIMINGS, statement.trim());
        const [startISO, endISO] = cueTimings.match(WEBVTT_TIMESTAMP_G);
        const start = Duration.fromISOTime(startISO).toMillis();
        const end = Duration.fromISOTime(endISO).toMillis();
        const time = { start, end };
        const [speaker, utterance] = sliceSubstring(SPEAKER, remainingStatement);
        const parsedStatement = { time, speaker: speaker.replace(":", "").trim(), utterance: utterance.trim() };
        return [...statements, parsedStatement];
    }, []);
}
export function transcriptFileStringFromData(transcriptData) {
    const msToZoom = ms => Duration.fromMillis(ms).toFormat("hh:mm:ss.SSS");
    const transcriptFileContents = `WEBVTT
  
${transcriptData
        .map(({ time, speaker, utterance }, index) => `${index + 1}\n${msToZoom(time.start)} --> ${msToZoom(time.end)}\n${speaker ? speaker + ": " : ""}${utterance}`)
        .join("\n\n")}\n`;
    return transcriptFileContents;
}
export function parseZoomCommentsFile(fileContent) {
    // If anyone types a syntactically correct Zoom timestamp at the start of a line in a multiline comment, a crack will open in the earth, swallowing whole cities and dividing nations. But sometimes you just have to take a chance in life.
    return fileContent.split(/\r?\n(?=\d\d:\d\d:\d\d\s)/).map(rawComment => {
        const startISO = rawComment.split(/\s/, 1)[0];
        const start = Duration.fromISOTime(startISO).toMillis();
        const remainingComment = rawComment.replace(startISO, "");
        const speaker = remainingComment.split(/:\s/, 1)[0].trim();
        const utterance = remainingComment.replace(speaker, "").replace(":", "").trim();
        return { time: { start }, speaker, utterance, isHighlighted: false };
    });
}
export function makeJSONFile(comments) {
    return __awaiter(this, void 0, void 0, function* () {
        const fileContents = JSON.stringify(comments);
        return fileFromString(fileContents, MIME_TYPE.JSON);
    });
}
export function fetchJSONFile(url) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield fetch(url, { mode: "cors", Accept: MIME_TYPE.JSON });
        return res.json();
    });
}
export function uploadRecapAsset(file, thinkinId) {
    return __awaiter(this, void 0, void 0, function* () {
        const fileExtensions = {
            [MIME_TYPE.VTT]: "vtt",
            [MIME_TYPE.JSON]: "json",
            [MIME_TYPE.MP4]: "mp4",
            [MIME_TYPE.PNG]: "png",
        };
        const params = new URLSearchParams({
            file_mime_type: file.type,
            file_extension: fileExtensions[file.type],
        });
        const { fields, postUrl, path, getUrl } = yield fetchData(`/thinkins/${thinkinId}/recap/uploadPermissions?${params}`);
        const formData = new FormData();
        Object.keys(fields).forEach(key => {
            formData.append(key, fields[key]);
        });
        // Actual file has to be appended last.
        formData.append("file", file);
        yield fetch(postUrl, {
            method: "POST",
            body: formData,
        });
        return { path, url: getUrl };
    });
}
export function resyncStatementList(statements, resyncFromMs, resyncToMs, upperBoundaryMs) {
    const differential = resyncToMs - resyncFromMs;
    const newStatements = statements.reduce((collectedStatements, thisStatement) => {
        const newStart = thisStatement.time.start + differential;
        if (newStart > upperBoundaryMs) {
            return collectedStatements;
        }
        if (thisStatement.time.start < resyncFromMs && thisStatement.time.start - differential > resyncToMs) {
            // discard statements falling in the gap
            return collectedStatements;
        }
        if (thisStatement.time.start < resyncFromMs) {
            return [...collectedStatements, thisStatement];
        }
        const newTime = Object.assign(Object.assign({}, thisStatement.time), { start: newStart });
        if (thisStatement.time.end) {
            newTime.end = thisStatement.time.end + differential;
        }
        const newStatement = Object.assign(Object.assign({}, thisStatement), { time: newTime });
        return [...collectedStatements, newStatement];
    }, []);
    return newStatements;
}
