<script>
  import { createEventDispatcher, onDestroy, onMount, tick } from "svelte";
  import ParticipantsDropdown from "../participants/ParticipantsDropdown";
  import { astons_on, chat_max, camera_id } from "../../stores/settings";
  import { user } from "../../stores/sessionStore";
  import { timestamp } from "../../common/helpers";

  import { session } from "./session";

  export let uuid;
  export let participant;
  export let index;
  export let host = false;
  export let canUnmute = false

  export let x;
  export let y;
  export let width = 100;

  export function getIndex() {
    return index;
  }

  export function getAge() {
    return Date.now() - added_at;
  }

  function play() {
    console.debug(timestamp(), uuid, participant.name, "tile.play()", participant.hasVideo);
    if (is_playing) {
      console.warn("**already playing");
      return Promise.resolve();
    }
    if (participant.uuid == $user.uuid) {
      console.debug(timestamp(), uuid, participant.name, "**starting and publishing my own camera");
      return session.startCamera(element, $camera_id)
        .then(() => {
          console.debug(timestamp(), "camera ok");
          is_playing = true;
          session.startStreamingCamera()
            .catch(handleError);
        });
    } else 
    if (participant.hasVideo) {
      console.warn(timestamp(), uuid, participant.name, "subscribing to stream");
      return session.subscribe(uuid, element, "camera")
        .then(() => {
          console.warn("now we're playing");
          is_playing = true;
        }).catch(handleError);
    } else {
      console.warn(timestamp(), uuid, participant.name, "waiting for stream");
      return Promise.resolve();
    }
  }

  function stop() {
    if (!is_playing) {
      console.warn("WARNING: no video to pause");
      return Promise.resolve();
    }
    if (is_me) {
      console.debug(timestamp(), uuid, participant.name, "stopping tile");
      dispatch("stop", { uuid: uuid, type: "camera" });
      is_playing = false;
    } else {
      console.debug(timestamp(), uuid, participant.name, "unsubscribing from participant");
      return session.unsubscribe(uuid, "camera")
        .then(() => {
          is_playing = false;
        });
    }
  }

  export function paused() {
    return !is_playing;
  }

  const dispatch = createEventDispatcher();

  let element;
  let actual_width;
  let dropdown_open = false;
  let added_at;
  let is_playing = false;
  let has_video = false;

  $: is_me = uuid == $user.uuid;

  $: updateStream(participant.hasVideo);

  let tagline;
  if (!participant) console.error("tile has no participant attached", uuid);

  if (participant.name) {
    let tags = [];
    if (participant.job_title) tags.push(participant.job_title);
    if (participant.location) tags.push(participant.location);
    tagline = tags.join(", ");
  }

  $: initials = participant.name && participant.name.split(" ").map(word => word.charAt(0)).join("").toUpperCase();
  $: size = actual_width < 200 ? "small" : actual_width < 400 ? "medium" : "large";
  $: volume = setVolume(participant.micLevel);
  $: tile_class = `tile tile-${ index + 1 } ${ size } ${ volume }`;
  $: tile_style = `width:${ width }px;height:${ width }px;transform:translate(${ x }px, ${ y }px)`;

  function updateStream() {
    if (!added_at || participant.hasVideo == undefined) return console.warn("cannot update stream yet");
    if (participant.hasVideo == has_video) return;
    has_video = participant.hasVideo;
    console.warn(`Tile: ${participant.name}  streaming changed to ${has_video} is_playing=${is_playing}`);
    if (is_me) {
      console.warn("my video updated", has_video);
      return;
    }
    if (!has_video && is_playing) return console.warn("WARNING: video has stopped");
    if (has_video && !is_playing) {
      console.warn("starting to subscribe");
      session.subscribe(uuid, element, "camera")
        .then(() => {
          console.log("setting is_playing true");
          is_playing = true;
        });     
    }
  }

  function toggleVideo() {
    console.debug(timestamp(), uuid, participant.name, "toggling video", participant.hasVideo);
    if (participant.hasVideo) play();
    else stop(); // is this right?
  }

  function handleAudioClick(event) {
    if (!host && !canUnmute ) return console.warn("NOT THE HOST");
    if (participant.hasAudio) dispatch("mute", uuid);
    else dispatch("unmute", uuid);
  }

  function setVolume(level) {
    if (level < .33) return "quiet";
    else if (level > .9) return "loud";
    else return "average";
  }

  function openDropdown(event) {
    if (!host) return; // not nec - what about messaging?
    dropdown_open = true;
  }

  function closeDropdown() {
    dropdown_open = false;
  }

  function handleError(err) {
    dispatch("error", {  message: err });
  }

  onMount(() => {
    console.warn(timestamp(), uuid, participant.name, "mounted tile", "streaming=", participant.hasVideo);
    added_at = Date.now();
    play();
  });

  onDestroy(() => {
    if (is_playing) stop();
    console.debug(timestamp(), uuid, participant.name, "destroyed tile");
  });

</script>

<style>
  .tile {
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 300ms, transform 160ms, width 160ms, height 160ms;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
  }
  .video {
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }
  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 4;
  }
  button {
    width: 32px;
    height: 32px;
    padding: 5px;
    border-radius: 50%;
    background: rgba(0,0,0,.2);
    opacity: 0;
    transition: opacity 200ms;
    position: relative;
    pointer-events: none;
  }
  .host button {
    pointer-events: auto;
  }
  button[name=audio][value=true] {
    opacity: 1;
  }
  button[name=hand] {
    background-color: #E04600;
    padding: 4px;
    opacity: 1;
  }
  button img {
    width: 100%;
    height: 100%;
  }

  .aston {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    padding: 30px 5px 15px 20px;
    z-index: 3;
    transition: opacity 200ms;
    pointer-events: none;
    background: linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.3));
    opacity: 0;
    transition: opacity 200ms;
    text-shadow: 0 0 2px rgba(0,0,0,.5);
  }
  .astons .aston,
  .tile:hover .aston,
  .tile:hover button,
  .tile.hasAudio .aston {
    opacity: 1;
  }

  button[name=audio]::before {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    left: -2px;
    top: -2px;
    width: 36px;
    height: 36px;
    transition: transform 160ms;
    opacity: .66;
    z-index: -1;
  }
  .tile.hasAudio button[name=audio] {
    background-color: #4D4D4D;
  }
  .tile.hasAudio button[name=audio]::before {
    background-color: var(--moss);
  }
  .average button[name=audio]::before {
    transform: scale(1.3);
  }
  .loud button[name=audio]::before {
    transform: scale(1.7);
    opacity: .5;
  }
  .aston span {
    display: block;
    font-family: Pegasus;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
  }
  .small .aston {
    padding: 15px 5px 8px 10px;
  }
  .small .aston span {
    font-size: 16px;
    line-height: 18px;
  }
  .small .aston span:last-child {
    display: none;
  }
  .large .aston {
    padding-bottom: 20px;
  }
  .aston span:last-child {
    color: var(--moss);
  }
  .tile:not(.hasVideo) .aston {
    opacity: 1;
  }
  @media (min-width: 600px) {
    .large header {
      padding: 15px;
    }
    .large .aston {
      padding: 30px 5px 20px 30px;
    }
    .large .aston span {
      font-size: 32px;
      line-height: 34px;
    }
    .large button {
      width: 48px;
      height: 48px;
      padding: 10px;
    }
    .large button[name=audio]::before {
      width: 52px;
      height: 52px;
    }
  }
  .host.spotlit::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(255,255,255,.1), rgba(255,255,255,0) 50%, rgba(0,0,0,.4) 90%);
    pointer-events: none;
  }
</style>

<div class={tile_class}
  class:spotlit={participant.spotlit}
  class:hasAudio={participant.hasAudio}
  class:hasVideo={participant.hasVideo}
  class:paused={!is_playing}
  class:astons={$astons_on}
  class:host
  style={tile_style}
  bind:offsetWidth={actual_width}
  data-id={uuid}>

  {#if !participant.hasVideo}
    {#if participant.profile_picture} 
      <img src={participant.profile_picture} alt="" class="avatar">
    {:else}
      <img src="/assets/avatar.png" alt="" class="avatar faceless">
    {/if}
  {/if}

  <header>
    <button name="audio" value="{participant.hasAudio}" title="Audio" on:click|stopPropagation={handleAudioClick}>
      <img alt="" src="/assets/mic_{ participant.hasAudio ? 'on' : 'off' }.svg" />
    </button>

    {#if host}
      <button name="hand" value={participant.handUp} title="Raised hand" hidden={!participant.handUp}>
        <img alt="" src="/assets/footericons/hand-up.svg" />
      </button>

      <button name="options" on:click={openDropdown}>
        <img alt="" src="/assets/kebab.svg" />
      </button>
    {/if}
  </header>

  <div class="aston">
    <span>{participant.name}</span>
    <span>{tagline}</span>
  </div>

  <div class="video" bind:this={element}></div>

  {#if dropdown_open}
    <ParticipantsDropdown
      {uuid}
      {host}
      top={55}
      right={15}
      icons={true}
      on:pin
      on:unpin
      on:mute={handleAudioClick}
      on:unmute={handleAudioClick}
      on:spotlight
      on:view
      on:message
      on:eject
      on:click_outside={closeDropdown} />
  {/if}
</div>     
