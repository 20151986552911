<div class="modal-group">
  <div class="modal-back-wall" />
  <div class="thank-you-container">
    <div class="thank-you-content">
      <div class="image-container">
        <svg
          class="paper-plane-image"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 748 359.5"
          style="enable-background:new 0 0 748 359.5;"
          xml:space="preserve">
          <g id="Layer_1">
            <path
              id="illustration"
              class="st0"
              d="M332.5,310.1c8.5,0,17,1.5,24.9,4.6c1.4,0.4,2.6,1.3,3.4,2.6c0.8,1.3,1,2.9,0.6,4.3
                      c-0.9,3-3.9,4.7-6.9,3.9c-3.6-1.4-7.4-2.5-11.3-3.3c-0.9-0.2-1.8-0.3-2.8-0.5l-0.5-0.1l-1.3-0.1c-2-0.2-4.1-0.3-6.1-0.3
                      c-3.1,0-5.6-2.5-5.6-5.6C327,312.6,329.4,310.1,332.5,310.1z M389.7,327.7c8.4-0.1,16.8,0.6,25.1,1.9c1.4,0.4,2.6,1.3,3.4,2.6
                      c0.8,1.3,1,2.9,0.6,4.3c-0.9,2.9-3.9,4.7-6.9,3.9c-0.5-0.1-0.9-0.1-1.4-0.2l-1.2-0.2l-0.8-0.1c-0.5-0.1-0.9-0.1-1.4-0.2
                      c-1.9-0.2-3.9-0.4-5.8-0.5c-3.9-0.3-7.7-0.4-11.6-0.3c-3.1,0-5.6-2.5-5.6-5.6C384.1,330.3,386.6,327.8,389.7,327.7L389.7,327.7z
                       M469.1,334.6l0.5-0.1c0.4-0.1,0.9-0.2,1.3-0.2c0.9-0.1,1.7-0.3,2.5-0.5c1.8-0.4,3.6-0.9,5.3-1.6c0.5-0.2,0.9-0.3,1.4-0.5l0.5-0.2
                      c0.4-0.2,0.2-0.1-0.5,0.2c0.2,0.1,1.1-0.5,1.4-0.6c0.4-0.2,0.9-0.4,1.3-0.6c1.6-0.8,3.2-1.8,4.8-2.8c2.7-1.5,6.1-0.6,7.7,2
                      c1.5,2.7,0.6,6.1-2,7.7c-7.2,4.7-15.5,7.6-24.1,8.4c-3.1,0-5.6-2.5-5.6-5.6C463.5,337.2,466,334.7,469.1,334.6z M537.3,315.6
                      c0.2-0.1,0.3-0.1,0.5-0.2c1.9-0.8,3.6-1.8,5.3-3c1.6-1.3,3-2.7,4.3-4.3c1.3-1.7,2.3-3.5,3.2-5.4c0.7-1.3,1.9-2.2,3.4-2.6
                      c1.5-0.4,3-0.2,4.3,0.6c1.3,0.7,2.2,1.9,2.6,3.4c0.3,1.5,0.1,3-0.6,4.3c-3.9,8.5-11.1,15-20,18.1c-1.5,0.4-3,0.2-4.3-0.6
                      c-1.3-0.7-2.2-2-2.6-3.4C532.7,319.6,534.4,316.5,537.3,315.6L537.3,315.6z M581.8,194.2c1.5-0.4,3-0.2,4.3,0.6
                      c1.2,0.8,2.1,2,2.6,3.4c2.4,6.2,3.5,12.9,3.1,19.6c-0.1,3.1-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6c0.1-2.5,0-5.1-0.3-7.6
                      c-0.5-3.1-1.3-6.1-2.4-9c-0.4-1.5-0.2-3,0.6-4.3C579.2,195.6,580.4,194.6,581.8,194.2L581.8,194.2z M545.5,162.9
                      c-2.3-1.3-4.7-2.4-7.1-3.5c0,0-0.6-0.3-0.8-0.4c-0.6-0.2-1.1-0.5-1.7-0.7c-1.1-0.5-2.3-0.9-3.4-1.3c-4.8-1.7-9.7-3-14.7-3.9
                      c-3-0.8-4.7-3.9-3.9-6.9c0.9-2.9,3.9-4.7,6.9-3.9c10.6,2,20.9,5.6,30.4,10.8c2.7,1.6,3.6,5,2,7.7
                      C551.6,163.5,548.2,164.4,545.5,162.9z M487.3,186.4c-0.2,1-0.3,1.9-0.5,2.9c-0.1,0.5-0.2,1-0.2,1.4l-0.1,0.7
                      c-0.5,4.1-0.7,8.2-0.8,12.4c0,3.1-2.5,5.6-5.6,5.6c-3.1-0.1-5.6-2.5-5.6-5.6c0.1-8.9,1.1-17.8,3.2-26.5c0.8-3,3.9-4.7,6.9-3.9
                      c2.9,0.9,4.7,3.9,3.9,6.9C488.1,182.3,487.7,184.4,487.3,186.4L487.3,186.4z M522,238.7c-1-0.8,0.6,0.4,0.7,0.5
                      c0.5,0.4,1,0.7,1.6,1c0.9,0.6,1.9,1.2,2.8,1.7c1.1,0.6,2.2,1.2,3.3,1.7l0.6,0.3l0.2,0.1c0.5,0.2,1,0.4,1.5,0.6
                      c2.2,0.8,4.5,1.4,6.8,1.9c0.5,0.1,1.1,0.2,1.6,0.3c0.3,0,0.5,0.1,0.8,0.1h0.1c1.2,0.1,2.5,0.2,3.7,0.3c3.1,0,5.6,2.5,5.6,5.6
                      c0,3.1-2.5,5.6-5.6,5.6c-11.5-0.5-22.6-4.6-31.7-11.8c-2.2-2.2-2.2-5.7,0-7.9C516.3,236.6,519.7,236.6,522,238.7z M594.9,246.6
                      c2.5-1.1,4.8-2.4,7.1-3.8c0.4-0.2,0.7-0.5,1.1-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.7-0.6,1.4-1.2,2-1.9c1.4-1.4,2.8-2.8,4.2-4.2
                      c2.3-2.1,4.7-4.1,7.2-5.9c1.3-0.8,2.9-1,4.3-0.6c1.4,0.4,2.6,1.3,3.4,2.6c0.8,1.3,1,2.9,0.6,4.3c-0.5,1.4-1.4,2.6-2.6,3.4l-0.3,0.3
                      c-0.3,0.2-0.5,0.4-0.7,0.6c-0.6,0.5-1.1,0.9-1.7,1.4c-0.9,0.7-1.7,1.5-2.6,2.3c-1.4,1.4-2.8,2.8-4.2,4.1c-3.7,3.4-7.9,6.2-12.6,8.2
                      c-1.3,0.7-2.9,0.9-4.3,0.6c-1.4-0.4-2.6-1.3-3.4-2.6C591.3,251.6,592.2,248.2,594.9,246.6z M633.2,142.7c3-0.8,6,1,6.9,3.9
                      c3.3,13.2,4.3,26.9,3.1,40.4c-0.1,3.1-2.6,5.5-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6c1.2-12.5,0.4-25.2-2.7-37.4
                      C628.5,146.6,630.3,143.6,633.2,142.7L633.2,142.7z M621.5,110.4c-3-10.6-2.9-21.8,0.3-32.4c0.9-3,3.9-4.7,6.9-3.9
                      c3,0.9,4.7,3.9,3.9,6.9c-0.7,2.4-1.3,4.8-1.7,7.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.5-0.1,1.1-0.2,1.6
                      c-0.1,1.2-0.2,2.5-0.2,3.7c0,2.2,0.1,4.5,0.4,6.7c0,0.3,0.1,0.9,0.2,1.1c0.1,0.6,0.2,1.2,0.4,1.8c0.3,1.2,0.6,2.4,0.9,3.6
                      c0.8,3-0.9,6.1-3.9,6.9C625.4,115.1,622.4,113.4,621.5,110.4L621.5,110.4z M651.3,23.5l0.7-0.3h0l0.3-0.1c0.6-0.3,1.1-0.5,1.7-0.8
                      c0.9-0.4,1.8-0.9,2.6-1.4c1.2-0.7,2.4-1.6,3.5-2.4c3.5-2.5,7.3-4.5,11.3-6c1.5-0.4,3-0.2,4.3,0.6c1.3,0.7,2.2,2,2.6,3.4
                      c0.4,1.5,0.2,3-0.6,4.3c-0.8,1.2-2,2.1-3.4,2.6c-0.2,0.1-0.4,0.2-0.7,0.3l0,0l-0.3,0.1c-0.6,0.3-1.1,0.5-1.7,0.8
                      c-0.9,0.4-1.8,0.9-2.6,1.4c-1.2,0.7-2.4,1.6-3.5,2.4c-3.5,2.5-7.3,4.5-11.3,6c-1.5,0.4-3,0.2-4.3-0.6c-1.3-0.8-2.2-2-2.6-3.4
                      c-0.4-1.5-0.2-3,0.6-4.3C648.7,24.9,649.9,24,651.3,23.5L651.3,23.5z" />
          </g>
          <g id="Layer_2">
            <path
              class="st0"
              d="M387,157.6c-0.1,0-0.2-0.1-0.3-0.2C276.2,120.2,162.3,94,46.7,79.2c-3.7-1.7-7.4-3.6-11.1-5.3
                      c-5.5-2.5-10.4,4-7.8,7.9c-1.2,2.1-0.8,4.7,1,6.2c63.8,62.7,119.4,133.1,165.6,209.7c3.7,6.2,13.4,0.5,9.7-5.7
                      c-0.8-1.4-1.7-2.7-2.5-4c13.5-7.9,26.3-16.9,38.4-26.8l0.7-0.6l46.4,41.9c0.3,1,0.5,2.1,0.8,3.1c0.2,0.7,0.6,1.3,1,1.9
                      c2,2.4,5.6,2.8,8.1,0.8c1.1-0.1,2.1-0.6,2.9-1.3c2.2-2,2.4-5.4,0.4-7.6c4-15.8,7.9-31.7,11.9-47.5c0.9-3.8,1.9-7.5,2.8-11.3
                      c0-0.2,0.1-0.3,0.1-0.5c3.5-12.8,5.8-25.9,6.8-39.2c0.3-0.1,0.6-0.3,0.9-0.4c1.4-0.6,2.7-1.3,4.1-2c2.9-1.5,5.7-3.1,8.5-4.8
                      c5.6-3.3,11.1-6.9,16.7-10.3c10.7-6.6,22.2-13.1,34.9-14.7c3.1-0.1,5.5-2.5,5.6-5.6C392.5,160.1,390,157.6,387,157.6z M107.4,109.9
                      c26.9,8.8,53.4,18.6,79.4,29.7c-0.1,0.7,0,1.3,0.2,1.9c3.3,8.2,7.4,16.1,12.2,23.6C169.4,145.3,138.8,126.9,107.4,109.9
                      L107.4,109.9z M234.2,251.5c-12.2,10-25.1,19-38.7,27c-41.8-67.1-90.9-129.3-146.3-185.7c77.2,36.9,150.2,82.1,217.7,134.7
                      C255.4,234.6,244.4,242.6,234.2,251.5z M253.8,200.9c-6.6-12.6-12.5-25.4-17.8-38.5c3.9,1.9,7.8,3.8,11.7,5.7
                      c10.2,15.6,19.6,31.8,28.1,48.4c-1.1,0.3-2.1,0.8-3,1.6c-6.2-4.8-12.3-9.6-18.5-14.3C254.5,202.9,254.3,201.8,253.8,200.9z
                       M236.3,190.8c-7.6-5.5-15.3-10.8-23-16.1l0,0c2.1-1.7,2.7-4.7,1.3-7.1c-3.5-4.6-6.7-9.4-9.6-14.4c-1.3-2.4-2.6-4.7-3.8-7.2
                      c6.7,3,13.4,6,20.1,9.1C225.8,167.2,230.8,179.1,236.3,190.8z M249.4,253.5c7.4-6.1,15.3-11.6,23.7-16.5c2.5,15.5,5.5,30.8,9.1,46
                      C271.2,273.2,260.3,263.4,249.4,253.5z M293.2,281c-3.7-16-6.9-32.1-9.5-48.4c4.8,10.2,9.4,20.4,13.5,30.9c0.1,0.2,0.2,0.3,0.3,0.4
                      C296,269.6,294.6,275.3,293.2,281z M301.9,246.1c-9.9-23.3-21.4-45.8-34.5-67.5c8,4.4,16,8.8,24,13.4c0.3,0.1,0.5,0.3,0.8,0.4
                      c-0.3-0.1-0.5-0.3-0.8-0.4c-1.7-0.8-3.7-0.8-5.4,0.1c-2.6,1.6-3.5,5-2,7.7c6.5,13.2,13.1,26.4,19.6,39.6
                      C303.1,241.7,302.5,243.9,301.9,246.1z M310.3,203.2C310.3,203.2,310.3,203.2,310.3,203.2c-0.5,6.4-1.4,12.8-2.7,19.1
                      c-4.6-9.3-9.3-18.7-13.9-28c-0.3-0.7-0.8-1.2-1.4-1.7C298.3,196.1,304.4,199.5,310.3,203.2C310.3,203.2,310.3,203.2,310.3,203.2
                      L310.3,203.2z M339.9,177.7c-7.2,4.7-14.7,9-22.4,12.9c-1.2,0.5-2.4,1.1-3.5,1.6c-27.3-16.5-55.3-31.7-84-45.4
                      c-1.1-2.1-3.4-3.2-5.8-2.8c-35.1-16.5-71.1-30.9-107.9-43.1c85.1,14.5,168.9,35.2,251,62C357.8,167.1,348.6,172,339.9,177.7z" />
          </g>
        </svg>
        <svg
          on:click
          on:keydown
          class="clos-btn"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12">
          <path
            id="close"
            d="M1045.344,719.383l4.547,4.547-1.453,1.453-4.547-4.547-4.547,4.547-1.453-1.453,4.546-4.547-4.546-4.547,1.453-1.453,4.547,4.547,4.547-4.547,1.453,1.453Z"
            transform="translate(-1037.89 -713.384)"
            fill="#666" />
        </svg>
      </div>
      <p class="thank-you-message">Thank you for signing up for the Sensemaker.</p>
      <p class="check-email-message">
        Please check your email (and junk) and add <a href="mailto:editor@newsroom.tortoisemedia.com"
          >editor@newsroom.tortoisemedia.com</a> to your contacts so the emails go straight to your inbox.
      </p>
      <a class="home-page-btn" target="_parent" href="https://www.tortoisemedia.com/">
        Back to homepage
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
          <path
            id="arrow"
            d="M18.082,10.936l3.435,3.439H4v1.25H21.5l-3.435,3.439L19,20l5-5.01L19.017,10Z"
            transform="translate(-4 -10)"
            fill="#fff" />
        </svg>
      </a>
    </div>
  </div>
</div>

<style>
  @font-face {
    font-family: Wolpe Pegasus;
    src: url(https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8)
        format("woff"),
      url(https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff2?19e86ec7fc8b96cbc0df2ba57fa1b485)
        format("woff2"),
      url(https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf?d44ed54f4974ec7cc8d03bba4c6e8244)
        format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: Wolpe Pegasus;
    src: url(https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?2f9c3518322062ef2031f6cee92945f7)
        format("woff"),
      url(https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff2?0cef5c6407280431c6afdb57fe9071f4)
        format("woff2"),
      url(https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf?e91ef41d7574bca90ca027d38ddd8067)
        format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  .modal-group {
    display: block;
    text-align: left;
  }

  .thank-you-container {
    background-color: #dce459;
    width: 568px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .thank-you-content {
    padding: 32px 32px 40px 32px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .paper-plane-image {
    width: 240px;
    height: 122px;
  }

  .clos-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    cursor: pointer;
  }

  .thank-you-message {
    font: normal normal normal 42px/50px Wolpe Pegasus;
    color: #333333;
    margin-top: 16px;
  }

  .check-email-message {
    font: normal normal normal 24px/34px Wolpe Pegasus;
    color: #333333;
    margin-bottom: 39px;
    margin-top: 16px;
  }

  .home-page-btn {
    padding: 15px 32px 20px 32px;
    border-radius: 34px;
    background-color: #006666;
    color: white;
    font: normal normal normal 24px/30px Wolpe Pegasus;
    text-decoration: none;
  }

  .home-page-btn:hover {
    background-color: #004d4d;
  }

  .home-page-btn:focus {
    background-color: #006666;
  }

  .modal-back-wall {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    position: fixed;
    top: 0%;
    left: 0%;
  }

  @media only screen and (max-width: 670px) {
    .thank-you-container {
      width: 320px;
    }
    .image-container {
      display: block;
    }

    .paper-plane-image {
      width: 145px;
      height: 75px;
      margin-left: 24px;
    }

    .thank-you-message {
      font: normal normal normal 32px/40px Wolpe Pegasus;
    }

    .check-email-message {
      font: normal normal normal 20px/24px Wolpe Pegasus;
    }

    .home-page-btn {
      padding: 12px 25px 17px 24px;
      border-radius: 34px;
      font: normal normal normal 20px/24px Wolpe Pegasus;
    }
  }
</style>
