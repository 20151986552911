<script>
  export let benefits;
  export let membershipGuide;
  export let displayMemberType;
</script>

<div class="page">
  <h1>As a <b>{displayMemberType}</b>, here's what you get:</h1>
  <ul>
    {#each benefits as benefit (benefit)}
      <li>
        <img src="/assets/checkmark.svg" alt="checkmark" class="checkmark" />
        {@html benefit}
      </li>
    {/each}
  </ul>
  <p>
    We'd love to help you make the most of your membership, so we've put together this little how-to guide to help you
    on your way:
  </p>
  <ul>
    {#each membershipGuide as { title, subtitle, img } (img.src)}
      <li>
        <img src={img.src} alt={img.alt} class="membership-guide-image" />
        <div>
          <h3 class="membership-guide-title">{title}</h3>
          <p>{@html subtitle}</p>
        </div>
      </li>
    {/each}
    <li>
      <img src="/assets/6-member-journey.png" alt="gift-box" class="membership-guide-image" />
      <div>
        <h3 class="membership-guide-title">Enjoy 20% off in the Tortoise shop</h3>
        <p>
          Members can enjoy an exclusive 20 per cent discount on almost everything in the Tortoise shop – this includes
          gift sets and memberships, The Tortoise Quarterly and signed books. Simply head to the shop and use the code
          SHELLOUT at checkout to receive your discount.
        </p>
        <a style="color:#006666;" href="https://www.tortoisemedia.com/shop/" target="_blank"
          >Visit the Tortoise shop →</a>
      </div>
    </li>
  </ul>
</div>

<style>
  .page {
    background-color: var(--stone);
    padding: var(--spacer-large) var(--spacer-medium) var(--spacer-s2) var(--spacer-medium);
    min-height: 100vh;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li,
  p,
  h1,
  img {
    text-align: left;
    font-family: pegasus, serif;
    font-weight: lighter;
  }

  p,
  li {
    font-size: 20px;
    line-height: 28px;
  }

  li {
    margin-bottom: var(--spacer-tiny);
  }

  .checkmark {
    height: 20px;
    margin-right: var(--spacer-small);
  }

  .membership-guide-title,
  .membership-guide-image {
    width: 105px;
    height: auto;
  }

  h3 {
    min-width: 205px;
  }

  li a {
    color: black;
  }
</style>
