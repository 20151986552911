<!-- NOTE membership type for the upgrade scheme is different to membership type overall. Consult the spreadsheet from hell https://docs.google.com/spreadsheets/d/1wtDLIPX1UWWicu4DjuA1Cc4k8IMU3_VsqHPMLTXwB5I/edit#gid=0 -->
<script>
  import { FullScreenSpinner } from "@galapagos/svelte-components";
  import { user } from "../../../stores/sessionStore";
  import { fetchData } from "../../../common/helpers";
  /**
   * @deprecated [#1] will be deleted in 2023
   * */
  $: upgradeMembershipType = $user.upgrade_membership_type || { upgradeFrom: "", upgradeTo: "" };

  let isLoading = true;
  let membershipUpdatedTo = "";
  let upgradeFromMembership = "";
  let isUpgraded = false;
  let isEligibleToUpgrade = false;
  const completedTasks = {};

  async function getCurrentStatus() {
    const data = await fetchData(`/public/users/${$user.uuid}/redeem-status`);

    completedTasks.hasAttendedEnoughThinkIns = data.has_attended_enough_thinkins;
    completedTasks.hasReferredEnoughUsers = data.has_referred_enough_users;
    completedTasks.hasSubmittedSurvey = data.has_submitted_survey;

    isUpgraded = data.status === "upgrade in progress" || data.status === "upgraded";
    isEligibleToUpgrade = !isUpgraded && !!upgradeMembershipType.upgradeTo;

    upgradeFromMembership = upgradeMembershipType.upgradeFrom;
    membershipUpdatedTo = isUpgraded ? upgradeMembershipType.upgradeTo : "";
    isLoading = false;
  }
  $: if (upgradeMembershipType) {
    getCurrentStatus();
  }
</script>

{#if isLoading}
  <FullScreenSpinner />
{:else}
  <slot {completedTasks} {membershipUpdatedTo} {upgradeFromMembership} {isUpgraded} {isEligibleToUpgrade} />
{/if}
