const IMG = {
  MAIL: { src: "/assets/4-Member-journey.png", alt: "mail" },
  SPEECH: { src: "/assets/2-Member-journey.png", alt: "Speech bubbles" },
  TICKET: { src: "/assets/3-Member-journey.png", alt: "Tickets" },
};

const STEP = {
  EMAIL: {
    img: IMG.MAIL,
    title: "Choose the emails you want",
    subtitle:
      "We’re here as an antidote to the daily noise, not to add to it. Manage which Tortoise Sensemakers and membership emails you receive by heading to the <a style='color:#006666' href='https://www.tortoisemedia.com/profile/newsletters/' >website<a/> or <a style='color:#006666' href='https://members.tortoisemedia.com/t/mytort/emailpreferences' >app</a> and selecting ‘My Tortoise’ – ‘My newsletters’.",
  },

  BOOK_EVENTS_BASIC: {
    img: IMG.TICKET,
    title: "Book Tortoise Live events",
    subtitle:
      "Your membership includes free and discounted tickets for you and a guest to all our live events. We host events online, in our London newsroom, and across the UK, giving you the option to attend digitally, or in-person with a complimentary drink from our open bar. Book your place <a style='color:#006666' href='https://www.tortoisemedia.com/book/' >on our website</a> or <a style='color:#006666' href='https://members.tortoisemedia.com/t/live/com.tortoisemedia.tortoise.timelinelive_thinkin_upcoming'>app</a> and join in along with Tortoise editors, members, and special guest speakers.",
  },
  BOOK_EVENTS_NEWSROOM: {
    img: IMG.TICKET,
    title: "Book Tortoise Live events",
    subtitle:
      "As a Newsroom Member, you can join in on any of our live events along with Tortoise editors, members and special guest speakers. We host events online, in our London newsroom, and across the UK, giving you the option to attend digitally, or in-person with a complimentary drink from our open bar. Book your place <a style='color:#006666' href='https://www.tortoisemedia.com/book/' >on our website</a> or in the app section titled <a style='color:#006666' href='https://members.tortoisemedia.com/t/live/com.tortoisemedia.tortoise.timelinelive_thinkin_upcoming' >‘ThinkIns’</a>.",
  },

  BOOK_EVENTS_FOUNDING: {
    img: IMG.TICKET,
    title: "Book Tortoise Live events",
    subtitle:
      "As a Founding Member, you can join in on any of our live events along with Tortoise editors, members and special guest speakers. We host events online, in our London newsroom, and across the UK, giving you the option to attend digitally, or in-person with a complimentary drink from our open bar. Book your place <a style='color:#006666' href='https://www.tortoisemedia.com/book/' >on our website</a> or in the app section titled <a style='color:#006666' href='https://members.tortoisemedia.com/t/live/com.tortoisemedia.tortoise.timelinelive_thinkin_upcoming' >‘ThinkIns’</a>.",
  },
  BOOK_EVENTS_FOT: {
    img: IMG.TICKET,
    title: "Book Tortoise Live events",
    subtitle:
      "As a Friend of Tortoise, you can join in on any of our live events along with Tortoise editors, members and special guest speakers. We host events online, in our London newsroom, and across the UK, giving you the option to attend digitally, or in-person with a complimentary drink from our open bar. Book your place <a style='color:#006666' href='https://www.tortoisemedia.com/book/' >on our website</a> or in the app section titled <a style='color:#006666' href='https://members.tortoisemedia.com/t/live/com.tortoisemedia.tortoise.timelinelive_thinkin_upcoming' >‘ThinkIns’</a>. ",
  },
  SHARE_FRIENDS: {
    img: IMG.SPEECH,
    title: "Share Tortoise with your friends",
    subtitle:
      "You can choose the lucky recipients of your four complimentary digital memberships in your ‘Membership’ page within My Tortoise on the <a style='color:#006666' href='https://www.tortoisemedia.com/my-account/' >website</a>. ",
  },
};

export const MEMBERSHIP_VALUES = {
  BASIC_MEMBERS: [STEP.EMAIL, STEP.BOOK_EVENTS_BASIC],
  STUDENTS: [STEP.EMAIL, STEP.BOOK_EVENTS_BASIC],
  NEWSROOM_MEMBERS: [STEP.EMAIL, STEP.BOOK_EVENTS_NEWSROOM, STEP.SHARE_FRIENDS],
  FOUNDING_MEMBERS_QUARTERLY: [STEP.EMAIL, STEP.BOOK_EVENTS_FOUNDING],
  FRIEND_OF_TORTOISE: [STEP.EMAIL, STEP.BOOK_EVENTS_FOT, STEP.SHARE_FRIENDS],
  FOUNDING_MEMBERS: [STEP.EMAIL, STEP.BOOK_EVENTS_FOUNDING],
  STANDARD_MEMBERS: [STEP.EMAIL, STEP.BOOK_EVENTS_BASIC],
  B2C_PRO: [STEP.EMAIL, STEP.BOOK_EVENTS_BASIC],
};
