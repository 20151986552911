<script>
  import { onMount } from "svelte";
  export let flourishId;
  let wrapper;

  onMount(() => {
    wrapper.setAttribute("data-src", `visualisation/${flourishId}?877034`)
    const script = document.createElement("script");
    script.setAttribute("src", "https://public.flourish.studio/resources/embed.js")
    wrapper.appendChild(script);
  });
</script>

<div class="flourish-embed" bind:this={wrapper} />
