import { MEMBERSHIP_TYPE_PUBLIC_FACING_NAME, USER_ENTITLEMENT_CATEGORY } from "./types";
export const THINKIN_DEFAULT_LOCATION = "22 Berners Street";
export const PIANO_RID_GROUPS = {
    staging: {
        DIGITAL_RID_GROUP: ["BRVICV9P", "BRQLA2RX", "BRAD4H94", "BRHIH1DZ", "BRFZMIAN"],
        NEWSROOM_RID_GROUP: ["BRSC45S4", "BRN6XNN9", "BRR0WOCB"],
        FOT_RID_GROUP: ["BRTX4Q0V", "BRBDPXWY", "BRDUUNGD", "BR3UUNMD", "BRY1DTL1", "BR4OJ3U9"],
        STUDENT_RID_GROUP: ["BRDFZUQJ"],
        NETWORK_RID_GROUP: ["BRPABJFR"],
        COALITION_RID_GROUP: ["BRM2546M"],
    },
    production: {
        DIGITAL_RID_GROUP: [
            "BR2BPEVX",
            "BRDA0JD5",
            "BRRJ0JM4",
            "BRWNTK6D",
            "BRI12O9L",
            "BR1BVGQJ",
            "BR20MC9V",
            "BR27DMGJ",
            "BR38GD61",
            "BR4UPSY7",
            "BR6DJGB4",
            "BR92LJLL",
            "BRA8WPMO",
            "BRC93XZH",
            "BRCP2BYH",
            "BRDDS5EU",
            "BRDT1114",
            "BRF6OCK4",
            "BRHQX3HW",
            "BRIRC7LK",
            "BRJ2C3GG",
            "BRJACOU8",
            "BRK6TOMA",
            "BRKMFJI0",
            "BRKU39OZ",
            "BRL68CCF",
            "BRLBAS1Y",
            "BRN7Q6NG",
            "BRNL9Y1V",
            "BRNY2AG2",
            "BROD1XHY",
            "BRPCK7LH",
            "BRPXIYN4",
            "BRTC0J90",
            "BRTZ3TNW",
            "BRV6O0Y9",
            "BRWAL70N",
            "BRYAJCD1",
            "BRYBIS0C",
            "BRYJ919A",
            "BRYSDX5R",
            "BRZA70HO",
            "BRZFZR4P",
        ],
        NEWSROOM_RID_GROUP: [
            "BRL3HLNT",
            "BR0B9IAM",
            "BRRZKB3Z",
            "BR07AXNL",
            "BR4GLL2E",
            "BR6FNYOD",
            "BR7OJE00",
            "BRM2SJK5",
            "BRRTRC3Z",
            "BRVE0GVY",
        ],
        FOT_RID_GROUP: ["BR6PX151", "BRVNNYMB", "BR66EEZD", "BRL2NQVZ", "BRDDS5EU", "BRYAJCD1"],
        STUDENT_RID_GROUP: ["BRDFZUQJ"],
        NETWORK_RID_GROUP: ["BRZWTWZS"],
        COALITION_RID_GROUP: ["BRWAL70N"],
    },
};
export const FOUNDING_MEMBERS_EXPORT_ID = {
    staging: "URZX1O6P8GRI",
    production: "URMV43C3LQX2",
};
export const DESCRIBE_RID = {
    // staging - apart from upgradeFrom and upgradeTo, these values are largely arbitrary - you can mess about with them to test different UIs.
    BRVICV9P: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRQLA2RX: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRAD4H94: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRHIH1DZ: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRFZMIAN: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRSC45S4: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRN6XNN9: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRR0WOCB: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRB9B3II: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRTX4Q0V: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRBDPXWY: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRDUUNGD: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BR3UUNMD: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRY1DTL1: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BR4OJ3U9: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRDTANEY: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    // production
    BR07AXNL: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BR0B9IAM: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BR4GLL2E: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BR6FNYOD: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BR7OJE00: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRL3HLNT: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRM2SJK5: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRRTRC3Z: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRRZKB3Z: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRVE0GVY: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BR66EEZD: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BR6PX151: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRDFZUQJ: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.STUDENT,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.STUDENT,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRL2NQVZ: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRVNNYMB: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOT,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.FOT,
    },
    BRZWTWZS: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.COMMUNITY_NETWORK,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.COMMUNITY_NETWORK,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BR1BVGQJ: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BR20MC9V: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BR27DMGJ: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BR2BPEVX: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BR38GD61: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BR4UPSY7: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BR6DJGB4: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BR92LJLL: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRA8WPMO: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRC93XZH: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRCP2BYH: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRDA0JD5: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRDDS5EU: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRDT1114: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRF6OCK4: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRHQX3HW: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRI12O9L: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRIRC7LK: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRJ2C3GG: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRJACOU8: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRK6TOMA: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRKMFJI0: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRKU39OZ: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRL68CCF: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRLBAS1Y: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRN7Q6NG: {
        upgradeFrom: "",
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRNL9Y1V: {
        upgradeFrom: "",
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.STAFF,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.STAFF,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.NEWSROOM,
    },
    BRNY2AG2: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BROD1XHY: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRPCK7LH: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRPXIYN4: {
        upgradeFrom: "",
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRRJ0JM4: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRTC0J90: {
        upgradeFrom: "",
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRTZ3TNW: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRV6O0Y9: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRWAL70N: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRWNTK6D: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRYAJCD1: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRYBIS0C: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRYJ919A: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
    BRYSDX5R: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRZA70HO: {
        upgradeFrom: "",
        upgradeTo: "",
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.GUEST,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL,
    },
    BRZFZR4P: {
        upgradeFrom: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.DIGITAL,
        upgradeTo: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.NEWSROOM,
        userFacingNameIfFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
        userFacingNameIfNotFoundingMember: MEMBERSHIP_TYPE_PUBLIC_FACING_NAME.FOUNDING,
        entitlementCategoryIfNotFoundingMember: USER_ENTITLEMENT_CATEGORY.DIGITAL_PLUS,
    },
};
