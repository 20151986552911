<script>
  // this component replicates the browser confirm() function but uses house styling
  import { Modal } from "@galapagos/svelte-components";
  let isOpen = false;
  let decision;

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function getDecision() {
    while (!decision) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(10);
    }
    return decision === "continue";
  }

  function reset() {
    isOpen = false;
    decision = null;
  }

  export const confirm = async () => {
    isOpen = true;
    const willContinue = await getDecision();
    reset();
    return willContinue;
  };
</script>

<Modal closed={!isOpen}>
  <div class="main">
    <slot />
  </div>
  <div class="buttons">
    <button on:click={() => (decision = "continue")}>Continue</button>
    <button on:click={() => (decision = "cancel")}>Cancel</button>
  </div>
</Modal>

<style>
  .main {
    margin-top: 32px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
</style>
