<script>
  import GTM from "../../components/gtm/GTM.svelte";
  import GTMNoScript from "../../components/gtm/GTM-noscript.svelte";
  import { checkGuest, initialiseTP } from "../../common/authoriser";
  import { Modal } from "@galapagos/svelte-components";

  let error;

  const guestKey = new URLSearchParams(window.location.search).get("key");
  const isRedeemPlusOne =
    new URLSearchParams(window.location.search).get("invitee_first_name") &&
    new URLSearchParams(window.location.search).get("verificationToken");
  let promise;

  if (guestKey && !isRedeemPlusOne) {
    promise = checkGuest();
  } else {
    promise = initialiseTP();
  }
</script>

<svelte:head>
  <GTM />
</svelte:head>
<GTMNoScript />

{#if error}
  <Modal>
    <p>
      {error}
    </p>
  </Modal>
{/if}

{#await promise then result}
  <slot />
{/await}

<style>
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
</style>
