<script>
  import { Modal } from "@galapagos/svelte-components";
  import { makeId, selectImageFromComputer, uploadImage } from "../../common/helpers";

  export let value;
  export let height = 200;
  export let placeholder;

  let show_toolbar;
  let is_bold, is_italic, is_bullets, is_link, is_heading;
  let can_undo, can_redo;
  let linking = false;
  let selected_range;
  let selected_url = "";
  let block_format = "p";
  let uploader;

  function update() {
    const selection = document.getSelection();
    if (!selection || selection.rangeCount == 0) {
      return;
    }
    selected_range = selection.getRangeAt(0);
    let el = selected_range.startContainer;
    if (el.nodeType == 3) {
      el = el.parentNode;
    }
    is_bold = el.closest("b,strong") !== null;
    is_italic = el.closest("i,em") !== null;
    is_bullets = el.closest("ul") !== null;
    is_link = el.closest("a") !== null;
    is_heading = el.closest("h1,h2,h3") !== null;
    can_undo = document.queryCommandEnabled("undo");
    const block_element = el.closest("h2,p");
    if (!block_element) {
      formatBlock("p");
    } else {
      block_format = block_element.tagName.toLowerCase();
    }
  }

  function refocus() {
    const selection = window.getSelection();
    selection.empty();
    selection.addRange(selected_range);
  }

  function format(event) {
    const name = event.target.name;
    switch (name) {
      case "bold":
        document.execCommand("bold");
        break;
      case "italic":
        document.execCommand("italic");
        break;
      case "bullets":
        document.execCommand("insertUnorderedList");
        break;
      case "style":
        formatBlock(event.target.value);
        break;
    }
    update();
  }

  function formatBlock(tag) {
    refocus();
    document.execCommand("formatBlock", false, tag);
    block_format = tag;
  }

  function link() {
    let el = selected_range.startContainer;
    if (el.nodeType == 3) {
      el = el.parentNode;
    }
    const a = el.closest("a");
    selected_url = (a && a.getAttribute("href")) || "";
    linking = true;
  }

  function applyLink() {
    let url = selected_url.trim();
    if (!url.startsWith("http") && !url.startsWith("mailto:")) {
      url = "https://" + url;
    }
    linking = false;
    refocus();
    document.execCommand("createLink", false, url);
  }

  function clearLink() {
    selected_url = "";
    applyLink();
  }

  function insertImage(e) {
    selectImageFromComputer(e, e => {
      const image = e.target.result;
      uploadImage(image, `pic-${makeId(9)}`, "thinkins-posters").then(url => {
        console.log("uploaded image to url:", url);
        if (!selected_range) {
          // TODO: make a selection
        }
        refocus();
        document.execCommand("insertImage", false, url);
      });
    });
  }

  function undo() {
    document.execCommand("undo");
    update();
  }

  function redo() {
    document.execCommand("redo");
    update();
  }
</script>

<div
  on:click={() => (show_toolbar = true)}
  on:keydown={() => (show_toolbar = true)}
  on:mouseleave={() => (show_toolbar = false)}
  class="container"
  class:showtoolbar={show_toolbar}>
  <div class="toolbar">
    <!-- svelte-ignore a11y-no-onchange -->
    <select size="1" name="style" bind:value={block_format} on:change={format}>
      <option value="p">Paragraph</option>
      <option value="h2">Heading</option>
    </select>
    <button name="bold" on:click|preventDefault={format} class:selected={is_bold}>B</button>
    <button name="italic" on:click|preventDefault={format} class:selected={is_italic}>I</button>
    <button name="link" on:click|preventDefault={link} class:selected={is_link} />
    <button name="bullets" on:click|preventDefault={format} class:selected={is_bullets} />
    <button name="image" on:click|preventDefault={() => uploader.click()} />
    <span>
      <button name="undo" disabled={!can_undo} on:click|preventDefault={undo} />
      <button name="redo" disabled={!can_redo} on:click|preventDefault={redo} />
    </span>
  </div>

  <div
    class="html"
    contenteditable="true"
    style={"height:" + height + "px"}
    bind:innerHTML={value}
    on:mouseup={update}
    on:keyup={update} />

  {#if placeholder && !show_toolbar && !value}
    <span class="placeholder">{placeholder}</span>
  {/if}

  <input type="file" accept=".jpg, .jpeg, .png" on:change={insertImage} bind:this={uploader} />
</div>

{#if linking}
  <Modal width="450" on:close={() => (linking = false)}>
    <div class="linker">
      <label for="url-input">Enter a link</label>
      <input id="url-input" type="url" name="url" placeholder="https://" bind:value={selected_url} />
      <p>
        <button class="btn primary" name="apply" on:click|preventDefault={applyLink}>Done</button>
        <button name="clear" on:click|preventDefault={clearLink}>Clear</button>
      </p>
    </div>
  </Modal>
{/if}

<style>
  .container {
    position: relative;
  }
  .toolbar {
    display: flex;
    align-items: flex-start;
    height: 31px;
    width: 100%;
    opacity: 0.4;
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
  .showtoolbar .toolbar {
    background: #001a38;
    opacity: 1;
  }
  .toolbar button {
    color: #fff;
  }
  .html {
    width: 100%;
    padding: 8px;
    font-family: Pegasus;
    font-size: 18px;
    background: #fff;
    z-index: 0;
    position: relative;
  }
  select {
    background-color: transparent;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px 20px 5px 10px;
    width: 120px;
    height: 31px;
    background-image: url(/assets/caret-white.svg);
    background-position: right 4px;
    color: #fff;
  }
  button {
    padding: 5px 10px;
    height: 30px;
    min-width: 30px;
    margin: 0;
    background-image: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  button:hover {
    background-color: rgba(0, 0, 0, 0.2);
    outline: 1px solid rgba(255, 255, 255, 0.2);
  }
  button[name="bold"] {
    font-weight: bold;
    font-family: Pegasus;
  }
  button[name="italic"] {
    font-style: italic;
    font-family: Pegasus;
    margin-right: 4px;
  }
  button[name="link"] {
    background-image: url(/assets/link.svg);
  }
  button[name="bullets"] {
    background-image: url(/assets/bullets.svg);
    margin-left: 20px;
  }
  button[name="image"] {
    background-image: url(/assets/slideicons/media.svg);
    margin-left: 15px;
  }
  button[name="undo"] {
    background-image: url(/assets/undo.svg);
    background-size: 24px 24px;
    background-position: center top;
  }
  button[name="redo"] {
    background-image: url(/assets/redo.svg);
    background-size: 24px 24px;
    background-position: center top;
  }
  .toolbar span {
    margin-left: auto;
  }
  button.selected {
    background-color: #fff;
    color: var(--midnight);
  }
  button[name="bullets"].selected {
    background-image: url(/assets/bullets-selected.svg);
  }
  button[name="link"].selected {
    background-image: url(/assets/link-selected.svg);
  }
  .linker {
    padding: 20px 20px 0;
  }
  .linker label {
    display: block;
    text-align: left;
    width: 100%;
  }
  .linker input {
    border: 1px solid #bbb;
    width: 100%;
    margin-top: 5px;
  }
  .linker button {
    margin-right: 20px;
  }
  input[type="file"] {
    display: none;
  }
  .html :global(h2) {
    margin: 8px 0;
  }
  .html :global(img) {
    max-width: 90%;
    margin: 10px 0;
  }
  .html :global(p) {
    display: block;
  }
  .placeholder {
    color: #999;
    position: absolute;
    top: 30px;
    left: 5px;
    width: 95%;
    font-family: Pegasus, serif;
    font-size: 18px;
    pointer-events: none;
    z-index: 0;
  }
</style>
