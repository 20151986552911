<script>
  import { createEventDispatcher, onDestroy, onMount, tick } from "svelte";
  import { fade, fly, slide } from "svelte/transition";
  import Cookies from "js-cookie";
  import { DateTime } from "luxon";
  import { handleError } from "../../common/errorHandling";
  import { saveResource, timestamp } from "../../common/helpers";
  import { closeSocket, getUserAudioInput } from "./captionUtils";

  import { session } from "./session";
  import { layouts, skinny_layout } from "./layouts";
  import { user, thinkin, participants } from "../../stores/sessionStore";
  import { privateMessages, messages, totalUnreadMessages  } from "../../stores/chatStore";

  import {
    video_on,
    captions_on,
    audio_on,
    chat_on,
    chat_max,
    volume,
    camera_id,
    mic_id,
    recording
  } from "../../stores/settings";

  import slideLeftRight from "../../common/slideLeftRight";

  import Avatar from "./Avatar";
  import Badge from "../badge/Badge";
  import CameraButton from "./CameraButton";
  import Chat from "../chat/Chat";
  import Clips from "./Clips";
  import Composer from "../chat/Composer";
  import Dropdown from "../dropdown/Dropdown";
  import Entry from "./Entry";
  import Feedback from "./Feedback";
  import Fullscreen from "./Fullscreen";
  import MicButton from "./MicButton";
  import { Modal } from "@galapagos/svelte-components";
  import Participants from "../participants/Participants";
  import Poll from "./Poll";
  import PollSetup from "./PollSetup";
  import Profile from "../participants/Profile";
  import Screenshare from "./Screenshare";
  import Settings from "./Settings";
  import Slides from "./Slides";
  import Timer from "./Timer";
  import Tile from "./Tile";
  import Title from "./Title";
  import Warnings from "./Warnings";

  import { fetchData } from "../../common/helpers";
  
  export let host = false;
  export let canUnmute = false;

  const dispatch = createEventDispatcher();

  const DEFAULT_LAYOUT = "layout-auto";

  let me = $user; // the participant for the current user
  participants.set([me]);

  let connected;
  let session_active = false;
  let video_enabled = true;
  let master_host;
  let active_speaker;
  let caption_text;
  let caption_text_timeout;
  let participant_toast = "";
  let participants_open = false;
  let composing_message = false;
  let clips_open = false;
  let queue_open = false;
  let screensharing = false;
  let screenshare;
  let archiveId = $thinkin.archive_id;
  let spotlit;
  let show_settings = false;
  let show_profile;
  let show_entry = true;
  let show_options = false;
  let device_access_allowed = false;
  let camera_preview;
  let avatar;
  let audio_blocked = false;
  let slides_open = false;
  let spotlit_message;
  let waiting_for_host = false;
  let ejecting;

  let starting_poll = false;
  let open_poll;
  let poll_votes = [];

  let debugging = false; // TODO: get from query param
  
  /*let open_poll = {
    id: 7,
    options: ["Sure", "WTF?"],
    question: "Are we human or are we dancer?",
    usage: "during"
  };
  let poll_votes = [{
    poll_id:7, uuid: "xyx", response: "Sure",
  },{
    poll_id:7, uuid: "adsfds", response: "Sure",
  },{
    poll_id:7, uuid: "xyq453x", response: "WTF?",
  }];*/

  let selected_layout = layouts[0];
  let selected_tiles = [];
  let stage_width;
  let layouts_open = false;
  let stored_layout = {};
  let warnings;
  let clips;
  let force_updated = false;

  let tiles = [];
  let tile_count = 0;
  let tile_layout;
  let page_count = 0;
  let page_size = 12;
  let current_page = 0;

  let hand_is_up = false;
  let is_recording = $thinkin.recording;
  let recipient;
  let most_recently_joined;

  const mobile = "ontouchstart" in window;

  const fake_users = [
    {name:"Imy Harper", picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2021/03/IH.jpg"},
    {name:"Alexi Mostrous", picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2021/03/AMO.jpg"},
    {name:"Edith", picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2020/09/EP.jpg"},
    {name:"Luke Gbedemah", picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2021/03/LG.jpg"},
    {name:"Jon Jones",picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2021/03/JJ.jpg"},
    {name:"Gurjinder Dhaliwal",picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2021/03/GD.jpg"},
    {name:"Liv Leight",picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2021/03/LL.jpg"},
    {name:"Giles Whittell",picture: "https://www.tortoisemedia.com/wp-content/uploads/sites/3/2020/09/GW.jpg"},
  ];

  window.afu = function(max) {
    fake_users.slice(0, max || fake_users.length).forEach((fake, index) => $participants.push({
      uuid: "fake" + index,
      name: fake.name,
      profile_picture: fake.picture,
      is_fake: true
    }));
  }

  let screen_width;
  $: narrow = screen_width < 600;

  $: canMute = host || canUnmute || (me && me.hasAudio) || $thinkin.audience == "team";

  $: show_captions = $thinkin.status == "Live" && $thinkin.captioning && $captions_on && caption_text;

  $: connection_count = $participants.filter((p) => p.connected).length;
  $: participant_count = $participants.filter((participant) => !participant.left_at).length;
  $: message_count = $messages.length;

  $: managing_clips = master_host && master_host.uuid == $user.uuid;

  let speakers = [];
  let audience = [];
  const AUDIENCE_TILES_PER_PAGE = 6; // actually should depend on screen size
  let audience_page_index = 0;
  let audience_open = true;
  $: audience_page_count = Math.ceil(audience.length / AUDIENCE_TILES_PER_PAGE);
  $: audience_page_index = Math.min(audience_page_index, audience_page_count);
  $: audience_start_index = audience_page_index * AUDIENCE_TILES_PER_PAGE;
  $: audience_tiles = audience.slice(audience_start_index, audience_start_index + AUDIENCE_TILES_PER_PAGE);

  function joinSession() {
    console.log("joining", me);
    session_active = true;

    if (host || $thinkin.audience == "team") {
      master_host = $participants.find((p) => { return p.role == "master"; });
      if (!master_host) {
        console.log("could not find existing master, so taking over");
        takeover();
      } else {
        if (master_host.uuid == me.uuid) console.log("%cI am already the master","color:purple");
        else console.log(`%cthe master is ${master_host.uuid} ${master_host.name}`,"color:purple");
      }
    } else {
      waiting_for_host = true;
    }

    return session.join(me.hasCamera, me.hasMic, host)
      .then((connections) => {
        warnings.log(`Joined session with ${ connections.length } connections`);
        connections.forEach((c) => { connectionAdded(c, true)});
        // Start render loop
        loop();
        if (host) {
          watchActiveSpeaker();
        }
      })
      .catch((err) => {
        warnings.error("Failed to initialize session");
        console.error(err);
      });
  }

  function startSessionEventHandlers() {
    // Start Tokbox event handlers
    session.listen({
      // me
      "connected": sessionConnected,
      "disconnected": sessionDisconnected,

      // other people
      "join": connectionJoined,
      "added": connectionAdded,
      "removed": connectionRemoved,
      "updated": streamUpdated,
      "audioblocked": () => { audio_blocked = true },

      "staging": updateStaging,
      "screenshareStarted": screenshareStarted,
      "screenshareEnded": screenshareEnded,

      "mute": muteMe,
      "unmute": unmuteMe,
      "audioLevel": audioLevelUpdated,

      "message": receiveChatMessage,
      "starred": starChatMessage,
      "spotlightmessage": showSpotlitMessage,
      "unspotlightmessage": hideSpotlitMessage,

      "toggleRiseHand": toggledRiseHand,

      "startTranscribe": startTranscribe,

      "start": thinkinStarted,
      "end": thinkinEnded,
      "settingsUpdated": thinkinSettingsUpdated,

      "pollstart": showPoll,
      "pollvote": recordVote,
      "pollend": hidePoll,

      "error": handleSessionError,
    });

    if (host) {
      session.listen({
        "takeover": handleTakeover,
      });
    }
  }

  // Respond to events from Tokbox

  function sessionConnected(connections) {
    console.debug(timestamp(),"sessionConnected()");
    warnings.log(`Session connected`);
    connected = true;
  }

  function sessionDisconnected(event) {
    console.debug(timestamp(),"sessionDisconnected() " + event.reason);
    warnings.log(`Session disconnected for reason: ${event.reason}`);
    if (event.reason == "networkDisconnected") {
      console.warn("Your network connection terminated.");
      document.body.classList.add("disconnected");
    }
    connected = false;
  }

  function connectionAdded(person, silent) {
    // A new user has connected to the session. Fires for yourself.
    console.warn("ADDING", person);
    const uuid = person.uuid;
    let participant = getParticipant(uuid);
    if (!participant) {
      participant = person;
      $participants.push(person);
      console.debug(timestamp(), uuid, participant.name, "connected (new)");
    } else {
      console.debug(timestamp(), uuid, participant.name, "ignoring existing connection");
      if (participant.left_at) delete participant.left_at;
    }
    if (!silent && !participant_toast) {
      participant_toast = `${participant.name} joined`;
      setTimeout(() => {participant_toast = ""}, 3000);
    }
    updateRecentlyJoined();
  }

  // streamPropertyChanged OR streamCreated/Destroyed OR signalled camera availability
  function streamUpdated(data) {
    const uuid = data.uuid;
    let participant = $participants.find((p) => p.uuid == uuid)
    if (!participant) return warnings.log(`Property changed but could not find participant ${ uuid }`);

    for (let name in data) {
      if (name !== "uuid") {
        participant[name] = data[name];
      }
    }

    if ("spotlit" in data) {
      updateSpotlight(participant);
    }
    touch(participant);
  }

  function connectionRemoved(data) {
    // A user who is not you has disconnected from the session
    const uuid = data.uuid;
    let participant = getParticipant(uuid);
    console.log(`%c ${timestamp()} ${participant.uuid} ${participant.name} disconnected`, "color:orange", data);
    warnings.log(`Connection dropped ${participant.name} (${participant.uuid}`);
    touch(participant, { connected: false, hasCamera: false, hasVideo: false, left_at: Date.now() });
  }

  function invite(event) {
    let uuid = event.detail;
    console.log("inviting", uuid);
    session.signal("invite", {}, uuid);
  }

  function mute(event) {
    const uuid = event.detail;
    let participant = getParticipant(uuid);
    if (!participant) return console.warn(timestamp(),"no participant found to mute", uuid);
    else console.debug(timestamp(),participant.uuid, participant.name, "sending mute signal");
    session.mute(uuid);
  }

  function unmute(event) {
    const uuid = event.detail;
    let participant = getParticipant(uuid);
    if (!participant) return console.warn(timestamp(),"no participant found to unmute", uuid);
    else console.debug(timestamp(),participant.uuid, participant.name, "sending unmute signal");
    session.unmute(uuid);
  }

  // Received a signal to mute my audio
  function muteMe(data) {
    console.info(timestamp(),"muting self", data);
    if (data) {
      const uuid = data.uuid;
      if (uuid !== $user.uuid) {
        return console.warn(timestamp(),"received wrong signal to mute", uuid, $user.uuid, data);
      }
      warnings.log(`Received signal to mute`);
    }
    session.mute(me.uuid);

    // TBD: should this be in session
    if ($thinkin.captioning) {
      closeSocket();
    }
  }

  function unmuteMe(data) {
    console.info(timestamp(), "unmuting self");
    if (data) {
      const uuid = data.uuid;
      if (uuid !== $user.uuid) {
        return console.warn(timestamp(),"unmute signal not for me", uuid, $user.uuid);
      }
      warnings.log(`Received signal to unmute`);
    }
    session.unmute(me.uuid);
    if ($thinkin.captioning) {
      getUserAudioInput();
    }
    if (hand_is_up) toggleHand();
  }

  function unblockAudio() {
    console.log("trying to unblock audio");
    session.unblockAudio();
    audio_blocked = false;
  }

  function audioLevelUpdated(data) {
    let participant = getParticipant(data.uuid);
    if (!participant) return console.warn("audioLevelUpdated: could not find participant", data.uuid);
    participant.micLevel = data.level;
    let index = getTileIndex(data.uuid);
    if (index !== -1) {
      tiles[index].participant = participant; 
    }
  }

  function findLoudestSpeaker() {
    let loudest;
    $participants.forEach((participant) => {
      if (participant.micLevel && (!loudest || participant.micLevel > loudest.micLevel)) {
        loudest = participant;
      }
    });
    return loudest;
  }

  function watchActiveSpeaker() {
    if (!session_active) return;
    let loudest = findLoudestSpeaker();
    if (!loudest) {
      active_speaker = null;
    } else
    if (!active_speaker || loudest.uuid !== active_speaker.uuid) {
      active_speaker = loudest;
      if (managing_clips) {
        clips.add(DateTime.utc().toString(), active_speaker);
      }
    }
    setTimeout(watchActiveSpeaker, 1000);
  }

  function toggledRiseHand(data) {
    const uuid = data.uuid;
    // here we have to update host ui by finding the corrrect icon in the overlay
    let participant = getParticipant(uuid);
    console.debug(timestamp(), participant.uuid, participant.name, "raised hand", data);
    touch(participant, { handUp: data.up });
  }

  function thinkinSettingsUpdated(data) {
    console.debug(timestamp(),"thinkinSettingsUpdated()", data);
    thinkin.set(Object.assign($thinkin, data));
    if ("captioning" in data && me.hasAudio) {
      if ($thinkin.captioning) {
        getUserAudioInput();
      } else {
        closeSocket();
      }
    }
  }

  function startTranscribe(data) {
    const { transcript, speakerName, speakerId } = data.signalData;
    caption_text = `${speakerName}: ${transcript}`;
    clearTimeout(caption_text_timeout);
    caption_text_timeout = setTimeout(() => (caption_text = ""), 5000);
  }

  function toggleShowCaptions() {
    $captions_on = !$captions_on;
  }

  // Chat messages

  function receiveChatMessage(data) {
    console.log(`${timestamp()} received message ${data.id}`, "color:#09c", data);
    const alreadyExists = $messages.filter(m => m.id == data.id)[0];
    if (alreadyExists) {
      return;
    }
    const receivedMessage = data;
    if (receivedMessage.type == "private") {
      const { user_id, name } = receivedMessage;
      privateMessages.update(privateMessages => {
        if (privateMessages[user_id]) {
          privateMessages[user_id].messages.push(receivedMessage);
          privateMessages[user_id].unreadMessages += 1;
        } else {
          privateMessages[user_id] = {};
          privateMessages[user_id].name = name;
          privateMessages[user_id].messages = [receivedMessage];
          privateMessages[user_id].unreadMessages = 1;
        }
        return privateMessages;
      });
    } else {
      messages.set([...$messages, receivedMessage]);
    }
  }

  function starChatMessage(data) {
    const { message_id, starred } = data;
    let index = $messages.findIndex((msg) => msg.id == message_id);
    if (index == -1) console.error("could not find message", message_id, $messages);
    else {
      $messages[index].starred = starred;
      messages.set($messages);
    }
  }

  // Screenshare 

  function toggleScreenShare() {
    if (!host && $thinkin.audience !== "team") return;
    if (me.hasScreenshare) {
      session.stopSharingScreen();
    } else {
      session.startSharingScreen().then(() => {
        me.hasScreenshare = true;
      });
    }
  }

  function screenshareStarted(data) {
    console.log("screensharestarted", data);
    const uuid = data.uuid;
    let participant = getParticipant(uuid);
    if (!participant) return console.warn("could not find participant to share");
    screenshare = participant;
    touch(participant, { hasScreenshare: true }); // is this useful?
    screensharing = true; // ditto?
  }

  function screenshareEnded(data) {
    console.debug(timestamp(),"**screenshareEnded");
    let participant = getParticipant(data.uuid);
    touch(participant, { hasScreenshare: false });
    screensharing = false;
    screenshare = null;
  }

  function getParticipant(uuid) {
    if (!uuid) {
      console.error(timestamp(),"getParticipant(): no uuid passed");
      return;
    }
    return $participants.find((p) => p.uuid == uuid);
  }

  function touch(participant, properties) {
    if (!participant) return console.warn("trying to touch nothing!");
    if (properties) {
      Object.assign(participant, properties);
    }
    const i = $participants.findIndex((p) => p.uuid == participant.uuid);
    if (i == -1) {
      return console.warn(timestamp(),"tried to update non-existent participant", participant);
    }
    // Force a refresh
    $participants[i] = participant;
    if (participant.uuid == me.uuid) me = participant;

    // Update tile if on screen
    let tile_index = tiles.findIndex((t) => t.uuid == participant.uuid);
    if (tile_index !== -1) {
      console.debug(timestamp(),participant.uuid, participant.name, "updating tile");
      tiles[tile_index].participant = participant;
    }
  }

  // TODO: need to secure this API endpoint so that if you're not a host, you can only update your own data
  function updateParticipant(participant, fields) {
    // TODO: parse out the participant fields that it is ok to update
    if (!host && participant.uuid !== me.uuid && $thinkin.audience !== "team") {
      console.error("Not authorized to update", participant.uuid);
      return Promise.resolve();
    }
    return fetch(
      `${process.env.BASE_API_URL}/thinkins/${$thinkin.id}/participants/${participant.uuid}`,
      {
        headers: {
          "content-type": "application/JSON",
          "Authorization": Cookies.get("thinkin_authToken")
        },
        method: "PUT",
        body: JSON.stringify(fields),
      }
    ).catch((error) => {
      console.error(timestamp(),"Error:", error);
    });
  }

  function updateRecentlyJoined() {
    let participant;
    for (let i = $participants.length - 1; i >= 0; i--) {
      if ($participants[i].uuid !== me.uuid && !$participants[i].left_at && $participants[i].profile_picture) {
        participant = $participants[i];
        break;
      }
    }
    most_recently_joined = participant || null;
  }

  function eject(event) {
    if (!host) return;
    const uuid = event.detail.uuid;
    let participant = getParticipant(uuid);
    if (!participant) return console.warn(timestamp(),"no participant found to eject", uuid);
    console.debug(timestamp(),participant.uuid, participant.name, "ejecting participant");
    // request confirmation
    ejecting = participant;
  }

  function ejectParticipant() {
    const uuid = ejecting.uuid;
    ejecting = null; // close the confirmation modal
    session.eject(uuid);
  }

  function togglePeople() {
    participants_open = !participants_open;
    if (narrow && participants_open) chat_on.set(false);
  }

  function toggleChat() {
    chat_on.set(!$chat_on);
    if (narrow && $chat_on) participants_open = false;
  }

  function toggleClips() {
    if (!host) return;
    clips_open = !clips_open;
    if (clips_open) queue_open = false;
  }

  function toggleQueue() {
    queue_open = !queue_open;
    if (queue_open) clips_open = false;
  }

  function toggleCamera() {
    me.hasCamera = !me.hasCamera;
    if (me.hasCamera) {
      session.startCamera().then(() => {
        touch(me, { hasVideo: true });
      }).then(session.startStreamingCamera)
      .then(() => {
        console.log("streaming my camera");
      });
    } else {
      console.warn(timestamp(),"STOPPING CAM");
      session.stopCamera().then(() => {
        touch(me, { hasVideo: false });
      });
    }
    if (managing_clips) {
      console.debug(timestamp(),"checking tiling after toggling camera");
      //checkTiling(me);
    } 
  }

  function toggleMic() {
    if (me.hasAudio) muteMe();
    else if (host || canUnmute) unmuteMe();
    else console.warn("ignoring unmute attempt");
  }

  function toggleHand() {
    hand_is_up = !hand_is_up;
    session.signal("toggleRiseHand",
      {
        uuid: $user.uuid,
        up: hand_is_up,
      });
  }

  // methods for forcing tile addition/removal
  function pin(event) {
    if (!host) return console.warn(timestamp(),"only hosts can pin");
    let uuid = event.detail;
    let participant = getParticipant(uuid);
    console.debug(timestamp(),uuid, participant.name, "pinning");
    session.signal("updated", { uuid, pinned: true });
  }

  function unpin(event) {
    let uuid = event.detail;
    let participant = getParticipant(uuid);
    console.debug(timestamp(),uuid, participant.name, "unpinning");
    session.signal("updated", { uuid, pinned: false });
  }

  function spotlight(event) {
    if (!host) return console.warn("You’re not the boss of me");
    let uuid = event.detail.uuid;
    let participant = getParticipant(uuid);
    session.signal("updated", { uuid, spotlit: !participant.spotlit });
  }
 
  function updateSpotlight(participant) {
    if (!participant.spotlit) return clearSpotlight(participant);
    const uuid = participant.uuid;
    console.debug(timestamp(),"Spotlighting", uuid);
    speakers.forEach((speaker_uuid, index) => {
      let speaker = getParticipant(speaker_uuid);
      if (speaker.pinned) return;
      speaker.spotlit = false;
      speakers.splice(index, 1)
    });

    speakers.push(uuid);
  }

  function clearSpotlight(participant) {
    console.log("clearing spotlight");
    const uuid = participant.uuid;
    let index = speakers.findIndex((speaker_uuid) => speaker_uuid == uuid);
    if (index == -1) return console.warn("failed to remove spotlight", uuid);
    speakers.splice(index, 1);
    console.log("clearing spotlight on", participant);
    if (participant.hasAudio && $thinkin.audience !== "team") session.mute(uuid);
    console.debug(timestamp(),participant.uuid, participant.name, "removing spotlight");
  }

  function viewProfile(event) {
    show_profile = { uuid: event.detail.uuid };
  }

  function spotlightMessage(event) {
    if (!host) return;
    session.signal("spotlightmessage", event.detail);
  }

  function unspotlightMessage() {
    if (!host) return;
    session.signal("unspotlightmessage");
  }

  function showSpotlitMessage(data) {
    spotlit_message = data;
  }

  function hideSpotlitMessage() {
    spotlit_message = null;
  }

  function tileStopped(event) {
    let uuid = event.detail.uuid;
    if (uuid == $user.uuid) {
      console.warn("tile stopped: transferring camera");
      if (session.getCameraElement()) {
        session.setCameraParent(avatar.getElement());
      } else console.warn("I don’t have a camera");
    }
  }

  async function record() {
    let recordingOptions = {
      hasAudio: true,
      hasVideo: true,
      archiveName: $thinkin.name,
      outputMode: "composed",
      session_id: $thinkin.session_id
    };
    const data = await saveResource("POST",`/thinkins/start-recording`, recordingOptions )
    console.debug(timestamp(),"RECORDING DATA", data);
    archiveId = data.archive.id;
  }

  async function stopRecording(archiveId) {
    console.debug(timestamp(),"archive id is ", archiveId);
    await fetchData(`/thinkins/stop-recording?archiveId=${archiveId}`);
  }

  function connectionJoined(data) {
    // check whether user has camera and mic
    const uuid = data.uuid;
   // if (uuid == me.uuid) return console.warn("no need to handle my own join");
    let participant = getParticipant(uuid);
    if (!participant) return console.warn(timestamp(),"connectionJoined(): could not find participant", uuid);
    touch(participant, { hasCamera: data.hasCamera, hasMic: data.hasMic });
    if (host) signalStaging(uuid);
  }

  function handleSessionError(event) {
    warnings.error(event.detail);
  }

  function takeover() {
    if (!host && $thinkin.audience !== "team") return;
    if (master_host) {
      // there is already a master host - need to tell them who the new boss is
      console.log(`%csignalling takeover to other hosts`, "color:purple");
      updateParticipant(master_host, { role: "host" });
      session.signal("takeover", { uuid: me.uuid });
    }
    master_host = me;
    touch(me, { role: "master" });
    updateParticipant(me, { role: "master" }).then(() => {
      console.log("I am now the master");
    });
  }

  function handleTakeover(data) {
    console.log(`%creceived takeover signal. new boss is ${data.uuid}`, "color:purple");
    master_host = getParticipant(data.uuid);
  }

  function signalStaging(uuid) {
    // Tell participants who is currently on stage
    // TODO: pinning
    session.signal("staging", {
      speakers,
      screenshare,
      host: me.uuid,
    }, uuid);
  }

  function updateStaging(data) {
    if (data.host == me.uuid) return;
    speakers = data.speakers;
    screenshare = data.screenshare;
  }

  /* Layout */
  const MIN_STAGE_WIDTH = 400;
  const MIN_TILE_SIZE = 120;
  const SHARE_ASPECT = 16 / 9;
  const FRAMES_PER_SECOND = 60;
  const FRAME_INTERVAL = 1000 / FRAMES_PER_SECOND;

  let looped_at = 0;
  let space_available = true;
  let max_tiles = 4;

  let available_width, available_height;

  $: resized = resizeStage(available_width, available_height, (AUDIENCE_TILES_PER_PAGE + 1) / AUDIENCE_TILES_PER_PAGE);

  function resizeStage(width, height, aspect) {
    const use_skinny = width < MIN_STAGE_WIDTH;
    if (use_skinny || width / height < aspect) {
      height = width / aspect;
    } else {
      width = height * aspect;
    }
    force_updated = true;
  }

  function loop() {
    // bail if session over
    if (!session_active) return;

    requestAnimationFrame(loop);

    if (Date.now() < looped_at + FRAME_INTERVAL) return;
    looped_at = Date.now();

    let updated = false;
    let staged_participants;

    if (force_updated) {
      updated = true;
      force_updated = false;
    }

    if (screenshare) {
      staged_participants = [];
    } else {
      if (speakers.length) {
        staged_participants = speakers.map((uuid) => {
          const participant = getParticipant(uuid);
          if (!participant) console.warn(`Could not find participant for ${ uuid }`);
          return participant;
        });
      } else {
        let pool = $participants
                    .filter((participant) => {
                      return participant.is_fake || participant.hasAudio || participant.hasVideo || participant.hasCamera;
                    });

        staged_participants = pool.slice(0, Math.min(pool.length, 12));
      }
    }
       
    if (staged_participants.length !== tiles.length) updated = true;
    else {
      staged_participants.forEach((participant, index) => {
        if (tiles[index].uuid !== participant.uuid) {
          updated = true;
        }
      });
    }

    if (updated) {
      let new_tiles = staged_participants.map((participant, index) => {
        return {
          uuid: participant.uuid,
          participant,
          index,
        };
      });

      audience = $participants.filter((participant) => {
        if (!participant.is_fake && !participant.hasVideo && !participant.hasCamera && !participant.hasAudio) return false;
        return new_tiles.findIndex((tile) => tile.uuid == participant.uuid) == -1;
      }).map((participant, index) => {
        return {
          uuid: participant.uuid,
          participant,
          index,
        };
      });
      updateStageLayout(new_tiles);
      tiles = new_tiles;
    }
  }

  function updateStageLayout(tiles) {
    const aspect = available_width / available_height;
    const count = Math.min(12, tiles.length);
    let columns = Math.sqrt(count * aspect);
    const rows = Math.max(1, Math.round(columns / aspect));
    columns = Math.ceil(count / rows);
    let unit_size = Math.min(available_height / rows, available_width / columns);

    const left_margin = (available_width - (columns * unit_size)) / 2;
    const top_margin = (available_height - (rows * unit_size)) / 2;
    
    for (let row = 0; row < rows; row++) {
      for (let column = 0; column < columns; column++) {
        let index = (row * columns) + column;
        if (tiles[index]) {
          tiles[index].width = unit_size;
          tiles[index].x = (column * unit_size) + left_margin;
          tiles[index].y = (row * unit_size) + top_margin;
        }
      }
    }
  }

  function getTileIndex(uuid) {
    return tiles.findIndex((t) => t.uuid == uuid);
  }

  /* end of layout methods */

  function openPoll(event) {
    starting_poll = false;
    //open_poll = event.detail.poll;
    //console.log("OPENING", open_poll);
    session.signal("pollstart", event.detail.poll);
  }

  function showPoll(data) {
    console.log("showPoll", data);
    open_poll = data;
    poll_votes = [];
  }

  function recordVote(data) {
    console.log("received vote", data);
    // TODO: deduplicate
    poll_votes = [...poll_votes, data];
  }

  function endPoll(event) {
    session.signal("pollend", event.detail.poll);
  }

  function hidePoll(data) {
    open_poll = null;
    // TODO: put results into chat
  }

  function openThinkin() {
    if (is_recording) {
      recording.set(true);
      record();
    }
    session.signal("start", {});
    dispatch("open");
  }

  function closeThinkin() {
    session.signal("end", {} )
    dispatch("close");
  }

  function leaveThinkin() {
    console.debug(timestamp(),"leaving!");
    dispatch("leave");
  }

  function thinkinStarted() {
    // TODO: some other host might have sent this signal
    console.warn(timestamp(),"TODO: thinkinStarted()");
  }

  async function thinkinEnded(event) {
    console.debug(timestamp(),"thinkinEnded()");
    if (is_recording) {
      recording.set(false);
      await stopRecording(archiveId);
    }
    // Update status - this will trigger component being destroyed
    thinkin.set(Object.assign($thinkin, { status: "Completed" }));
  }

  function showSettings(show) {
    show_settings = show;
  }

  function entryClosed(event) {
    session.setCameraParent(avatar.getElement());
    if ($camera_id) {
      //avatar.getElement().appendChild(event.detail.element);
      me.hasCamera = true;
    } else {
      session.setCameraParent(avatar.getElement()); // is this necessary?
      me.hasCamera = false;
    }
    me.hasMic = !!$mic_id;
    show_entry = false;
    joinSession();
  }

  function resetStartTime() {
    $thinkin.started_at = DateTime.utc().toString();
    session.signal("settingsUpdated", { started_at: $thinkin.started_at });
    dispatch("reset"); // why?
  }

  function startPrivateMessage(event) {
    console.log("startPrivateMessage", event.detail);
    if (!$chat_on) chat_on.set(true);
    recipient = getParticipant(event.detail.uuid);
    composing_message = true;
  }

  function nextPage() {
    console.log("next", audience_page_index, audience_page_count); 
    audience_page_index++;
    if (audience_page_index >= audience_page_count) audience_page_index = 0;
    console.log("NOW", audience_page_index);
  }

  function previousPage() {
    if (audience_page_index == 0) audience_page_index = audience_page_count - 1;
    else audience_page_index--;
  }

  function toggleThinkInState(event) {
    const name = event.target.getAttribute("data-name");
    console.log("toggling state on", name);
    let fields = {};
    $thinkin[name] = !$thinkin[name];
    fields[name] = $thinkin[name];
    console.log("settings updated", fields);
    session.signal("settingsUpdated", fields);
    saveResource("PUT", `/thinkins/${$thinkin.id}`, fields);
  }

  function toggleCameras() {
    // TODO
  }

  onMount(async () => {
    warnings.log(`${$user.name} (${$user.uuid}) signed in on ${navigator.userAgent}`);

    if (localStorage.getItem("thinkin_id") !== $thinkin.id.toString()) {
      privateMessages.set({});
      localStorage.setItem("thinkin_id", $thinkin.id);
    }
    warnings.log("Connected to session");
    console.log("....connecting");
    session.connect($thinkin, $user.uuid).then(() => {
      console.log("...connected");
      startSessionEventHandlers();
      show_entry = true;
    });
  });

  onDestroy(() => {
    console.debug(timestamp(),"Player.onDestroy()");
    session_active = false;
    
    recording.set(false);
    if ($thinkin.captioning) {
      closeSocket(); // don’t forget to add the same function to stop transcribing the hosts themselves.
      //thinkin.set("captioning", false);
    }
    
    console.debug(timestamp(),"disconnecting");
    session.disconnect();  
  });
  
</script>

<main class:mobile class:host class:narrow bind:offsetWidth={screen_width}>

<header hidden={show_entry}>
  <div>
    <Avatar bind:this={avatar} on:click={() => showSettings(true)} />

    <span class="logos">
      {#if host}
        <a href={`/host/thinkin/${$thinkin.id}/settings`}><img alt="" src="/assets/tortoise-wordmark-moss.svg" class="wordmark" /></a>
      {:else}
        <span>ThinkIn <i>with</i></span>
        <img alt="" src="/assets/tortoise-wordmark-moss.svg" class="wordmark" />
      {/if}
    </span>
  </div>

  <Title name={$thinkin.name} isRecording={$recording} on:click={() => slides_open = !slides_open}/>

  <div class="btn-grp">
    <Warnings bind:this={warnings} {connected} hidden={!debugging} />
    {#if !mobile && !narrow}
      <Fullscreen />
    {/if}
    {#if show_entry}
      <!-- no buttons -->
    {:else if host}
      {#if $thinkin.status == "Green room"}
        <button name="open" class="btn primary" on:click={openThinkin}>Open to all</button>
      {:else if $thinkin.status == "Live"}
        <button name="end" class="btn danger" on:click={closeThinkin}>End now</button>
      {:else}
        <!-- this shouldn't happen -->
        <span>{$thinkin.status}</span>
      {/if}
    {:else}
      <button class="btn primary" name="leave" on:click={leaveThinkin}>Leave</button>
    {/if}
  </div>
</header>

{#if show_entry}
  <Entry {host} on:close={entryClosed} />
{:else if session_active}

<section 
  class="player"
  class:chat-on={$chat_on}
  class:chat-max={$chat_on && $chat_max}
  class:host>

  {#if participants_open}
    <div transition:slideLeftRight class="participants">
      <Participants {host}
        on:pin={pin}
        on:unpin={unpin}
        on:mute={mute}
        on:unmute={unmute}
        on:spotlight={spotlight}
        on:invite={invite}
        on:message={startPrivateMessage}
        on:eject={eject} />
    </div>
  {/if}

  {#if host}
    <div class="clips-drawer" class:open={clips_open} transition:slide>
      <Clips bind:this={clips} open={clips_open} />
    </div>
    <!--div class="queue" class:open={queue_open} transition:slide>
      <Queue open={queue_open} />
    </div-->
  {/if}

  <div class="main"
    class:greenroom={$thinkin.status == "Green room"}
    class:captioning={show_captions}>

    {#if video_enabled}
      <div transition:slide class="video-panel"
          bind:offsetWidth={available_width}
          bind:offsetHeight={available_height}>

        <div id="stage" 
          class={`layout-auto tiles-${tile_count}`}
          bind:offsetWidth={stage_width}>

          {#if screenshare}
            <Screenshare participant={screenshare} />
          {:else}

            {#if audience_tiles.length}
              <div class="audience">
                {#each audience_tiles as tile, index (tile.uuid)}
                  <Tile {host}
                    {...tile}
                    {index}
                    small
                    on:mute={mute}
                    on:unmute={unmute}
                    on:spotlight={spotlight}
                    on:view={viewProfile}
                    on:pin={pin}
                    on:unpin={unpin}
                    on:message={startPrivateMessage}
                    on:eject={eject}
                    on:stop={tileStopped}
                    on:error={warnings.error} />
                {/each}

                <button name="prev" on:click={previousPage} disabled={audience_page_count < 2}></button>
                <button name="next" on:click={nextPage} disabled={audience_page_count < 2}></button>
              </div>
            {/if}
   

            {#each tiles as tile (tile.uuid)}
              <Tile {host}
                {...tile}
                on:mute={mute}
                on:unmute={unmute}
                on:spotlight={spotlight}
                on:view={viewProfile}
                on:pin={pin}
                on:unpin={unpin}
                on:message={startPrivateMessage}
                on:eject={eject}
                on:stop={tileStopped}
                on:error={warnings.error} />
            {/each}
          {/if}
        </div>

      </div>

      {#if show_captions}
        <div class="captions-container">
          <p class="captions">
            {caption_text}
          </p>
        </div>
      {/if}

      {#if $thinkin.status == "Live"}
        <Timer duration={$thinkin.duration} started={$thinkin.started_at} />
      {/if}

      {#if $thinkin.status == "Green room"}
        <div class="green-room">
          <h2>Welcome to the Green Room</h2>
          {#if host}
            <p>You can invite in specific individuals from the participants list</p>
          {/if}
        </div>
      {/if}

    {:else}
      <!-- Video is not streaming -->
      <div class="summary">
        {#if $thinkin.status == "Completed"}
          <Feedback />
        {:else}
          <!-- maybe some "starting soon" text? -->
          <div class="description"></div>
        {/if}
      </div>
    {/if}
  </div>

  <footer>
    <div class="participant-buttons" class:hidden={narrow && $chat_on} class:reverse={narrow && participants_open}>
      {#if narrow && participants_open}
        <button class="roundel" on:click={togglePeople}>
          <svg width="12" height="12" viewBox="0 0 8 8"><polygon fill="#fff" points="4.969 4 8 7.031 7.031 8 4 4.969 0.969 8 0 7.031 3.031 4 0 0.969 0.969 0 4 3.031 7.031 0 8 0.969"></polygon></svg>
          </button>
      {:else}
        <button
          name="participants"
          class="roundel"
          on:click={togglePeople}>
          {#if most_recently_joined}
            <img class="avatar" src={ most_recently_joined.profile_picture } alt="participants" />
          {:else}
            <img src="/assets/participant.svg" alt="participants" />
          {/if}
        </button>
      {/if}
      <span class="count" on:keydown={togglePeople} on:click={togglePeople}>
      {#if participant_toast}
        { participant_toast }
      {:else}
        { participant_count } participant{#if participant_count !== 1}s{/if}
      {/if}
      </span>
    </div>

    <div class="main-controls" class:hidden={narrow && ($chat_on || participants_open)}>
      <div class="button">
        <MicButton on:click={toggleMic} on={me.hasAudio} disabled={!canMute} />
      </div>

      <div class="button">
        <CameraButton on:click={toggleCamera} on={me.hasCamera} />
      </div>

      {#if host || $thinkin.audience == "team"}
        <button
          class="tooltip roundel"
          class:on={me.hasScreenshare}
          data-text={me.hasScreenshare ? "Stop presenting" : "Present my screen"}
          value="present"
          on:click={toggleScreenShare}>
          <img src="/assets/footericons/present.svg" alt="Present" />
        </button>
      {/if}

      {#if host}
        <button class="tooltip roundel"
          data-text="Start a poll"
          on:click={() => starting_poll = true}>
          <img src="/assets/footericons/start_poll.svg" alt="" />
        </button>

        <button
          class="tooltip roundel"
          data-text="Clips"
          value="clip"
          on:click={toggleClips}>
          <img src="/assets/clips.svg" alt="clip" />
        </button>

        <button
          class="roundel checkable"
          class:tooltip={!show_options}
          data-text="Host options"
          value="options"
          on:click={() => show_options = !show_options}>
          <img src="/assets/options.svg" alt="options" />
        {#if show_options}
          <Dropdown bottom="52" width="190" on:click_outside={() => show_options = false}>
            <li class="small">These options affect all participants</li>
            <li class="checked" data-name="cameras" on:click|stopPropagation={toggleCameras} on:keydown|stopPropagation={toggleCameras}>Cameras on</li>
            <li class:checked={$thinkin.recording} data-name="recording" on:click|stopPropagation={toggleThinkInState} on:keydown|stopPropagation={toggleThinkInState}>Recording {#if $thinkin.recording}on{:else}off{/if}</li>
            <li class:checked={$thinkin.captioning} data-name="captioning" on:click|stopPropagation={toggleThinkInState} on:keydown|stopPropagation={toggleThinkInState}>Captions {#if $thinkin.captioning}enabled{:else}disabled{/if}</li>
            <li class:checked={$thinkin.chatting} data-name="chatting" on:click|stopPropagation={toggleThinkInState} on:keydown|stopPropagation={toggleThinkInState}>Chat {#if $thinkin.chatting}open{:else}closed{/if}</li>
            <li class="divider-above" on:click|stopPropagation={resetStartTime} on:keydown|stopPropagation={resetStartTime}>Reset timer</li>
          </Dropdown>
        {/if}
        </button>

      {:else}
        <button
          name="handup"
          class="tooltip roundel"
          class:active={hand_is_up}
          data-text={hand_is_up ? "Lower hand" : "Raise hand"}
          on:click={toggleHand}>
          <img src="/assets/hand.svg" alt="">
        </button>

        {#if $thinkin.captioning}
          <button
            name="captions"
            class="tooltip roundel"
            hidden={narrow && $chat_on}
            data-text={$captions_on ? "Hide captions" : "Show captions"}
            on:click={toggleShowCaptions}>
            {#if $captions_on}
              <img src="/assets/captions_on.svg" alt="">
            {:else}
              <img src="/assets/captions_off.svg" alt="">
            {/if}
          </button>
        {/if}
      {/if}
    </div>

    <div class="chat-buttons" class:hidden={narrow && participants_open}>
      {#if $chat_on}
        {#if narrow}
          <button class="roundel" on:click={toggleChat}><svg width="12" height="12" viewBox="0 0 8 8"><polygon fill="#fff" points="4.969 4 8 7.031 7.031 8 4 4.969 0.969 8 0 7.031 3.031 4 0 0.969 0.969 0 4 3.031 7.031 0 8 0.969"></polygon></svg></button>
        {/if}
        <button class="btn primary" name="compose" on:click={() => composing_message = true}>Have your say</button>
      {:else if message_count}
        <span on:click={toggleChat} on:keydown={toggleChat} class="count" in:fade>{message_count} message{#if message_count !== 1}s{/if}</span>
      {/if}

      {#if !(narrow && $chat_on)}
        <button
          name="chat"
          class="roundel"
          on:click={toggleChat}>
          <img src="/assets/footericons/chat.svg" alt="Chat" />
          {#if $totalUnreadMessages > 0 && !$chat_on}
            <Badge count={$totalUnreadMessages} />
          {/if}
        </button>
      {/if}
    </div>
  </footer>

  {#if $chat_on}
    <div transition:slideLeftRight class="chat">
      <Chat {host} enabled={$thinkin.chatting} on:view={viewProfile} on:spotlight={spotlightMessage} on:close={() => chat_on.set(false)}/>
    </div>
  {/if}

  {#if composing_message}
    <div class="compose" transition:slide>
      <Composer {recipient} on:close={() => composing_message = false} />
    </div>
  {/if}

</section>
{/if}

</main>

{#if spotlit_message}
  <Modal background="transparent" width=800 dismissable={host} on:close={unspotlightMessage}>
    <div id="spotlit-message">
      <div class="message">{@html spotlit_message.message}</div>
      <p class="by">{ spotlit_message.name } at { spotlit_message.time }</p>
    </div>
  </Modal>
{/if}

{#if show_profile}
  <Profile uuid={show_profile.uuid} on:message={startPrivateMessage} on:close={() => show_profile = null} />
{/if}

{#if slides_open}
  <Slides on:close={() => slides_open = false }/>
{/if}

{#if show_settings}
  <Settings on:close={() => showSettings(false)} />
{/if}

{#if audio_blocked}
  <Modal>
    <div id="request-audio">
      <p>Thanks for joining us. We need your permission to start playing audio</p>
      <button class="btn primary" on:click={unblockAudio}>Continue</button>
    </div>
  </Modal>
{/if}

{#if host && ejecting}
  <Modal on:close={() => ejecting = null}>
    <div id="eject-participant">
      <p>Are you sure you want to eject { ejecting.name } from this ThinkIn?</p>
      <p>
        <button class="btn danger" on:click={ejectParticipant}>Yes</button>
        <button class="btn" on:click={() => ejecting = null}>No</button>
      </p>
    </div>
  </Modal>
{/if}

{#if host && starting_poll}
  <PollSetup on:open={openPoll} on:close={() => starting_poll = false}/>
{/if}

{#if open_poll}
  <Poll poll={open_poll} votes={poll_votes} {host} />
 {/if}

<svelte:head>
<style>
body > .OT_root { position: fixed; top: 60px; right:30px; max-width: 100px; max-height: 100px;}
.OT_publisher{ min-width: 36px; min-height: 36px;}
</style>
</svelte:head>

<style>
  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
  }
  header[hidden] {
    display: none;
  }
  header > * {
    width: 25%;
  }
  header > *:first-child {
    padding-left: 42px;
    white-space: nowrap;
  }
  header > *:nth-child(2) {
    width: 50%;
  }
  header > *:last-child {
    justify-content: flex-end;
  }
  .logos {
    font-family: Pegasus;
    font-size: 24px;
    line-height: 34px;
  }
  .logos span {
    pointer-events: none;
  }
  button.primary {
    background-image: none;
  }
  main {
    background-color: var(--kelpDark);
    color: white;
    font-family: Koopman;
    font-size: 16px;
    line-height: normal;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
    padding-top: 50px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  main.host {
    background-color: var(--midnight);
  }
  .player {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 2fr auto 70px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    gap: 0;
  }
  .main {
    grid-column: 2;
    grid-row: 1/2;
    position: relative;
    max-height: 100%;
    overflow: hidden;
    padding: 20px 0 40px;
    z-index: 1;
  }
  .main.captioning {
    padding-bottom: 60px;    
  }
  .main.greenroom {
    padding-bottom: 90px;
  }
  .video-panel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0;
  }
  .waiting {
    text-align: center;
    opacity: .8;
    font-size: 18px;
  }
  #stage {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;
  }
  .chat-max #stage {
    align-items: flex-start;
  }
  .chat {
    grid-column: 3;
    grid-row: 1/-2;
    width: 300px;
    z-index: 4;
  }
  .narrow .chat {
    width: 100vw;
  }
  .clips-drawer, 
  .queue {
    height: 0;
    grid-column: 2;
    grid-row: 2;
    background: rgba(0,0,0,.9);
  }
  .clips-drawer.open {
    height: 290px;
    margin-top: 10px;
  }
  .queue.open {
    height: 140px;
    margin-top: 10px;
  }
  .participants {
    width: 240px;
    grid-column: 1;
    grid-row: 1 / -2;
    display: flex;
    flex-direction: column;
    background-color: var(--kelpDark);
    z-index: 3;
  }
  .audience {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 16.66em;
    height: 100%;
    position: relative;
  }
  .audience :global(.tile) {
    width: 16.66em;
    height: 16.66em;
    position: relative;
  }
  .chat-max .chat {
    width: calc(100vw - 300px);
  }
  /* TODO:  sort out mobile properly */
  .narrow .participants {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 0;
  }
  .summary {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .description {
    font-family: Pegasus;
    font-size: 20px;
    text-align: left;
    width: 600px;
    max-width: 90%;
    margin: 0 auto;
  }
  li.small {
    font-size: 12px;
    opacity: .6;
    padding: 8px 16px 2px 12px;
    pointer-events: none;
  }
  footer {
    grid-column: 1/-1;
    grid-row: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    color: white;
    padding: 0;
    z-index: 3;
  }
  footer > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 300px;
  }
  footer > div:last-child {
    justify-content: flex-end;
  }
  .main-controls {
    width: 100%;
    justify-content: center;
  }
  footer .button,
  footer button {
    margin: 0 12px;
    width: 48px;
    height: 48px;
    position: relative;
  }
  footer button {
    padding: 11px;
  }
  footer button img {
    width: 24px;
    height: 24px;
  }
  footer button[name="participants"],
  footer button[name="chat"] {
    position: relative;
    display: flex;
    justify-content: center;
  }
  button[name="participants"] img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .count {
    display: inline-block;
    margin: 0;
    padding: 5px 0;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .captions-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    background-color: rgba(0, 0, 0, 0.281);
    display: inline-block;
    max-width: 80%;
    padding: 10px 30px;
    font-family: Pegasus,serif;
  }
  .captions {
    font-size: 20px;
    line-height: 1;
    color: var(--white);
    font-weight: normal;
    margin: 0;
  }
  .toast {
    color: rgba(255, 255, 255, 0.6);
  }
  .green-room {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    text-align: center;
    font-family: Pegasus,serif;
    opacity: .9;
  }
  .green-room h2 {
    margin: 0;
    font-weight: normal;
  }
  .green-room p {
    margin:0;
    font-size: 18px;
  }
  #request-audio {
    padding: 20px;
  }

  #spotlit-message {
    background: transparent;
    color: #fff;
  }
  #spotlit-message .message {
    background: #fff;
    color: #333;
    font-size: 32px;
    font-family: Pegasus,serif;
    padding: 20px 30px 30px;
  }
  #spotlit-message .message::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 15px;
    left: 24px;
    width: 24px;
    height: 24px;
    background-image: url(/assets/triangle-white.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  #spotlit-message .message + p {
    margin: 0;
    padding: 8px 10px 10px 60px;
  }

  #eject-participant {
    padding: 20px 30px;
  }

  button[name=prev], button[name=next] {
    position: absolute;
    left: -20px;
    width: 26px;
    height: 26px;
    background-size: cover;
    opacity: 0;
    transition: opacity 200ms;
  }
  .audience:hover button[name=prev], 
  .audience:hover button[name=next] {
    opacity: 1;
  }
  button[name=prev][disabled], button[name=next][disabled] {
    opacity: 0;
    pointer-events: none;
  }
  button.on {
    background-color: var(--moss);
  }
  button[name=prev] {
    top: 0;
    background-image: url(/assets/arrow-up.svg);
  }
  button[name=next] {
    bottom: 0;
    background-image: url(/assets/arrow-down.svg);
  }
  button[name=handup] img {
    transform-origin: center center;
    transition: transform 200ms;
  }
  button[name=handup].active {
    background-color: #E04600;
  }
  button[name=handup].active img {
    transform: rotate(45deg);
  }
  button[name=leave] {
    background-color: var(--kelp);
    color: #fff;
  }
  li.divider-above {
    border-top: 1px solid #ddd;
  }
  button[name=compose] {
    height: 40px;
    width: auto;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .compose {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    z-index: 5;
  }
  @media (max-width: 599px) {
    header > div {
      width: 33.33%;
    }
    header .logos {
      display: none;
    }
    header > div:last-child button:not(.btn) {
      display: none;
    }
    footer .count {
      display: none;
    }
    footer > div:first-child, 
    footer > div:last-child {
      width: 20%;
      justify-content: center;
    }
    footer > div.main-controls {
      width: 60%;
    }
    footer > div.hidden {
      display: none;
    }
    .chat-on .chat-buttons {
      width: 100%;
      justify-content: space-between;
    }
    .participant-buttons.reverse {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: flex-start;
    }
  }
  @media (min-width: 600px) {
    .main {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
</style>
