<script>
  import { createEventDispatcher } from 'svelte';
  import { Modal } from "@galapagos/svelte-components";
  import { participants } from "../../stores/sessionStore";

  export let uuid;

  const dispatch = createEventDispatcher();

  let index;
  let participant;
  let has_next = false;
  let has_previous = false;

  $: active_participants = $participants.filter((p) => !p.left_at);

  $: {
    index = active_participants.findIndex((p) => p.uuid == uuid);
    participant = active_participants[index];
    has_next = index < active_participants.length - 1;
    has_previous = index > 0;
  }

  function previous() {
    uuid = active_participants[index - 1].uuid;
  }

  function next() {
    uuid = active_participants[index + 1].uuid;
  }

  function handlePrivateMessage() {
    console.log("PSST PRIVATE!");
    dispatch("message", { uuid: participant.uuid });
    dispatch("close");
  }
</script>

<Modal on:close>

  <fieldset>
    <figure>
      <img class:empty={!participant.profile_picture}
        src={participant.profile_picture ? participant.profile_picture : "/assets/person.svg"}
        alt=""
      />
    </figure>
    <h1>{participant && participant.name}</h1>
    {#if participant.job_title}
      <p class="title">{participant.job_title}</p>
    {/if}
    {#if participant.location}
      <p class="location">{participant.location}</p>
    {/if}
    <p class="bio">{participant.biography || ""}</p>
  </fieldset>

  <footer>
    <button class="btn primary" on:click={handlePrivateMessage}>Private message</button>
    <span>
      <button name="prev" disabled={!has_previous} on:click={previous}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#066" d="M12.734,12.749 L9.987,15.5 L24,15.5 L24,16.5 L10,16.5 L12.75,19.251 L12,20 L8,15.992 L11.987,12 L12.734,12.749 Z M16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 C24.836556,32 32,24.836556 32,16 C32,11.7565362 30.3142906,7.68687356 27.3137085,4.6862915 C24.3131264,1.68570945 20.2434638,0 16,0 Z M16,31 C7.71572875,31 1,24.2842712 1,16 C1,7.71572875 7.71572875,1 16,1 C24.2842712,1 31,7.71572875 31,16 C31,24.2842712 24.2842712,31 16,31 Z"></path></svg>
      </button>
      <button name="next" disabled={!has_next} on:click={next}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#066" d="M19.266,12.749,22.013,15.5H8v1H22L19.25,19.251,20,20l4-4.008L20.013,12ZM16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31Z"/></svg>
      </button>
    </span>
  </footer>
</Modal>

<style>
  fieldset {
    width: 100%;
    margin: 0;
    padding: 10px 20px 20px 190px;
    padding-left: 170px;
    position: relative;
    min-height: 180px;
  }
  figure {
    width: 140px;
    height: 140px;
    background-color: #eee;
    position: absolute;
    left: 15px;
    top: 16px;
    margin: 0;
    padding: 0;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  img.empty {
    opacity: .9;
  }
  h1, p {
    color: #000;
    font-family: Pegasus,serif;
    font-weight: normal;
    margin: 0;
    font-size: 24px;
    line-height: 34px;
  }
  .title, .location {
    color: #666;
    font-family: Koopman;
    font-size: 18px;
    line-height: 24px;
  }
  .bio {
    margin-top: 10px;
    max-height: 30vh;
    overflow-y: auto;
    font-size: 20px;
    line-height: 28px;
  }
  footer {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
  }
  footer span {
    display: flex;
    align-items: center;
  }
  footer span button {
    margin-left: 6px;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
  }
  footer button svg {
    width: 100%;
    height: 100%;
  }
  footer button[name=next] {
    width: 48px;
    height: 48px;
  }
</style>
