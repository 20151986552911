var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
export const exponentialBackoff = (maxWaitMs, cb, waitMs = 2, errors = []) => __awaiter(void 0, void 0, void 0, function* () {
    if (waitMs > maxWaitMs) {
        const lastError = errors.pop();
        errors.forEach(e => console.error(e));
        throw lastError;
    }
    yield sleep(waitMs);
    try {
        // await is important for the try catch
        return yield cb();
    }
    catch (e) {
        return yield exponentialBackoff(maxWaitMs, cb, waitMs ** 2, [...errors, e]);
    }
});
