<script>
  import Cookies from "js-cookie";
  import { isActive, url } from "@sveltech/routify";
  import { Auth } from "aws-amplify";
  import * as Sentry from "@sentry/browser";

  const LINKS = [
    ["/host/thinkin", "Thinkins"],
    ["/host/people", "People"],
    ["/host/insight", "Insight"],
    ["/host/redeem-status", "Rewards"],
  ];

  function handleSignOut() {
    Cookies.remove("thinkin_authToken");
    Auth.signOut();
    Sentry.configureScope(scope => scope.setUser(null));
  }
</script>

<aside>
  <nav class="primary">
    {#each LINKS as [path, name]}
      <a class:active={$isActive(path)} href={$url(path)}>{name}</a>
    {/each}
  </nav>
  <nav class="secondary">
    <a class:active={$isActive("/host/tickets")} href="/host/tickets">Invite codes</a>
    <a on:click={handleSignOut} href="#logout">Sign out</a>
  </nav>
</aside>

<style>
  aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 100%;
    padding: 41px 0 30px;
    background: #001a38;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
  }
  nav a {
    display: block;
    text-align: left;
    text-decoration: none;
    font-family: Koopman;
    padding: 10px 20px;
    width: 100%;
  }
  nav a {
    color: #dce459;
    opacity: 0.9;
  }
  .active {
    color: #fff;
    background: #000;
    opacity: 1;
  }
  nav a:hover {
    opacity: 1;
  }
  nav.secondary a {
    padding: 8px 20px;
    font-size: 14px;
  }
</style>
