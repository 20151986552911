import { makeId } from "../../common/helpers";
export function generateICS(thinkin, dates) {
	const { startWithISO, endWithISO, timestamp } = dates;
	const calendarInput = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//ical.marudot.com//iCal Event Maker
CALSCALE:GREGORIAN
BEGIN:VTIMEZONE
TZID:Europe/London
TZURL:http://tzurl.org/zoneinfo-outlook/Europe/London
X-LIC-LOCATION:Europe/London
BEGIN:DAYLIGHT
TZOFFSETFROM:+0000
TZOFFSETTO:+0100
TZNAME:BST
DTSTART:19700329T010000
RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU
END:DAYLIGHT
BEGIN:STANDARD
TZOFFSETFROM:+0100
TZOFFSETTO:+0000
TZNAME:GMT
DTSTART:19701025T020000
RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${timestamp}
UID:${makeId(7) + "@tortoisemedia.com"}
DTSTART;TZID=Europe/London:${startWithISO.replace("Z", "")}
DTEND;TZID=Europe/London:${endWithISO.replace("Z", "")}
SUMMARY:${thinkin.name}
DESCRIPTION:${thinkin.description}
LOCATION:${thinkin.external_url || ""}
END:VEVENT
END:VCALENDAR
`;
	const fileForCalendar = new Blob([calendarInput], { type: "text/calendar" });
	const icsFile = window.URL.createObjectURL(fileForCalendar);

	return icsFile;
}
