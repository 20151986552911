<script>
  import { goto } from "@sveltech/routify";
  import { fetchData, handleTimeFormatting } from "../../common/helpers";
  import { thinkin } from "../../stores/sessionStore";

  export let id;
  let thinkins;
  const now = Date.now();
  fetchData(`/public/thinkins?${new URLSearchParams({ page: 0, size: 3, direction: "DESC" })}`).then(data => {
    if (!data.thinkins) {
      return;
    }
    const futureThinkins = [...data.thinkins].filter(thinkin => {
      return (
        thinkin.id !== id &&
        thinkin.status == "Published" &&
        thinkin.scheduled_date &&
        new Date(thinkin.scheduled_date).getTime() > now
      );
    });
    thinkins = futureThinkins.sort((t1, t2) => {
      return new Date(t1.scheduled_date).getTime() > new Date(t2.scheduled_date).getTime() ? -1 : 1;
    });
  });

  function viewThinkin(thinkinId) {
    thinkin.set({});
    $goto(`/thinkin/${thinkinId}`);
  }
</script>

<section>
  <div>
    {#if thinkins && thinkins.length}
      <h5>Now booking</h5>
      <div class="upcoming">
        {#each thinkins as { id, name, scheduled_date, poster_url }}
          <button on:click|preventDefault={() => viewThinkin(id)}>
            {#if poster_url}<figure>
                <img src={poster_url} alt="" />
              </figure>{/if}
            <div>
              <h2>{name}</h2>
              <span class="date">
                {handleTimeFormatting(scheduled_date).DDMMMYYY} • {handleTimeFormatting(scheduled_date).TIME}
              </span>
            </div>
          </button>
        {/each}
      </div>
      <p class="footer">All times are London times</p>
    {/if}
  </div>
</section>

<style>
  section {
    text-align: left;
    padding: 10px 30px;
    max-width: 100%;
    color: #111;
  }
  section > div {
    max-width: 100%;
    margin: 0 auto;
  }
  .upcoming button {
    display: block;
    padding: 10px 20px 10px 0;
    text-decoration: none;
    text-align: left;
  }
  figure {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 56.25%;
    margin: 0 0 10px;
  }
  .upcoming img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h2 {
    font-family: Pegasus, serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }
  h5 {
    font-size: 28px;
    line-height: 36px;
    font-weight: normal;
    margin: 20px 0;
  }
  .date {
    font-family: Koopman, sans-serif;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    color: #666;
    margin: 8px 0;
    display: block;
  }
  p {
    padding: 10px 0;
    opacity: 0.5;
    margin: 0;
  }
  .footer {
    font-size: 14px;
    font-family: Koopman, sans-serif;
  }

  @media screen and (min-width: 654px) {
    section {
      margin-left: 8.33vw;
      width: 83.33vw;
    }
    h2,
    h5 {
      font-size: 32px;
      line-height: 40px;
    }
    .upcoming {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .upcoming button {
      width: 50%;
    }
  }
  @media screen and (min-width: 1280px) {
    .upcoming button {
      width: 33.33%;
    }
  }
</style>
