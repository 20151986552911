<script>
  import { params } from "@sveltech/routify";
  import User from "../../../../../components/user/User";

  const uuid = $params.uuid;
</script>

<main>

  <header>
    <div><a href="../settings" class="btn lettuce home">← Back</a></div>
    <div>
      <button name="action" class="btn primary" disabled>Archive</button>
    </div>
  </header>
  <aside />

  <User {uuid} />

</main>

<style>
  main {
    padding: 45px 0 0 180px;
    width: 100%;
    height: 100%;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 3;
  }
  header > div:first-child {
    padding: 10px;
    text-align: left;
  }
  header > div:last-child {
    padding: 5px 10px;
    text-align: right;
  }
  aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 100%;
    background: #001a38;
    z-index: 2;
  }
</style>
