const BENEFITS = {
  AUDIO: "Early and ad-free access to <b>award-winning audio</b> stories and investigations",
  SENSEMAKER_NEWSLETTERS: "Your pick of <b>Sensemaker newsletters</b>",
  LIVE_EVENTS: "<span style='color:#fab3b1' >Free</span> tickets to all our <b>Live events</b>, plus £10 guest tickets",
  KITE: "Discounted tickets to <a style='all:unset; text-decoration:underline; cursor:pointer; font-weight:bold;' href='https://kitefestival.co.uk/' >KITE festival</a>",
  DISCOUNTS_EVERYTHING:
    "Discounts on everything in the <a style='all:unset; text-decoration:underline; cursor:pointer; font-weight:bold;' href='https://www.tortoisemedia.com/shop/' >Tortoise shop</a>",
  MEMBER_EVENTS: "Exclusive <b>member-only events</b>, hosted by Tortoise editors",
  COMPLIMENTARY_MEMBERSHIPS: "<b>Four complimentary digital memberships</b> to share with whoever you like",
  TORTOISE_QUARTERLY: "The <b>Tortoise Quarterly book</b>, in glorious old-fashioned print",
};

const SHARED_BENEFITS = [
  BENEFITS.AUDIO,
  BENEFITS.SENSEMAKER_NEWSLETTERS,
  BENEFITS.LIVE_EVENTS,
  BENEFITS.KITE,
  BENEFITS.DISCOUNTS_EVERYTHING,
];

export const MEMBERSHIP_BENEFITS = {
  BASIC_MEMBERS: [...SHARED_BENEFITS],
  NEWSROOM_MEMBERS: [
    ...SHARED_BENEFITS,
    BENEFITS.MEMBER_EVENTS,
    BENEFITS.COMPLIMENTARY_MEMBERSHIPS,
    BENEFITS.TORTOISE_QUARTERLY,
  ],
  FOUNDING_MEMBERS_QUARTERLY: [...SHARED_BENEFITS, BENEFITS.TORTOISE_QUARTERLY, BENEFITS.MEMBER_EVENTS],
  FRIEND_OF_TORTOISE: [
    ...SHARED_BENEFITS,
    BENEFITS.MEMBER_EVENTS,
    BENEFITS.COMPLIMENTARY_MEMBERSHIPS,
    BENEFITS.TORTOISE_QUARTERLY,
  ],
  FOUNDING_MEMBERS: [...SHARED_BENEFITS],
  STANDARD_MEMBERS: [...SHARED_BENEFITS, BENEFITS.TORTOISE_QUARTERLY, BENEFITS.MEMBER_EVENTS],
  STUDENTS: [...SHARED_BENEFITS],
  B2C_PRO: [...SHARED_BENEFITS, BENEFITS.TORTOISE_QUARTERLY, BENEFITS.MEMBER_EVENTS],
};
