<script>
  import { user } from "../../stores/sessionStore";
  import { fetchData } from "../../common/helpers";
  import TicketCounter from "../../components/profile/TicketCounter.svelte";
  import TabbedThinkinBrowser from "../../components/profile/TabbedThinkinBrowser.svelte";
  import { FullScreenSpinner } from "@galapagos/svelte-components";
  import { tpAPI } from "../../common/utils";
  let loading = true;
  let accessLevel = "";
  let newsroomExpiry;

  let pastThinkins = [];
  let futureThinkins = [];

  async function fetchAttendedThinkins() {
    const { uuid } = $user;
    const data = await fetchData(`/users/${uuid}/thinkins`);
    const userThinkins = data.attendedThinkins;
    const now = Date.now();

    pastThinkins = (userThinkins || []).filter(thinkin => new Date(thinkin.scheduled_date) < now);
    futureThinkins = (userThinkins || []).filter(thinkin => new Date(thinkin.scheduled_date) >= now);
  }

  async function fetchTicketAllocation() {
    const accessList = await tpAPI("/access/list");
    const friendOfTortoiseAccess = accessList.data.find(access => access.resource.rid === process.env.FOT_RESOURCE_ID);
    const newsroomAccess = accessList.data.find(access => access.resource.rid === process.env.NEWSROOM_RESOURCE_ID);
    const isUserFriendOfTortoise = !!friendOfTortoiseAccess && friendOfTortoiseAccess.granted;
    const isUserNewsroomMember = !!(newsroomAccess && newsroomAccess.granted);

    accessLevel = isUserFriendOfTortoise ? "Friend of Tortoise" : isUserNewsroomMember ? "Newsroom Member" : "";
    newsroomExpiry = newsroomAccess && newsroomAccess.expire_date;
  }

  async function initialFetch() {
    try {
      await Promise.all([fetchAttendedThinkins(), fetchTicketAllocation()]);
    } finally {
      // no catch because, in the failure case, just let userTickets be empty for now, as if they have none
      loading = false;
    }
  }
  $: if ($user && loading) {
    initialFetch();
  }
</script>

<svelte:head><title>My tickets</title></svelte:head>

<FullScreenSpinner visible={loading} />

<div class="page">
  <header>
    <div class="central-column--mobile central-column--desktop">
      <div class="title-spacing" />
      <p class="text desktop-body-text strapline-spacing">
        {#if accessLevel === "Friend of Tortoise"}
          As a Friend of Tortoise, you can attend as many ThinkIns as you like, in-person or online.
        {:else if $user.amnesty_applied}
          As a Founding Member, you get
          {#if $user.galapagos_in_person_thinkin_allocation}
            <span class="nowrap">{$user.galapagos_in_person_thinkin_allocation} x </span>
          {/if}
          in-person tickets to ThinkIns, bringing a friend. Enjoy!
        {:else if accessLevel === "Newsroom Member"}
          As a Newsroom Member, you get
          {#if $user.galapagos_in_person_thinkin_allocation}
            <span class="nowrap">{$user.galapagos_in_person_thinkin_allocation} x </span>
          {/if}
          in-person tickets to ThinkIns, bringing a friend. Enjoy!
        {:else}
          As a Member, you can attend unlimited online ThinkIns, and invite a friend to join you.
        {/if}
      </p>
    </div>
  </header>

  {#if accessLevel === "Newsroom Member"}
    <div class="central-column--mobile central-column--desktop">
      <TicketCounter {newsroomExpiry} />
    </div>
  {/if}

  <div class="central-column--desktop page-body">
    {#if !loading}
      <TabbedThinkinBrowser
        thinkinSets={[
          {
            name: "Upcoming",
            thinkins: futureThinkins,
            fallbackText: "You have no tickets to upcoming events.",
          },
          {
            name: "Attended",
            thinkins: pastThinkins,
            fallbackText: "You haven’t been to a ThinkIn.",
          },
        ]} />
    {/if}
  </div>
</div>

<style>
  .page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
  }

  .page-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  header {
    background-color: var(--stone);
  }

  .central-column--mobile {
    margin-right: var(--spacer-medium);
    margin-left: var(--spacer-s3);
  }

  .text {
    all: unset;
    display: block;
    text-align: start;
  }

  .nowrap {
    white-space: nowrap;
  }

  .desktop-body-text {
    font-family: Pegasus, serif;
    font-size: 20px;
    line-height: 28px;
  }

  .title-spacing {
    padding-top: var(--spacer-large);
    padding-bottom: var(--spacer-large);
  }

  .strapline-spacing {
    padding-bottom: var(--spacer-s5);
  }

  @media screen and (min-width: 645px) {
    .central-column--mobile {
      margin: unset;
    }

    .central-column--desktop {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }

    .title-spacing {
      padding-top: var(--spacer-s2);
      padding-bottom: var(--spacer-s5);
    }

    .strapline-spacing {
      padding-bottom: var(--spacer-s4);
    }

    .desktop-body-text {
      font-size: 24px;
      line-height: 34px;
    }
  }

  @media screen and (min-width: 1920px) {
    .central-column--desktop {
      max-width: var(--supersize-central-column-width);
    }
  }
</style>
