<script>
  import { TabBrowser } from "@galapagos/svelte-components";
  import StatementBrowserHeader from "./StatementBrowserHeader.svelte";
  import StatementBrowserPane from "./StatementBrowserPane.svelte";

  export let handleFileDrop;

  export let commentData;
  export let transcriptData;

  export let doesVideoExist;

  export let isTranscriptLoading;
  export let areCommentsLoading;

  export let videoCurrentTime;

  export let handleBeginEditing;
  export let updateTranscript;
  export let updateComments;
  export let resyncStatements;

  let highlightsOnly = false;

  function setHighlightsOnly(value) {
    highlightsOnly = value;
  }

  $: sharedProps = {
    handleFileDrop,
    doesVideoExist,
    handleBeginEditing,
    videoCurrentTime,
    resyncStatements,
    highlightsOnly,
  };

  $: bodyPanes = [
    {
      component: StatementBrowserPane,
      props: {
        ...sharedProps,
        isLoading: isTranscriptLoading,
        statements: transcriptData || [],
        fallbackText: "Upload transcript",
        updateStatements: updateTranscript,
      },
    },
    {
      component: StatementBrowserPane,
      props: {
        ...sharedProps,
        isLoading: areCommentsLoading,
        statements: commentData || [],
        fallbackText: "Upload comments",
        handleBeginEditing,
        updateStatements: updateComments,
      },
    },
  ];
</script>

<TabBrowser
  header={{
    component: StatementBrowserHeader,
    props: { highlightsOnly, setHighlightsOnly },
  }}
  {bodyPanes} />
