<script>
  import { params } from "@sveltech/routify";
  import { thinkin, user } from "../../../../../stores/sessionStore";
  import Slide from "../../../../../components/edit/Slide";
import { fetchData } from "../../../../../common/helpers";

  const slide_id = $params.slide_id;
  const thinkin_id = $params.id;
  if (!$thinkin || $thinkin.id !== slide_id) {
  	fetchThinkIn(thinkin_id);
  }

  async function fetchThinkIn(thinkin_id) {
    let thinkinData = await fetchData(`/thinkins/${thinkin_id}`)
    thinkin.set(thinkinData)
  }

</script>

<svelte:head>
  <title>Galapagos :: Slide editor</title>
</svelte:head>

<main>
{#if $thinkin}
  <Slide thinkin_id={thinkin_id} id={slide_id} />
{/if}
</main>

<style>
  main {
    width: 100%;
    height: 100%;
    padding: 40px 0 0 200px;
  }
</style>
