<script>export let profilePicture = "";
export let jobTitle = "";
export let biography = "";
export let name;
export let orientation = "row";
</script>

<figure class={orientation}>
  {#if profilePicture}
    <img src={profilePicture} alt="" />
  {/if}
  <figcaption class={orientation == "column" ? "reverse" : ""}>
    {#if jobTitle}
      <span class="author-jobTitle">
        {jobTitle}
      </span>
    {/if}
    {#if biography}
      <span class="author-biography">
        {biography}
      </span>
    {/if}
    <span class="author-name">
      {name}
    </span>
  </figcaption>
</figure>

<style>@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Italic"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Italic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "Pegasus";
  src: local("WolpePegasus-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.woff?1f968fe17d94daf738d801723d071bb8") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/WolpePegasus-Bold.ttf") format("truetype");
  font-weight: bold; }

/* use Koopman for fixed labels (button) */
@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Regular"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Regular.tt") format("truetype"); }

@font-face {
  font-family: "Koopman";
  src: local("FSKoopman-Bold"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.woff?12b5ced9aa26d60d8aa85c7f038fea24") format("woff"), url("https://www.tortoisemedia.com/wp-content/themes/pugpig-tortoise-theme/fonts/FSKoopman-Bold.tt") format("truetype");
  font-weight: bold; }

* {
  box-sizing: border-box;
  font-family: Pegasus,serif; }

/* This one isn't in the styleguide but it is in the app */
figure {
  margin: 0 0 24px;
  display: flex;
  max-width: 372px;
  width: 100%; }

figcaption {
  line-height: 22px; }

figcaption span {
  display: block; }

.column {
  flex-direction: column;
  align-items: flex-start; }

.row {
  flex-direction: row;
  align-items: center; }

.reverse {
  display: flex;
  flex-direction: column-reverse; }

img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 12px; }

.author-name,
.author-biography,
.author-jobTitle {
  color: #333333; }

.author-name {
  font-size: 20px;
  font-style: italic;
  font-family: Pegasus, serif; }

.author-biography,
.author-jobTitle {
  font-size: 16px;
  font-family: Koopman, serif; }
</style>
