export default function selectable(node) {
    const handleClick = () => node.dispatchEvent(new CustomEvent("select"));
    const handlePress = (event) => {
        if (event.key === "Enter") {
            node.dispatchEvent(new CustomEvent("select"));
        }
    };
    node.addEventListener("click", handleClick);
    node.addEventListener("keyup", handlePress);
    return {
        destroy() {
            node.removeEventListener("click", handleClick);
            node.removeEventListener("keyup", handlePress);
        },
    };
}
