<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import Cookies from "js-cookie";
  import Dropdown from "../dropdown/Dropdown";
  import CameraButton from "./CameraButton";
  import MicButton from "./MicButton";

  import { camera_id, mic_id } from "../../stores/settings";
  import { session } from "./session";
  import { resizeImage, saveResource, selectImageFromComputer, uploadImage } from "../../common/helpers";
  import { user } from "../../stores/sessionStore";

  const dispatch = createEventDispatcher();

  export let host = false;

  let element;
  let cameras = [];
  let mics = [];
  let devices_loaded = false;
  let show_settings = false;
  let snapshotting = false;
  let camera_started = false;
  let mic_started = false;
  let user_updated = false;
  let snapshot;

  $: camera_name = ($camera_id && cameras.length && cameras.find((d) => d.deviceId == $camera_id).label) || "None selected";

  function takeSnapshot(event) {
    if (!camera_started) return;
    // capture a snapshot
    console.log("taking snapshot");
    snapshotting = true;
    let raw_snapshot = session.getSnapshot($user.uuid);
    if (!raw_snapshot) {
      console.warn("no snapshot found");
    } else {
      resizeImage(raw_snapshot, 300).then((resized_snapshot) => {
        snapshot = resized_snapshot;
      });
    }
  }

  function importPhoto(e) {
    selectImageFromComputer(e, (e) => {
      resizeImage(e.target.result, 300).then((resized_image) => {
        snapshot = resized_image;
        snapshotting = true;
      });
    });
  }

  function saveSnapshot() {
    uploadImage(snapshot, $user.name, "thinkins-profile-pictures", $user.id + "-" + Date.now()).then((profile_picture) => {
      snapshotting = false;
      snapshot = null;
      saveResource("PUT", `/users/${ $user.uuid }`, { profile_picture })
        .then((data) => {
          console.log("setting user profile picture", data);
          user.set(data);
          $user.profile_picture = data.profile_picture;
        });
    });
  }

  function cancelSnapshot() {
    snapshotting = false;
    snapshot = null;
  }

  function setUpdated() {
    user_updated = true;
  }

  function updateUserDetails() {
    saveResource("PUT", `/users/${$user.uuid}`, {
      job_title: $user.job_title,
      location: $user.location,
      biography: $user.biography,
    }).then((response) => {
      user.set(response);
    });
  }

  async function joinThinkIn() {
    if (camera_started) $camera_id = session.getCamera();
    else $camera_id = null;
    console.debug("found camera_id=", $camera_id);
    Cookies.set("camera_id", $camera_id);
    Cookies.set("mic_id", $mic_id);

    if (user_updated) updateUserDetails();

    dispatch("close", {
      element: element.firstChild,
    });
  }

  function displayLabel(text) {
    if (text.indexOf("(") == -1) return text;
    else return text.substr(0, text.indexOf("("));
  }

  function toggleCamera() {
    if (camera_started) {
      session.stopCamera();
      camera_started = false;
    } else {
      session.startCamera().then(() => {
        camera_started = true;
      });
    }
  }

  function toggleMic() {
    mic_started = !mic_started;
    // TODO:
  }

  onMount(() => {
    session.listDevices().then((devices) => {
      cameras = devices.filter((d) => d.kind == "videoInput");
      if (!cameras.length) console.warn("no cameras detected");

      mics = devices.filter((d) => d.kind == "audioInput");
      if (!mics.length) console.warn("no mics detected");

      devices_loaded = true;

      if ($camera_id && !devices.find((cam) => cam.deviceId == $camera_id)) {
        // Probably means this camera has been unplugged
        camera_id.set(null);
      }

      session.startCamera(element, $camera_id).then((devices) => {
        camera_id.set(devices.camera_id);
        mic_id.set(devices.mic_id);
        camera_started = true;
      }).catch(err => {
        console.warn("starting camera failed", err);
      });
    }).catch((err) => {
      console.warn("permission refused",err);
    });
  });

</script>

<div class="entry">
  <div class="cols">
    <div class="col device">
      <div class="camera" bind:this={element}>
        {#if $user.profile_picture}
          <img src={$user.profile_picture} alt="">
        {:else}
          <img class="avatar" src="/assets/avatar.png" alt="">
        {/if}
      </div>
      <div class="buttons">
        {#if !snapshotting}
          <MicButton on:click={toggleMic} on={mic_started} />
          <CameraButton on:click={toggleCamera} on={camera_started} />

          <button class="btn roundel tooltip"
            name="snapshot" 
            data-text="We’ll use this on your profile so other members can see who is in the room" 
            on:click={takeSnapshot}>
            <img src="/assets/photo.svg" alt="">
            {#if camera_started}
              <span>Take a snapshot</span>
            {:else}
              <span>Upload a photo</span>
              <input type="file" accept=".jpg, .jpeg, .png" on:change={importPhoto}/>
            {/if}
          </button>
        {:else}
          <button name="cancelsnapshot" on:click={cancelSnapshot}>Try again</button>
          <button class="btn snapshotting" name="snapshot" on:click={saveSnapshot}>
            <img class="snapshot" src={snapshot} alt="">
            <span>Use this one</span>
          </button>
        {/if}
      </div>
    </div>

    <div class="col">
      <div class="content">
        {#if !$user.name}
          <p class="intro">Please use your real, full name</p>
          <label for="member-name">Name</label>
          <input type="text" id="member-name" bind:value={$user.name} on:change={setUpdated}>
        {:else if !host}
          <p class="intro">Hello { $user.first_name }. We’d love to learn a little more about you</p>
        {/if}
        <label for="member-role">Role</label>
        <input type="text" id="member-role" bind:value={$user.job_title} on:change={setUpdated}>
        <label for="member-location">Location</label>
        <input type="text" id="member-location" bind:value={$user.location} on:change={setUpdated}>
        <label for="member-bio">Tell us something about yourself</label>
        <textarea id="member-bio" bind:value={$user.biography} on:change={setUpdated}></textarea>
        {#if !host}
          <p class="small-print">
            This event will be recorded and made available to watch on our website and used for marketing materials. By joining, you consent to being recorded.
          </p>
        {/if}
      </div>
      <footer>
        <button class="btn primary large arrow-right" on:click={joinThinkIn}>Continue</button>
      </footer>
    </div>
  </div>
</div>

<style>
  .entry {
    color: #fff;
    font-family: Pegasus,serif;
    text-align: left;
    width: 700px;
    max-width: 100%;
    margin: 20px auto 0;
  }
  .cols {
    padding: 0 20px;
  }
  .col:first-child {
    margin-bottom: 20px;
  }
  .device {
    width: 320px;
    max-width: 100%;
  }
  .camera {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 0 8px;
    background-color: rgba(0,0,0,.7);
    position: relative;
    transition: border-radius 300ms;
    overflow: hidden;
  }
  .camera img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .avatar {
    opacity: .4;
  }
  p {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }
  p.intro {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  p.small-print {
    font-family: Koopman;
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
  label {
    display: block;
    font-family: Koopman;
    font-size: 14px;
    opacity: .7;
  }
  .intro + label {
    margin-top: 24px;
  }
  input[type=text] {
    appearance: none;
    font: inherit;
    font-size: 20px;
    background: transparent;
    color: #fff;
    border: none;
    border-bottom: 1px solid rgba(255,255,255,.3);
    border-radius: 0;
    padding-bottom: 12px;
    margin-bottom: 18px;
    width: 100%;
    outline: none;
  }
  textarea {
    font-size: 20px;
    background: transparent;
    color: #fff;
    width: 100%;
    height: 90px;
    resize: none;
    border-bottom: 1px solid rgba(255,255,255,.3);
    outline: none;
  }
  footer {
    padding: 14px 0;
    display: flex;
  }
  button.primary {
    background-image: none;
    margin-right: 20px;
    height: 48px;
    border-radius: 24px;
  }
  .snapshot {
    margin: 12px 0 0;
    padding: 0;
    display: flex;
  }
  .buttons {
    max-width: 300px;
    position: relative;
  }
  .buttons button,
  .buttons > * {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    color: #fff;
    font-family: Koopman;
    font-size: 16px;
    background: rgba(0,0,0,.4);
    margin: 5px 0;
    padding: 12px;
  }
  .buttons img {
    width: 24px;
    height: 24px;
  }
   button[name=snapshot] {
    position: relative;
    padding-left: 45px;
    padding-right: 18px;
    width: 180px;
  }
  button[name=snapshot] img {
    position: absolute;
    top: 12px;
    left: 14px;
    margin: 0;
  }
  button[name=snapshot] img.snapshot {
    top: 2px;
    left: 2px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
  button[name=snapshot] input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  button[name=cancelsnapshot] {
    width: 105px;
  }
  @media (max-width: 599px) {
    .entry {
      margin-top: -20px;
    }
  }
  @media (min-width: 600px) {
    .entry {
      margin-top: 60px;
    }
    .cols {
      display: flex;
      padding: 0;
    }
    .col {
      width: 50%;
    }
    .content {
      min-height: 300px;
    }
    p.intro {
      font-size: 24px;
      line-height: 34px;
    }
 }
</style>
